import React from 'react';
import { useAppSelector } from '../Common/Hooks/reactReduxHooks';
import { FlexDiv, RFC } from '../Common/Components/FlexContainers';
import { Spacer } from '../Common/Components/Spacer';
import issuesMobile from '../Assets/Issues_Mobile.svg';
import issuesDesktop from '../Assets/Issues_Desktop.svg';
import { useTheme } from 'styled-components';
import { ThemeType, styled } from '../Contexts/ThemeGlobalAndCtxt';
import { useMediaQuery } from '@react-hook/media-query';
import { useHistory } from 'react-router-dom';
import { faHome } from '@fortawesome/pro-duotone-svg-icons';
import { selectAppType } from '../ReduxSlices/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NextSteps = () => {
    //@ts-ignore
    const theme: ThemeType = useTheme();
    let history = useHistory();
    const laptop = useMediaQuery(theme.breakpoints.mediaQueries.laptop);
    const appType = useAppSelector(selectAppType);

    return (
        <FlexDiv backgroundColor={theme.colors.white}>
            <RFC axis="column" align="center" justify="center" css="padding: 2.5rem 0; width: 100%;">
                <RFC
                    axis="column"
                    align="center"
                    css={
                        laptop
                            ? 'width: 60%; min-width: 500px; position: relative;'
                            : 'width: 100%; position: relative; padding: 1rem;'
                    }
                >
                    {appType === 'EAP' && (
                        <>
                            <h2>Next Steps</h2>
                            <div
                                className="fontAwesomeCircleWrapper"
                                tabIndex={0}
                                style={
                                    laptop
                                        ? { position: 'absolute', top: '-0.4rem', left: '0rem' }
                                        : { position: 'absolute', top: '1rem', left: '0rem' }
                                }
                                onClick={() => {
                                    history.push('/home');
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        history.push('/home');
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faHome} style={{ color: 'inherit' }} />
                            </div>
                            <Spacer spaceParam="large" />
                            <p>
                                Your playbook has been successfully created. This playbook has been sent to the In Case
                                of Crisis Online Portal to be published for download. See below for next steps.
                            </p>
                            <Spacer spaceParam="large" />
                            <Spacer spaceParam="large" />
                            <StyledRFC axis="column" align="center">
                                <RFC axis="row" justify="flex-start">
                                    <RFC axis="row" basis="20%" justify="flex-end">
                                        <img
                                            alt="Logo"
                                            src={issuesMobile}
                                            height={laptop ? 125 : 95}
                                            width={laptop ? 125 : 95}
                                        />
                                    </RFC>
                                    <Spacer spaceParam="large" />
                                    <Spacer spaceParam="large" />
                                    <RFC axis="column" basis="60%" align="flex-start">
                                        <h4>To view your playbook in the In Case of Crisis Mobile App</h4>
                                        <br />
                                        <p>
                                            Simply open the In Case of Crisis app on your mobile device and confirm the
                                            playbook download when prompted to do so.
                                        </p>
                                        <br />
                                        <p>
                                            If you have not yet installed the In Case of Crisis mobile app, then you may
                                            do the following:
                                        </p>
                                        <p>- Search for "In Case of Crisis" in your device's app store</p>
                                        <p>- Download and install the app</p>
                                        <p>- Log into your account</p>
                                        <p>- Accept the download of your new playbook</p>
                                    </RFC>
                                </RFC>
                                <Spacer spaceParam="large" />
                                <Spacer spaceParam="large" />
                                <RFC axis="row" justify="flex-start">
                                    <RFC axis="row" basis="20%" justify="flex-end">
                                        <img
                                            alt="Logo"
                                            src={issuesDesktop}
                                            height={laptop ? 125 : 95}
                                            width={laptop ? 125 : 95}
                                        />
                                    </RFC>
                                    <Spacer spaceParam="large" />
                                    <Spacer spaceParam="large" />
                                    <RFC axis="column" basis="60%" align="flex-start">
                                        <h4>To make changes to your playbook in the Online Portal</h4>
                                        <br />
                                        <p>
                                            If you would like to make further updates to your playbook, please return to
                                            the Online Portal at{' '}
                                            <a href="https://incaseofcrisis.com">incaseofcrisis.com</a>. Open your
                                            playbook from the home page and then publish your changes through the Online
                                            Portal.
                                        </p>
                                    </RFC>
                                </RFC>
                                <Spacer spaceParam="large" />
                                <Spacer spaceParam="large" />
                                <RFC axis="row" justify="flex-start">
                                    <RFC axis="row" basis="20%" justify="flex-end"></RFC>
                                    <Spacer spaceParam="large" />
                                    <Spacer spaceParam="large" />
                                    <RFC axis="column" basis="60%" align="flex-start">
                                        <p>
                                            Questions? Contact your dedicated Client Success Manager or email{' '}
                                            <a href="mailto: support@incaseofcrisis.com">support@incaseofcrisis.com</a>.
                                        </p>
                                    </RFC>
                                </RFC>
                            </StyledRFC>
                        </>
                    )}
                    {appType === 'Advisory' && (
                        <>
                            <h2>Next Steps</h2>
                            <div
                                className="fontAwesomeCircleWrapper"
                                tabIndex={0}
                                style={{ position: 'absolute', top: '-0.4rem', left: '0rem' }}
                                onClick={() => {
                                    history.push('/home');
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        history.push('/home');
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faHome} style={{ color: 'inherit' }} />
                            </div>
                            <Spacer spaceParam="large" />
                            <p>
                                Your advisory playbook has been successfully created. This playbook has been sent to the
                                In Case of Crisis Online Portal to be published for download and it will also be
                                assigned to issue stakeholders. See below for next steps.
                            </p>
                            <Spacer spaceParam="large" />
                            <Spacer spaceParam="large" />
                            <StyledRFC axis="column" align="center">
                                <RFC axis="column" flip alignMobile="center" justify="flex-start">
                                    <RFC axis="row" basis="20%" alignMobile="flex-end" justifyLaptop="flex-end">
                                        <img
                                            alt="Logo"
                                            src={issuesDesktop}
                                            height={laptop ? 125 : 95}
                                            width={laptop ? 125 : 95}
                                        />
                                    </RFC>
                                    <Spacer spaceParam="large" />
                                    {laptop && <Spacer spaceParam="large" />}
                                    <RFC axis="column" basis="60%" align="flex-start">
                                        <h4>To view your advisory playbook in a web browser</h4>
                                        <br />
                                        <p>
                                            Once the playbook has been assigned to you (either from the issue
                                            Stakeholder Details panel or from the Online Portal), simply open the top
                                            left menu and select the Playbooks Listing option. Your new advisory
                                            playbook will be included in the Playbook Listing.
                                        </p>
                                    </RFC>
                                </RFC>
                                <Spacer spaceParam="large" />
                                <Spacer spaceParam="large" />
                                <RFC axis="column" flip alignMobile="center" justify="flex-start">
                                    <RFC axis="row" basis="20%" alignMobile="flex-end" justifyLaptop="flex-end">
                                        <img
                                            alt="Logo"
                                            src={issuesMobile}
                                            height={laptop ? 125 : 95}
                                            width={laptop ? 125 : 95}
                                        />
                                    </RFC>
                                    <Spacer spaceParam="large" />
                                    {laptop && <Spacer spaceParam="large" />}
                                    <RFC axis="column" basis="60%" align="flex-start">
                                        <h4>To view your advisory playbook in the In Case of Crisis Mobile App</h4>
                                        <br />
                                        <p>
                                            Once the playbook has been assigned to you (either from the issue
                                            Stakeholder Details panel or from the Online Portal), simply open the In
                                            Case of Crisis app on your mobile device and confirm the playbook download
                                            when prompted to do so.
                                        </p>
                                        <br />
                                        <p>
                                            If you have not yet installed the In Case of Crisis mobile app, then you may
                                            do the following:
                                        </p>
                                        <p>- Search for "In Case of Crisis" in your device's app store</p>
                                        <p>- Download and install the app</p>
                                        <p>- Log into your account</p>
                                        <p>- Accept the download of your new playbook</p>
                                    </RFC>
                                </RFC>
                                <Spacer spaceParam="large" />
                                <Spacer spaceParam="large" />
                                <RFC axis="column" flip alignMobile="center" justify="flex-start">
                                    <RFC axis="row" basis="20%" justify="flex-end"></RFC>
                                    <Spacer spaceParam="large" />
                                    {laptop && <Spacer spaceParam="large" />}
                                    <RFC axis="column" basis="60%" align="flex-start">
                                        <h4>To make changes to your advisory playbook in the Advisory Tool</h4>
                                        <p>
                                            If you would like to make further updates to your advisory playbook, please
                                            return to the corresponding issue and open the Stakeholder/Influencer
                                            Details panel. From there you will be able to revisit the Advisory Tool,
                                            make edits to any of your selections, data, and files, and then regenerate
                                            your advisory playbook. Please note that if any edits have been made to your
                                            advisory playbook from within the Online Portal, those edits will be
                                            overwritten once the playbook is regenerated.
                                        </p>
                                    </RFC>
                                </RFC>
                                <Spacer spaceParam="large" />
                                <Spacer spaceParam="large" />
                                <RFC axis="column" flip alignMobile="center" justify="flex-start">
                                    <RFC axis="row" basis="20%" justify="flex-end"></RFC>
                                    <Spacer spaceParam="large" />
                                    {laptop && <Spacer spaceParam="large" />}
                                    <RFC axis="column" basis="60%" align="flex-start">
                                        <p>
                                            Questions? Contact your dedicated Client Success Manager or email{' '}
                                            <a href="mailto: support@incaseofcrisis.com">support@incaseofcrisis.com</a>.
                                        </p>
                                    </RFC>
                                </RFC>
                            </StyledRFC>
                        </>
                    )}
                </RFC>
            </RFC>
        </FlexDiv>
    );
};

const StyledRFC = styled(RFC)`
    background-color: ${({ theme }) => theme.colors.blueGray[10]};
    width: '80%';
    padding: 1rem;
`;
