import React, { useState, useEffect, MutableRefObject, useRef } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { useLocation, useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ThemeType } from '../../Contexts/ThemeGlobalAndCtxt';

import { useAppDispatch, useAppSelector } from './reactReduxHooks';
import { RootState } from '../../store';
import { selectUnSavedFields, selectAPIGetStatus } from '../../ReduxSlices/userSlice';
import { selectSectionStatuses } from '../../ReduxSlices/sectionProgressSlice';
import { selectFormsDataPostStatus, resetFormsDataPostStatus, purgeEmptyNodes } from '../../ReduxSlices/formsData';

// Hook
export function useOnScreen<T extends Element>(ref: MutableRefObject<T>, rootMargin: string = '0px'): boolean {
    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState<boolean>(false);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when observer callback fires
                setIntersecting(entry.isIntersecting);
            },
            {
                rootMargin,
            },
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        let reactNodeRef = ref.current;
        return () => {
            observer.unobserve(reactNodeRef);
        };
    }, [ref, rootMargin]); // Empty array ensures that effect is only run on mount and unmount
    return isIntersecting;
}

const initBeforeUnLoad = (showExitPrompt) => {
    window.onbeforeunload = (event: any) => {
        if (showExitPrompt) {
            const e = event || window.event;
            e.preventDefault();
            if (e) {
                e.returnValue = '';
            }
            return '';
        }
    };
};

export const useExitPrompt = (bool: boolean): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
    const [showExitPrompt, setShowExitPrompt] = useState(bool);

    window.onload = function () {
        initBeforeUnLoad(showExitPrompt);
    };

    useEffect(() => {
        initBeforeUnLoad(showExitPrompt);
    }, [showExitPrompt]);

    return [showExitPrompt, setShowExitPrompt];
};

export const useSectionsQualityOfLife = () => {
    const dispatch = useAppDispatch();
    const apiGetStatus = useAppSelector(selectAPIGetStatus);
    const apiPostStatus = useAppSelector(selectFormsDataPostStatus);
    const timerID = useRef<NodeJS.Timeout>();
    const unSavedFields = useAppSelector(selectUnSavedFields);
    //@ts-ignore
    const theme: ThemeType = useTheme();
    const laptop = useMediaQuery(theme.breakpoints.mediaQueries.laptop);
    const sections = useAppSelector((state: RootState) => {
        return state.sectionProgress.sections;
    });
    const history = useHistory();
    const currentUrl = useLocation().pathname;
    const sectionStatuses = useAppSelector(selectSectionStatuses);
    const sectionIndex = sections.findIndex((section) => section.routerURL === '/' + currentUrl.split('/')[1]);
    const subSectionIndex = sections[sectionIndex].subSections?.findIndex(
        (subSection) => subSection.routerURL === currentUrl,
    );
    const subSectionTitle = useAppSelector((state: RootState) => {
        return state.sectionProgress.sections[sectionIndex].subSections[subSectionIndex].subSectionTitle;
    });

    useEffect(() => {
        if (apiPostStatus === 'succeeded') {
            timerID.current = setTimeout(() => {
                dispatch(resetFormsDataPostStatus());
                //TODO logic to navigate to the next page not current, nextSteps if build playbook, and home if alt step
                let nextUrl: string = '';
                if (sections[sectionIndex].subSections[subSectionIndex].nextURL) {
                    //@ts-ignore
                    nextUrl = sections[sectionIndex].subSections[subSectionIndex].nextURL;
                } else {
                    nextUrl = '/home';
                }
                history.push(nextUrl);
            }, 1000);
        }
    }, [apiPostStatus, history, sections, dispatch, sectionIndex, subSectionIndex]);

    useEffect(() => {
        return () => {
            dispatch(purgeEmptyNodes());
            dispatch(resetFormsDataPostStatus());
        };
    }, [dispatch]);

    return {
        sectionIndex: sectionIndex,
        subSectionIndex: subSectionIndex,
        subSectionTitle: subSectionTitle,
        dispatch: dispatch,
        apiGetStatus: apiGetStatus,
        apiPostStatus: apiPostStatus,
        unSavedFields: unSavedFields,
        theme: theme,
        laptop: laptop,
        sections: sections,
        history: history,
        currentUrl: currentUrl,
        sectionStatus: sections[sectionIndex].subSections[subSectionIndex].status,
        sectionStatuses: sectionStatuses,
        timerID: timerID.current,
    };
};
