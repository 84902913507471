import React, { useEffect, useState, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { ThemeType } from '../Contexts/ThemeGlobalAndCtxt';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ServerStyleSheet } from 'styled-components';
import { useAppSelector, useAppDispatch } from '../Common/Hooks/reactReduxHooks';
import { DataGrid, GridRowsProp, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { ThemeContextProvider, ThemeGlobalStyle } from '../Contexts/ThemeGlobalAndCtxt';

// Individual components for styling
import { Spacer } from '../Common/Components/Spacer';
import { FlexDiv, RFC } from '../Common/Components/FlexContainers';
import { FormWrapper } from '../Common/Components/FormWrapper';
import { StyledBackdrop, StyledPromptForm } from '../Common/Components/CommonStyled';
import {
    getEAPStateFromDB,
    selectAppData,
    updateShared,
    selectAPIGetStatus,
    updatePubTitle,
    selectUserEmail,
} from '../ReduxSlices/userSlice';
import { updateSectionProgress, section } from '../ReduxSlices/sectionProgressSlice';
import {
    setEAPDataToDB,
    selectFormsData,
    IformsData,
    resetFormsDataPostStatus,
    updateFormsDataNode,
    selectFormsDataPostStatus,
} from '../ReduxSlices/formsData';
import { EAPReviewPDF } from './EAPReview';
import {
    selectListings,
    updateCurrentListingID,
    updateCurrentListingName,
    checkListingUniqueName,
    getListingsFromDB,
    resetUniqueNameStatus,
    resetListingApiStatus,
} from '../ReduxSlices/listingsSlice';
import dayjs from 'dayjs';
import Slide from '@mui/material/Slide';
import type { RootState } from '../store';
import { useMediaQuery } from '@react-hook/media-query';

export const Listings = () => {
    // @ts-ignore
    const theme: ThemeType = useTheme();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const listings = useAppSelector(selectListings);
    const fetchedSourceData = useRef(false);
    const apiGetStatus = useAppSelector(selectAPIGetStatus);
    const mediaTablet = useMediaQuery(theme.breakpoints.mediaQueries.tablet);
    const formsDataPostStatus = useAppSelector(selectFormsDataPostStatus);
    const appData = useAppSelector(selectAppData);
    const [listingNamePromptToggled, setListingNamePromptToggled] = useState<boolean>(false);
    const [listingNamePrompt, setListingNamePrompt] = useState<string>('');
    const [editWarning, setEditWarning] = useState(false);
    const sourceListingID = useRef(0);
    const sourceListingName = useRef('');
    const sections = useAppSelector((state: RootState) => {
        return state.sectionProgress.sections;
    });
    const reportHtml = useRef('');
    const reportName = useRef('');
    const localStorageID = useRef<number>(0);
    const pdfForm = useRef<HTMLFormElement | null>();
    const [pdfPost, setPdfPost] = useState(false);
    const sortUnpublished = useRef<'asc' | 'desc'>('asc');

    const formsData = useAppSelector(selectFormsData);

    const userEmail = useAppSelector(selectUserEmail);

    useEffect(() => {
        if (listings.status === 'idle') {
            dispatch(getListingsFromDB());
        } else if (listings.status === 'succeeded') {
            localStorageID.current =
                //@ts-ignore
                localStorage.getItem('listingID') === null ? 0 : parseInt(localStorage.getItem('listingID'));
        }
    }, [dispatch, listings.status]);

    useEffect(() => {
        if (pdfPost) {
            pdfForm.current?.submit();
            setPdfPost(false);
        }
    }, [pdfPost]);

    //need to adjust the double build for building a new playbook
    useEffect(() => {
        if (listings.uniqueName.status === 'succeeded' && !fetchedSourceData.current) {
            dispatch(getEAPStateFromDB(sourceListingID.current));
            fetchedSourceData.current = true;
        } else if (
            listings.uniqueName.status === 'succeeded' &&
            fetchedSourceData.current === true &&
            apiGetStatus === 'succeeded'
        ) {
            if (formsDataPostStatus === 'idle') {
                dispatch(updateCurrentListingName(listingNamePrompt));
                dispatch(updateCurrentListingID(0));
                dispatch(updatePubTitle(''));
                dispatch(updateShared('N'));
                dispatch(updateSectionProgress({ sectionIndex: 2, subSectionIndex: 0, status: 'not started' }));
                dispatch(updateSectionProgress({ sectionIndex: 3, subSectionIndex: 0, status: 'not started' }));
                dispatch(
                    updateFormsDataNode({
                        node: 'playbookInfo',
                        data: { playbookName: listingNamePrompt, publishDate: '' },
                    }),
                );
                dispatch(setEAPDataToDB('all'));
            } else if (formsDataPostStatus === 'succeeded') {
                history.push('/home');
            }
        }
    }, [listings.uniqueName.status, listingNamePrompt, dispatch, history, apiGetStatus, formsDataPostStatus]);

    useEffect(() => {
        return () => {
            dispatch(resetListingApiStatus());
            dispatch(resetFormsDataPostStatus());
            dispatch(resetUniqueNameStatus());
        };
    }, [dispatch]);

    useEffect(() => {
        if (editWarning) {
            document.getElementById('editWarningCancel')?.focus();
        } else if (listingNamePromptToggled) {
            document.getElementById('listingNameInput')?.focus();
        } else {
            setTimeout(() => document.getElementById('newListing')?.focus(), 100);
        }
    }, [editWarning, listingNamePromptToggled]);

    const columns: GridColDef[] = [
        {
            field: 'col1',
            headerName: 'Playbook Name',
            flex: 1.5,
            minWidth: 170,
            sortComparator: (v1, v2) => (v1 ? (v2 ? v1['listingName'].localeCompare(v2['listingName']) : 0) : 0),
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div
                        className="DataGrid-cellDiv"
                        style={
                            params.value
                                ? localStorageID.current === params.value['listingID']
                                    ? { position: 'relative', left: '-7px' }
                                    : {}
                                : {}
                        }
                    >
                        <div
                            style={
                                params.value
                                    ? localStorageID.current === params.value['listingID']
                                        ? { borderLeft: `solid 3px ${theme.colors.yellow[5]}`, paddingLeft: '4px' }
                                        : {}
                                    : {}
                            }
                        >
                            {params.value
                                ? params.value[`pubTitle`]
                                    ? params.value[`pubTitle`]
                                    : params.value[`listingName`]
                                : ''}
                            <br />
                            {params.value ? params.value['orgName'] : ''}
                        </div>
                    </div>
                );
            },
        },
        {
            field: 'col2',
            headerName: 'Last Edited in Tool',
            flex: 1,
            minWidth: 50,
            sortComparator: (v1, v2) =>
                v1 ? (v2 ? (dayjs(v1['dateString']).isBefore(dayjs(v2['dateString'])) ? 1 : -1) : 0) : 0,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) =>
                params.value && params.value[`dateString`] ? (
                    <div className="DataGrid-cellDiv">
                        {params.value ? dayjs(params.value['dateString']).format('DD-MMM-YYYY').toString() : ''}
                        <br />
                        {params.value ? params.value['editedBy'] : ''}
                    </div>
                ) : (
                    <div className="DataGrid-cellDiv">Unsaved</div>
                ),
        },
        {
            field: 'col3',
            headerName: 'Status in Portal',
            flex: 1,
            minWidth: 170,
            sortComparator: (v1, v2) => {
                let beforeAfterNA: number = 0;
                if (v1 && v2) {
                    if (v1['dateString'] && !v2['dateString']) {
                        beforeAfterNA = sortUnpublished.current === 'asc' ? -1 : 1;
                    } else if (!v1['dateString'] && v2['dateString']) {
                        beforeAfterNA = sortUnpublished.current === 'asc' ? 1 : -1;
                    } else if (v1['dateString'] && v2['dateString']) {
                        beforeAfterNA = dayjs(v1['dateString']).isBefore(dayjs(v2['dateString']))
                            ? 1
                            : dayjs(v2['dateString']).isBefore(dayjs(v1['dateString']))
                            ? -1
                            : 0;
                    }
                }
                return beforeAfterNA;
            },
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <div className="DataGrid-cellDiv">
                    {params.value ? params.value[`status`] : ''}
                    <br />
                    {params.value ? (
                        params.value['dateString'] ? (
                            <>
                                {`Edited: ` + dayjs(params.value['dateString']).format('DD-MMM-YYYY').toString()}
                                <br />
                            </>
                        ) : (
                            ''
                        )
                    ) : (
                        ''
                    )}
                    {params.value ? params.value['editedBy'] ? <>{`By: ` + params.value['editedBy']}</> : '' : ''}
                </div>
            ),
        },

        {
            field: 'col4',
            headerName: 'Shared to Sub-orgs?',
            flex: 1,
            minWidth: 100,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <div className="DataGrid-cellDiv">{params.value ? params.value[`shared`] : ''}</div>
            ),
        },
        {
            field: 'col5',
            headerName: 'Actions',
            flex: 2,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <RFC axis="row" justify="flex-start" className="DataGrid-cellDiv">
                    <RFC
                        axis="column"
                        justifyLaptop="flex-start"
                        basis="15%"
                        justifyMobile="center"
                        alignMobile="flex-start"
                        flip={true}
                        breakpoint={1200}
                    >
                        {appData.returnData['curOrgString'] ===
                            (params.value ? params.value['planOriginalOrg'] : '') && (
                            <>
                                <button
                                    className="button-secondary button-secondary-darker"
                                    onClick={() => {
                                        if (params.value && params.value['planEdited']) {
                                            sourceListingID.current = params.value
                                                ? parseInt(params.value['listingID'])
                                                : 0;
                                            sourceListingName.current = params.value
                                                ? params.value['pubTitle']
                                                    ? params.value['pubTitle']
                                                    : params.value['listingName']
                                                : '';
                                            setEditWarning(true);
                                        } else {
                                            dispatch(
                                                updateCurrentListingID(params.value ? params.value['listingID'] : 0),
                                            );
                                            dispatch(
                                                updateCurrentListingName(
                                                    params.value
                                                        ? params.value['pubTitle']
                                                            ? params.value['pubTitle']
                                                            : params.value['listingName']
                                                        : '',
                                                ),
                                            );
                                            dispatch(getEAPStateFromDB(params.value ? params.value['listingID'] : 0));
                                            history.push('/home');
                                        }
                                    }}
                                >
                                    Edit
                                </button>
                                <Spacer spaceParam="medium" />
                            </>
                        )}
                        <button
                            className="button-secondary button-secondary-darker"
                            onClick={() => {
                                sourceListingID.current = params.value ? parseInt(params.value['listingID']) : 0;
                                setListingNamePromptToggled(true);
                            }}
                        >
                            Create From
                        </button>
                    </RFC>
                    {params.value && params.value[`orgName`] === appData.returnData['curOrgString'] && (
                        <RFC axis="row" justify="flex-start" basis="15%">
                            <Spacer spaceParam="medium" />
                            <button
                                className="button-tertiary button-tertiary-darker"
                                onClick={() => {
                                    reportName.current = params.value ? params.value['listingName'] : 'EAP_Export';
                                    reportHtml.current = RenderElementToString({
                                        formsData: formsData,
                                        sections: sections,
                                        metaData: {
                                            Org: params.value ? params.value['orgName'] : 'No Org Name Provided',
                                            lastEdited: params.value
                                                ? dayjs(params.value['lastEdited']).format('DD-MMM-YYYY').toString()
                                                : 'No Date Provided',
                                            EditedBy: params.value ? params.value['EditedBy'] : 'No Editor Provided',
                                        },
                                    });

                                    setPdfPost(true);
                                }}
                            >
                                Print PDF
                            </button>
                        </RFC>
                    )}
                </RFC>
            ),
        },
    ];

    const mobileColumns: GridColDef[] = [
        {
            field: 'col1',
            headerName: 'Playbook Name',
            flex: 1.5,
            minWidth: 170,
            sortComparator: (v1, v2) => (v1 ? (v2 ? v1['listingName'].localeCompare(v2['listingName']) : 0) : 0),
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div
                        className="DataGrid-cellDiv"
                        style={
                            params.value
                                ? localStorageID.current === params.value['listingID']
                                    ? { position: 'relative', left: '-7px' }
                                    : {}
                                : {}
                        }
                    >
                        <div
                            style={
                                params.value
                                    ? localStorageID.current === params.value['listingID']
                                        ? { borderLeft: `solid 3px ${theme.colors.yellow[5]}`, paddingLeft: '4px' }
                                        : {}
                                    : {}
                            }
                        >
                            {params.value
                                ? params.value[`pubTitle`]
                                    ? params.value[`pubTitle`]
                                    : params.value[`listingName`]
                                : ''}
                            <br />
                            {params.value ? params.value['orgName'] : ''}
                        </div>
                    </div>
                );
            },
        },

        {
            field: 'col3',
            headerName: 'Status in Portal',
            flex: 1,
            minWidth: 170,
            sortComparator: (v1, v2) => {
                let beforeAfterNA: number = 0;
                if (v1 && v2) {
                    if (v1['dateString'] && !v2['dateString']) {
                        beforeAfterNA = sortUnpublished.current === 'asc' ? -1 : 1;
                    } else if (!v1['dateString'] && v2['dateString']) {
                        beforeAfterNA = sortUnpublished.current === 'asc' ? 1 : -1;
                    } else if (v1['dateString'] && v2['dateString']) {
                        beforeAfterNA = dayjs(v1['dateString']).isBefore(dayjs(v2['dateString']))
                            ? 1
                            : dayjs(v2['dateString']).isBefore(dayjs(v1['dateString']))
                            ? -1
                            : 0;
                    }
                }
                return beforeAfterNA;
            },
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <div className="DataGrid-cellDiv">
                    {params.value ? params.value[`status`] : ''}
                    <br />
                    {params.value ? (
                        params.value['dateString'] ? (
                            <>
                                {`Edited: ` + dayjs(params.value['dateString']).format('DD-MMM-YYYY').toString()}
                                <br />
                            </>
                        ) : (
                            ''
                        )
                    ) : (
                        ''
                    )}
                    {params.value ? params.value['editedBy'] ? <>{`By: ` + params.value['editedBy']}</> : '' : ''}
                </div>
            ),
        },
        {
            field: 'col5',
            headerName: 'Actions',
            flex: 2,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <RFC axis="row" justify="flex-start" className="DataGrid-cellDiv">
                    <RFC
                        axis="column"
                        justifyLaptop="flex-start"
                        basis="15%"
                        justifyMobile="center"
                        alignMobile="flex-start"
                        flip={true}
                        breakpoint={1200}
                    >
                        {appData.returnData['curOrgString'] ===
                            (params.value ? params.value['planOriginalOrg'] : '') && (
                            <>
                                <button
                                    className="button-secondary button-secondary-darker"
                                    onClick={() => {
                                        if (params.value && params.value['planEdited']) {
                                            sourceListingID.current = params.value
                                                ? parseInt(params.value['listingID'])
                                                : 0;
                                            sourceListingName.current = params.value
                                                ? params.value['pubTitle']
                                                    ? params.value['pubTitle']
                                                    : params.value['listingName']
                                                : '';
                                            setEditWarning(true);
                                        } else {
                                            dispatch(
                                                updateCurrentListingID(params.value ? params.value['listingID'] : 0),
                                            );
                                            dispatch(
                                                updateCurrentListingName(
                                                    params.value
                                                        ? params.value['pubTitle']
                                                            ? params.value['pubTitle']
                                                            : params.value['listingName']
                                                        : '',
                                                ),
                                            );
                                            dispatch(getEAPStateFromDB(params.value ? params.value['listingID'] : 0));
                                            history.push('/home');
                                        }
                                    }}
                                >
                                    Edit
                                </button>
                                <Spacer spaceParam="medium" />
                            </>
                        )}
                        <button
                            className="button-secondary button-secondary-darker"
                            onClick={() => {
                                sourceListingID.current = params.value ? parseInt(params.value['listingID']) : 0;
                                setListingNamePromptToggled(true);
                            }}
                        >
                            Create From
                        </button>
                    </RFC>
                    {params.value && params.value[`orgName`] === appData.returnData['curOrgString'] && (
                        <RFC axis="row" justify="flex-start" basis="15%">
                            <Spacer spaceParam="medium" />
                            <button
                                className="button-tertiary button-tertiary-darker"
                                onClick={() => {
                                    reportName.current = params.value ? params.value['listingName'] : 'EAP_Export';
                                    reportHtml.current = RenderElementToString({
                                        formsData: formsData,
                                        sections: sections,
                                        metaData: {
                                            Org: params.value ? params.value['orgName'] : 'No Org Name Provided',
                                            lastEdited: params.value
                                                ? dayjs(params.value['lastEdited']).format('DD-MMM-YYYY').toString()
                                                : 'No Date Provided',
                                            EditedBy: params.value ? params.value['EditedBy'] : 'No Editor Provided',
                                        },
                                    });

                                    setPdfPost(true);
                                }}
                            >
                                Print PDF
                            </button>
                        </RFC>
                    )}
                </RFC>
            ),
        },
    ];

    const rows: GridRowsProp = listings.listings.map((listing, index) => {
        return {
            id: index,
            col1: {
                listingName: listing.listingName,
                pubTitle: listing.pubTitle,
                orgName: listing.orgName,
                listingID: listing.listingID,
            },
            col2: {
                dateString: listing.listingEditedDate,
                editedBy: listing.listingEditedBy,
            },
            col3: {
                status: listing.pubID ? 'Published' : 'Unpublished',
                dateString: listing.planEditedDate,
                editedBy: listing.planEditedBy,
            },
            col4: {
                shared: listing.shared === 'Y' ? 'Yes' : 'No',
            },
            col5: {
                listingID: listing.listingID,
                listingName: listing.listingName,
                pubTitle: listing.pubTitle,
                planOriginalOrg: listing.orgName,
                orgName: listing.orgName,
                lastEdited: listing.listingEditedDate,
                EditedBy: listing.listingEditedBy,
                planEdited: listing.planEditedDate,
                planEditedBy: listing.planEditedBy,
            },
        };
    });

    return (
        <FlexDiv backgroundColor={theme.colors.white}>
            <RFC axis="row" justify="center">
                <RFC axis="column" basis={'90%'} justify={mediaTablet ? 'center' : 'flex-start'}>
                    <Spacer spaceParam="large" />
                    <h2>Playbook Builder Listings</h2>
                    <Spacer spaceParam="large" />
                    <FlexDiv style={{ overflowX: 'scroll', boxSizing: 'border-box' }}>
                        <DataGrid
                            rows={rows}
                            rowHeight={70}
                            columns={mediaTablet ? columns : mobileColumns}
                            autoHeight
                            loading={listings.status === 'pending'}
                            hideFooter
                            style={mediaTablet ? { minWidth: theme.breakpoints.deviceSizes.laptop } : {}}
                            onSortModelChange={(model: GridSortModel) => {
                                if (model[0] && model[0]['field'] && model[0]['field'] === 'col3' && model[0]['sort']) {
                                    sortUnpublished.current = model[0]['sort'];
                                }
                            }}
                        />
                    </FlexDiv>
                    <Spacer spaceParam="large" />
                    <button
                        id="newListing"
                        tabIndex={2}
                        onClick={() => {
                            sourceListingID.current = 0;
                            setListingNamePromptToggled(true);
                        }}
                    >
                        Create New EAP Entry
                    </button>
                </RFC>
                {listingNamePromptToggled && (
                    <StyledBackdrop onClick={() => setListingNamePromptToggled((prev) => !prev)} />
                )}
                <Slide in={listingNamePromptToggled} direction="down" mountOnEnter unmountOnExit>
                    <StyledPromptForm>
                        <FormWrapper
                            formWidth="100%"
                            formMinWidth="350px"
                            formMaxWidth="500px"
                            inputWidth="80%"
                            status={listings.uniqueName.status}
                            onSubmit={(e) => {
                                e.preventDefault();
                                dispatch(
                                    checkListingUniqueName({
                                        listingID: 0,
                                        listingName: listingNamePrompt,
                                        userEmail: userEmail,
                                    }),
                                );
                            }}
                        >
                            <RFC axis="column" align="center" css="padding: 0.8rem;">
                                <h4>Please provide new EAP listing name</h4>
                                <label>(this can be changed later)</label>
                                <Spacer spaceParam="large" />
                                <input
                                    id="listingNameInput"
                                    tabIndex={1}
                                    type="text"
                                    disabled={
                                        listings.uniqueName.status === 'pending' ||
                                        listings.uniqueName.status === 'succeeded'
                                    }
                                    value={listingNamePrompt}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setListingNamePrompt(e.target.value);
                                    }}
                                />
                                <Spacer spaceParam="large" />
                                <button
                                    id="listingNameConfirm"
                                    tabIndex={1}
                                    onBlur={() => {
                                        if (listingNamePromptToggled) {
                                            document.getElementById('listingNameInput')?.focus();
                                        }
                                    }}
                                >
                                    Submit
                                </button>
                                {listings.uniqueName.errorMessage && <p>{listings.uniqueName.errorMessage}</p>}
                            </RFC>
                        </FormWrapper>
                    </StyledPromptForm>
                </Slide>
                {editWarning && <StyledBackdrop onClick={() => setEditWarning((prev) => !prev)} />}
                <Slide in={editWarning} direction="down" mountOnEnter unmountOnExit>
                    <StyledPromptForm>
                        <FormWrapper
                            formWidth="100%"
                            formMinWidth="350px"
                            formMaxWidth="500px"
                            inputWidth="80%"
                            status={listings.uniqueName.status}
                            onSubmit={(e) => {
                                e.preventDefault();
                                dispatch(updateCurrentListingID(sourceListingID.current));
                                dispatch(updateCurrentListingName(sourceListingName.current));
                                dispatch(getEAPStateFromDB(sourceListingID.current));
                                history.push('/home');
                            }}
                        >
                            <RFC axis="column" align="center" css="padding: 0.8rem;">
                                <p style={{ fontSize: '1rem' }}>
                                    Are you sure you want to make changes to this EAP? Once built, any updates you have
                                    previously made to this playbook on the Online Portal will be lost.
                                </p>
                                <Spacer spaceParam="large" />
                                <RFC axis="row" justify="center">
                                    <button
                                        id="editWarningCancel"
                                        tabIndex={1}
                                        className="button-secondary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setEditWarning(false);
                                        }}
                                        onBlur={() => {
                                            if (editWarning) {
                                                document.getElementById('editWarningConfirm')?.focus();
                                            }
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <Spacer spaceParam="medium" />
                                    <button
                                        id="editWarningConfirm"
                                        tabIndex={1}
                                        onBlur={() => {
                                            if (editWarning) {
                                                document.getElementById('editWarningCancel')?.focus();
                                            }
                                        }}
                                    >
                                        Continue
                                    </button>
                                </RFC>
                            </RFC>
                        </FormWrapper>
                    </StyledPromptForm>
                </Slide>
            </RFC>
            <form
                method="post"
                action="api/export/pdfform"
                target="_blank"
                encType="multipart/form-data"
                ref={(el) => (pdfForm.current = el)}
                style={{ boxShadow: 'none' }}
            >
                <input type="hidden" name="html" value={window.btoa(encodeURIComponent(reportHtml.current))} />
                <input type="hidden" name="name" value={reportName.current} />
            </form>
        </FlexDiv>
    );
};

const RenderElementToString = ({
    formsData,
    sections,
    metaData,
}: {
    formsData: IformsData['EAPdata'];
    sections: Array<section>;
    metaData: { Org: string; EditedBy: string; lastEdited: string };
}) => {
    const sheet = new ServerStyleSheet();

    let html: string = '';
    let styleTags: string = '';
    try {
        html = renderToString(
            sheet.collectStyles(
                <ThemeContextProvider>
                    <ThemeGlobalStyle />
                    <EAPReviewPDF formsData={formsData} sections={sections} metaData={metaData} />
                </ThemeContextProvider>,
            ),
        );
        styleTags = sheet.getStyleTags(); // or sheet.getStyleElement();
        // styleTags = styleTags.slice(0, styleTags.length - 8)
        // Object.entries(document.styleSheets).forEach(stylesheet => {
        //     Object.entries(stylesheet[1]['cssRules']).forEach(cssRule => {
        //         styleTags = styleTags + cssRule[1]['cssText']
        //     })
        // })
        // styleTags = styleTags + '</style>'
    } catch (error) {
        // handle error
        console.error(error);
    } finally {
        sheet.seal();
    }
    let htmlAndStyle = '<div>' + html + styleTags + '</div>';
    return htmlAndStyle;
};
