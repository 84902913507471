import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ThemeType } from '../Contexts/ThemeGlobalAndCtxt';
import { useMediaQuery } from '@react-hook/media-query';
import { useAppSelector } from '../Common/Hooks/reactReduxHooks';
import { selectAppType, selectUnSavedFields } from '../ReduxSlices/userSlice';
import type { RootState } from '../store';

import { Stepper } from '../Common/Components/Stepper';
import { Spacer } from '../Common/Components/Spacer';
import { FlexDiv, RFC } from '../Common/Components/FlexContainers';
import { StyledSnackbarConfirmButton } from '../Common/Components/CommonStyled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-duotone-svg-icons';
import Snackbar from '@mui/material/Snackbar';

interface IProps {
    sectionIndex: number;
    childrenComponents: Array<() => JSX.Element>;
}
export const SubSectionsWrapper = ({ sectionIndex, childrenComponents }: IProps) => {
    const urlLocation = useLocation();
    const [activeStep, setActiveStep] = useState(parseInt(urlLocation.pathname.slice(-1)) - 1);
    const [snackbarWarningStep, setSnackbarWarningStep] = useState<number>(0);
    const [snackbarWarningHome, setSnackbarWarningHome] = useState<boolean>(false);
    //@ts-ignore
    const theme: ThemeType = useTheme();
    const laptop = useMediaQuery(theme.breakpoints.mediaQueries.laptop);
    const history = useHistory();
    const appType = useAppSelector(selectAppType);
    const section = useAppSelector((state: RootState) => {
        return state.sectionProgress.sections[sectionIndex];
    });
    const unSavedFields = useAppSelector(selectUnSavedFields);
    const subSections = section.subSections;

    let steps: Array<{ label: string; status: boolean | 'disabled' }> = [];

    subSections.forEach((subSection, index) => {
        steps.push({
            label: subSection.subSectionTitle,
            status:
                appType === 'Advisory' &&
                section.subSections.length - 1 === index &&
                section.subSections[section.subSections.length - 2].status === 'not started'
                    ? 'disabled'
                    : subSection.status === 'not started'
                    ? false
                    : true,
        });
    });

    const handleStepperClick = (i: number) => {
        if (i !== activeStep) {
            if (unSavedFields) {
                setSnackbarWarningStep(i + 1);
            } else {
                setActiveStep(i);
                history.push(`${section.subSections[i].routerURL}`);
            }
        }
    };

    useEffect(() => {
        setActiveStep(subSections.findIndex((subSection) => subSection.routerURL === urlLocation.pathname));
    }, [urlLocation.pathname, subSections]);

    return (
        <>
            <RFC
                axis="column"
                flip
                basis="0%"
                css={`
                    background-color: ${theme.colors.white};
                `}
            >
                {appType === 'EAP' && (
                    <RFC axis="row" basis="0">
                        <div
                            className="fontAwesomeCircleWrapper"
                            tabIndex={0}
                            onClick={() => {
                                if (unSavedFields) {
                                    setSnackbarWarningHome(true);
                                } else {
                                    history.push('/home');
                                }
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    if (unSavedFields) {
                                        setSnackbarWarningHome(true);
                                    } else {
                                        history.push('/home');
                                    }
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faHome} style={{ color: 'inherit' }} />
                        </div>
                        <Spacer spaceParam="medium" />
                        <h3>{section.sectionTitle === 'Build Playbook' ? 'Playbook' : section.sectionTitle}</h3>
                    </RFC>
                )}
                {laptop && appType === 'EAP' ? (
                    <>
                        <Spacer spaceParam="large" />
                        <Spacer spaceParam="large" />
                    </>
                ) : (
                    <Spacer spaceParam="medium" />
                )}
                <Stepper activeStep={activeStep} underline handleClick={handleStepperClick} steps={steps} noBubbles />
            </RFC>
            <FlexDiv backgroundColor={theme.colors.white}>
                <Spacer spaceParam="small" />
                <Switch>
                    {childrenComponents.map((Component, index) => {
                        return (
                            <Route key={index} exact path={`${subSections[index].routerURL}`}>
                                <Component />
                            </Route>
                        );
                    })}
                </Switch>
            </FlexDiv>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarWarningStep ? true : false}
                message="If you leave this section, your unsaved changes will be lost."
                action={
                    <RFC axis="row">
                        <StyledSnackbarConfirmButton
                            onClick={() => {
                                setSnackbarWarningStep(0);
                            }}
                        >
                            Stay
                        </StyledSnackbarConfirmButton>
                        <Spacer spaceParam="medium" />
                        <StyledSnackbarConfirmButton
                            onClick={() => {
                                setActiveStep(snackbarWarningStep - 1);
                                history.push(`${section.subSections[snackbarWarningStep - 1].routerURL}`);
                                setSnackbarWarningStep(0);
                            }}
                        >
                            Continue
                        </StyledSnackbarConfirmButton>
                    </RFC>
                }
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarWarningHome}
                message="If you leave this section, your unsaved changes will be lost."
                action={
                    <RFC axis="row">
                        <StyledSnackbarConfirmButton
                            onClick={() => {
                                setSnackbarWarningHome(false);
                            }}
                        >
                            Stay
                        </StyledSnackbarConfirmButton>
                        <Spacer spaceParam="medium" />
                        <StyledSnackbarConfirmButton
                            onClick={() => {
                                history.push(`/home`);
                                // setSnackbarWarningHome(false)
                            }}
                        >
                            Continue
                        </StyledSnackbarConfirmButton>
                    </RFC>
                }
            />
        </>
    );
};
