import axios from 'axios';
import { RootState } from '../store';
import FormData from 'form-data';
import { selectUserEmail, setAuthExpired, updateAppError } from '../ReduxSlices/userSlice';
import { escapeSpecialChars } from '../Common/utilityFunctions';
import { useAppSelector } from './Hooks/reactReduxHooks';

export const GetUserSavedState = async (listingID: number) => {
    try {
        let response = await axios.get(`/api/planinfo?listingid=${listingID}`);
        if (response.data === 'Final Return path for error') {
            return 'No data returned';
        } else {
            return response.data;
        }
    } catch (error: any) {
        return { error: error.response.status };
    }
};

export const GetListings = async () => {
    try {
        let response = await axios.get(`/api/planinfo/getlistings`);
        if (response.data === 'Final Return path for error') {
            return [];
        } else {
            return response.data;
        }
    } catch (error: any) {
        return { error: error.response.status };
    }
};
interface IpostData {
    node_json: string;
    node_path: string;
    completeYN?: boolean;
    listingID?: number;
    listing_name?: string;
    shared?: 'Y' | 'N';
}
export const SetUserSavedState = async (postData: IpostData) => {
    try {
        let postResponse = await axios({
            url: `/api/planinfo`,
            method: 'post',
            data: postData,
        });
        return { status: postResponse.statusText, value: postResponse.data };
    } catch (error: any) {
        return { error: error.response.status };
    }
};
interface IEAPupload {
    whichFile: string;
    listingID: number;
    file: File;
}
export const SetEAPFileUpload = async (postData: IEAPupload) => {
    const form = new FormData();
    form.append('listingID', postData.listingID);
    form.append('whichFile', postData.whichFile);
    form.append('fileType', postData.file.type);
    form.append('file', postData.file, postData.file.name);
    try {
        let postResponse = await axios({
            method: 'post',
            url: `/api/fileupload`,
            data: form,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return postResponse.statusText;
    } catch (error: any) {
        return { error: error.response.status };
    }
};
interface IadvisoryUpload {
    fileID: number;
    file: File;
}
export const SetAdvisoryFileUpload = async (postData: IadvisoryUpload) => {
    const form = new FormData();
    form.append('fileID', postData.fileID);
    form.append('fileType', postData.file.type);
    form.append('file', postData.file, postData.file.name);
    try {
        let postResponse = await axios({
            method: 'post',
            url: `/api/fileupload`,
            data: form,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return { status: postResponse.status, data: postResponse.data };
    } catch (error: any) {
        return { error: error.response.status };
    }
};

export const GetAppData = async () => {
    try {
        let response = await axios.get(`/api/appdata`);
        return response.data;
    } catch (error: any) {
        return { error: error.response.status };
    }
};
export const AuthRefresh = async () => {
    try {
        let response = await axios.get(`/api/authrefresh`);
        return { status: response.status };
    } catch (error: any) {
        return { error: error.response.status };
    }
};
interface IlistingCheck {
    listingID: number;
    listingName: string;
}
export const getCheckUniqueName = async (listing: IlistingCheck) => {
    try {
        let postResponse = await axios({
            url: `/api/planinfo/uniquename`,
            method: 'get',
            params: {
                listingID: listing.listingID,
                listingName: listing.listingName,
            },
        });
        return { status: postResponse.statusText, value: postResponse.data };
    } catch (error: any) {
        return { error: error.response.status };
    }
};

export const handleErrorStatus = (error: number, dispatch) => {
    console.log('There was an error! Status: ' + error);
    if (error === 401) {
        dispatch(setAuthExpired());
    } else {
        setTimeout(() => dispatch(updateAppError(error)), 2000);
    }
};

export const prepareData = (state: RootState, node_path: string, node_json: {}) => {
    const stringified_node_json = JSON.stringify(node_json);
    const stringified_and_escaped_json = escapeSpecialChars(stringified_node_json);

    let returnData: IpostData = {
        completeYN: state.user.completeYN,
        node_json: stringified_and_escaped_json,
        node_path: node_path,
        listingID: state.listings.data.listingID,
        listing_name: state.listings.data.listingName,
        shared: state.user.shared,
    };
    return returnData;
};
export const prepareDataAdvisory = (state: RootState, node_path: string, node_json: {}) => {
    const stringified_node_json = JSON.stringify(node_json);
    const stringified_and_escaped_json = escapeSpecialChars(stringified_node_json);

    let returnData: IpostData = {
        node_json: stringified_and_escaped_json,
        node_path: node_path,
    };
    return returnData;
};

export const PublishPlan = async (listingID: number) => {
    try {
        let postResponse;
        if (listingID) {
            postResponse = await axios({
                url: `/api/publish`,
                method: 'post',
                params: {
                    listingid: listingID,
                },
            });
        } else {
            postResponse = await axios({
                url: `/api/publish`,
                method: 'get',
            });
        }
        return { status: postResponse.statusText };
    } catch (error: any) {
        return { error: error.response.status };
    }
};

export const GetPDF = async ({ name, html }: { name: string; html: string }) => {
    try {
        let postResponse = await axios({
            url: `/api/export/pdf`,
            method: 'post',
            data: {
                name: name,
                html: window.btoa(encodeURIComponent(html)),
            },
        });
        return { status: postResponse.statusText };
    } catch (error: any) {
        return { error: error.response.status };
    }
};

export interface IreturnDataValue {
    id: number;
    title: string;
    content: string;
    isActiveYN: string;
    seq: number;
    Location: string;
}
export const getKnowledgeBase = async () => {
    try {
        let postResponse = await axios({
            url: `/home/knowledge_base`,
            method: 'get',
        });
        let responseData: Array<IreturnDataValue> = postResponse.data;
        return { value: responseData, status: postResponse.status };
    } catch (error: any) {
        return { error: error.response.status };
    }
};

export const DeleteAdvisoryFileUpload = async (fileID: number) => {
    try {
        let postResponse = await axios({
            url: `/api/fileupload/deletefile`,
            method: 'post',
            params: {
                fileID: fileID,
            },
        });
        return { status: postResponse.statusText };
    } catch (error: any) {
        return { error: error.response.status };
    }
};

export const logXSSToDatabase = async (dirty: string, inputLocation: string, userEmail: string) => {
    try {
        await axios({
            url: '/api/SystemLog/XSSLog',
            method: 'post',
            params: {
                dirty,
                inputLocation,
                userEmail,
            },
        });
    } catch (error: any) {
        return error;
    }
};
