import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { alphaNumericCharacter } from '../Common/utilityFunctions';
import { useAppSelector } from '../Common/Hooks/reactReduxHooks';
import { useSectionsQualityOfLife } from '../Common/Hooks/customHooks';
import { selectUserEmail, setUnSavedFields } from '../ReduxSlices/userSlice';
import {
    setEAPDataToDB,
    selectFormsData,
    addNormalizedData,
    removeNormalizedData,
    updateNormalizedData,
    updateFormsDataNode,
    purgeConditionalItems,
} from '../ReduxSlices/formsData';

import { updateSectionProgress } from '../ReduxSlices/sectionProgressSlice';
import { FormWrapper } from '../Common/Components/FormWrapper';
import { Spacer } from '../Common/Components/Spacer';
import { RFC } from '../Common/Components/FlexContainers';
import { SuccessAlert } from '../Common/Components/SuccessAlert';
import {
    StyledSaveButton,
    SelectStyles,
    states,
    criticalEmployeesOptions,
    medicalEmployeesOptions,
    frequencyOptions,
} from '../Common/Components/CommonStyled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons';
import { faAngleDown, faAngleUp, faTimes } from '@fortawesome/pro-light-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import Select from 'react-select';
import PhoneInput from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { isValueXSSSafe } from '../Common/Security/IcoSanitize';

// import 'react-phone-number-input/style.css'

export const BasicInfo = () => {
    const companyData = useAppSelector(selectFormsData).basicInfo;

    const {
        sectionIndex,
        subSectionIndex,
        subSectionTitle,
        dispatch,
        apiGetStatus,
        unSavedFields,
        sectionStatus,
        theme,
        apiPostStatus,
        laptop,
    } = useSectionsQualityOfLife();

    const setInitialValues = useRef(false);

    const [listToggles, setListToggles] = useState<Array<boolean>>(
        companyData.companyPlanContacts.allIDs.map((_, index) => {
            if (index === 0) {
                return true;
            } else {
                return false;
            }
        }),
    );
    const deleteRef = useRef<HTMLButtonElement>(null);
    const [removeCompanyContactAnchorEl, setRemoveCompanyContactAnchorEl] = useState(null);
    const [removeCompanyContactPopperToggled, setRemoveCompanyContactPopperToggled] = useState(false);
    const [companyContactRemovalTarget, setCompanyContactRemovalTarget] = useState<{
        index: number;
        registeredField1: string;
        registeredField2: string;
        registeredField3: string;
        registeredField4: string;
    } | null>(null);
    const [addedCompanyContact, setAddedCompanyContact] = useState(false);

    const { register, handleSubmit, setFocus, formState, getValues, setValue, unregister, control, watch } = useForm({
        mode: 'onSubmit',
    });
    const watchInputs = watch();

    const userEmail = useAppSelector(selectUserEmail);

    useEffect(() => {
        if (!setInitialValues.current && apiGetStatus === 'succeeded') {
            setTimeout(() => {
                setValue('name', companyData.companyName);
                setValue('locationNickname', companyData.address.siteNickname);
                setValue('locationAddress', companyData.address.streetAddress);
                setValue('locationCity', companyData.address.city);
                setValue(
                    'locationState',
                    states.find((item) => item.value === companyData.address.state),
                );
                setValue('locationZip', companyData.address.zip);

                companyData.companyPlanContacts.allIDs.forEach((id) => {
                    setValue(`companyContact${id}name`, companyData.companyPlanContacts.byID[id].name);
                    setValue(`companyContact${id}title`, companyData.companyPlanContacts.byID[id].title);
                    setValue(`companyContact${id}phone`, companyData.companyPlanContacts.byID[id].phone);
                    setValue(`companyContact${id}email`, companyData.companyPlanContacts.byID[id].email);
                    setValue(
                        `companyContact${id}employeeContact`,
                        companyData.companyPlanContacts.byID[id].employeeContact ? 'Yes' : 'No',
                    );
                });

                setListToggles(
                    companyData.companyPlanContacts.allIDs.map((_, index) => {
                        if (index === 0) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                );
                setInitialValues.current = true;
            }, 0);
        }
    }, [apiGetStatus, companyData, setValue, formState.errors]);

    useEffect(() => {
        if (!unSavedFields && setInitialValues.current) {
            if (
                (watchInputs['name'] !== undefined && watchInputs['name'] !== companyData.companyName) ||
                (watchInputs['locationNickname'] !== undefined &&
                    watchInputs['locationNickname'] !== companyData.address.siteNickname) ||
                (watchInputs['locationAddress'] !== undefined &&
                    watchInputs['locationAddress'] !== companyData.address.streetAddress) ||
                (watchInputs['locationCity'] !== undefined &&
                    watchInputs['locationCity'] !== companyData.address.city) ||
                (watchInputs['locationState'] !== undefined &&
                    watchInputs['locationState'].value !== companyData.address.state) ||
                (watchInputs['locationZip'] !== undefined && watchInputs['locationZip'] !== companyData.address.zip)
            ) {
                dispatch(setUnSavedFields(true));
            }
            companyData.companyPlanContacts.allIDs.forEach((id) => {
                if (
                    (watchInputs[`companyContact${id}name`] !== undefined &&
                        watchInputs[`companyContact${id}name`] !== companyData.companyPlanContacts.byID[id].name) ||
                    (watchInputs[`companyContact${id}title`] !== undefined &&
                        watchInputs[`companyContact${id}title`] !== companyData.companyPlanContacts.byID[id].title) ||
                    (watchInputs[`companyContact${id}phone`] !== undefined &&
                        watchInputs[`companyContact${id}phone`] !== companyData.companyPlanContacts.byID[id].phone) ||
                    (watchInputs[`companyContact${id}email`] !== undefined &&
                        watchInputs[`companyContact${id}email`] !== companyData.companyPlanContacts.byID[id].email) ||
                    (watchInputs[`companyContact${id}employeeContact`] !== undefined &&
                        (watchInputs[`companyContact${id}employeeContact`] === 'Yes') !==
                            companyData.companyPlanContacts.byID[id].employeeContact)
                ) {
                    dispatch(setUnSavedFields(true));
                }
            });
        }
    }, [watchInputs, unSavedFields, setInitialValues, dispatch, companyData]);

    useEffect(() => {
        if (addedCompanyContact) {
            setFocus(
                `companyContact${
                    companyData.companyPlanContacts.allIDs[companyData.companyPlanContacts.allIDs.length - 1]
                }name`,
            );
            setAddedCompanyContact(false);
        }
    }, [companyData.companyPlanContacts, setFocus, addedCompanyContact]);

    useLayoutEffect(() => {
        if (removeCompanyContactPopperToggled) {
            //@ts-ignore
            setTimeout(() => deleteRef.current.focus(), 0);
        }
    }, [removeCompanyContactPopperToggled]);

    const handleRemoveCompanyContactToggle = (event: any) => {
        setRemoveCompanyContactAnchorEl(removeCompanyContactAnchorEl ? null : event.currentTarget);
        setRemoveCompanyContactPopperToggled((prev) => !prev);
    };
    const removeCompanyContactAnchorElID = removeCompanyContactPopperToggled ? 'transitions-popper' : undefined;

    const onSubmit = (data: any) => {
        dispatch(
            updateFormsDataNode({
                node: 'basicInfo',
                data: {
                    companyName: getValues('name'),
                    companyPlanContacts: companyData.companyPlanContacts,
                    address: {
                        streetAddress: getValues('locationAddress'),
                        city: getValues('locationCity'),
                        state: getValues('locationState')?.value,
                        zip: getValues('locationZip'),
                        siteNickname: getValues('locationNickname'),
                    },
                },
            }),
        );
        for (let i = 0; i < companyData.companyPlanContacts.allIDs.length; i++) {
            dispatch(
                updateNormalizedData({
                    targetIndex: i,
                    node: 'Contact',
                    value: {
                        name: getValues(`companyContact${companyData.companyPlanContacts.allIDs[i]}name`),
                        title: getValues(`companyContact${companyData.companyPlanContacts.allIDs[i]}title`),
                        phone: getValues(`companyContact${companyData.companyPlanContacts.allIDs[i]}phone`),
                        email: getValues(`companyContact${companyData.companyPlanContacts.allIDs[i]}email`),
                        employeeContact:
                            getValues(`companyContact${companyData.companyPlanContacts.allIDs[i]}employeeContact`) ===
                            'Yes'
                                ? true
                                : false,
                    },
                }),
            );
        }
        dispatch(
            updateSectionProgress({
                sectionIndex: sectionIndex,
                subSectionIndex: subSectionIndex,
                status: 'completed',
            }),
        );
        dispatch(setEAPDataToDB('basicInfo'));
        dispatch(setEAPDataToDB('address'));
        dispatch(setUnSavedFields(false));
    };

    let companyFormElements: JSX.Element[] = [];
    //name input
    companyFormElements.push(
        <React.Fragment key={-2}>
            <RFC axis="row" justify="space-between" css="width: 100%;">
                <h2 style={{ alignSelf: 'left' }}>{subSectionTitle}</h2>
                {sectionStatus === 'completed' && (
                    <>
                        <Spacer spaceParam="large" />
                        {unSavedFields ? (
                            <h3 style={{ color: theme.colors.red[6] }}>Unsaved Changes</h3>
                        ) : (
                            <h3 style={{ color: theme.colors.cyan[5] }}>Complete</h3>
                        )}
                    </>
                )}
            </RFC>
            <Spacer spaceParam="large" />
            <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                <label
                    htmlFor={'name'}
                    className={watchInputs['name'] ? 'input_label_transitioned' : ''}
                    onClick={(e) => setFocus('name')}
                >
                    Company Name
                </label>
                <input
                    {...register('name', {
                        validate: {
                            required: (value) => (value && value.length > 0) || 'Must provide a company name',
                            maxLength: (value) =>
                                value.length === 0 || value.length < 1000 || 'Length must be < 1000 characters',
                            notBlankSpaces: (value) => {
                                let regex = new RegExp(/.*\S.*/);
                                let notOnlyBlanks = false;
                                if (regex.exec(value)) {
                                    notOnlyBlanks = true;
                                }
                                if (regex.exec(companyData.companyName)) {
                                    notOnlyBlanks = true;
                                }
                                return value.length === 0 || notOnlyBlanks || 'Name cannot be only blank spaces';
                            },
                            isSafe: (value) => {
                                return isValueXSSSafe(value, 'Basic User Name Info', userEmail)
                                    ? true
                                    : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                            },
                        },
                    })}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            setFocus(`companyContact${companyData.companyPlanContacts.allIDs[0]}name`);
                        }
                    }}
                />
            </RFC>
            {formState.errors['name'] && formState.errors['name'].type && (
                <>
                    <Spacer direction="vertical" spaceParam="small" />
                    <p>{formState.errors['name'].message}</p>
                    <Spacer direction="vertical" spaceParam="small" />
                </>
            )}
        </React.Fragment>,
    );
    //location inputs
    companyFormElements.push(
        <React.Fragment key={-1}>
            <Spacer spaceParam="large" />
            <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                <label
                    htmlFor={'locationNickname'}
                    className={watchInputs['locationNickname'] ? 'input_label_transitioned' : ''}
                    onClick={(e) => setFocus('locationNickname')}
                >
                    Site Nickname
                </label>
                <input
                    {...register('locationNickname', {
                        validate: {
                            required: (value) => (value && value.length > 0) || 'Must provide a site nickname',
                            maxLength: (value) =>
                                value.length === 0 || value.length < 1000 || 'Length must be < 1000 characters',
                            notBlankSpaces: (value) => {
                                let regex = new RegExp(/.*\S.*/);
                                let notOnlyBlanks = false;
                                if (regex.exec(value)) {
                                    notOnlyBlanks = true;
                                }
                                if (regex.exec(companyData.companyName)) {
                                    notOnlyBlanks = true;
                                }
                                return value.length === 0 || notOnlyBlanks || 'Nickname cannot be only blank spaces';
                            },
                            isSafe: (value) => {
                                return isValueXSSSafe(value, 'Site names info', userEmail)
                                    ? true
                                    : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                            },
                        },
                    })}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            setFocus(`locationEmployees`);
                        }
                    }}
                />
            </RFC>
            {formState.errors['locationNickname'] && formState.errors['locationNickname'].type && (
                <>
                    <Spacer direction="vertical" spaceParam="small" />
                    <p>{formState.errors['locationNickname'].message}</p>
                    <Spacer direction="vertical" spaceParam="small" />
                </>
            )}

            <Spacer spaceParam="large" />
            <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                <label
                    htmlFor={'locationAddress'}
                    className={watchInputs['locationAddress'] ? 'input_label_transitioned' : ''}
                    onClick={(e) => setFocus('locationAddress')}
                >
                    Street Address
                </label>
                <input
                    {...register('locationAddress', {
                        validate: {
                            required: (value) => (value && value.length > 0) || 'Must provide a street address',
                            maxLength: (value) =>
                                value.length === 0 || value.length < 1000 || 'Length must be < 1000 characters',
                            notBlankSpaces: (value) => {
                                let regex = new RegExp(/.*\S.*/);
                                let notOnlyBlanks = false;
                                if (regex.exec(value)) {
                                    notOnlyBlanks = true;
                                }
                                if (regex.exec(companyData.companyName)) {
                                    notOnlyBlanks = true;
                                }
                                return (
                                    value.length === 0 || notOnlyBlanks || 'Street address cannot be only blank spaces'
                                );
                            },
                            isSafe: (value) => {
                                return isValueXSSSafe(value, 'Basic info street address', userEmail)
                                    ? true
                                    : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                            },
                        },
                    })}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            setFocus(`locationCity`);
                        }
                    }}
                />
            </RFC>
            {formState.errors['locationAddress'] && formState.errors['locationAddress'].type && (
                <>
                    <Spacer direction="vertical" spaceParam="small" />
                    <p>{formState.errors['locationAddress'].message}</p>
                    <Spacer direction="vertical" spaceParam="small" />
                </>
            )}

            <Spacer spaceParam="large" />
            <RFC axis="row" justify="space-between" align="flex-start">
                <RFC axis="column" align="flex-start" justify="flex-start" basis="30%">
                    <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                        <label
                            htmlFor={'locationCity'}
                            className={watchInputs['locationCity'] ? 'input_label_transitioned' : ''}
                            onClick={(e) => setFocus('locationCity')}
                        >
                            City
                        </label>
                        <input
                            style={{ minWidth: '100px' }}
                            {...register('locationCity', {
                                validate: {
                                    required: (value) => (value && value.length > 0) || 'Need city name',
                                    maxLength: (value) =>
                                        value.length === 0 || value.length < 1000 || 'Length must be < 1000 characters',
                                    notBlankSpaces: (value) => {
                                        let regex = new RegExp(/.*\S.*/);
                                        let notOnlyBlanks = false;
                                        if (regex.exec(value)) {
                                            notOnlyBlanks = true;
                                        }
                                        if (regex.exec(companyData.companyName)) {
                                            notOnlyBlanks = true;
                                        }
                                        return (
                                            value.length === 0 || notOnlyBlanks || 'city cannot be only blank spaces'
                                        );
                                    },
                                    isSafe: (value) => {
                                        return isValueXSSSafe(value, 'Basic user city info', userEmail)
                                            ? true
                                            : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                    },
                                },
                            })}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    setFocus(`locationState`);
                                }
                            }}
                        />
                    </RFC>
                    {formState.errors['locationCity'] && formState.errors['locationCity'].type && (
                        <>
                            <Spacer direction="vertical" spaceParam="small" />
                            <p>{formState.errors['locationCity'].message}</p>
                            <Spacer direction="vertical" spaceParam="small" />
                        </>
                    )}
                </RFC>

                <RFC axis="column" align="flex-start" justify="flex-start" basis="30%">
                    <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                        <label
                            htmlFor={'locationState'}
                            className="input_label_transitioned"
                            onClick={(e) => setFocus('locationState')}
                        >
                            State
                        </label>
                        <Controller
                            name="locationState"
                            control={control}
                            defaultValue={{ value: 'pre-load', label: '...loading' }}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    value={value}
                                    options={states}
                                    width="30%"
                                    styles={SelectStyles}
                                    onChange={onChange}
                                    borderColor={theme.colors.blueGray[9]}
                                    borderColorFocus={theme.colors.blue[5]}
                                    backgroundFocus={theme.colors.yellow[10]}
                                    selectedColor={theme.colors.blueGray[1]}
                                    theme={(oldTheme) => ({
                                        ...oldTheme,
                                        borderRadius: 0,
                                        colors: {
                                            ...oldTheme.colors,
                                            primary25: theme.colors.blue[8],
                                            primary: theme.colors.blue[5],
                                        },
                                    })}
                                />
                            )}
                        />
                    </RFC>
                </RFC>

                <RFC axis="column" align="flex-start" justify="flex-start" basis="30%">
                    <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                        <label
                            htmlFor={'locationZip'}
                            className={watchInputs['locationZip'] ? 'input_label_transitioned' : ''}
                            onClick={(e) => setFocus('locationZip')}
                        >
                            Zip Code
                        </label>
                        <input
                            style={{ minWidth: '100px' }}
                            {...register('locationZip', {
                                validate: {
                                    required: (value) => (value && value.length > 0) || 'Must provide a zip code',
                                    valid5Numeric: (value) => {
                                        let valid5Numeric = true;

                                        if (value && value.length > 0) {
                                            if (value.length !== 5) {
                                                valid5Numeric = false;
                                            } else {
                                                for (let character of value.split('')) {
                                                    if (!alphaNumericCharacter(character)) {
                                                        valid5Numeric = false;
                                                    }
                                                }
                                            }
                                        }

                                        return valid5Numeric || 'Must be a valid zipcode with 5 numeric characters';
                                    },
                                    notBlankSpaces: (value) => {
                                        let regex = new RegExp(/.*\S.*/);
                                        let notOnlyBlanks = false;
                                        if (regex.exec(value)) {
                                            notOnlyBlanks = true;
                                        }
                                        if (regex.exec(companyData.companyName)) {
                                            notOnlyBlanks = true;
                                        }
                                        return notOnlyBlanks || 'zip code cannot be only blank spaces';
                                    },
                                    isSafe: (value) => {
                                        return isValueXSSSafe(value, 'Basic user zip info', userEmail)
                                            ? true
                                            : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                    },
                                },
                            })}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </RFC>
                    {formState.errors['locationZip'] && formState.errors['locationZip'].type && (
                        <>
                            <Spacer direction="vertical" spaceParam="small" />
                            <p>{formState.errors['locationZip'].message}</p>
                            <Spacer direction="vertical" spaceParam="small" />
                        </>
                    )}
                </RFC>
            </RFC>
        </React.Fragment>,
    );
    //company contact
    for (let i = 0; i < companyData.companyPlanContacts.allIDs.length; i++) {
        let registeredField = `companyContact${companyData.companyPlanContacts.allIDs[i]}name`;
        let registeredField2 = `companyContact${companyData.companyPlanContacts.allIDs[i]}title`;
        let registeredField3 = `companyContact${companyData.companyPlanContacts.allIDs[i]}phone`;
        let registeredField4 = `companyContact${companyData.companyPlanContacts.allIDs[i]}email`;
        let registeredField5 = `companyContact${companyData.companyPlanContacts.allIDs[i]}employeeContact`;
        if (i === 0) {
            companyFormElements.push(
                <React.Fragment key={-3}>
                    <Spacer direction="vertical" spaceParam="large" />
                    <Spacer direction="vertical" spaceParam="large" />
                    <h4>Primary Company Contact (who is the main contact person for this Emergency Action Plan?)</h4>
                </React.Fragment>,
            );
        }
        companyFormElements.push(
            <React.Fragment key={i}>
                <Spacer direction="vertical" spaceParam="medium" />
                <RFC axis="row" justify="space-between">
                    <RFC axis="row" justify="flex-start" basis="60%">
                        <div
                            className="fontAwesomeCircleWrapper"
                            tabIndex={0}
                            style={{ padding: '0.4rem 0.7rem 0.4rem' }}
                            onClick={() => {
                                setListToggles((prev) => {
                                    let newList: Array<boolean> = [];
                                    prev.forEach((item, index) => {
                                        index === i ? newList.push(!item) : newList.push(item);
                                    });
                                    return newList;
                                });
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    setListToggles((prev) => {
                                        let newList: Array<boolean> = [];
                                        prev.forEach((item, index) => {
                                            index === i ? newList.push(!item) : newList.push(item);
                                        });
                                        return newList;
                                    });
                                }
                            }}
                        >
                            <FontAwesomeIcon
                                icon={listToggles[i] ? faAngleDown : faAngleUp}
                                style={{ color: 'inherit' }}
                                size="lg"
                            />
                        </div>
                        <Spacer spaceParam="medium" />

                        <span>
                            {companyData.companyPlanContacts.allIDs.length === 1
                                ? `Company Contact${
                                      listToggles[i]
                                          ? ''
                                          : ': ' +
                                            getValues(`companyContact${companyData.companyPlanContacts.allIDs[i]}name`)
                                  }`
                                : `Company Contact #${i + 1}${
                                      listToggles[i]
                                          ? ''
                                          : ': ' +
                                            getValues(`companyContact${companyData.companyPlanContacts.allIDs[i]}name`)
                                  }`}
                        </span>
                    </RFC>
                    {companyData.companyPlanContacts.allIDs.length > 1 && (
                        <>
                            <div
                                className="fontAwesomeCircleWrapper"
                                tabIndex={0}
                                aria-describedby={removeCompanyContactAnchorElID}
                                style={{ padding: '0.65rem 0.65rem 0.65rem 0.7rem' }}
                                onClick={(e) => {
                                    handleRemoveCompanyContactToggle(e);
                                    setCompanyContactRemovalTarget({
                                        index: i,
                                        registeredField1: registeredField,
                                        registeredField2: registeredField2,
                                        registeredField3: registeredField3,
                                        registeredField4: registeredField4,
                                    });
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleRemoveCompanyContactToggle(e);
                                        setCompanyContactRemovalTarget({
                                            index: i,
                                            registeredField1: registeredField,
                                            registeredField2: registeredField2,
                                            registeredField3: registeredField3,
                                            registeredField4: registeredField4,
                                        });
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'inherit' }} />
                            </div>

                            <Popper
                                id={removeCompanyContactAnchorElID}
                                open={removeCompanyContactPopperToggled}
                                anchorEl={removeCompanyContactAnchorEl}
                                transition
                            >
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={0}>
                                        <RFC
                                            axis="column"
                                            css={`
                                                background: ${theme.colors.blueGray[10]};
                                                padding: 1rem;
                                                width: 100%;
                                                max-width: 300px;
                                                border-radius: 1rem 1rem 1rem 1rem;
                                            `}
                                        >
                                            <h4>Remove Company Contact</h4>
                                            <Spacer spaceParam="medium" />
                                            <span>
                                                Are you sure you want to remove this contact? This action cannot be
                                                undone.
                                            </span>
                                            <Spacer spaceParam="medium" />
                                            <RFC axis="row" justify="space-around">
                                                <button
                                                    className="button-tertiary"
                                                    onClick={(e) => handleRemoveCompanyContactToggle(e)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="button-delete"
                                                    ref={deleteRef}
                                                    onClick={(e) => {
                                                        setTimeout(() => {
                                                            setListToggles((prev) => {
                                                                let value = prev.pop();
                                                                if (value === true) {
                                                                    prev[prev.length - 1] = true;
                                                                }
                                                                return prev;
                                                            });

                                                            dispatch(
                                                                removeNormalizedData({
                                                                    node: 'Contact',
                                                                    //@ts-ignore
                                                                    targetIndex: companyContactRemovalTarget.index,
                                                                }),
                                                            );

                                                            unregister([
                                                                //@ts-ignore
                                                                companyContactRemovalTarget?.registeredField1,
                                                                //@ts-ignore
                                                                companyContactRemovalTarget?.registeredField2,
                                                                //@ts-ignore
                                                                companyContactRemovalTarget?.registeredField3,
                                                                //@ts-ignore
                                                                companyContactRemovalTarget?.registeredField4,
                                                            ]);
                                                            //@ts-ignore
                                                            if (companyContactRemovalTarget.index > 0) {
                                                                //@ts-ignore
                                                                setFocus(
                                                                    `companyContact${
                                                                        //@ts-ignore
                                                                        companyData.companyPlanContacts.allIDs[
                                                                            //@ts-ignore
                                                                            companyContactRemovalTarget.index - 1
                                                                        ]
                                                                    }name`,
                                                                );
                                                            } else {
                                                                setFocus(
                                                                    `companyContact${
                                                                        companyData.companyPlanContacts.allIDs[
                                                                            //@ts-ignore
                                                                            companyContactRemovalTarget.index + 1
                                                                        ]
                                                                    }name`,
                                                                );
                                                            }
                                                        }, 350);
                                                        handleRemoveCompanyContactToggle(e);
                                                    }}
                                                >
                                                    Confirm
                                                </button>
                                            </RFC>
                                        </RFC>
                                    </Fade>
                                )}
                            </Popper>
                        </>
                    )}
                </RFC>

                <RFC axis="row" justify="flex-start" css={listToggles[i] ? '' : 'display: none;'}>
                    <Spacer spaceParam="large" />
                    <RFC axis="column" basis="100%" align="flex-start">
                        <Spacer spaceParam="large" />
                        <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                            <label
                                htmlFor={registeredField}
                                className={watchInputs[registeredField] ? 'input_label_transitioned' : ''}
                                onClick={(e) => setFocus(registeredField)}
                            >
                                Name
                            </label>
                            <input
                                className="predetermined-size-33"
                                {...register(registeredField, {
                                    validate: {
                                        required: (value) => (value && value.length > 0) || 'Must provide name',
                                        maxLength: (value) =>
                                            value.length === 0 ||
                                            value.length < 1000 ||
                                            'Length must be < 1000 characters',
                                        notBlankSpaces: (value) => {
                                            let regex = new RegExp(/.*\S.*/);
                                            let notOnlyBlanks = false;
                                            if (regex.exec(value)) {
                                                notOnlyBlanks = true;
                                            }
                                            return (
                                                notOnlyBlanks ||
                                                value.length === 0 ||
                                                'Name cannot be only blank spaces'
                                            );
                                        },
                                        isSafe: (value) => {
                                            return isValueXSSSafe(value, 'Company Contact name', userEmail)
                                                ? true
                                                : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                        },
                                    },
                                })}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        setFocus(registeredField2);
                                    }
                                }}
                            />
                        </RFC>
                        {formState.errors[registeredField] && formState.errors[registeredField].type && (
                            <>
                                <Spacer direction="vertical" spaceParam="small" />
                                <p>{formState.errors[registeredField].message}</p>
                                <Spacer direction="vertical" spaceParam="small" />
                            </>
                        )}

                        <Spacer spaceParam="large" />
                        <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                            <label
                                htmlFor={registeredField2}
                                className={watchInputs[registeredField2] ? 'input_label_transitioned' : ''}
                                onClick={(e) => setFocus(registeredField2)}
                            >
                                Title
                            </label>
                            <input
                                className="predetermined-size-33"
                                {...register(registeredField2, {
                                    validate: {
                                        required: (value) => (value && value.length > 0) || 'Must provide title',
                                        maxLength: (value) =>
                                            value.length === 0 ||
                                            value.length < 1000 ||
                                            'Length must be < 1000 characters',
                                        notBlankSpaces: (value) => {
                                            let regex = new RegExp(/.*\S.*/);
                                            let notOnlyBlanks = false;
                                            if (regex.exec(value)) {
                                                notOnlyBlanks = true;
                                            }
                                            return (
                                                notOnlyBlanks ||
                                                value.length === 0 ||
                                                'Title cannot be only blank spaces'
                                            );
                                        },
                                        isSafe: (value) => {
                                            return isValueXSSSafe(value, 'Company Contact Title', userEmail)
                                                ? true
                                                : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                        },
                                    },
                                })}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        setFocus(registeredField3);
                                    }
                                }}
                            />
                        </RFC>
                        {formState.errors[registeredField2] && formState.errors[registeredField2].type && (
                            <>
                                <Spacer direction="vertical" spaceParam="small" />
                                <p>{formState.errors[registeredField2].message}</p>
                                <Spacer direction="vertical" spaceParam="small" />
                            </>
                        )}

                        <Spacer spaceParam="large" />
                        <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                            <label
                                htmlFor={registeredField3}
                                className={watchInputs[registeredField3] ? 'input_label_transitioned' : ''}
                            >
                                Phone
                            </label>
                            <Controller
                                name={registeredField3}
                                control={control}
                                rules={{
                                    validate: (value) => isValidPhoneNumber(value),
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <PhoneInput
                                        defaultCountry="US"
                                        value={value}
                                        onChange={onChange}
                                        id={registeredField3}
                                        className="predetermined-size-33"
                                    />
                                )}
                            />
                        </RFC>
                        {formState.errors[registeredField3] && (
                            <>
                                <Spacer direction="vertical" spaceParam="small" />
                                <p>Must input a valid phone number</p>
                                <Spacer direction="vertical" spaceParam="small" />
                            </>
                        )}

                        <Spacer spaceParam="large" />
                        <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                            <label
                                htmlFor={registeredField4}
                                className={watchInputs[registeredField4] ? 'input_label_transitioned' : ''}
                                onClick={(e) => setFocus(registeredField4)}
                            >
                                Email
                            </label>
                            <input
                                className="predetermined-size-33"
                                {...register(registeredField4, {
                                    required: true,
                                    pattern:
                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                })}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        // setFocus(registeredField2)
                                    }
                                }}
                            />
                        </RFC>
                        {formState.errors[registeredField4] && formState.errors[registeredField4].type && (
                            <>
                                <Spacer direction="vertical" spaceParam="small" />
                                <p>Email is required</p>
                                <Spacer direction="vertical" spaceParam="small" />
                            </>
                        )}

                        <Spacer spaceParam="large" />
                        <label>
                            Is this the same person that employees should contact with questions about this plan,
                            training, or their duties during an emergency?
                        </label>
                        <Spacer spaceParam="medium" />
                        <RFC axis="row" justify="flex-start">
                            <Spacer spaceParam="large" />
                            <RFC axis="column" align="flex-start" className="radioButtonContainer">
                                <label>
                                    <input {...register(registeredField5)} type="radio" value="Yes" defaultChecked />{' '}
                                    Yes
                                </label>
                                <Spacer spaceParam="small" />
                                <label>
                                    <input {...register(registeredField5)} type="radio" value="No" /> No
                                </label>
                            </RFC>
                        </RFC>
                    </RFC>

                    <Spacer spaceParam="large" />
                </RFC>

                {i === companyData.companyPlanContacts.allIDs.length - 1 && (
                    <>
                        <Spacer spaceParam="large" direction="vertical" />
                        <button
                            type="button"
                            className="button-secondary"
                            onClick={() => {
                                dispatch(addNormalizedData({ node: 'Contact' }));
                                setAddedCompanyContact(true);
                                setListToggles((prev) => {
                                    let newList: Array<boolean> = [];
                                    prev.forEach((item) => newList.push(false));
                                    newList.push(true);
                                    return newList;
                                });
                            }}
                        >
                            Add another contact?
                        </button>
                    </>
                )}
            </React.Fragment>,
        );
    }
    return (
        <RFC axis="column" justify="flex-start" align="center">
            <FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                formWidth="70%"
                formMinWidth="350px"
                formMaxWidth="900px"
                inputWidth="100%"
                status={apiPostStatus}
            >
                {companyFormElements}

                <Spacer direction="vertical" spaceParam="large" />
                <RFC axis="column" justify="flex-start" align="flex-end" alignLaptop="center">
                    {(apiPostStatus === 'succeeded' || laptop) && (
                        <RFC
                            axis="row"
                            basis="75%"
                            align="center"
                            alignLaptop="center"
                            justify="flex-end"
                            justifyLaptop="flex-end"
                            css={!laptop ? 'padding-top: 1em;' : ''}
                        >
                            <SuccessAlert active={apiPostStatus === 'succeeded'} style={{ alignSelf: 'right' }}>
                                Successfully Saved!
                            </SuccessAlert>
                            <Spacer spaceParam="medium" direction="horizontal" />
                        </RFC>
                    )}
                    <RFC axis="row" justify="flex-end" basis="25%">
                        <StyledSaveButton
                            type="submit"
                            postStatus={apiPostStatus}
                            noChanges={!unSavedFields}
                            onClick={(e) => {
                                setListToggles((prev) => {
                                    let newList: Array<boolean> = [];
                                    prev.forEach((item) => newList.push(true));
                                    return newList;
                                });
                                if (apiPostStatus !== 'idle') {
                                    e.preventDefault();
                                }
                            }}
                        >
                            Save
                        </StyledSaveButton>
                    </RFC>
                </RFC>
            </FormWrapper>
            <Spacer direction="vertical" spaceParam="large" />
        </RFC>
    );
};

export const RolesResponsibilities = () => {
    const RRdata = useAppSelector(selectFormsData).rolesResponsibilities;
    const {
        sectionIndex,
        subSectionIndex,
        subSectionTitle,
        dispatch,
        apiGetStatus,
        unSavedFields,
        sectionStatus,
        theme,
        apiPostStatus,
        laptop,
    } = useSectionsQualityOfLife();

    const setInitialValues = useRef(false);

    const [criticalEmployeesListToggles, setCriticalEmployeesListToggles] = useState<Array<boolean>>(
        RRdata.criticalEmployeesList.allIDs.map((_, index) => {
            if (index === 0) {
                return true;
            } else {
                return false;
            }
        }),
    );

    const deleteRef = useRef<HTMLButtonElement>(null);

    const [removeCriticalEmployeeAnchorEl, setRemoveCriticalEmployeeAnchorEl] = useState(null);
    const [removeCriticalEmployeePopperToggled, setRemoveCriticalEmployeePopperToggled] = useState(false);
    const [CriticalEmployeeRemovalTarget, setCriticalEmployeeRemovalTarget] = useState<{
        index: number;
        registeredFields: Array<string>;
    } | null>(null);
    const [addedCriticalEmployee, setAddedCriticalEmployee] = useState(false);

    const [addedOperation, setAddedOperation] = useState<false | number>(false);

    const [addedMedicalEmployee, setAddedMedicalEmployee] = useState(false);

    const { register, watch, handleSubmit, setFocus, formState, getValues, setValue, unregister, control } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });
    const inputsWatch = watch();

    const userEmail = useAppSelector(selectUserEmail);
    useEffect(() => {
        if (!setInitialValues.current && apiGetStatus === 'succeeded') {
            setTimeout(() => {
                setValue(
                    'criticalEmployees',
                    criticalEmployeesOptions.find((item) => item.value === RRdata.criticalEmployeesPermissions),
                );
                RRdata.criticalEmployeesList.allIDs.forEach((employeeID) => {
                    setValue(`criticalEmployee${employeeID}name`, RRdata.criticalEmployeesList.byID[employeeID].name);
                    RRdata.criticalEmployeesList.byID[employeeID].operations.allIDs.forEach((operationID) => {
                        setValue(
                            `criticalEmployee${employeeID}operation${operationID}`,
                            RRdata.criticalEmployeesList.byID[employeeID].operations.byID[operationID],
                        );
                    });
                });
                setValue(
                    'permission',
                    medicalEmployeesOptions.find((item) => item.value === RRdata.medicalEmergencyPermissions),
                );
                RRdata.medicalEmergencyEmployeesList.allIDs.forEach((id) => {
                    setValue(`employeeAllowed${id}`, RRdata.medicalEmergencyEmployeesList.byID[id]);
                });

                setCriticalEmployeesListToggles(
                    RRdata.criticalEmployeesList.allIDs.map((_, index) => {
                        if (index === 0) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                );

                setInitialValues.current = true;
            }, 0);
        }
    }, [apiGetStatus, RRdata, setValue, formState.errors]);
    useEffect(() => {
        if (!unSavedFields && setInitialValues.current) {
            if (
                inputsWatch['criticalEmployees'].value !== undefined &&
                inputsWatch['criticalEmployees'].value !== 'pre-load' &&
                inputsWatch['criticalEmployees'].value !== RRdata.criticalEmployeesPermissions
            ) {
                dispatch(setUnSavedFields(true));
            }
            if (RRdata.criticalEmployeesPermissions === 'Specific') {
                RRdata.criticalEmployeesList.allIDs.forEach((employeeID) => {
                    if (
                        inputsWatch[`criticalEmployee${employeeID}name`] !== undefined &&
                        inputsWatch[`criticalEmployee${employeeID}name`] !==
                            RRdata.criticalEmployeesList.byID[employeeID].name
                    ) {
                        dispatch(setUnSavedFields(true));
                    }
                    RRdata.criticalEmployeesList.byID[employeeID].operations.allIDs.forEach((operationID) => {
                        if (
                            inputsWatch[`criticalEmployee${employeeID}operation${operationID}`] !== undefined &&
                            inputsWatch[`criticalEmployee${employeeID}operation${operationID}`] !==
                                RRdata.criticalEmployeesList.byID[employeeID].operations.byID[operationID]
                        ) {
                            dispatch(setUnSavedFields(true));
                        }
                    });
                });
            }
            if (
                inputsWatch['permission'].value !== undefined &&
                inputsWatch['permission'].value !== 'pre-load' &&
                inputsWatch['permission'].value !== RRdata.medicalEmergencyPermissions
            ) {
                dispatch(setUnSavedFields(true));
            }
            if (RRdata.medicalEmergencyPermissions === 'Specific') {
                RRdata.medicalEmergencyEmployeesList.allIDs.forEach((employeeID) => {
                    if (
                        inputsWatch[`employeeAllowed${employeeID}`] !== undefined &&
                        inputsWatch[`employeeAllowed${employeeID}`] !==
                            RRdata.medicalEmergencyEmployeesList.byID[employeeID]
                    ) {
                        dispatch(setUnSavedFields(true));
                    }
                });
            }
        }
    }, [inputsWatch, unSavedFields, setInitialValues, dispatch, RRdata]);

    useEffect(() => {
        if (addedCriticalEmployee) {
            setFocus(
                `criticalEmployee${
                    RRdata.criticalEmployeesList.allIDs[RRdata.criticalEmployeesList.allIDs.length - 1]
                }name`,
            );
            setAddedCriticalEmployee(false);
        }
    }, [RRdata.criticalEmployeesList, setFocus, addedCriticalEmployee]);
    useEffect(() => {
        if (addedOperation) {
            setFocus(
                `criticalEmployee${addedOperation}operation${
                    RRdata.criticalEmployeesList.byID[addedOperation].operations.allIDs.length - 1
                }`,
            );
            setAddedOperation(false);
        }
    }, [RRdata.criticalEmployeesList, setFocus, addedOperation]);
    useEffect(() => {
        if (addedMedicalEmployee) {
            setFocus(
                `employeeAllowed${
                    RRdata.medicalEmergencyEmployeesList.allIDs[RRdata.medicalEmergencyEmployeesList.allIDs.length - 1]
                }`,
            );
            setAddedMedicalEmployee(false);
        }
    }, [RRdata.medicalEmergencyEmployeesList, setFocus, addedMedicalEmployee]);

    const onSubmit = (data: any) => {
        let RRupdate: typeof RRdata = {
            criticalEmployeesPermissions: getValues('criticalEmployees')?.value,
            criticalEmployeesPermissionsAll: getValues('criticalEmployees')?.value === 'All',
            criticalEmployeesPermissionsSpecific: getValues('criticalEmployees')?.value === 'Specific',
            criticalEmployeesPermissionsNone: getValues('criticalEmployees')?.value === 'None',
            criticalEmployeesList: RRdata.criticalEmployeesList,
            medicalEmergencyPermissions: getValues('permission')?.value,
            medicalEmergencyPermissionsAll: getValues('permission')?.value === 'All',
            medicalEmergencyPermissionsCertified: getValues('permission')?.value === 'Certified',
            medicalEmergencyPermissionsSpecific: getValues('permission')?.value === 'Specific',
            medicalEmergencyPermissionsNone: getValues('permission')?.value === 'None',
            medicalEmergencyEmployeesList: RRdata.medicalEmergencyEmployeesList,
        };
        dispatch(updateFormsDataNode({ node: 'rolesResponsibilities', data: RRupdate }));

        if (inputsWatch['criticalEmployees'].value === 'Specific') {
            for (
                let employeeIterator = 0;
                employeeIterator < RRdata.criticalEmployeesList.allIDs.length;
                employeeIterator++
            ) {
                let employeeIndex = RRdata.criticalEmployeesList.allIDs[employeeIterator];
                dispatch(
                    updateNormalizedData({
                        targetIndex: employeeIterator,
                        node: 'Critical employee',
                        value: {
                            name: getValues(`criticalEmployee${employeeIndex}name`),
                            operations: RRdata.criticalEmployeesList.byID[employeeIndex].operations,
                        },
                    }),
                );

                for (
                    let operationIterator = 0;
                    operationIterator < RRdata.criticalEmployeesList.byID[employeeIndex].operations.allIDs.length;
                    operationIterator++
                ) {
                    let operationIndex =
                        RRdata.criticalEmployeesList.byID[employeeIndex].operations.allIDs[operationIterator];
                    dispatch(
                        updateNormalizedData({
                            node: 'Critical operation',
                            targetIndex: operationIterator,
                            parentIndex: employeeIndex.toString(),
                            value: getValues(`criticalEmployee${employeeIndex}operation${operationIndex}`),
                        }),
                    );
                }
            }
        }
        if (inputsWatch['permission'].value === 'Specific') {
            for (let i = 0; i < RRdata.medicalEmergencyEmployeesList.allIDs.length; i++) {
                dispatch(
                    updateNormalizedData({
                        node: 'Medical employee',
                        targetIndex: i,
                        value: getValues(`employeeAllowed${RRdata.medicalEmergencyEmployeesList.allIDs[i]}`),
                    }),
                );
            }
        }
        dispatch(purgeConditionalItems());
        dispatch(
            updateSectionProgress({
                sectionIndex: sectionIndex,
                subSectionIndex: subSectionIndex,
                status: 'completed',
            }),
        );
        dispatch(setEAPDataToDB('rolesResponsibilities'));
        dispatch(setUnSavedFields(false));
    };

    const handleRemoveCriticalEmployeeToggle = (event: any) => {
        setRemoveCriticalEmployeeAnchorEl(removeCriticalEmployeeAnchorEl ? null : event.currentTarget);
        setRemoveCriticalEmployeePopperToggled((prev) => !prev);
    };
    const removeCriticalEmployeeAnchorElID = removeCriticalEmployeePopperToggled ? 'transitions-popper' : undefined;

    let mappedFormElements: JSX.Element[] = [];
    let mappedFormElements2: JSX.Element[] = [];
    //mapped normalized fields critical employees
    for (let employeeIterator = 0; employeeIterator < RRdata.criticalEmployeesList.allIDs.length; employeeIterator++) {
        let employeeIndex = RRdata.criticalEmployeesList.allIDs[employeeIterator];
        let registeredField1 = `criticalEmployee${employeeIndex}name`;
        let registeredFields = [`criticalEmployee${employeeIndex}name`];
        let currentCriticalEmployee = RRdata.criticalEmployeesList.byID[employeeIndex];

        let mappedFormElementsLevel2: JSX.Element[] = [];

        for (
            let operationIterator = 0;
            operationIterator < currentCriticalEmployee.operations.allIDs.length;
            operationIterator++
        ) {
            let operationIndex = currentCriticalEmployee.operations.allIDs[operationIterator];
            let registeredField2 = `criticalEmployee${employeeIndex}operation${operationIndex}`;
            registeredFields.push(`criticalEmployee${employeeIndex}operation${operationIndex}`);

            mappedFormElementsLevel2.push(
                <React.Fragment key={operationIterator}>
                    <Spacer spaceParam="medium" />
                    <Spacer spaceParam="medium" />
                    <Spacer spaceParam="medium" />
                    <RFC axis="row" justify="flex-start" align="flex-start" className="input_label_wrapper">
                        <textarea
                            {...register(registeredField2, {
                                validate: {
                                    required: (value) =>
                                        (inputsWatch['criticalEmployees'] &&
                                            inputsWatch['criticalEmployees'].value !== 'Specific') ||
                                        (value && value.length > 0) ||
                                        'Must provide operation',
                                    maxLength: (value) =>
                                        (inputsWatch['criticalEmployees'] &&
                                            inputsWatch['criticalEmployees'].value !== 'Specific') ||
                                        value.length < 1000000 ||
                                        'Length must be < 1,000,000 characters',
                                    notBlankSpaces: (value) => {
                                        let regex = new RegExp(/.*\S.*/);
                                        let notOnlyBlanks = false;
                                        if (regex.exec(value)) {
                                            notOnlyBlanks = true;
                                        }
                                        return (
                                            (inputsWatch['criticalEmployees'] &&
                                                inputsWatch['criticalEmployees'].value !== 'Specific') ||
                                            notOnlyBlanks ||
                                            value.length === 0 ||
                                            'Operation cannot be only blank spaces'
                                        );
                                    },
                                    isSafe: (value) => {
                                        return isValueXSSSafe(value, 'Critical Employee operation', userEmail)
                                            ? true
                                            : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                    },
                                },
                            })}
                        />
                        <label
                            htmlFor={registeredField2}
                            className={inputsWatch[registeredField2] ? 'input_label_transitioned' : ''}
                            onClick={(e) => setFocus(registeredField2)}
                        >
                            {currentCriticalEmployee.operations.allIDs.length === 1
                                ? `Operation name and procedure`
                                : `Operation #${operationIterator + 1} name and procedure`}
                        </label>
                        {currentCriticalEmployee.operations.allIDs.length > 1 && (
                            <>
                                <div
                                    className="fontAwesomeCircleWrapper input_icon_repositioned"
                                    tabIndex={0}
                                    style={{ padding: '0.43rem 0.55rem 0.4rem 0.55rem' }}
                                    onClick={() => {
                                        dispatch(
                                            removeNormalizedData({
                                                node: 'Critical operation',
                                                targetIndex: operationIterator,
                                                parentIndex: employeeIndex.toString(),
                                            }),
                                        );
                                        unregister(registeredField2);
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            dispatch(
                                                removeNormalizedData({
                                                    node: 'Critical operation',
                                                    targetIndex: operationIterator,
                                                    parentIndex: employeeIndex.toString(),
                                                }),
                                            );
                                            unregister(registeredField2);
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTimes} style={{ color: 'inherit' }} />
                                </div>
                            </>
                        )}
                    </RFC>
                    {formState.errors[registeredField2] && formState.errors[registeredField2].type && (
                        <>
                            <Spacer direction="vertical" spaceParam="small" />
                            <p>{formState.errors[registeredField2].message}</p>
                            <Spacer direction="vertical" spaceParam="small" />
                        </>
                    )}

                    {operationIterator === currentCriticalEmployee.operations.allIDs.length - 1 && (
                        <>
                            <Spacer spaceParam="large" direction="vertical" />
                            <button
                                type="button"
                                className="button-secondary"
                                onClick={() => {
                                    dispatch(
                                        addNormalizedData({
                                            node: 'Critical operation',
                                            parentIndex: employeeIndex.toString(),
                                        }),
                                    );

                                    setAddedOperation(employeeIndex);
                                }}
                            >
                                Add another operation?
                            </button>
                        </>
                    )}
                </React.Fragment>,
            );
        }

        mappedFormElements.push(
            <React.Fragment key={employeeIterator}>
                <Spacer direction="vertical" spaceParam="large" />
                <RFC axis="row" justify="space-between">
                    <RFC axis="row" justify="flex-start" basis="60%">
                        {RRdata.criticalEmployeesList.allIDs.length > 1 && (
                            <>
                                <div
                                    className="fontAwesomeCircleWrapper"
                                    tabIndex={0}
                                    style={{ padding: '0.4rem 0.7rem 0.4rem' }}
                                    onClick={() => {
                                        setCriticalEmployeesListToggles((prev) => {
                                            let newList: Array<boolean> = [];
                                            prev.forEach((item, index) => {
                                                index === employeeIterator ? newList.push(!item) : newList.push(item);
                                            });
                                            return newList;
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            setCriticalEmployeesListToggles((prev) => {
                                                let newList: Array<boolean> = [];
                                                prev.forEach((item, index) => {
                                                    index === employeeIterator
                                                        ? newList.push(!item)
                                                        : newList.push(item);
                                                });
                                                return newList;
                                            });
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={criticalEmployeesListToggles[employeeIterator] ? faAngleDown : faAngleUp}
                                        style={{ color: 'inherit' }}
                                        size="lg"
                                    />
                                </div>
                                <Spacer spaceParam="medium" />
                            </>
                        )}
                        <label>
                            {RRdata.criticalEmployeesList.allIDs.length === 1
                                ? `Critical Employee${
                                      criticalEmployeesListToggles[employeeIterator]
                                          ? ''
                                          : ': ' + getValues(`criticalEmployee${employeeIndex}name`)
                                  }`
                                : `Critical Employee #${employeeIterator + 1}${
                                      criticalEmployeesListToggles[employeeIterator]
                                          ? ''
                                          : ': ' + getValues(`criticalEmployee${employeeIndex}name`)
                                  }`}
                        </label>
                    </RFC>

                    {RRdata.criticalEmployeesList.allIDs.length > 1 && (
                        <>
                            <div
                                className="fontAwesomeCircleWrapper"
                                tabIndex={0}
                                aria-describedby={removeCriticalEmployeeAnchorElID}
                                style={{ padding: '0.65rem 0.65rem 0.65rem 0.7rem' }}
                                onClick={(e) => {
                                    handleRemoveCriticalEmployeeToggle(e);
                                    setCriticalEmployeeRemovalTarget({
                                        index: employeeIterator,
                                        registeredFields: registeredFields,
                                    });
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleRemoveCriticalEmployeeToggle(e);
                                        setCriticalEmployeeRemovalTarget({
                                            index: employeeIterator,
                                            registeredFields: registeredFields,
                                        });
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'inherit' }} />
                            </div>
                            <Popper
                                id={removeCriticalEmployeeAnchorElID}
                                open={removeCriticalEmployeePopperToggled}
                                anchorEl={removeCriticalEmployeeAnchorEl}
                                transition
                            >
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={0}>
                                        <RFC
                                            axis="column"
                                            css={`
                                                background: ${theme.colors.blueGray[10]};
                                                padding: 1rem;
                                                width: 100%;
                                                max-width: 300px;
                                                border-radius: 1rem 1rem 1rem 1rem;
                                            `}
                                        >
                                            <h4>Remove Critical Employee</h4>
                                            <Spacer spaceParam="medium" />
                                            <span>
                                                Are you sure you want to remove this employee? This action cannot be
                                                undone.
                                            </span>
                                            <Spacer spaceParam="medium" />
                                            <RFC axis="row" justify="space-around">
                                                <button
                                                    className="button-tertiary"
                                                    onClick={(e) => handleRemoveCriticalEmployeeToggle(e)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="button-delete"
                                                    ref={deleteRef}
                                                    onClick={(e) => {
                                                        setTimeout(() => {
                                                            setCriticalEmployeesListToggles((prev) => {
                                                                let value = prev.pop();
                                                                if (value === true) {
                                                                    prev[prev.length - 1] = true;
                                                                }
                                                                return prev;
                                                            });

                                                            dispatch(
                                                                removeNormalizedData({
                                                                    node: 'Critical employee',
                                                                    //@ts-ignore
                                                                    targetIndex: CriticalEmployeeRemovalTarget.index,
                                                                }),
                                                            );
                                                            //@ts-ignore
                                                            for (let field of CriticalEmployeeRemovalTarget?.registeredFields) {
                                                                unregister(field);
                                                            }
                                                        }, 350);
                                                        handleRemoveCriticalEmployeeToggle(e);
                                                    }}
                                                >
                                                    Confirm
                                                </button>
                                            </RFC>
                                        </RFC>
                                    </Fade>
                                )}
                            </Popper>
                        </>
                    )}
                </RFC>
                <RFC
                    axis="row"
                    justify="flex-start"
                    css={criticalEmployeesListToggles[employeeIterator] ? '' : 'display: none;'}
                >
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" basis="100%">
                        <Spacer spaceParam="large" />
                        <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                            <label
                                htmlFor={registeredField1}
                                className={inputsWatch[registeredField1] ? 'input_label_transitioned' : ''}
                                onClick={(e) => setFocus(registeredField1)}
                            >
                                {RRdata.criticalEmployeesList.allIDs.length === 1
                                    ? `Name/role of critical employee`
                                    : `Name/role of critical employee #${employeeIterator + 1}`}
                            </label>
                            <input
                                className="predetermined-size-33"
                                {...register(registeredField1, {
                                    validate: {
                                        required: (value) =>
                                            (inputsWatch['criticalEmployees'] &&
                                                inputsWatch['criticalEmployees'].value !== 'Specific') ||
                                            (value && value.length > 0) ||
                                            'Must provide name',
                                        maxLength: (value) =>
                                            (inputsWatch['criticalEmployees'] &&
                                                inputsWatch['criticalEmployees'].value !== 'Specific') ||
                                            value.length === 0 ||
                                            value.length < 1000 ||
                                            'Length must be < 1000 characters',
                                        notBlankSpaces: (value) => {
                                            let regex = new RegExp(/.*\S.*/);
                                            let notOnlyBlanks = false;
                                            if (regex.exec(value)) {
                                                notOnlyBlanks = true;
                                            }
                                            return (
                                                (inputsWatch['criticalEmployees'] &&
                                                    inputsWatch['criticalEmployees'].value !== 'Specific') ||
                                                notOnlyBlanks ||
                                                value.length === 0 ||
                                                'Name cannot be only blank spaces'
                                            );
                                        },
                                        isSafe: (value) => {
                                            return isValueXSSSafe(value, 'Critical Employee name', userEmail)
                                                ? true
                                                : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                        },
                                    },
                                })}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </RFC>
                        {formState.errors[registeredField1] && formState.errors[registeredField1].type && (
                            <>
                                <Spacer direction="vertical" spaceParam="small" />
                                <p>{formState.errors[registeredField1].message}</p>
                                <Spacer direction="vertical" spaceParam="small" />
                            </>
                        )}

                        {mappedFormElementsLevel2}
                    </RFC>
                    <Spacer spaceParam="large" />
                </RFC>

                {employeeIterator === RRdata.criticalEmployeesList.allIDs.length - 1 && (
                    <>
                        <Spacer spaceParam="medium" direction="vertical" />
                        <button
                            type="button"
                            className="button-secondary"
                            onClick={() => {
                                dispatch(addNormalizedData({ node: 'Critical employee' }));
                                setAddedCriticalEmployee(true);
                                setCriticalEmployeesListToggles((prev) => {
                                    let newList: Array<boolean> = [];
                                    prev.forEach((item) => newList.push(false));
                                    newList.push(true);
                                    return newList;
                                });
                            }}
                        >
                            Add another critical employee?
                        </button>
                    </>
                )}
            </React.Fragment>,
        );
    }
    //mapped normalized fields medical employees
    for (let i = 0; i < RRdata.medicalEmergencyEmployeesList.allIDs.length; i++) {
        let registeredField = `employeeAllowed${RRdata.medicalEmergencyEmployeesList.allIDs[i]}`;

        mappedFormElements2.push(
            <React.Fragment key={i}>
                <Spacer direction="vertical" spaceParam="large" />

                <RFC
                    axis="row"
                    justify="flex-start"
                    className={
                        RRdata.medicalEmergencyEmployeesList.allIDs.length === 1
                            ? 'input_label_wrapper'
                            : 'input_label_wrapper_with_icon'
                    }
                >
                    <label
                        htmlFor={registeredField}
                        className={inputsWatch[registeredField] ? 'input_label_transitioned' : ''}
                        onClick={(e) => setFocus(registeredField)}
                    >
                        {RRdata.medicalEmergencyEmployeesList.allIDs.length === 1
                            ? `Name of employee allowed`
                            : `Name of employee #${i + 1} allowed`}
                    </label>
                    <input
                        className="predetermined-size-33"
                        {...register(registeredField, {
                            validate: {
                                required: (value) =>
                                    (inputsWatch['permission'] && inputsWatch['permission'].value !== 'Specific') ||
                                    (value && value.length > 0) ||
                                    'Must provide name',
                                maxLength: (value) =>
                                    (inputsWatch['permission'] && inputsWatch['permission'].value !== 'Specific') ||
                                    value.length === 0 ||
                                    value.length < 1000 ||
                                    'Length must be < 1000 characters',
                                notBlankSpaces: (value) => {
                                    let regex = new RegExp(/.*\S.*/);
                                    let notOnlyBlanks = false;
                                    if (regex.exec(value)) {
                                        notOnlyBlanks = true;
                                    }
                                    return (
                                        (inputsWatch['permission'] && inputsWatch['permission'].value !== 'Specific') ||
                                        notOnlyBlanks ||
                                        value.length === 0 ||
                                        'Name cannot be only blank spaces'
                                    );
                                },
                                isSafe: (value) => {
                                    return isValueXSSSafe(value, 'Allowed Employees', userEmail)
                                        ? true
                                        : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                },
                            },
                        })}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        }}
                    />
                    {RRdata.medicalEmergencyEmployeesList.allIDs.length > 1 && (
                        <>
                            <div
                                className="fontAwesomeCircleWrapper"
                                tabIndex={0}
                                aria-describedby={removeCriticalEmployeeAnchorElID}
                                style={{ padding: '0.5rem 0.6rem 0.45rem' }}
                                onClick={() => {
                                    dispatch(removeNormalizedData({ node: 'Medical employee', targetIndex: i }));
                                    unregister(registeredField);
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        dispatch(removeNormalizedData({ node: 'Medical employee', targetIndex: i }));
                                        unregister(registeredField);
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} style={{ color: 'inherit' }} />
                            </div>
                        </>
                    )}
                </RFC>
                {formState.errors[registeredField] && formState.errors[registeredField].type && (
                    <>
                        <Spacer direction="vertical" spaceParam="small" />
                        <p>{formState.errors[registeredField].message}</p>
                        <Spacer direction="vertical" spaceParam="small" />
                    </>
                )}

                {i === RRdata.medicalEmergencyEmployeesList.allIDs.length - 1 && (
                    <>
                        <Spacer spaceParam="large" direction="vertical" />
                        <button
                            type="button"
                            className="button-secondary"
                            onClick={() => {
                                dispatch(addNormalizedData({ node: 'Medical employee' }));
                                setAddedMedicalEmployee(true);
                            }}
                        >
                            Add another employee?
                        </button>
                    </>
                )}
            </React.Fragment>,
        );
    }
    return (
        <RFC axis="column" justify="flex-start" align="center">
            <FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                formWidth="70%"
                formMinWidth="350px"
                formMaxWidth="900px"
                inputWidth="100%"
                status={apiPostStatus}
            >
                <RFC axis="row" justify="space-between">
                    <h2 style={{ alignSelf: 'left' }}>{subSectionTitle}</h2>
                    {sectionStatus === 'completed' && (
                        <>
                            <Spacer spaceParam="large" />
                            {unSavedFields ? (
                                <h3 style={{ color: theme.colors.red[6] }}>Unsaved Changes</h3>
                            ) : (
                                <h3 style={{ color: theme.colors.cyan[5] }}>Complete</h3>
                            )}
                        </>
                    )}
                </RFC>

                <Spacer spaceParam="large" />
                <label>
                    In the event of an emergency, who is permitted to stay behind to shut down and/or monitor critical
                    business operations prior to evacuation?
                </label>
                <Spacer spaceParam="medium" />
                <Controller
                    name="criticalEmployees"
                    control={control}
                    defaultValue={{ value: 'pre-load', label: '...loading' }}
                    render={({ field: { onChange, value } }) => (
                        <Select
                            value={value}
                            options={criticalEmployeesOptions}
                            styles={SelectStyles}
                            onChange={onChange}
                            borderColor={theme.colors.blueGray[9]}
                            borderColorFocus={theme.colors.blue[5]}
                            backgroundFocus={theme.colors.yellow[10]}
                            selectedColor={theme.colors.blueGray[1]}
                            theme={(oldTheme) => ({
                                ...oldTheme,
                                borderRadius: 0,
                                colors: {
                                    ...oldTheme.colors,
                                    primary25: theme.colors.blue[8],
                                    primary: theme.colors.blue[5],
                                },
                            })}
                        />
                    )}
                />

                <RFC
                    axis="column"
                    align="flex-start"
                    css={
                        inputsWatch['criticalEmployees'] && inputsWatch['criticalEmployees'].value === 'Specific'
                            ? ''
                            : 'display: none;'
                    }
                >
                    <Spacer spaceParam="large" />
                    <span>
                        List the employees who are permitted to shut down and/or monitor critical business operations.
                    </span>
                    {mappedFormElements}
                </RFC>

                <Spacer spaceParam="large" />
                <Spacer spaceParam="large" />
                <label>
                    In the event of a medical emergency, who is permitted to administer CPR, operate AEDs, and perform
                    other medical duties?
                </label>
                <Spacer spaceParam="medium" />
                <Controller
                    name="permission"
                    control={control}
                    defaultValue={{ value: 'pre-load', label: '...loading' }}
                    render={({ field: { onChange, value } }) => (
                        <Select
                            value={value}
                            options={medicalEmployeesOptions}
                            styles={SelectStyles}
                            onChange={onChange}
                            borderColor={theme.colors.blueGray[9]}
                            borderColorFocus={theme.colors.blue[5]}
                            backgroundFocus={theme.colors.yellow[10]}
                            selectedColor={theme.colors.blueGray[1]}
                            theme={(oldTheme) => ({
                                ...oldTheme,
                                borderRadius: 0,
                                colors: {
                                    ...oldTheme.colors,
                                    primary25: theme.colors.blue[8],
                                    primary: theme.colors.blue[5],
                                },
                            })}
                        />
                    )}
                />

                <RFC
                    axis="column"
                    align="flex-start"
                    css={
                        inputsWatch['permission'] && inputsWatch['permission'].value === 'Specific'
                            ? ''
                            : 'display: none;'
                    }
                >
                    <Spacer spaceParam="large" />
                    <span>
                        List the employees who are permitted to administer CPR, operate AEDs, and perform other medical
                        duties.
                    </span>
                    <RFC axis="row" justify="flex-start">
                        <Spacer spaceParam="large" />
                        <RFC axis="column" align="flex-start" basis="100%">
                            {mappedFormElements2}
                        </RFC>
                        <Spacer spaceParam="large" />
                    </RFC>
                </RFC>

                <Spacer direction="vertical" spaceParam="large" />
                <RFC axis="column" justify="flex-start" align="flex-end" alignLaptop="center">
                    {(apiPostStatus === 'succeeded' || laptop) && (
                        <RFC
                            axis="row"
                            basis="75%"
                            align="center"
                            alignLaptop="center"
                            justify="flex-end"
                            justifyLaptop="flex-end"
                            css={!laptop ? 'padding-top: 1em;' : ''}
                        >
                            <SuccessAlert active={apiPostStatus === 'succeeded'} style={{ alignSelf: 'right' }}>
                                Successfully Saved!
                            </SuccessAlert>
                            <Spacer spaceParam="medium" direction="horizontal" />
                        </RFC>
                    )}
                    <RFC axis="row" justify="flex-end" basis="25%">
                        <StyledSaveButton
                            type="submit"
                            postStatus={apiPostStatus}
                            noChanges={!unSavedFields}
                            onClick={(e) => {
                                if (apiPostStatus !== 'idle') {
                                    e.preventDefault();
                                }
                            }}
                        >
                            Save
                        </StyledSaveButton>
                    </RFC>
                </RFC>
            </FormWrapper>
        </RFC>
    );
};

export const TrainingReview = () => {
    const TrainingReviewData = useAppSelector(selectFormsData).trainingAndReview;
    const {
        sectionIndex,
        subSectionIndex,
        subSectionTitle,
        dispatch,
        apiGetStatus,
        unSavedFields,
        sectionStatus,
        theme,
        apiPostStatus,
        laptop,
    } = useSectionsQualityOfLife();

    const setInitialValues = useRef(false);

    const { register, handleSubmit, formState, getValues, setValue, control, watch } = useForm({ mode: 'onSubmit' });
    let inputsWatch = watch();

    const userEmail = useAppSelector(selectUserEmail);

    useEffect(() => {
        if (!setInitialValues.current && apiGetStatus === 'succeeded') {
            setTimeout(() => {
                setValue(
                    'training',
                    frequencyOptions.find((item) => item.value === TrainingReviewData.trainingFrequency),
                );
                setValue('ICOCtrackTraining', TrainingReviewData.ICOCtrackTraining ? 'Yes' : 'No');
                setValue(
                    'review',
                    frequencyOptions.find((item) => item.value === TrainingReviewData.planReviewFrequency),
                );
                if (TrainingReviewData.lastReview) {
                    setValue('lastReview', dayjs(TrainingReviewData.lastReview).toDate());
                }
                setInitialValues.current = true;
            }, 0);
        }
    }, [apiGetStatus, TrainingReviewData, setValue, formState.errors]);
    useEffect(() => {
        if (!unSavedFields && setInitialValues.current) {
            if (
                (inputsWatch['training'] !== undefined &&
                    inputsWatch['training'].value !== 'pre-load' &&
                    inputsWatch['training'].value !== TrainingReviewData.trainingFrequency) ||
                (inputsWatch['review'] !== undefined &&
                    inputsWatch['review'].value !== 'pre-load' &&
                    inputsWatch['review'].value !== TrainingReviewData.planReviewFrequency) ||
                (inputsWatch[`ICOCtrackTraining`] !== undefined &&
                    (inputsWatch[`ICOCtrackTraining`] === 'Yes') !== TrainingReviewData.ICOCtrackTraining) ||
                (inputsWatch[`lastReview`] !== undefined &&
                    !(
                        dayjs(inputsWatch[`lastReview`]).format('DD-MMM-YYYY').toString() ===
                            TrainingReviewData.lastReview ||
                        dayjs(inputsWatch[`lastReview`]).format('DD-MMM-YYYY').toString() ===
                            dayjs().format('DD-MMM-YYYY').toString()
                    ))
            ) {
                dispatch(setUnSavedFields(true));
            }
        }
    }, [inputsWatch, unSavedFields, setInitialValues, dispatch, TrainingReviewData]);

    const onSubmit = (data: any) => {
        let TrainingReviewdataUpdate: typeof TrainingReviewData = {
            trainingFrequency: getValues('training')?.value,
            ICOCtrackTraining: getValues('ICOCtrackTraining') === 'Yes' ? true : false,
            planReviewFrequency: getValues('review')?.value,
            lastReview: dayjs(getValues('lastReview')).format('DD-MMM-YYYY').toString(),
        };
        dispatch(updateFormsDataNode({ node: 'trainingAndReview', data: TrainingReviewdataUpdate }));
        dispatch(
            updateSectionProgress({
                sectionIndex: sectionIndex,
                subSectionIndex: subSectionIndex,
                status: 'completed',
            }),
        );
        dispatch(setEAPDataToDB('trainingAndReview'));
        dispatch(setUnSavedFields(false));
    };

    return (
        <RFC axis="column" justify="flex-start" align="center">
            <FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                formWidth="70%"
                formMinWidth="350px"
                formMaxWidth="900px"
                inputWidth="100%"
                status={apiPostStatus}
            >
                <RFC axis="row" justify="space-between" css="width: 100%;">
                    <h2 style={{ alignSelf: 'left' }}>{subSectionTitle}</h2>
                    {sectionStatus === 'completed' && (
                        <>
                            <Spacer spaceParam="large" />
                            {unSavedFields ? (
                                <h3 style={{ color: theme.colors.red[6] }}>Unsaved Changes</h3>
                            ) : (
                                <h3 style={{ color: theme.colors.cyan[5] }}>Complete</h3>
                            )}
                        </>
                    )}
                </RFC>

                <Spacer spaceParam="large" />
                <label>Employee training for this plan and general emergency training is provided to staff:</label>
                <Spacer spaceParam="medium" />
                <Controller
                    name="training"
                    control={control}
                    defaultValue={{ value: 'pre-load', label: '...loading' }}
                    render={({ field: { onChange, value } }) => (
                        <Select
                            value={value}
                            options={frequencyOptions}
                            styles={SelectStyles}
                            onChange={onChange}
                            borderColor={theme.colors.blueGray[9]}
                            borderColorFocus={theme.colors.blue[5]}
                            backgroundFocus={theme.colors.yellow[10]}
                            selectedColor={theme.colors.blueGray[1]}
                            theme={(oldTheme) => ({
                                ...oldTheme,
                                borderRadius: 0,
                                colors: {
                                    ...oldTheme.colors,
                                    primary25: theme.colors.blue[8],
                                    primary: theme.colors.blue[5],
                                },
                            })}
                        />
                    )}
                />

                <Spacer spaceParam="large" />
                <label>Would you like to use In Case of Crisis to track training for each employee?</label>
                <Spacer spaceParam="medium" />
                <RFC axis="row" justify="flex-start">
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" className="radioButtonContainer">
                        <label>
                            <input {...register('ICOCtrackTraining')} type="radio" value="Yes" defaultChecked /> Yes
                        </label>
                        <Spacer spaceParam="small" />
                        <label>
                            <input {...register('ICOCtrackTraining')} type="radio" value="No" /> No
                        </label>
                    </RFC>
                </RFC>

                <Spacer spaceParam="large" />
                <label>The content of this plan is reviewed for accuracy and completeness:</label>
                <Spacer spaceParam="medium" />
                <Controller
                    name="review"
                    control={control}
                    defaultValue={{ value: 'pre-load', label: '...loading' }}
                    render={({ field: { onChange, value } }) => (
                        <Select
                            value={value}
                            options={frequencyOptions}
                            styles={SelectStyles}
                            onChange={onChange}
                            borderColor={theme.colors.blueGray[9]}
                            borderColorFocus={theme.colors.blue[5]}
                            backgroundFocus={theme.colors.yellow[10]}
                            selectedColor={theme.colors.blueGray[1]}
                            theme={(oldTheme) => ({
                                ...oldTheme,
                                borderRadius: 0,
                                colors: {
                                    ...oldTheme.colors,
                                    primary25: theme.colors.blue[8],
                                    primary: theme.colors.blue[5],
                                },
                            })}
                        />
                    )}
                />

                <Spacer spaceParam="large" />
                <label>Date of last review?</label>
                <Controller
                    name="lastReview"
                    control={control}
                    defaultValue={dayjs().toDate()}
                    render={({ field: { onChange, value } }) => <DatePicker selected={value} onChange={onChange} />}
                />

                <Spacer direction="vertical" spaceParam="large" />
                <RFC axis="column" justify="flex-start" align="flex-end" alignLaptop="center" css="width: 100%;">
                    {(apiPostStatus === 'succeeded' || laptop) && (
                        <RFC
                            axis="row"
                            basis="75%"
                            align="center"
                            alignLaptop="center"
                            justify="flex-end"
                            justifyLaptop="flex-end"
                            css={!laptop ? 'padding-top: 1em;' : ''}
                        >
                            <SuccessAlert active={apiPostStatus === 'succeeded'} style={{ alignSelf: 'right' }}>
                                Successfully Saved!
                            </SuccessAlert>
                            <Spacer spaceParam="medium" direction="horizontal" />
                        </RFC>
                    )}
                    <RFC axis="row" justify="flex-end" basis="25%">
                        <StyledSaveButton
                            type="submit"
                            postStatus={apiPostStatus}
                            noChanges={!unSavedFields}
                            onClick={(e) => {
                                if (apiPostStatus !== 'idle') {
                                    e.preventDefault();
                                }
                            }}
                        >
                            Save
                        </StyledSaveButton>
                    </RFC>
                </RFC>
            </FormWrapper>
        </RFC>
    );
};

export const AdditionalInfo = () => {
    const additionalInfo = useAppSelector(selectFormsData).additionalInfo;
    const {
        sectionIndex,
        subSectionIndex,
        subSectionTitle,
        dispatch,
        apiGetStatus,
        unSavedFields,
        sectionStatus,
        theme,
        apiPostStatus,
        laptop,
    } = useSectionsQualityOfLife();

    const setInitialValues = useRef(false);

    const { register, handleSubmit, setFocus, formState, getValues, setValue, watch } = useForm({ mode: 'onSubmit' });
    const inputsWatch = watch();

    const userEmail = useAppSelector(selectUserEmail);
    useEffect(() => {
        if (!setInitialValues.current && apiGetStatus === 'succeeded') {
            setTimeout(() => {
                setValue('additionalInfo', additionalInfo.additionalInfoChecked ? 'Yes' : 'No');
                setValue('additionalInfoText', additionalInfo.additionalInfoText);
                setInitialValues.current = true;
            }, 0);
        }
    }, [apiGetStatus, additionalInfo, setValue, formState.errors]);

    useEffect(() => {
        if (!unSavedFields && setInitialValues.current) {
            if (
                (inputsWatch[`additionalInfo`] !== undefined &&
                    (inputsWatch[`additionalInfo`] === 'Yes') !== additionalInfo.additionalInfoChecked) ||
                (inputsWatch[`additionalInfoText`] !== undefined &&
                    inputsWatch[`additionalInfoText`] !== additionalInfo.additionalInfoText)
            ) {
                dispatch(setUnSavedFields(true));
            }
        }
    }, [inputsWatch, unSavedFields, setInitialValues, dispatch, additionalInfo]);

    const onSubmit = (data: any) => {
        dispatch(
            updateFormsDataNode({
                node: 'additionalInfo',
                data: {
                    additionalInfoChecked: getValues('additionalInfo') === 'Yes' ? true : false,
                    additionalInfoText: getValues('additionalInfoText'),
                },
            }),
        );
        dispatch(purgeConditionalItems());
        dispatch(
            updateSectionProgress({
                sectionIndex: sectionIndex,
                subSectionIndex: subSectionIndex,
                status: 'completed',
            }),
        );
        dispatch(setEAPDataToDB('additionalInfo'));
        dispatch(setUnSavedFields(false));
    };

    return (
        <RFC axis="column" justify="flex-start" align="center">
            <FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                formWidth="70%"
                formMinWidth="350px"
                formMaxWidth="900px"
                inputWidth="100%"
                status={apiPostStatus}
            >
                <RFC axis="row" justify="space-between" css="width: 100%;">
                    <h2 style={{ alignSelf: 'left' }}>{subSectionTitle}</h2>
                    {sectionStatus === 'completed' && (
                        <>
                            <Spacer spaceParam="large" />
                            {unSavedFields ? (
                                <h3 style={{ color: theme.colors.red[6] }}>Unsaved Changes</h3>
                            ) : (
                                <h3 style={{ color: theme.colors.cyan[5] }}>Complete</h3>
                            )}
                        </>
                    )}
                </RFC>

                <Spacer spaceParam="large" />
                <label>Is there any additional information you would like to provide?</label>
                <Spacer spaceParam="medium" />
                <RFC axis="row" justify="flex-start">
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" className="radioButtonContainer">
                        <label>
                            <input {...register('additionalInfo')} type="radio" value="Yes" defaultChecked /> Yes
                        </label>
                        <Spacer spaceParam="small" />
                        <label>
                            <input {...register('additionalInfo')} type="radio" value="No" /> No
                        </label>
                    </RFC>
                </RFC>

                <Spacer spaceParam="large" />
                <RFC
                    axis="column"
                    align="flex-start"
                    css={inputsWatch['additionalInfo'] === 'Yes' ? '' : 'display: none;'}
                >
                    <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                        <textarea
                            {...register('additionalInfoText', {
                                validate: {
                                    maxLength: (value) =>
                                        value.length < 1000000 || 'Length must be < 1,000,000 characters',
                                    isSafe: (value) => {
                                        return isValueXSSSafe(value, 'Company info additional info', userEmail)
                                            ? true
                                            : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                    },
                                },
                            })}
                        />
                        <label
                            htmlFor={'additionalInfoText'}
                            className={inputsWatch['additionalInfoText'] ? 'input_label_transitioned' : ''}
                            onClick={(e) => setFocus('additionalInfoText')}
                        >
                            Provide Additional Information
                        </label>
                    </RFC>
                    {formState.errors['additionalInfoText'] && formState.errors['additionalInfoText'].type && (
                        <>
                            <Spacer direction="vertical" spaceParam="small" />
                            <p>{formState.errors['additionalInfoText'].message}</p>
                            <Spacer direction="vertical" spaceParam="small" />
                        </>
                    )}
                </RFC>

                <Spacer direction="vertical" spaceParam="large" />
                <RFC axis="column" justify="flex-start" align="flex-end" alignLaptop="center" css="width: 100%;">
                    {(apiPostStatus === 'succeeded' || laptop) && (
                        <RFC
                            axis="row"
                            basis="75%"
                            align="center"
                            alignLaptop="center"
                            justify="flex-end"
                            justifyLaptop="flex-end"
                            css={!laptop ? 'padding-top: 1em;' : ''}
                        >
                            <SuccessAlert active={apiPostStatus === 'succeeded'} style={{ alignSelf: 'right' }}>
                                Successfully Saved!
                            </SuccessAlert>
                            <Spacer spaceParam="medium" direction="horizontal" />
                        </RFC>
                    )}
                    <RFC axis="row" justify="flex-end" basis="25%">
                        <StyledSaveButton
                            type="submit"
                            postStatus={apiPostStatus}
                            noChanges={!unSavedFields}
                            onClick={(e) => {
                                if (apiPostStatus !== 'idle') {
                                    e.preventDefault();
                                }
                            }}
                        >
                            Save
                        </StyledSaveButton>
                    </RFC>
                </RFC>
            </FormWrapper>
        </RFC>
    );
};
