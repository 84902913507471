import { configureStore } from '@reduxjs/toolkit';
import sectionProgressReducer from './ReduxSlices/sectionProgressSlice';
import listingsReducer from './ReduxSlices/listingsSlice';
import userReducer from './ReduxSlices/userSlice';
import formsDataReducer from './ReduxSlices/formsData';

export const store = configureStore({
    reducer: {
        user: userReducer,
        listings: listingsReducer,
        sectionProgress: sectionProgressReducer,
        formsData: formsDataReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export interface NormalizedObjects<T> {
    byID: { [id: number]: T };
    allIDs: number[];
}
export interface sliceAPI {
    postStatus: 'idle' | 'pending' | 'succeeded' | 'failed';
    getStatus: 'idle' | 'pending' | 'succeeded' | 'failed';
    errorMessage: any;
}
