import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { prepareData, SetUserSavedState, handleErrorStatus } from '../Common/apiRequests';
import { setApiDateTimeRefresh } from './userSlice';
import { RootState, sliceAPI } from '../store';
import dayjs from 'dayjs';

type subSection = {
    subSectionTitle: string;
    subText: string;
    status: 'not started' | 'completed';
    routerURL: string;
    nextURL?: string;
};
export type section = {
    sectionTitle: string;
    sectionSubtext: string;
    subSections: Array<subSection>;
    routerURL: string;
};
export interface IsectionProgress extends sliceAPI {
    sections: Array<section>;
    helpPane: boolean;
}

const advisorySections: Array<section> = [
    {
        sectionTitle: 'Advisory',
        sectionSubtext: `Provide the required information for your advisory | THIS NEEDS COPY |`,
        subSections: [
            {
                subSectionTitle: 'Overview',
                subText: 'does this get used?',
                status: 'not started',
                routerURL: '/advisory/section1',
                nextURL: '/advisory/section2',
            },
            {
                subSectionTitle: 'Checklist',
                subText: 'if this gets used add copy',
                status: 'not started',
                routerURL: '/advisory/section2',
                nextURL: '/advisory/section3',
            },
            {
                subSectionTitle: 'Contacts',
                subText: 'if this gets used add copy',
                status: 'not started',
                routerURL: '/advisory/section3',
                nextURL: '/advisory/section4',
            },
            {
                subSectionTitle: 'Files',
                subText: 'if this gets used add copy',
                status: 'not started',
                routerURL: '/advisory/section4',
                nextURL: '/advisory/review',
            },
            {
                subSectionTitle: 'Review',
                subText: 'This is the review page',
                status: 'not started',
                routerURL: '/advisory/review',
                nextURL: '/advisory/build',
            },
            {
                subSectionTitle: 'Build',
                subText: 'This is the build / regenerate page',
                status: 'not started',
                routerURL: '/advisory/build',
                nextURL: '/nextsteps',
            },
        ],
        routerURL: '/advisory',
    },
];
const EAPsections: Array<section> = [
    {
        sectionTitle: 'Company',
        sectionSubtext: `Provide basic information regarding your company, contacts, roles and responsibilities,
            training, and any additional information you would like to provide within your Emergency Action Plan (EAP)
            that will become an actionable playbook.`,
        subSections: [
            {
                subSectionTitle: 'Basic Information',
                subText:
                    'Please provide information about the company, # of offices, plan contact information, and training plan',
                status: 'not started',
                routerURL: '/company-information/section1',
                nextURL: '/company-information/section2',
            },
            {
                subSectionTitle: 'Roles and Responsibilities',
                subText: 'Sometimes specific employees are responsible for shutting down critical operations',
                status: 'not started',
                routerURL: '/company-information/section2',
                nextURL: '/company-information/section3',
            },
            {
                subSectionTitle: 'Training and Review',
                subText: 'How will employees be trained and refreshed about this plan?',
                status: 'not started',
                routerURL: '/company-information/section3',
                nextURL: '/company-information/section4',
            },
            {
                subSectionTitle: 'Additional Information',
                subText: 'This is more of a free input section in case a company has extra info they would like to add',
                status: 'not started',
                routerURL: '/company-information/section4',
                nextURL: '/home',
            },
        ],
        routerURL: '/company-information',
    },
    {
        sectionTitle: 'Policies',
        sectionSubtext: `Answer simple questions and provide brief descriptions of your policies on alerts,
            evacuation procedures, and other topics specific to your organization.`,
        subSections: [
            {
                subSectionTitle: 'Alerts During an Emergency',
                subText: 'Indicate which alerts are used during an emergency',
                status: 'not started',
                routerURL: '/policies/section1',
                nextURL: '/policies/section2',
            },
            {
                subSectionTitle: 'Evacuation Procedures',
                subText: 'Specify company policy regarding evacuation at this location',
                status: 'not started',
                routerURL: '/policies/section2',
                nextURL: '/policies/section3',
            },
            {
                subSectionTitle: 'Fire',
                subText: 'Fire specifics',
                status: 'not started',
                routerURL: '/policies/section3',
                nextURL: '/policies/section4',
            },
            {
                subSectionTitle: 'Chemicals',
                subText: 'If you maintain chemicals onsite you will have to fill out this section',
                status: 'not started',
                routerURL: '/policies/section4',
                nextURL: '/home',
            },
        ],
        routerURL: '/policies',
    },
    {
        sectionTitle: 'Review',
        sectionSubtext: `Review and confirm the information you entered. If changes are needed, select the
            pencil icon to return to that section within the tool. This step is required before you may proceed
            to the final Build Playbook step.`,
        subSections: [
            {
                subSectionTitle: 'Review',
                subText: 'This section reviews and confirming the submitted information is correct',
                status: 'not started',
                routerURL: '/review',
                nextURL: '/home',
            },
        ],
        routerURL: '/review',
    },
    {
        sectionTitle: 'Build Playbook',
        sectionSubtext: `In this final step, provide a name for your playbook and click Create. Your new
            playbook will then be sent to the In Case of Crisis Online Portal to be published for download.`,
        subSections: [
            {
                subSectionTitle: 'Build Playbook',
                subText: 'This section is about easing them into the next step of integration',
                status: 'not started',
                routerURL: '/playbooks',
                nextURL: '/nextsteps',
            },
        ],
        routerURL: '/playbooks',
    },
];

const sectionProgress: IsectionProgress = {
    sections: EAPsections,
    helpPane: false,
    getStatus: 'idle',
    postStatus: 'idle',
    errorMessage: '',
};

export const setProgressToDB = createAsyncThunk<any, void, { state: RootState }>(
    'sectionProgress/setProgressToDB',
    async (_, { getState, dispatch, rejectWithValue }) => {
        let state = getState();
        let postDataForProgress = prepareData(state, '$.sectionProgress', { sections: state.sectionProgress.sections });
        let responseData = await SetUserSavedState(postDataForProgress);
        if (responseData.error) {
            handleErrorStatus(responseData.error, dispatch);
            return rejectWithValue(responseData.error);
        } else {
            dispatch(setApiDateTimeRefresh(dayjs().toISOString()));
        }
        return responseData;
    },
);

export const sectionProgressSlice = createSlice({
    name: 'sectionProgress',
    initialState: sectionProgress,
    reducers: {
        updateSectionProgress: (
            state,
            action: PayloadAction<{
                sectionIndex: number;
                subSectionIndex: number;
                status: 'not started' | 'completed';
            }>,
        ) => {
            state.sections[action.payload.sectionIndex].subSections[action.payload.subSectionIndex].status =
                action.payload.status;
        },
        updateAllSectionsProgress: (state, action: PayloadAction<IsectionProgress>) => {
            action.payload.sections.forEach((section, index) => {
                section.subSections.forEach((subSection, index2) => {
                    state.sections[index].subSections[index2].status = subSection.status;
                });
            });
        },
        toggleHelpPane: (state) => {
            state.helpPane = !state.helpPane;
        },
        resetSectionProgress: (state, action: PayloadAction<'EAP' | 'Advisory' | ''>) => {
            if (action.payload === 'Advisory') {
                state.sections = advisorySections;
            } else {
                state.sections = EAPsections;
            }
        },
        resetProgressGetStatus: (state) => {
            state.getStatus = 'idle';
        },
        resetProgressPostStatus: (state) => {
            state.postStatus = 'idle';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setProgressToDB.pending, (state) => {
                state.postStatus = 'pending';
            })
            .addCase(setProgressToDB.fulfilled, (state) => {
                state.getStatus = 'succeeded';
            })
            .addCase(setProgressToDB.rejected, (state, action) => {
                if (action.payload) {
                    state.errorMessage = action.payload;
                } else {
                    state.errorMessage = action.error.message;
                }
                state.getStatus = 'failed';
            });
    },
});

export const {
    updateSectionProgress,
    toggleHelpPane,
    updateAllSectionsProgress,
    resetSectionProgress,
    resetProgressGetStatus,
    resetProgressPostStatus,
} = sectionProgressSlice.actions;
export const selectAllSections = (state: RootState) => state.sectionProgress.sections;

export const selectProgressPostStatus = (state: RootState) => state.sectionProgress.postStatus;
export const selectHelpPane = (state: RootState) => state.sectionProgress.helpPane;
export const selectInfoSubSections = (state: RootState) =>
    state.sectionProgress.sections.find((section) => section.sectionTitle === 'Company')?.subSections;
export const selectReportingSubSections = (state: RootState) =>
    state.sectionProgress.sections.find((section) => section.sectionTitle === 'Policies')?.subSections;
export const selectEmergencySubSections = (state: RootState) =>
    state.sectionProgress.sections.find((section) => section.sectionTitle === 'Review')?.subSections;
export const selectAfterEmergencySubSections = (state: RootState) =>
    state.sectionProgress.sections.find((section) => section.sectionTitle === 'Build Playbook')?.subSections;
export const selectAdvisorySubSections = (state: RootState) =>
    state.sectionProgress.sections.find((section) => section.sectionTitle === 'Advisory')?.subSections;
export const selectIsProcessStarted = (state: RootState) => {
    let isProcessStarted = false;
    for (let section of state.sectionProgress.sections) {
        for (let subSection of section.subSections) {
            if (subSection.status === 'completed') {
                isProcessStarted = true;
            }
        }
    }
    return isProcessStarted;
};
export const selectIsProcessComplete = (state: RootState) => {
    let isComplete = true;
    for (let section of state.sectionProgress.sections) {
        for (let subSection of section.subSections) {
            if (subSection.status === 'not started') {
                isComplete = false;
            }
        }
    }
    return isComplete;
};
export const selectSectionStatuses = (state: RootState) => {
    let sectionStatuses: Array<'completed' | 'not started' | 'in progress'> = [];

    if (state.sectionProgress.sections[0].sectionTitle === 'Advisory') {
        for (let subSection of state.sectionProgress.sections[0].subSections) {
            sectionStatuses.push(subSection.status);
        }
    } else {
        for (let section of state.sectionProgress.sections) {
            let count = 0;
            for (let subSection of section.subSections) {
                if (subSection.status === 'completed') {
                    count++;
                }
            }
            if (count === 0) {
                sectionStatuses.push('not started');
            } else if (count === section.subSections.length) {
                sectionStatuses.push('completed');
            } else {
                sectionStatuses.push('in progress');
            }
        }
    }
    return sectionStatuses;
};

export default sectionProgressSlice.reducer;
