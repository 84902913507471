import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { styled, ThemeType } from '../Contexts/ThemeGlobalAndCtxt';
import { useTheme } from 'styled-components';

import { useAppSelector, useAppDispatch } from '../Common/Hooks/reactReduxHooks';
import {
    updateSectionProgress,
    selectSectionStatuses,
    setProgressToDB,
    section,
} from '../ReduxSlices/sectionProgressSlice';
import { selectFormsData, IEAPdata } from '../ReduxSlices/formsData';

import type { RootState } from '../store';

import { Spacer, HorizontalDivider, StyledDivLine } from '../Common/Components/Spacer';
import { RFC } from '../Common/Components/FlexContainers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-duotone-svg-icons';
import ReactTooltip from 'react-tooltip';

export const EAPReview = () => {
    //@ts-ignore
    const theme: ThemeType = useTheme();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const currentUrl = useLocation().pathname;
    const sections = useAppSelector((state: RootState) => {
        return state.sectionProgress.sections;
    });
    const sectionIndex = sections.findIndex((section) => section.routerURL === '/' + currentUrl.split('/')[1]);
    const subSectionIndex = sections[sectionIndex].subSections.findIndex(
        (subSection) => subSection.routerURL === currentUrl,
    );

    const sectionStatuses = useAppSelector(selectSectionStatuses);
    const formsData = useAppSelector(selectFormsData);

    return (
        <RFC axis="row">
            <RFCBorder axis="column" justify="flex-start" align="flex-start">
                <RFC axis="row" justify="space-between" css="width: 100%;">
                    <h2 style={{ alignSelf: 'left' }}>{sections[0].sectionTitle}</h2>
                    <Spacer spaceParam="large" />
                    {sectionStatuses[0] !== 'completed' ? (
                        <h3 style={{ color: theme.colors.red[6] }}>Incomplete</h3>
                    ) : (
                        <h3 style={{ color: theme.colors.cyan[5] }}>Complete</h3>
                    )}
                </RFC>

                <HorizontalDivider />

                <RFC axis="row" justify="space-between">
                    <h3>{sections[0].subSections[0].subSectionTitle}</h3>
                    <div
                        className="fontAwesomeCircleWrapper"
                        tabIndex={0}
                        style={{ padding: '0.7rem 0.6rem 0.7rem 0.8rem' }}
                        onClick={() => {
                            history.push(sections[0].subSections[0].routerURL);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                history.push(sections[0].subSections[0].routerURL);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} style={{ color: 'inherit' }} />
                    </div>
                </RFC>
                <span>
                    Company Name:&nbsp;&nbsp;&nbsp;<b>{formsData.basicInfo.companyName}</b>
                </span>
                <span>
                    Site Nickname:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{formsData.basicInfo.address?.siteNickname}</b>
                </span>
                <span>
                    Address:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                        {formsData.basicInfo.address?.streetAddress}, {formsData.basicInfo.address?.city},{' '}
                        {formsData.basicInfo.address?.state} {formsData.basicInfo.address?.zip}
                    </b>
                </span>
                <br />
                <span>Primary Company Contact(s):</span>
                <br />
                {formsData.basicInfo.companyPlanContacts.allIDs.map((item, index) => {
                    return (
                        <RFC axis="row" justify="flex-start" key={index}>
                            <Spacer spaceParam="medium" />
                            <span>
                                Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{formsData.basicInfo.companyPlanContacts.byID[item].name}</b>
                                <br />
                                Title:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{formsData.basicInfo.companyPlanContacts.byID[item].title}</b>
                                <br />
                                Phone:&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{formsData.basicInfo.companyPlanContacts.byID[item].phone}</b>
                                <br />
                                Email:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{formsData.basicInfo.companyPlanContacts.byID[item].email}</b>
                                <br />
                                <br />
                            </span>
                        </RFC>
                    );
                })}
                <span>
                    Company Contact(s) for employees to ask questions about this plan, training, or their duties during
                    an emergency:
                </span>
                <br />
                {formsData.basicInfo.companyPlanContacts.allIDs.map((item, index) => {
                    if (formsData.basicInfo.companyPlanContacts.byID[item].employeeContact) {
                        return (
                            <RFC axis="row" justify="flex-start" key={index}>
                                <Spacer spaceParam="medium" />
                                <span>
                                    Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <b>{formsData.basicInfo.companyPlanContacts.byID[item].name}</b>
                                    <br />
                                    Title:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <b>{formsData.basicInfo.companyPlanContacts.byID[item].title}</b>
                                    <br />
                                    Phone:&nbsp;&nbsp;&nbsp;&nbsp;
                                    <b>{formsData.basicInfo.companyPlanContacts.byID[item].phone}</b>
                                    <br />
                                    Email:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <b>{formsData.basicInfo.companyPlanContacts.byID[item].email}</b>
                                    <br />
                                    <br />
                                </span>
                            </RFC>
                        );
                    } else {
                        return <React.Fragment key={index}></React.Fragment>;
                    }
                })}

                <HorizontalDivider />

                <RFC axis="row" justify="space-between">
                    <h3>{sections[0].subSections[1].subSectionTitle}</h3>
                    <div
                        className="fontAwesomeCircleWrapper"
                        tabIndex={0}
                        style={{ padding: '0.7rem 0.6rem 0.7rem 0.8rem' }}
                        onClick={() => {
                            history.push(sections[0].subSections[1].routerURL);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                history.push(sections[0].subSections[1].routerURL);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} style={{ color: 'inherit' }} />
                    </div>
                </RFC>
                <span>
                    In the event of an emergency, who is permitted to stay behind to shut down and/or monitor critical
                    business operations prior to evacuation:
                </span>
                <br />
                {formsData.rolesResponsibilities.criticalEmployeesPermissions !== 'Specific' ? (
                    <>
                        <span>
                            <b>{formsData.rolesResponsibilities.criticalEmployeesPermissions}</b>
                        </span>
                        <br />
                    </>
                ) : (
                    formsData.rolesResponsibilities.criticalEmployeesList.allIDs.map((item, index) => {
                        return (
                            <RFC axis="row" justify="flex-start" key={index}>
                                <Spacer spaceParam="medium" />
                                <span>
                                    Name/Role #{index + 1}:&nbsp;&nbsp;&nbsp;&nbsp;
                                    <b>{formsData.rolesResponsibilities.criticalEmployeesList.byID[item].name}</b>
                                    <br />
                                    {formsData.rolesResponsibilities.criticalEmployeesList.byID[
                                        item
                                    ].operations.allIDs.map((item2, index2) => {
                                        return (
                                            <RFC axis="row" justify="flex-start" key={index2}>
                                                <Spacer spaceParam="medium" />
                                                <span>
                                                    Operation/Procedure #{index2 + 1}:&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <b>
                                                        {
                                                            formsData.rolesResponsibilities.criticalEmployeesList.byID[
                                                                item
                                                            ].operations.byID[item2]
                                                        }
                                                    </b>
                                                </span>
                                            </RFC>
                                        );
                                    })}
                                    <br />
                                </span>
                            </RFC>
                        );
                    })
                )}
                <span>
                    In the event of a medical emergency, who is permitted to administer CPR, operate AEDs, and perform
                    other medical duties:
                </span>
                <br />
                {formsData.rolesResponsibilities.medicalEmergencyPermissions !== 'Specific' ? (
                    <>
                        <span>
                            <b>{formsData.rolesResponsibilities.criticalEmployeesPermissions}</b>
                        </span>
                        <br />
                    </>
                ) : (
                    formsData.rolesResponsibilities.medicalEmergencyEmployeesList.allIDs.map((item, index) => {
                        return (
                            <RFC axis="row" justify="flex-start" key={index}>
                                <Spacer spaceParam="medium" />
                                <span>
                                    Name #{index + 1}:&nbsp;&nbsp;&nbsp;&nbsp;
                                    <b>{formsData.rolesResponsibilities.medicalEmergencyEmployeesList.byID[item]}</b>
                                    <br />
                                    <br />
                                </span>
                            </RFC>
                        );
                    })
                )}

                <HorizontalDivider />

                <RFC axis="row" justify="space-between">
                    <h3>{sections[0].subSections[2].subSectionTitle}</h3>
                    <div
                        className="fontAwesomeCircleWrapper"
                        tabIndex={0}
                        style={{ padding: '0.7rem 0.6rem 0.7rem 0.8rem' }}
                        onClick={() => {
                            history.push(sections[0].subSections[2].routerURL);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                history.push(sections[0].subSections[2].routerURL);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} style={{ color: 'inherit' }} />
                    </div>
                </RFC>
                <span>
                    Employee training for this plan and general emergency training is provided to
                    staff:&nbsp;&nbsp;&nbsp;&nbsp;<b>{formsData.trainingAndReview.trainingFrequency}</b>
                </span>
                <span>
                    In Case of Crisis will track training for each employee:&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>{formsData.trainingAndReview.ICOCtrackTraining ? 'Yes' : 'No'}</b>
                </span>
                <span>
                    The content of this plan is reviewed for accuracy and completeness:&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>{formsData.trainingAndReview.planReviewFrequency}</b>
                </span>
                <span>
                    Date of last Review:&nbsp;&nbsp;&nbsp;&nbsp;<b>{formsData.trainingAndReview.lastReview}</b>
                </span>

                <HorizontalDivider />

                <RFC axis="row" justify="space-between">
                    <h3>{sections[0].subSections[3].subSectionTitle}</h3>
                    <div
                        className="fontAwesomeCircleWrapper"
                        tabIndex={0}
                        style={{ padding: '0.7rem 0.6rem 0.7rem 0.8rem' }}
                        onClick={() => {
                            history.push(sections[0].subSections[3].routerURL);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                history.push(sections[0].subSections[3].routerURL);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} style={{ color: 'inherit' }} />
                    </div>
                </RFC>
                <span>
                    Is there any additional information you would like to provide:&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                        {!formsData.additionalInfo.additionalInfoChecked ? (
                            'No'
                        ) : (
                            <>
                                <br />
                                {formsData.additionalInfo.additionalInfoText}
                            </>
                        )}
                    </b>
                </span>

                <HorizontalDivider />

                <Spacer spaceParam="large" />
                <Spacer spaceParam="large" />
                <RFC axis="row" justify="space-between" css="width: 100%;">
                    <h2 style={{ alignSelf: 'left' }}>{sections[1].sectionTitle}</h2>
                    <Spacer spaceParam="large" />
                    {sectionStatuses[1] !== 'completed' ? (
                        <h3 style={{ color: theme.colors.red[6] }}>Incomplete</h3>
                    ) : (
                        <h3 style={{ color: theme.colors.cyan[5] }}>Complete</h3>
                    )}
                </RFC>
                <HorizontalDivider />

                <RFC axis="row" justify="space-between">
                    <h3>{sections[1].subSections[0].subSectionTitle}</h3>
                    <div
                        className="fontAwesomeCircleWrapper"
                        tabIndex={0}
                        style={{ padding: '0.7rem 0.6rem 0.7rem 0.8rem' }}
                        onClick={() => {
                            history.push(sections[1].subSections[0].routerURL);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                history.push(sections[1].subSections[0].routerURL);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} style={{ color: 'inherit' }} />
                    </div>
                </RFC>
                <span>In an emergency, employees are alerted by:</span>
                <br />
                <RFC axis="row" justify="flex-start">
                    <Spacer spaceParam="medium" />
                    <span>
                        {formsData.alerts.alertsUsed.verbal && (
                            <b>
                                Verbal Alert
                                <br />
                            </b>
                        )}
                        {formsData.alerts.alertsUsed.ICOCnotifications && (
                            <b>
                                In Case of Crisis Notifications
                                <br />
                            </b>
                        )}
                        {formsData.alerts.alertsUsed.PAsystem && (
                            <b>
                                PA System
                                <br />
                            </b>
                        )}
                        {formsData.alerts.alertsUsed.onSiteAlarmSystem && (
                            <b>
                                On-site alarm system
                                <br />
                            </b>
                        )}
                        {formsData.alerts.alertsUsed.emergencyMassNotification && (
                            <b>
                                Emergency mass notification tool
                                <br />
                            </b>
                        )}
                        {formsData.alerts.alertsUsed.otherAlert.otherAlertChecked && (
                            <b>
                                Other: {formsData.alerts.alertsUsed.otherAlert.otherAlertText}
                                <br />
                            </b>
                        )}
                    </span>
                </RFC>
                <br />
                <span>
                    Identify and describe the specific alarm used for <em>fire</em> emergency:
                </span>
                <span>
                    <b>{formsData.alerts.specificAlarms.fire}</b>
                </span>
                <br />
                <span>
                    Identify and describe the specific alarm used for <em>earthquake</em> emergency:
                </span>
                <span>
                    <b>{formsData.alerts.specificAlarms.earthquake}</b>
                </span>
                <br />
                <span>
                    Identify and describe the specific alarm used for <em>evacuation</em> emergency:
                </span>
                <span>
                    <b>{formsData.alerts.specificAlarms.evacuation}</b>
                </span>
                <br />

                <span>
                    Do you provide drills and training to employees for each emergency alert:{' '}
                    {formsData.alerts.drills.drillsChecked ? <b>Yes</b> : <b>No</b>}
                </span>
                {formsData.alerts.drills.drillsChecked && (
                    <>
                        <span>
                            <b>{formsData.alerts.drills.drillsText}</b>
                        </span>
                        <br />
                    </>
                )}

                <span>
                    What procedures should employees follow to report an emergency: <br />
                    <b>{formsData.alerts.reporting}</b>
                </span>
                <HorizontalDivider />

                <RFC axis="row" justify="space-between">
                    <h3>{sections[1].subSections[1].subSectionTitle}</h3>
                    <div
                        className="fontAwesomeCircleWrapper"
                        tabIndex={0}
                        style={{ padding: '0.7rem 0.6rem 0.7rem 0.8rem' }}
                        onClick={() => {
                            history.push(sections[1].subSections[1].routerURL);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                history.push(sections[1].subSections[1].routerURL);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} style={{ color: 'inherit' }} />
                    </div>
                </RFC>
                <span>
                    Does the following accurately state your emergency evacuation policy: “In the event of an emergency,
                    ALL employees should evacuate immediately.”&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>{formsData.evacuation.policyProcedures.evacuationPolicy.allEmployeesEvacuate ? 'Yes' : 'No'}</b>
                </span>
                <br />
                <span>Additional information about your evacuation policies:</span>
                <br />
                <span>
                    <b>{formsData.evacuation.policyProcedures.evacuationPolicy.evacuationPolicyDetails}</b>
                </span>
                <br />
                <span>
                    Do you assign Floor Wardens at this location to help manage safe and effective
                    evacuation:&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>{formsData.evacuation.policyProcedures.floorWardens.floorWardensExist ? 'Yes' : 'No'}</b>
                </span>
                <br />
                {formsData.evacuation.policyProcedures.floorWardens.floorWardensExist && (
                    <>
                        <span>
                            Additional Info about Floor Wardens: <br />
                            <b>{formsData.evacuation.policyProcedures.floorWardens.wardensPolicyDetails}</b>
                        </span>
                        <br />
                    </>
                )}
                <span>
                    Does the following accurately state your emergency evacuation routes? “In the event of an emergency,
                    ALL employees should evacuate through the nearest available marked exit.”&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>{formsData.evacuation.evacuationRoutes.routesDefault ? 'Yes' : 'No'}</b>
                </span>
                <br />
                <span>Additional information about your evacuation routes:</span>
                <br />
                <span>
                    <b>{formsData.evacuation.evacuationRoutes.routesDescription}</b>
                </span>
                <br />

                <span>Designated Assembly Areas used at this location during an evacuation:</span>
                <br />
                {formsData.evacuation.assembly.assemblyPoints.allIDs.map((item, index) => {
                    return (
                        <RFC axis="row" justify="flex-start" key={index}>
                            <Spacer spaceParam="medium" />
                            <span>
                                Name:&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{formsData.evacuation.assembly.assemblyPoints.byID[item].name}</b>
                                <br />
                                Description:&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{formsData.evacuation.assembly.assemblyPoints.byID[item].description}</b>
                                <br />
                                <br />
                            </span>
                        </RFC>
                    );
                })}
                <span>Once gathered at the assembly point after evacuation, how you account for your employees:</span>
                <br />
                <span>
                    <b>{formsData.evacuation.rollCall.description}</b>
                </span>
                <br />
                <span>
                    Do you use or plan to use In Case of Crisis to manage the Roll Call process for
                    you:&nbsp;&nbsp;&nbsp;&nbsp;<b>{formsData.evacuation.rollCall.ICOCmanaged ? 'Yes' : 'No'}</b>
                </span>

                <HorizontalDivider />

                <RFC axis="row" justify="space-between">
                    <h3>{sections[1].subSections[2].subSectionTitle}</h3>
                    <div
                        className="fontAwesomeCircleWrapper"
                        tabIndex={0}
                        style={{ padding: '0.7rem 0.6rem 0.7rem 0.8rem' }}
                        onClick={() => {
                            history.push(sections[1].subSections[2].routerURL);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                history.push(sections[1].subSections[2].routerURL);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} style={{ color: 'inherit' }} />
                    </div>
                </RFC>
                <span>What specific steps should an employee follow in the event of a fire emergency:</span>
                <br />
                <span>
                    <b>{formsData.fire.policyProcedures}</b>
                </span>
                <br />
                <span>
                    Do you have portable fire extinguishers on site:&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>{formsData.fire.portableFireExtinguishers ? 'Yes' : 'No'}</b>
                </span>
                <br />
                {formsData.fire.portableFireExtinguishers && (
                    <>
                        <span>
                            In the event of a fire, who is permitted to use portable fire
                            extinguishers:&nbsp;&nbsp;&nbsp;&nbsp;
                            <b>
                                {formsData.fire.permissionToUse === 'All'
                                    ? 'All Employees'
                                    : formsData.fire.permissionToUse === 'None'
                                    ? 'Employees are not permitted to use the portable fire extinguisher'
                                    : ''}
                            </b>
                        </span>
                        <br />
                    </>
                )}
                {formsData.fire.portableFireExtinguishers &&
                    formsData.fire.permissionToUse === 'Specific' &&
                    formsData.fire.employeesAllowed.allIDs.map((item, index) => {
                        return (
                            <RFC axis="row" justify="flex-start" key={index}>
                                <Spacer spaceParam="medium" />
                                <span>
                                    Name #{index + 1}:&nbsp;&nbsp;&nbsp;&nbsp;
                                    <b>{formsData.fire.employeesAllowed.byID[item]}</b>
                                    <br />
                                    <br />
                                </span>
                            </RFC>
                        );
                    })}

                <HorizontalDivider />

                <RFC axis="row" justify="space-between">
                    <h3>{sections[1].subSections[3].subSectionTitle}</h3>
                    <div
                        className="fontAwesomeCircleWrapper"
                        tabIndex={0}
                        style={{ padding: '0.7rem 0.6rem 0.7rem 0.8rem' }}
                        onClick={() => {
                            history.push(sections[1].subSections[3].routerURL);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                history.push(sections[1].subSections[3].routerURL);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} style={{ color: 'inherit' }} />
                    </div>
                </RFC>
                <span>
                    Do you maintain chemicals onsite at this location:&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>{formsData.chemicals.chemicalsOnsite ? 'Yes' : 'No'}</b>
                </span>
                <br />
                {formsData.chemicals.chemicalsOnsite && (
                    <>
                        {formsData.chemicals.chemicalsList.allIDs.map((item, index) => {
                            return (
                                <RFC axis="row" justify="flex-start" key={index}>
                                    <Spacer spaceParam="medium" />
                                    <span>
                                        Chemical #{index + 1}:&nbsp;&nbsp;&nbsp;&nbsp;
                                        <b>{formsData.chemicals.chemicalsList.byID[item]}</b>
                                        <br />
                                        <br />
                                    </span>
                                </RFC>
                            );
                        })}

                        <span>
                            Do you have the proper documentation onsite to the SDS for each
                            chemical:&nbsp;&nbsp;&nbsp;&nbsp;
                            <b>{formsData.chemicals.properDocumentation ? 'Yes' : 'No'}</b>
                        </span>
                        <br />
                        <span>What is the process for reporting near misses:</span>
                        <br />
                        <span>
                            <b>{formsData.chemicals.reportingNearMisses}</b>
                        </span>
                        <br />
                        <span>What is the process for reporting spills:</span>
                        <br />
                        <span>
                            <b>{formsData.chemicals.reportingSpills}</b>
                        </span>
                        <br />
                    </>
                )}

                <HorizontalDivider />

                <Spacer spaceParam="large" />
                {sectionStatuses[0] !== 'completed' || sectionStatuses[1] !== 'completed' ? (
                    <span data-tip="Must complete both the Company section and the Policies section before completing Review">
                        <RFC axis="row" justify="center">
                            <button disabled>Confirm</button>
                        </RFC>
                    </span>
                ) : (
                    <RFC axis="row" justify="center">
                        <button
                            onClick={() => {
                                dispatch(
                                    updateSectionProgress({
                                        sectionIndex: sectionIndex,
                                        subSectionIndex: subSectionIndex,
                                        status: 'completed',
                                    }),
                                );
                                dispatch(setProgressToDB());
                                history.push('/home');
                            }}
                        >
                            {sectionStatuses[2] === 'completed' ? 'Reviewed' : 'Confirm'}
                        </button>
                    </RFC>
                )}
                <ReactTooltip
                    backgroundColor={theme.colors.blue[5]}
                    textColor={theme.colors.white}
                    place="top"
                    effect="solid"
                />
                <Spacer spaceParam="large" />
                <Spacer spaceParam="large" />
            </RFCBorder>
        </RFC>
    );
};

export const EAPReviewPDF = ({
    formsData,
    sections,
    metaData,
}: {
    formsData: IEAPdata;
    sections: Array<section>;
    metaData: { Org: string; EditedBy: string; lastEdited: string };
}) => {
    return (
        <PDFBorder>
            <h2>EAP Data For Playbook: {formsData.playbookInfo.playbookName}</h2>
            <br />
            <br />
            <h2>Organization: {metaData.Org}</h2>
            <br />
            <br />
            <span>Last Edited By: {metaData.EditedBy}</span>
            <br />
            <span>Last Edited: {metaData.lastEdited}</span>
            <br />
            <br />
            <br />
            <br />
            <br />

            <h2>{sections[0].sectionTitle}</h2>

            <br />
            <StyledDivLine />
            <br />

            <h3>{sections[0].subSections[0].subSectionTitle}</h3>
            <br />

            <span>
                Company Name:&nbsp;&nbsp;&nbsp;<b>{formsData.basicInfo.companyName}</b>
            </span>
            <br />
            <span>
                Site Nickname:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{formsData.basicInfo.address?.siteNickname}</b>
            </span>
            <br />
            <span>
                Address:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <b>
                    {formsData.basicInfo.address?.streetAddress}, {formsData.basicInfo.address?.city},{' '}
                    {formsData.basicInfo.address?.state} {formsData.basicInfo.address?.zip}
                </b>
            </span>
            <br />
            <br />
            <span>Primary Company Contact(s):</span>
            <br />
            <br />
            {formsData.basicInfo.companyPlanContacts.allIDs.map((item, index) => {
                return (
                    <RFC axis="row" justify="flex-start" key={index}>
                        <Spacer spaceParam="medium" />
                        <span>
                            Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <b>{formsData.basicInfo.companyPlanContacts.byID[item].name}</b>
                            <br />
                            Title:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <b>{formsData.basicInfo.companyPlanContacts.byID[item].title}</b>
                            <br />
                            Phone:&nbsp;&nbsp;&nbsp;&nbsp;
                            <b>{formsData.basicInfo.companyPlanContacts.byID[item].phone}</b>
                            <br />
                            Email:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <b>{formsData.basicInfo.companyPlanContacts.byID[item].email}</b>
                            <br />
                            <br />
                        </span>
                    </RFC>
                );
            })}
            <span>
                Company Contact(s) for employees to ask questions about this plan, training, or their duties during an
                emergency:
            </span>
            <br />
            <br />
            {formsData.basicInfo.companyPlanContacts.allIDs.map((item, index) => {
                if (formsData.basicInfo.companyPlanContacts.byID[item].employeeContact) {
                    return (
                        <RFC axis="row" justify="flex-start" key={index}>
                            <Spacer spaceParam="medium" />
                            <span>
                                Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{formsData.basicInfo.companyPlanContacts.byID[item].name}</b>
                                <br />
                                Title:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{formsData.basicInfo.companyPlanContacts.byID[item].title}</b>
                                <br />
                                Phone:&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{formsData.basicInfo.companyPlanContacts.byID[item].phone}</b>
                                <br />
                                Email:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{formsData.basicInfo.companyPlanContacts.byID[item].email}</b>
                                <br />
                                <br />
                            </span>
                        </RFC>
                    );
                } else {
                    return <React.Fragment key={index}></React.Fragment>;
                }
            })}

            <br />
            <StyledDivLine />
            <br />

            <h3>{sections[0].subSections[1].subSectionTitle}</h3>
            <br />

            <span>
                In the event of an emergency, who is permitted to stay behind to shut down and/or monitor critical
                business operations prior to evacuation:
            </span>
            <br />
            {formsData.rolesResponsibilities.criticalEmployeesPermissions !== 'Specific' ? (
                <>
                    <span>
                        <b>{formsData.rolesResponsibilities.criticalEmployeesPermissions}</b>
                    </span>
                    <br />
                </>
            ) : (
                formsData.rolesResponsibilities.criticalEmployeesList.allIDs.map((item, index) => {
                    return (
                        <RFC axis="row" justify="flex-start" key={index}>
                            <Spacer spaceParam="medium" />
                            <span>
                                Name/Role #{index + 1}:&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{formsData.rolesResponsibilities.criticalEmployeesList.byID[item].name}</b>
                                <br />
                                {formsData.rolesResponsibilities.criticalEmployeesList.byID[item].operations.allIDs.map(
                                    (item2, index2) => {
                                        return (
                                            <RFC axis="row" justify="flex-start" key={index2}>
                                                <Spacer spaceParam="medium" />
                                                <span>
                                                    Operation/Procedure #{index2 + 1}:&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <b>
                                                        {
                                                            formsData.rolesResponsibilities.criticalEmployeesList.byID[
                                                                item
                                                            ].operations.byID[item2]
                                                        }
                                                    </b>
                                                </span>
                                            </RFC>
                                        );
                                    },
                                )}
                                <br />
                            </span>
                        </RFC>
                    );
                })
            )}
            <br />

            <span>
                In the event of a medical emergency, who is permitted to administer CPR, operate AEDs, and perform other
                medical duties:
            </span>
            <br />
            {formsData.rolesResponsibilities.medicalEmergencyPermissions !== 'Specific' ? (
                <>
                    <span>
                        <b>{formsData.rolesResponsibilities.criticalEmployeesPermissions}</b>
                    </span>
                    <br />
                </>
            ) : (
                formsData.rolesResponsibilities.medicalEmergencyEmployeesList.allIDs.map((item, index) => {
                    return (
                        <RFC axis="row" justify="flex-start" key={index}>
                            <Spacer spaceParam="medium" />
                            <span>
                                Name #{index + 1}:&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{formsData.rolesResponsibilities.medicalEmergencyEmployeesList.byID[item]}</b>
                                <br />
                                <br />
                            </span>
                        </RFC>
                    );
                })
            )}
            <br />

            <br />
            <StyledDivLine />
            <br />

            <h3>{sections[0].subSections[2].subSectionTitle}</h3>
            <br />

            <span>
                Employee training for this plan and general emergency training is provided to
                staff:&nbsp;&nbsp;&nbsp;&nbsp;<b>{formsData.trainingAndReview.trainingFrequency}</b>
            </span>
            <br />
            <br />
            <span>
                In Case of Crisis will track training for each employee:&nbsp;&nbsp;&nbsp;&nbsp;
                <b>{formsData.trainingAndReview.ICOCtrackTraining ? 'Yes' : 'No'}</b>
            </span>
            <br />
            <br />
            <span>
                The content of this plan is reviewed for accuracy and completeness:&nbsp;&nbsp;&nbsp;&nbsp;
                <b>{formsData.trainingAndReview.planReviewFrequency}</b>
            </span>
            <br />
            <br />
            <span>
                Date of last Review:&nbsp;&nbsp;&nbsp;&nbsp;<b>{formsData.trainingAndReview.lastReview}</b>
            </span>
            <br />

            <br />
            <StyledDivLine />
            <br />

            <h3>{sections[0].subSections[3].subSectionTitle}</h3>
            <br />

            <span>
                Is there any additional information you would like to provide:&nbsp;&nbsp;&nbsp;&nbsp;
                <b>
                    {!formsData.additionalInfo.additionalInfoChecked ? (
                        'No'
                    ) : (
                        <>
                            <br />
                            {formsData.additionalInfo.additionalInfoText}
                        </>
                    )}
                </b>
            </span>
            <br />

            <br />
            <StyledDivLine />
            <br />

            <Spacer spaceParam="large" />
            <Spacer spaceParam="large" />
            <h2>{sections[1].sectionTitle}</h2>
            <br />
            <StyledDivLine />
            <br />

            <h3>{sections[1].subSections[0].subSectionTitle}</h3>
            <br />

            <span>In an emergency, employees are alerted by:</span>
            <br />
            <RFC axis="row" justify="flex-start">
                <Spacer spaceParam="medium" />
                <span>
                    {formsData.alerts.alertsUsed.verbal && (
                        <>
                            <b>Verbal Alert</b>
                            <br />
                        </>
                    )}
                    {formsData.alerts.alertsUsed.ICOCnotifications && (
                        <>
                            <b>In Case of Crisis Notifications</b>
                            <br />
                        </>
                    )}
                    {formsData.alerts.alertsUsed.PAsystem && (
                        <>
                            <b>PA System</b>
                            <br />
                        </>
                    )}
                    {formsData.alerts.alertsUsed.onSiteAlarmSystem && (
                        <>
                            <b>On-site alarm system</b>
                            <br />
                        </>
                    )}
                    {formsData.alerts.alertsUsed.emergencyMassNotification && (
                        <>
                            <b>Emergency mass notification tool</b>
                            <br />
                        </>
                    )}
                    {formsData.alerts.alertsUsed.otherAlert.otherAlertChecked && (
                        <>
                            <b>Other: {formsData.alerts.alertsUsed.otherAlert.otherAlertText}</b>
                            <br />
                        </>
                    )}
                </span>
            </RFC>
            <br />
            <span>
                Identify and describe the specific alarm used for <em>fire</em> emergency:
            </span>
            <br />
            <span>
                <b>{formsData.alerts.specificAlarms.fire}</b>
            </span>
            <br />
            <br />
            <span>
                Identify and describe the specific alarm used for <em>earthquake</em> emergency:
            </span>
            <br />
            <span>
                <b>{formsData.alerts.specificAlarms.earthquake}</b>
            </span>
            <br />
            <br />
            <span>
                Identify and describe the specific alarm used for <em>evacuation</em> emergency:
            </span>
            <br />
            <span>
                <b>{formsData.alerts.specificAlarms.evacuation}</b>
            </span>
            <br />
            <br />

            <span>
                Do you provide drills and training to employees for each emergency alert:{' '}
                {formsData.alerts.drills.drillsChecked ? <b>Yes</b> : <b>No</b>}
            </span>
            <br />
            {formsData.alerts.drills.drillsChecked && (
                <>
                    <span>
                        <b>{formsData.alerts.drills.drillsText}</b>
                    </span>
                    <br />
                    <br />
                </>
            )}

            <span>What procedures should employees follow to report an emergency:</span>
            <br />
            <span>
                <b>{formsData.alerts.reporting}</b>
            </span>
            <br />

            <br />
            <StyledDivLine />
            <br />

            <h3>{sections[1].subSections[1].subSectionTitle}</h3>
            <br />

            <span>
                Does the following accurately state your emergency evacuation policy: “In the event of an emergency, ALL
                employees should evacuate immediately.”&nbsp;&nbsp;&nbsp;&nbsp;
                <b>{formsData.evacuation.policyProcedures.evacuationPolicy.allEmployeesEvacuate ? 'Yes' : 'No'}</b>
            </span>
            <br />
            <br />
            <span>Additional information about your evacuation policies:</span>
            <br />
            <span>
                <b>{formsData.evacuation.policyProcedures.evacuationPolicy.evacuationPolicyDetails}</b>
            </span>
            <br />
            <br />
            <span>
                Do you assign Floor Wardens at this location to help manage safe and effective
                evacuation:&nbsp;&nbsp;&nbsp;&nbsp;
                <b>{formsData.evacuation.policyProcedures.floorWardens.floorWardensExist ? 'Yes' : 'No'}</b>
            </span>
            <br />
            <br />
            {formsData.evacuation.policyProcedures.floorWardens.floorWardensExist && (
                <>
                    <span>
                        Additional Info about Floor Wardens:
                        <br />
                        <b>{formsData.evacuation.policyProcedures.floorWardens.wardensPolicyDetails}</b>
                    </span>
                    <br />
                    <br />
                </>
            )}
            <span>
                Does the following accurately state your emergency evacuation routes? “In the event of an emergency, ALL
                employees should evacuate through the nearest available marked exit.”&nbsp;&nbsp;&nbsp;&nbsp;
                <b>{formsData.evacuation.evacuationRoutes.routesDefault ? 'Yes' : 'No'}</b>
            </span>
            <br />
            <br />
            <span>Additional information about your evacuation routes:</span>
            <br />
            <span>
                <b>{formsData.evacuation.evacuationRoutes.routesDescription}</b>
            </span>
            <br />
            <br />

            <span>Designated Assembly Areas used at this location during an evacuation:</span>
            <br />
            <br />
            {formsData.evacuation.assembly.assemblyPoints.allIDs.map((item, index) => {
                return (
                    <RFC axis="row" justify="flex-start" key={index}>
                        <Spacer spaceParam="medium" />
                        <span>
                            Name:&nbsp;&nbsp;&nbsp;&nbsp;
                            <b>{formsData.evacuation.assembly.assemblyPoints.byID[item].name}</b>
                            <br />
                            Description:&nbsp;&nbsp;&nbsp;&nbsp;
                            <b>{formsData.evacuation.assembly.assemblyPoints.byID[item].description}</b>
                            <br />
                            <br />
                        </span>
                    </RFC>
                );
            })}
            <span>Once gathered at the assembly point after evacuation, how you account for your employees:</span>
            <br />
            <span>
                <b>{formsData.evacuation.rollCall.description}</b>
            </span>
            <br />
            <br />
            <span>
                Do you use or plan to use In Case of Crisis to manage the Roll Call process for
                you:&nbsp;&nbsp;&nbsp;&nbsp;<b>{formsData.evacuation.rollCall.ICOCmanaged ? 'Yes' : 'No'}</b>
            </span>
            <br />

            <br />
            <StyledDivLine />
            <br />

            <h3>{sections[1].subSections[2].subSectionTitle}</h3>
            <br />

            <span>What specific steps should an employee follow in the event of a fire emergency:</span>
            <br />
            <span>
                <b>{formsData.fire.policyProcedures}</b>
            </span>
            <br />
            <br />
            <span>
                Do you have portable fire extinguishers on site:&nbsp;&nbsp;&nbsp;&nbsp;
                <b>{formsData.fire.portableFireExtinguishers ? 'Yes' : 'No'}</b>
            </span>
            <br />
            <br />
            {formsData.fire.portableFireExtinguishers && (
                <>
                    <span>
                        In the event of a fire, who is permitted to use portable fire
                        extinguishers:&nbsp;&nbsp;&nbsp;&nbsp;
                        <b>
                            {formsData.fire.permissionToUse === 'All'
                                ? 'All Employees'
                                : formsData.fire.permissionToUse === 'None'
                                ? 'Employees are not permitted to use the portable fire extinguisher'
                                : ''}
                        </b>
                    </span>
                    <br />
                </>
            )}
            {formsData.fire.portableFireExtinguishers &&
                formsData.fire.permissionToUse === 'Specific' &&
                formsData.fire.employeesAllowed.allIDs.map((item, index) => {
                    return (
                        <RFC axis="row" justify="flex-start" key={index}>
                            <Spacer spaceParam="medium" />
                            <span>
                                Name #{index + 1}:&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{formsData.fire.employeesAllowed.byID[item]}</b>
                                <br />
                                <br />
                            </span>
                        </RFC>
                    );
                })}

            <br />
            <StyledDivLine />
            <br />

            <h3>{sections[1].subSections[3].subSectionTitle}</h3>
            <br />

            <span>
                Do you maintain chemicals onsite at this location:&nbsp;&nbsp;&nbsp;&nbsp;
                <b>{formsData.chemicals.chemicalsOnsite ? 'Yes' : 'No'}</b>
            </span>
            <br />
            {formsData.chemicals.chemicalsOnsite && (
                <>
                    <br />
                    {formsData.chemicals.chemicalsList.allIDs.map((item, index) => {
                        return (
                            <RFC axis="row" justify="flex-start" key={index}>
                                <Spacer spaceParam="medium" />
                                <span>
                                    Chemical #{index + 1}:&nbsp;&nbsp;&nbsp;&nbsp;
                                    <b>{formsData.chemicals.chemicalsList.byID[item]}</b>
                                    <br />
                                    <br />
                                </span>
                            </RFC>
                        );
                    })}

                    <span>
                        Do you have the proper documentation onsite to the SDS for each
                        chemical:&nbsp;&nbsp;&nbsp;&nbsp;<b>{formsData.chemicals.properDocumentation ? 'Yes' : 'No'}</b>
                    </span>
                    <br />
                    <br />
                    <span>What is the process for reporting near misses:</span>
                    <br />
                    <br />
                    <span>
                        <b>{formsData.chemicals.reportingNearMisses}</b>
                    </span>
                    <br />
                    <br />
                    <span>What is the process for reporting spills:</span>
                    <br />
                    <br />
                    <span>
                        <b>{formsData.chemicals.reportingSpills}</b>
                    </span>
                    <br />
                </>
            )}
        </PDFBorder>
    );
};

const RFCBorder = styled(RFC)`
    max-width: 90%;

    @media ${({ theme }) => theme.breakpoints.mediaQueries.laptop} {
        width: 60%;
        max-width: 60%;
    }
`;
const PDFBorder = styled.div`
     display: block;
    align-items: left;
    width: 100%;
    padding-top: 2rem;
    &&, && * {
        overflow: visible;
    }
    && h1, && h2, && h3, && h4, && h5, && h6 {
        font-family: Inter, Helvetica Neue, Arial, sans-serif;
        color: ${({ theme }) => theme.colors.blueGray[3]};
        margin: 0;
    }

    }
    && span {
        color: ${({ theme }) => theme.colors.blueGray[5]};
        font-size: 0.9rem;
        overflow-wrap: break-word;
        white-space: normal;
        
    }
    && p, && li, && h1, && h2, && h3, && h4, && h5, && h6, && span, && b {
        text-align: left;
        letter-spacing: .08rem;
        break-inside: avoid !important;
        && {display: inline;}
    }

`;
