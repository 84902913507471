import DOMPurify from 'dompurify';
import { selectUserEmail } from '../../ReduxSlices/userSlice';
import { logXSSToDatabase } from '../apiRequests';
import { useAppSelector } from '../Hooks/reactReduxHooks';

const entities = require('entities');

const defaultOptions = {
    ALLOWED_URI_REGEXP:
        /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|cid|xmpp|xxx|iba-icoc-corp|sms):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i,
};

export const IcoSanitize = (dirty: string | Node, inputLocation: string, options?: DOMPurify.Config) => {
    const sanitized = DOMPurify.sanitize(dirty, {
        ...defaultOptions,
        ...options,
    }) as string;

    return sanitized;
};

export const isValueXSSSafe = (dirty: string | Node, inputLocation: string, userEmail: string) => {
    DOMPurify.sanitize(dirty, {
        ...defaultOptions,
    }) as string;
    if (DOMPurify.removed.length <= 0) return true;
    logXSSToDatabase(entities.encodeHTML(dirty as string), inputLocation, userEmail);
    return false;
};
