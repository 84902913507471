import React, { useState, useEffect } from 'react';
import { useAppSelector } from '../Common/Hooks/reactReduxHooks';
import { useHistory } from 'react-router-dom';

import { useTheme } from 'styled-components';
import { ThemeType } from '../Contexts/ThemeGlobalAndCtxt';

import { selectAppType } from '../ReduxSlices/userSlice';

import { FlexDiv, RFC } from '../Common/Components/FlexContainers';
import { Spacer } from '../Common/Components/Spacer';

export const WhatYouNeed = () => {
    //@ts-ignore
    const theme: ThemeType = useTheme();
    let history = useHistory();
    const appType = useAppSelector(selectAppType);
    const [height, setHeight] = useState(100);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            // Depending on the layout, you may need to swap inlineSize with blockSize
            // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize
            setHeight(event[0].contentRect.height);
        });
        let blueBox = document.getElementById('blueBox');
        if (blueBox !== null) {
            resizeObserver.observe(blueBox);
        }
        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <FlexDiv backgroundColor={theme.colors.white}>
            <RFC
                axis="column"
                align="center"
                justify="flex-start"
                css={`
                    padding: 2.5rem 0;
                `}
            >
                <RFC
                    axis="column"
                    flip
                    align="flex-start"
                    id="blueBox"
                    justify="flex-start"
                    style={{
                        width: '80%',
                        height: '100%',
                        minHeight: '100%',
                        border: `solid 1px ${theme.colors.blue[5]}`,
                    }}
                >
                    <RFC axis="column" basis="50%" align="flex-start" justify="flex-start" style={{ padding: '2rem' }}>
                        {appType === 'EAP' && (
                            <>
                                <h3>Getting Started</h3>
                                <br />

                                <p>
                                    The EAP tool transforms your organization's Emergency Action Plan (EAP) into an
                                    actionable Playbook accessible within the In Case of Crisis platform.
                                </p>
                                <br />
                                <p>
                                    The following workflow will allow you to input information specific to your
                                    organization and deliver an OSHA-compliant Emergency Action Plan in 30 minutes or
                                    less.
                                </p>
                                <br />
                                <p>
                                    After completing your first Emergency Action Plan for a single location, you will be
                                    able to easily complete Emergency Action Plans for additional office locations.
                                </p>
                                <br />
                                <p>
                                    If you have questions or need assistance, please contact your dedicated Client
                                    Success Manager or email{' '}
                                    <a href="mailto: support@incaseofcrisis.com">support@incaseofcrisis.com</a>.
                                </p>
                            </>
                        )}
                        {appType === 'Advisory' && (
                            <>
                                <h3>Getting Started</h3>
                                <br />

                                <p>
                                    The Advisory Playbook Builder provides an easy workflow to build and publish an
                                    advisory for the Stakeholders of an Issue, including a quick Overview of the Issue,
                                    the Present Position of the Organization with regards to the Issue, and any Current
                                    Guidance to be followed.
                                </p>
                                <br />
                                <p>
                                    Advisories are dynamic and may change over time. Therefore, each Advisory Playbook
                                    will be clearly marked with the Effective Timeframe for the advisory.
                                </p>
                                <br />
                                <p>
                                    The Advisory Playbook will be assigned to each Stakeholder to help them stay
                                    informed and to present them with a list of further Contacts, a Form to be filled
                                    out for additional information, a Checklist to be followed, and any important
                                    Attachments for reference.
                                </p>
                                <br />
                                <p>
                                    If you have questions or need assistance, please contact your dedicated Client
                                    Success Manager or email{' '}
                                    <a href="mailto: support@incaseofcrisis.com">support@incaseofcrisis.com</a>.
                                </p>
                            </>
                        )}
                    </RFC>
                    <RFC
                        axis="column"
                        basis="50%"
                        align="flex-start"
                        justify="flex-start"
                        style={{ padding: '2rem', backgroundColor: theme.colors.blue[5], height: height + 'px' }}
                    >
                        {appType === 'EAP' && (
                            <>
                                <h3 style={{ color: theme.colors.white }}>What You'll Need:</h3>
                                <br />
                                <ul>
                                    <li style={{ color: theme.colors.white }}>
                                        Basic company information (office address, phone numbers, etc.)
                                    </li>
                                    <br />
                                    <li style={{ color: theme.colors.white }}>
                                        Any existing Emergency Action Plan documents, handbooks, and company policies
                                    </li>
                                    <br />
                                    <li style={{ color: theme.colors.white }}>
                                        Evacuation and reunification maps in a supported format (.pdf, .doc, .png, .jpg,
                                        .gif, .tif)
                                    </li>
                                    <br />
                                    <li style={{ color: theme.colors.white }}>
                                        Contact information for those within your organization responsible for
                                        maintaining your Emergency Action Plans
                                    </li>
                                    <br />
                                    <li style={{ color: theme.colors.white }}>
                                        A list of chemicals used or housed onsite and their respective Safety Data
                                        Sheets (SDS)
                                    </li>
                                    <br />
                                </ul>
                            </>
                        )}
                        {appType === 'Advisory' && (
                            <>
                                <h3 style={{ color: theme.colors.white }}>What You'll Need:</h3>
                                <br />
                                <ul>
                                    <li style={{ color: theme.colors.white }}>Basic company information</li>
                                    <br />
                                    <li style={{ color: theme.colors.white }}>
                                        Present Position for the current Issue
                                    </li>
                                    <br />
                                    <li style={{ color: theme.colors.white }}>
                                        Any Guidance that the organization would like to provide to the Stakeholder
                                        Group
                                    </li>
                                    <br />
                                    <li style={{ color: theme.colors.white }}>
                                        List of Contacts which can be referenced for further information, including
                                        their email address and phone number
                                    </li>
                                    <br />
                                    <li style={{ color: theme.colors.white }}>
                                        Attachments which will be beneficial to have for further reference in handling
                                        the current issue
                                    </li>
                                    <br />
                                </ul>
                            </>
                        )}
                    </RFC>
                </RFC>
                <Spacer spaceParam="large" />
                <Spacer spaceParam="large" />
                {/* need to add the logic for the EAP frontend */}
                <button onClick={() => (appType === 'EAP' ? history.push('/listings') : history.push('/home'))}>
                    Begin
                </button>
                <Spacer spaceParam="large" />
                <Spacer spaceParam="large" />
            </RFC>
        </FlexDiv>
    );
};
