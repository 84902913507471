import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../Common/Hooks/reactReduxHooks';
import { resetAppError, selectAppError } from '../ReduxSlices/userSlice';
import { FlexDiv, RFC } from '../Common/Components/FlexContainers';

import { useTheme } from 'styled-components';
import { ThemeType } from '../Contexts/ThemeGlobalAndCtxt';
import { useParams } from 'react-router-dom';

export const Error = () => {
    //@ts-ignore
    const theme: ThemeType = useTheme();
    let params = useParams<{ errorNumber: string }>();
    const dispatch = useAppDispatch();
    const appError = useAppSelector(selectAppError);
    const refAppError = useRef<number>(0);

    useEffect(() => {
        if (appError) {
            refAppError.current = appError;
            dispatch(resetAppError());
        }
    }, [appError, dispatch]);

    return (
        <FlexDiv backgroundColor={theme.colors.white}>
            <RFC axis="column" css="padding: 3rem;">
                {params.errorNumber === '401'
                    ? `Authentication Expired. Please log in again on the Online Portal and relaunch this tool.`
                    : params.errorNumber === '404'
                    ? `Page Not Found`
                    : params.errorNumber === '500'
                    ? `Internal Server Error`
                    : `There was an error! Error Code: ${refAppError.current}`}
            </RFC>
        </FlexDiv>
    );
};
