import React from 'react';
import { ThemeType, styled } from '../../Contexts/ThemeGlobalAndCtxt';
import { useTheme } from 'styled-components';
import { useMediaQuery } from '@react-hook/media-query';
import { useAppSelector } from '../Hooks/reactReduxHooks';
import { selectAppType } from '../../ReduxSlices/userSlice';

import { RFC } from './FlexContainers';
import { Spacer } from './Spacer';
import ReactTooltip from 'react-tooltip';

type steps = {
    label: string | JSX.Element;
    //TODO adjust playbook stepper button to have disabled capabilities
    // and prevent any onClicks and style to be a settled gray
    status: boolean | 'disabled';
};

interface IProps {
    steps: steps[];
    handleClick: (stepClicked: number) => void;
    activeStep: number;
    direction?: 'row' | 'column';
    keepDirection?: boolean;
    spacerSize?: 'default' | number;
    maxWidth?: string;
    noBubbles?: boolean;
    underline?: boolean;
}

export const Stepper = ({
    steps,
    handleClick,
    activeStep,
    direction = 'row',
    noBubbles = false,
    underline = false,
}: IProps) => {
    // @ts-ignore
    const theme: ThemeType = useTheme();
    const laptop = useMediaQuery(theme.breakpoints.mediaQueries.laptop);
    const appType = useAppSelector(selectAppType);
    const MenuElements: JSX.Element[] = [];
    steps.forEach((step, i) => {
        step.status === 'disabled'
            ? MenuElements.push(
                  <StyledSpan
                      key={i * 2}
                      tabIndex={0}
                      isActiveStep={i === activeStep}
                      underline={underline}
                      disabled
                      data-tip="Must Review sections on the Review tab and click Confirm before proceeding"
                  >
                      {step.label}
                  </StyledSpan>,
              )
            : MenuElements.push(
                  <StyledSpan
                      key={i * 2}
                      tabIndex={0}
                      isActiveStep={i === activeStep}
                      underline={underline}
                      appType={appType}
                      onClick={() => handleClick(i)}
                  >
                      {step.label}
                  </StyledSpan>,
              );
        if (laptop && i !== steps.length - 1) {
            MenuElements.push(<Spacer spaceParam="large" key={i + 100} />);
        }
    });

    return (
        <RFC
            axis="column"
            justify="flex-start"
            align="center"
            css={`
                flex-basis: 0;
                background-color: ${theme.colors.white};
            `}
        >
            {laptop ? <Spacer spaceParam="large" /> : <Spacer spaceParam="medium" />}
            <RFC
                axis="row"
                justify={laptop ? 'center' : 'space-between'}
                align="flex-end"
                css={laptop ? 'position: relative;' : 'position: relative; padding: 0rem 0.5rem 0rem;'}
            >
                {MenuElements}
            </RFC>
            {laptop ? <Spacer spaceParam="large" /> : <Spacer spaceParam="medium" />}
            <ReactTooltip
                backgroundColor={theme.colors.blue[5]}
                textColor={theme.colors.white}
                place="top"
                effect="solid"
            />
        </RFC>
    );
};

interface IStyledActiveStepProps {
    isActiveStep: boolean;
    underline: boolean;
    disabled?: boolean;
    appType?: 'Advisory' | 'EAP' | '';
}

const StyledMenuButton = styled.button<IStyledActiveStepProps>`
    && {
        width: fit-content;
        min-width: 60px;
        && {
            padding: 0rem;
        }
        background-color: transparent;
        border-radius: 0px;
        color: ${({ theme }) => theme.colors.blueGray[3]};
        display: flex;
        align-items: center;
        ${(props) =>
            props.disabled
                ? ``
                : `&:hover {
            background-color: transparent;
            color: ${({ theme }) => theme.colors.blueGray[1]};
            & > div > span {
                color: ${({ theme }) => theme.colors.blueGray[1]};
            }
        }`}

        &:focus {
            box-shadow: none;
            && span {
                border-bottom: 2px solid ${({ theme }) => theme.colors.yellow[5]};
            }
        }
        &:active {
            box-shadow: none;
        }
    }
`;

const StyledSpan = styled.span<IStyledActiveStepProps>`
    width: fit-content;
    && {
        font-size: 0.5rem;
    }
    flex-basis: 0;
    text-align: center;
    padding-bottom: 0.5rem;
    padding-left: 0.5em;
    padding-right: 0.5em;
    cursor: pointer;
    ${({ appType }) => (appType === 'Advisory' ? 'white-space: nowrap;' : '')}
    ${({ underline }) => (underline ? `padding-bottom: 0.5rem;` : 'padding: 0.25rem 0;')}
    ${({ isActiveStep, theme }) =>
        isActiveStep
            ? `font-weight: 900; color: ${theme.colors.blueGray[3]}; border-bottom: 2px solid ${theme.colors.blueGray[3]};`
            : `border-bottom: 2px solid transparent;`}
    @media ${({ theme }) => theme.breakpoints.mediaQueries.laptop} {
        && {
            font-size: 0.7rem;
        }
        min-width: 135px;
        padding-bottom: 1rem;
    }

    &:focus {
        box-shadow: none;
        border-bottom: 2px solid ${({ theme }) => theme.colors.yellow[5]};
    }
    &:active {
        box-shadow: none;
        color: ${({ theme }) => theme.colors.blueGray[2]};
    }
`;
