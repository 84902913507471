import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { Spacer } from './Spacer';
import { RFC } from './FlexContainers';

import { styled } from '../../Contexts/ThemeGlobalAndCtxt';

import Grow from '@mui/material/Grow';

interface IProps {
    style: {};
    active: boolean;
    children?: any;
}

export const SuccessAlert = ({ style, active, children }: IProps) => {
    return (
        <Grow in={active}>
            <StyledAlertSpan style={style}>
                <RFC axis="row" align="center">
                    <FontAwesomeIcon icon={faCheckCircle} size={'sm'} />
                    {children && <Spacer direction="horizontal" spaceParam="small" />}
                    {children}
                </RFC>
            </StyledAlertSpan>
        </Grow>
    );
};

const StyledAlertSpan = styled.span`
    margin: 0;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.cyan[6]};
`;
