import React, { useEffect } from 'react';
import { styled, ThemeType } from '../Contexts/ThemeGlobalAndCtxt';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';

import { useAppSelector } from '../Common/Hooks/reactReduxHooks';
import { selectAllSections, selectSectionStatuses } from '../ReduxSlices/sectionProgressSlice';
import { selectCurrentListingName } from '../ReduxSlices/listingsSlice';
// Individual components for styling
import { Spacer, StyledDivLine } from '../Common/Components/Spacer';
import { ResponsiveFlexContainer, RFC, FlexDiv } from '../Common/Components/FlexContainers';
import { StyledBoxWrapper } from '../Common/Components/StyledBoxWrapper';
import { Stepper } from '../Common/Components/Stepper';
import ReactTooltip from 'react-tooltip';

export const HomePage = () => {
    // @ts-ignore
    const theme: ThemeType = useTheme();
    const playbookName = useAppSelector(selectCurrentListingName);
    const history = useHistory();
    const sections = useAppSelector(selectAllSections);
    const sectionStatuses = useAppSelector(selectSectionStatuses);
    let steps: Array<{ label: string; status: boolean | 'disabled' }> = [];
    sections.forEach((section, index) => {
        steps.push({
            label: section.sectionTitle,
            status:
                index === sections.length - 1 && sectionStatuses[sections.length - 2] !== 'completed'
                    ? 'disabled'
                    : true,
        });
    });

    const handleStepperClick = (i: number) => {
        history.push(sections[i].routerURL);
    };
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [sectionStatuses]);
    return (
        <>
            <RFC
                axis="column"
                align="center"
                basis="0%"
                css={`
                    background-color: ${theme.colors.white};
                `}
            >
                <RFC axis="column" align="center" justify="center" basis="10%" css="width: 50%; min-width: 350px;">
                    <Spacer spaceParam="large" />
                    {/* <StyledDivLine /> */}
                    <h2>{playbookName}</h2>
                    <Spacer spaceParam="large" />
                    <StyledDivLine />
                </RFC>
                <Stepper steps={steps} activeStep={10} handleClick={handleStepperClick} noBubbles />
            </RFC>

            <FlexDiv backgroundColor={theme.colors.white}>
                <Spacer spaceParam="small" />
                <HomePageContainer
                    mobileFlexDirection="column"
                    keepFlexDirection
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    {sections.map((section, index) => {
                        return (
                            <RFC
                                axis="column"
                                align="flex-start"
                                basis="10%"
                                key={index}
                                css="width: 50%; min-width: 350px;"
                            >
                                {index !== 0 && <Spacer direction="vertical" spaceParam="large" />}
                                <Spacer spaceParam="tiny" direction="vertical" />
                                <StyledBoxWrapper widthType="100%">
                                    <RFC axis="column" align="center" flip>
                                        <RFC axis="column" align="flex-start" basis="70%">
                                            <h3>{section.sectionTitle}</h3>
                                            <Spacer direction="vertical" spaceParam="medium" />
                                            <span>{section.sectionSubtext}</span>
                                        </RFC>
                                        <Spacer spaceParam="medium" />
                                        <RFC axis="row" align="center" justifyLaptop="flex-end" basis="30%">
                                            {index === sections.length - 1 && sectionStatuses[2] !== 'completed' ? (
                                                <span data-tip="Must Review sections on the Review tab and click Confirm before proceeding">
                                                    <CallToActionButton disabled status={sectionStatuses[index]}>
                                                        Build
                                                    </CallToActionButton>
                                                </span>
                                            ) : (
                                                <CallToActionButton
                                                    onClick={() => history.push(section.routerURL)}
                                                    status={sectionStatuses[index]}
                                                >
                                                    {index === 3
                                                        ? 'Build'
                                                        : index === 2
                                                        ? 'Review'
                                                        : sectionStatuses[index] === 'not started'
                                                        ? 'Start'
                                                        : sectionStatuses[index] === 'completed'
                                                        ? 'Edit'
                                                        : 'Continue'}
                                                </CallToActionButton>
                                            )}
                                            <ReactTooltip
                                                backgroundColor={theme.colors.blue[5]}
                                                textColor={theme.colors.white}
                                                place="top"
                                                effect="solid"
                                            />
                                        </RFC>
                                    </RFC>
                                </StyledBoxWrapper>
                            </RFC>
                        );
                    })}
                </HomePageContainer>
            </FlexDiv>
        </>
    );
};

const HomePageContainer = styled(ResponsiveFlexContainer)`
    background-color: ${({ theme }) => theme.colors.white};
    min-height: 100%;
`;

interface ICTAbutton {
    status?: 'not started' | 'completed' | 'in progress';
}
const CallToActionButton = styled.button<ICTAbutton>`
    ${({ theme, status }) =>
        status === 'completed'
            ? `background-color: transparent; 
    border: 1px solid ${theme.colors.blueGray[5]}; 
    color: ${theme.colors.blueGray[5]}; 
    &:hover {
        background-color: ${theme.colors.blueGray[10]}; 
        border: 1px solid ${theme.colors.blueGray[3]};
        color: ${theme.colors.blueGray[3]};
    }
    &:focus {
        border: 1px solid transparent;
        color: ${theme.colors.blueGray[5]}; 
    }
    &:active {
        border: 1px solid ${theme.colors.blueGray[3]};
        box-shadow: 0 0 0 1px ${theme.colors.blueGray[3]};
        color: ${theme.colors.blueGray[3]};
    }
    
    `
            : status === 'in progress'
            ? `background-color: ${theme.colors.cyan[5]};
    &:hover {
        background-color: ${theme.colors.cyan[3]};
    }`
            : ``}
`;
