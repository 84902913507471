import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { GetListings, handleErrorStatus, getCheckUniqueName } from '../Common/apiRequests';
import { selectUserEmail, setApiDateTimeRefresh } from './userSlice';
import { RootState, sliceAPI } from '../store';
import dayjs from 'dayjs';
import { isValueXSSSafe } from '../Common/Security/IcoSanitize';
import { useAppSelector } from '../Common/Hooks/reactReduxHooks';

interface Ilisting {
    listingID: number;
    listingName: string;
    orgName: string;
    listingEditedDate: string;
    listingEditedBy: string;
    pubID: null | number;
    planEditedDate: null | string;
    planEditedBy: null | string;
    shared: string;
    pubTitle: string | null;
}

interface Ilocation extends sliceAPI {
    data: {
        listingID: number;
        listingName: string;
        listings: Array<Ilisting>;
    };
    checkUniqueNameStatus: 'idle' | 'pending' | 'succeeded' | 'failed';
    uniqueNameErrorMessage: any;
}

const initialState: Ilocation = {
    data: {
        listingID: 0,
        listingName: '',
        listings: [],
    },
    postStatus: 'idle',
    getStatus: 'idle',
    checkUniqueNameStatus: 'idle',
    errorMessage: null,
    uniqueNameErrorMessage: '',
};

export const getListingsFromDB = createAsyncThunk<any, void, { state: RootState }>(
    'listings/getListingsFromDB',
    async (_, { dispatch, rejectWithValue }) => {
        const responseData = await GetListings();

        if (responseData.error) {
            handleErrorStatus(responseData.error, dispatch);
            return rejectWithValue(responseData.error);
        } else {
            dispatch(setApiDateTimeRefresh(dayjs().toISOString()));
            //do stuff here

            let newListings = responseData.map((DBlisting) => {
                let listing: Ilisting = {
                    listingID: 0,
                    listingName: '',
                    listingEditedBy: '',
                    listingEditedDate: '',
                    orgName: '',
                    pubID: 0,
                    planEditedBy: '',
                    planEditedDate: '',
                    shared: 'N',
                    pubTitle: '',
                };

                listing.listingID = DBlisting.id;
                listing.listingName = DBlisting.location_nickname;
                listing.listingEditedBy = DBlisting.listing_updater;
                listing.listingEditedDate = DBlisting.updated_utc;
                listing.orgName = DBlisting.OrgName;
                listing.pubID = DBlisting.PubID ? DBlisting.PubID : 0;
                listing.planEditedBy = DBlisting.pub_updater ? DBlisting.pub_updater : '';
                listing.planEditedDate = DBlisting.LastUpdate ? DBlisting.LastUpdate : '';
                listing.shared = DBlisting.shared;
                listing.pubTitle = DBlisting.PubTitle ? DBlisting.PubTitle : '';
                return listing;
            });
            dispatch(updateListings(newListings));
        }

        return responseData;
    },
);

export const checkListingUniqueName = createAsyncThunk<
    any,
    { listingID: number; listingName: string; userEmail: string },
    { state: RootState }
>('listings/checkUniqueName', async (listing, { dispatch, rejectWithValue }) => {
    if (!isValueXSSSafe(listing.listingName, 'EAP listing name', listing.userEmail)) {
        return rejectWithValue('Entry cannot be a security risk');
    }
    const responseData = await getCheckUniqueName(listing);
    if (responseData.error) {
        handleErrorStatus(responseData.error, dispatch);
        return rejectWithValue(responseData.error);
    } else {
        dispatch(setApiDateTimeRefresh(dayjs().toISOString()));
        if (responseData.value === 'Final Return path for error') {
            return 'Success';
        } else {
            return rejectWithValue('Must be a unique name');
        }
    }
});

export const listingsSlice = createSlice({
    name: 'listings',
    initialState,
    reducers: {
        updateCurrentListingID: (state, action: PayloadAction<number>) => {
            state.data.listingID = action.payload;
        },
        updateCurrentListingName: (state, action: PayloadAction<string>) => {
            state.data.listingName = action.payload;
        },
        updateListings: (state, action: PayloadAction<Array<Ilisting>>) => {
            state.data.listings = action.payload;
        },
        resetListingApiStatus: (state) => {
            state.getStatus = 'idle';
        },
        resetUniqueNameStatus: (state) => {
            state.checkUniqueNameStatus = 'idle';
            state.uniqueNameErrorMessage = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListingsFromDB.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getListingsFromDB.fulfilled, (state) => {
                state.getStatus = 'succeeded';
            })
            .addCase(getListingsFromDB.rejected, (state, action) => {
                if (action.payload) {
                    state.errorMessage = action.payload;
                } else {
                    state.errorMessage = action.error.message;
                }
                state.getStatus = 'failed';
            })
            .addCase(checkListingUniqueName.pending, (state) => {
                state.checkUniqueNameStatus = 'pending';
            })
            .addCase(checkListingUniqueName.fulfilled, (state) => {
                state.checkUniqueNameStatus = 'succeeded';
            })
            .addCase(checkListingUniqueName.rejected, (state, action) => {
                if (action.payload) {
                    state.uniqueNameErrorMessage = action.payload;
                } else {
                    state.uniqueNameErrorMessage = action.error.message;
                }
                state.checkUniqueNameStatus = 'failed';
            });
    },
});

export const {
    updateCurrentListingID,
    updateCurrentListingName,
    updateListings,
    resetUniqueNameStatus,
    resetListingApiStatus,
} = listingsSlice.actions;
export const selectCurrentListingID = (state: RootState) => state.listings.data.listingID;
export const selectCurrentListingName = (state: RootState) => state.listings.data.listingName;
export const selectListings = (state: RootState) => {
    return {
        status: state.listings.getStatus,
        error: state.listings.errorMessage,
        listings: state.listings.data.listings,
        uniqueName: {
            status: state.listings.checkUniqueNameStatus,
            errorMessage: state.listings.uniqueNameErrorMessage,
        },
    };
};
export const selectUniqueNameInfo = (state: RootState) => ({
    status: state.listings.checkUniqueNameStatus,
    error: state.listings.uniqueNameErrorMessage,
});

export default listingsSlice.reducer;
