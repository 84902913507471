import React from 'react';
import baseStyled, { ThemedStyledInterface, ThemeProvider, createGlobalStyle } from 'styled-components';

const breakpoints = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
};

const mediaBreakpoints = {
    mobileS: `(min-width: ${breakpoints.mobileS})`,
    mobileM: `(min-width: ${breakpoints.mobileM})`,
    mobileL: `(min-width: ${breakpoints.mobileL})`,
    tablet: `(min-width: ${breakpoints.tablet})`,
    laptop: `(min-width: ${breakpoints.laptop})`,
    laptopL: `(min-width: ${breakpoints.laptopL})`,
    desktop: `(min-width: ${breakpoints.desktop})`,
    desktopL: `(min-width: ${breakpoints.desktop})`,
};

const colors = {
    blue: {
        1: '#003E6B',
        2: '#0A558C',
        3: '#0F609B',
        4: '#186FAF',
        5: '#2680C2',
        6: '#4098D7',
        7: '#62B0E8',
        8: '#84C5F4',
        9: '#B6E0FE',
        10: '#DCEEFB',
    },
    yellow: {
        1: '#8D2B0B',
        2: '#B44D12',
        3: '#CB6E17',
        4: '#DE911D',
        5: '#F0B429',
        6: '#F7C948',
        7: '#FADB5F',
        8: '#FCE588',
        9: '#FFF3C4',
        10: '#FFFBEA',
    },
    blueGray: {
        1: '#102A43',
        2: '#243B53',
        3: '#334E68',
        4: '#486581',
        5: '#627D98',
        6: '#829AB1',
        7: '#9FB3C8',
        8: '#BCCCDC',
        9: '#D9E2EC',
        10: '#F0F4F8',
    },
    nuetralGray: { 1: '#474747', 2: '#6e6e6e', 3: '#949494', 4: '#c2c2c2', 5: '#e0e0e0' },
    coolGray: { 1: '#3d4247', 2: '#60686e', 3: '#828c94', 4: '#a3b2c2', 5: '#b1cae0' },
    red: {
        1: '#610404',
        2: '#780A0A',
        3: '#911111',
        4: '#A61B1B',
        5: '#BA2525',
        6: '#D64545',
        7: '#E66A6A',
        8: '#F29B9B',
        9: '#FACDCD',
        10: '#FFEEEE',
    },
    green: { 1: '#768c34', 2: '#94B042' },
    cyan: {
        1: '#044E54',
        2: '#0A6C74',
        3: '#0E7C86',
        4: '#14919B',
        5: '#2CB1BC',
        6: '#38BEC9',
        7: '#54D1DB',
        8: '#87EAF2',
        9: '#BEF8FD',
        10: '#E0FCFF',
    },
    white: '#FFFFFF',
};

export const Theme = {
    breakpoints: {
        deviceSizes: breakpoints,
        mediaQueries: mediaBreakpoints,
    },
    colors: colors,
    spacer: {
        sizes: {
            tiny: 1,
            small: 4,
            medium: 10,
            large: 20,
        },
    },
    testing: false,
};

export type ThemeType = typeof Theme;

export const ThemeContextProvider = ({ children }) => {
    return <ThemeProvider theme={Theme}>{children}</ThemeProvider>;
};

export const styled = baseStyled as ThemedStyledInterface<ThemeType>;

export const ThemeGlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
    #root {
        background-color: #d9e2ec;
        height: 100%;
        margin: 0;
    }
    html, body {
        font-family: Roboto, Helvetica Neue, Arial, sans-serif;
        font-size: 16px;
        background-color: ${({ theme }) => theme.colors.blueGray[10]};
        text-shadow: none;
        margin: 0;
        box-sizing: border-box;
        height: 100%;
        overflow: hidden;
        overflow-wrap: break-word;
        min-height: 100%;
        min-height: 100%;
        appearance: none;
        letter-spacing: 0.08rem;
        --moz-appearance: none;
        --webkit-appearance: none;
    }
    input, textarea {
        font-family: Avenir, Lato, Helvetica Neue, Arial, sans-serif;
    }

    * {
        box-sizing: border-box;
        margin: 0;
        margin-left: 0;
        margin-right: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        &::before, &::after, &:active, &:hover, &:focus {
            box-sizing: border-box;
            margin: 0;
        }
        &:focus {
            outline: 0;
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.yellow[5]};
            margin: 0;
        }
    }
    
    
    button {
        all: revert;
        background: ${({ theme }) => theme.colors.blue[5]};
        color: white;
        text-transform: uppercase;
        border: none;
        width: fit-content;
        min-width: 100px;
        height: fit-content;
        letter-spacing: .08rem;
        padding: 1em 2em;
        font-size: .6rem;
        font-weight: 400;
        border-radius: 3em;
        margin: 0;
        
        cursor: pointer;
        &:active, &:hover {
            background: ${({ theme }) => theme.colors.blue[4]};
            color: white;
        }
        &:focus {
            color: white;
        }
        &:active {
            box-shadow: none;
        }
        
        @media ${({ theme }) => theme.breakpoints.mediaQueries.tablet} {
            font-size: .7rem;
            min-width: 120px;
        }
    }

    button:disabled {
        color: ${({ theme }) => theme.colors.nuetralGray[5]}; 
        background-color: ${({ theme }) => theme.colors.nuetralGray[3]}; 
        &:hover {
            background-color: ${({ theme }) => theme.colors.nuetralGray[3]};
        }
    }
    .react-datepicker-wrapper {
        display: flex;
        justify-content: flex-start;
        padding: 0;
        border: 0;
        width: 100%;
    }
    .react-datepicker__input-container {
        position: relative;
        width: 33%;
        margin: 0;
    }
    
    .react-datepicker, {
        font-size: 0.8rem;
        background-color: #fff;
        color: #000;
        border: 1px solid #aeaeae;
        border-radius: 0.3rem;
        display: inline-block;
        position: relative;
    }
    .react-datepicker__month {
        margin: 0;
    }
    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
        bottom: 0;
        margin-bottom: -8px;
    }
    .react-datepicker__navigation--previous {
        left: 3px;
    }
    .react-datepicker__navigation--next {
        right: 3px;
    }
    .react-datepicker__navigation {
        align-items: center;
        background: none;
        display: flex;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        position: absolute;
        top: 2px;
        border: none;
        z-index: 1;
        height: 32px;
        text-indent: -999em;
        overflow: hidden;

        color: white;
        text-transform: uppercase;
        width: 32px;
        min-width: 32px;
        letter-spacing: 0em;
        padding: 0.1rem 0;
        font-size: .1rem;
        font-weight: 500;
        border: none;
        border-radius: 3rem;
        &:hover {
            background: none;
        }
    }
    .react-datepicker__navigation-icon {
        position: relative;
        top: 0px;
        font-size: 20px;
    }
    .react-datepicker__navigation-icon--previous {
        right: 1px;
    }
    .react-datepicker__navigation-icon--next {
        left: 1px;
    }
    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range, .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
        background-color: ${({ theme }) => theme.colors.blue[5]};
    }
    .react-datepicker__day-names, .react-datepicker__day-name, .react-datepicker__day {
        margin: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
    }
    .react-datepicker__header {
        padding: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: Inter, Helvetica Neue, Arial, sans-serif;
        color: ${({ theme }) => theme.colors.blueGray[3]};
        margin: 0;
    }

    h1 {
        font-size: 16px;
        font-weight: 500;
        padding: 0;
        @media ${({ theme }) => theme.breakpoints.mediaQueries.laptop} {
            font-size: 24px;
        }
    }

    span {
        color: ${({ theme }) => theme.colors.blueGray[5]};
        font-size: 0.9rem;
    }
    p, li, h1, h2, h3, h4, h5, h6, span {
        text-align: left;
        letter-spacing: .08rem;
    }

    details {
        text-align: left;
        padding: 0.5rem;
        width: 100%;

        & summary:hover {
            cursor: help;
        }
    }
    summary {
        font-weight: 500;
    }
    .button-secondary {
        background: transparent;
        color: ${({ theme }) => theme.colors.blueGray[6]};
        text-transform: uppercase;
        border: none;
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.blueGray[6]};
        width: fit-content;
        padding: .9em 1.1em;
        letter-spacing: .1em;
        &:hover {
            color: ${({ theme }) => theme.colors.blueGray[3]};
            background-color: transparent;
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.blueGray[3]};
        }
        &:focus {
            color: ${({ theme }) => theme.colors.blueGray[6]};
            background-color: transparent;
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.yellow[5]};
        }
        &:active {
            color: ${({ theme }) => theme.colors.blueGray[3]};
            background-color: transparent;
            box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.blueGray[3]};
        }
    }
    .button-secondary-darker {
        color: ${({ theme }) => theme.colors.blueGray[4]};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.blueGray[4]};
        background-color: transparent;
        &:hover {
            background-color: transparent;
            color: ${({ theme }) => theme.colors.blueGray[2]};
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.blueGray[2]};
        }
        &:focus {
            background-color: transparent;
            color: ${({ theme }) => theme.colors.blueGray[4]};
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.yellow[5]} ;
        }
        &:active {
            color: ${({ theme }) => theme.colors.blueGray[2]};
            box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.blueGray[2]};
        }
    }
    .button-tertiary {
        background: transparent;
        color: ${({ theme }) => theme.colors.blueGray[6]};
        text-transform: uppercase;
        border: none;
        box-shadow: none;
        width: fit-content;
        &:hover {
            color: ${({ theme }) => theme.colors.blueGray[3]};
            background-color: transparent;
            box-shadow: none;
        }
        &:focus {
            color: ${({ theme }) => theme.colors.blueGray[6]};
            background-color: transparent;
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.yellow[5]}
        }
        &:active {
            color: ${({ theme }) => theme.colors.blueGray[3]};
            background-color: transparent;
            box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.blueGray[3]}
        }
    }
    .button-tertiary-darker {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.blueGray[4]};
        &:hover {
            color: ${({ theme }) => theme.colors.blueGray[2]};
            background-color: transparent;
        }
        &:focus {
            color: ${({ theme }) => theme.colors.blueGray[4]};
            background-color: transparent;
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.yellow[5]}
        }
        &:active {
            background-color: transparent;
            color: ${({ theme }) => theme.colors.blueGray[2]};
            box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.blueGray[2]}
        }
    }
    .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
        padding: 0 !important;
    }
    .MuiDataGrid-columnsContainer button {
        all: revert;
        background: transparent;
        color: inherit;
        text-transform: uppercase;
        border: none;
        width: fit-content;
        min-width: none;
        height: fit-content;
        letter-spacing: .08rem;
        padding: none;
        font-size: .6rem;
        font-weight: 400;
        border-radius: 50%;
        cursor: pointer;
        &:active, &:hover {
            background: transparent;
        }
        &:active, &:focus {
            box-shadow: none;
        }
        @media ${({ theme }) => theme.breakpoints.mediaQueries.tablet} {
            font-size: .7rem;
            min-width: 120px;
        }

    }
    .MuiDataGrid-root *, .MuiGridFilterForm-root * {
        &:focus {
            box-shadow: none;
        }
    }
    .MuiDataGrid-root .DataGrid-cellDiv {
        letter-spacing: 0;
        line-height: 1rem;
        overflow-wrap: normal;
        white-space: normal;        
    }
    .MuiDataGrid-columnsContainer {
        && {
            background-color: ${({ theme }) => theme.colors.blue[5]};
        }
        && * {
            padding: 0px;
        }
    }

    .MuiDataGrid-columnHeaderTitleContainer {
        && {
            padding: 0px;
            color: ${({ theme }) => theme.colors.blue};
            &:active, &:focus {
                box-shadow: none;
                border: none;
            }
        }
    }
    .MuiDataGrid-cell button {
            font-size: .5rem;
            padding: 1em 2em;
            font-weight: 400;
            min-width: 100px;
            white-space: nowrap;
            background: transparent;
            &:active, &:focus {
                box-shadow: none;
                border: none;
            }
    }
    
    .MuiDataGrid-cell .button-tertiary-darker {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.blueGray[4]};
        padding: 0px;
            min-width: 60px;
        &:hover {
            color: ${({ theme }) => theme.colors.blueGray[2]};
            background-color: transparent;
        }
        &:focus {
            color: ${({ theme }) => theme.colors.blueGray[4]};
            background-color: transparent;
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.yellow[5]}
        }
        &:active {
            background-color: transparent;
            color: ${({ theme }) => theme.colors.blueGray[2]};
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.blueGray[2]}
        }


    }
    .MuiDataGrid-cell .button-tertiary {
        background: transparent;
        color: ${({ theme }) => theme.colors.blueGray[6]};
        text-transform: uppercase;
        border: none;
        box-shadow: none;
        width: fit-content;
        padding: 0px;
        min-width: 60px;
        &:hover {
            color: ${({ theme }) => theme.colors.blueGray[3]};
            background-color: transparent;
            box-shadow: none;
        }
        &:focus {
            color: ${({ theme }) => theme.colors.blueGray[6]};
            background-color: transparent;
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.yellow[5]}
        }
        &:active {
            color: ${({ theme }) => theme.colors.blueGray[3]};
            background-color: transparent;
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.blueGray[3]}
        }
    }
    .MuiDataGrid-cell .button-secondary {
        background: transparent;
        color: ${({ theme }) => theme.colors.blueGray[6]};
        text-transform: uppercase;
        border: none;
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.blueGray[6]};
        
        &:hover {
            color: ${({ theme }) => theme.colors.blueGray[3]};
            background-color: transparent;
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.blueGray[3]};
        }
        &:focus {
            color: ${({ theme }) => theme.colors.blueGray[6]};
            background-color: transparent;
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.yellow[5]};
        }
        &:active {
            color: ${({ theme }) => theme.colors.blueGray[3]};
            background-color: transparent;
            box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.blueGray[3]};
        }
    }
    .MuiDataGrid-cell .button-secondary-darker {
        background: transparent;
        color: ${({ theme }) => theme.colors.blueGray[4]};
        text-transform: uppercase;
        border: none;
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.blueGray[4]};
        
        &:hover {
            background-color: transparent;
            color: ${({ theme }) => theme.colors.blueGray[2]};
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.blueGray[2]};
        }
        &:focus {
            background-color: transparent;
            color: ${({ theme }) => theme.colors.blueGray[4]};
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.yellow[5]} ;
        }
        &:active {
            color: ${({ theme }) => theme.colors.blueGray[2]};
            box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.blueGray[2]};
        }
    }
    .DataGrid-cellDiv {
        padding: 0;
    }

    .MuiInputLabel-root {
        top: -5px;
    }
    .MuiGridFilterForm-root button {
        min-width: 0px;
    }
    .MuiDataGrid-columnSeparator {
        visibility: hidden;
    }
    .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeader--sortable {
        padding: 0 0 0 0;
    }
    .MuiToolbar-root p {
            color:  rgba(0, 0, 0, 0.87);
            &::before {
                content: none;
            }
    }
    .MuiPaper-root.MuiSnackbarContent-root {
        color: ${({ theme }) => theme.colors.blueGray[1]};
        background-color: ${({ theme }) => theme.colors.blueGray[9]};
        && button {
            color: white;
            background-color: ${({ theme }) => theme.colors.blue[5]};
            &:hover {
                color: white;
                background-color: ${({ theme }) => theme.colors.blue[4]};
            }
            &:active {
                color: white;
                background-color: ${({ theme }) => theme.colors.blue[4]};
            }
        }
    }
    .PrivatePickersYear-root {
        margin: 0;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
    }
    .PrivatePickersYear-yearButton {
        color: ${({ theme }) => theme.colors.blueGray[1]};
        text-align: center;
        align-items: center;
        margin: 0;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        &:focus {
            color: ${({ theme }) => theme.colors.blueGray[1]};
            background-color: transparent;
            background: transparent;
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.yellow[5]};
        }
        &:hover {
            color: ${({ theme }) => theme.colors.blueGray[1]};
            background-color: transparent;
            background: transparent;
        }
    }
    .MuiPickersDay-root {
        width: 36px;
        min-width: 0px;
        color: ${({ theme }) => theme.colors.coolGray[1]};
        &:hover, &:focus, &:active {
            color: ${({ theme }) => theme.colors.coolGray[1]};
        }
    }

    .MuiIconButton-root {
        && {
            background-color: transparent;
            text-transform: uppercase;
            border: none;
            min-width: 0px;
            cursor: pointer;
            transition-property: none;
            color: ${({ theme }) => theme.colors.blue[5]};
            &:hover {
                background-color: transparent;
                color: ${({ theme }) => theme.colors.blue[4]};
            }

            &:active {
                background-color: transparent;
                color: ${({ theme }) => theme.colors.blue[4]};
            }
        }
    }

    .MuiIconBase-root {
        background-color: ${({ theme }) => theme.colors.blue[5]};
    }
    .MuiInputBase-input.MuiInput-input {
        border: none;
        border-bottom: 1px solid white;
        
        &:hover, &:active, &:focus {
            color: ${({ theme }) => theme.colors.coolGray[1]};
        }
        &:hover {
            border-bottom: 1px solid ${({ theme }) => theme.colors.blue[5]};
        }
        &:focus {
            border-radius: 1px;
            border-bottom: 1px solid ${({ theme }) => theme.colors.blue[5]};
            box-shadow: none;
            background-color: ${({ theme }) => theme.colors.yellow[10]};
        }
    }
    .MuiOutlinedInput-input {
        && {
            padding: 0.3rem 0.3rem;
            padding-left: 0.3rem;
            padding-right: 0.3rem;
            padding-top: 0rem;
            padding-bottom: 0;
            font-size: 1rem;
            font-weight: 400;
            margin: 0;
            background-color: ${({ theme }) => theme.colors.white};
            letter-spacing: 0.08rem;
            font-family: Roboto, Helvetica Neue, Arial, sans-serif;
            color: ${({ theme }) => theme.colors.coolGray[1]};
        }
    }

    .MuiTabs-root {
        background-color: transparent;
        &:hover, &:active, &:focus {

            border-bottom: none;
            border-radius: none;
            border: none;
            background-color: transparent;
        }
        && button {
            color: ${({ theme }) => theme.colors.blue[5]};
            &:focus {
                background-color: transparent;
                color: ${({ theme }) => theme.colors.blue[5]};
            }
            &:hover, &:active {
                background-color: transparent;
                color: ${({ theme }) => theme.colors.blue[4]};
            }
        }
    }
    .MuiTabs-root ~ * {
        && {
            box-shadow: none;
            &:focus, &:active {
                box-shadow: none;
            }
            && * {
                box-shadow: none;
                &:focus, &:active {
                    box-shadow: none;
                }
            }
        }
    }

    .fontAwesomeCircleWrapper {
        border-radius: 100%;
        border: 1px solid transparent;
        background: transparent;
        color: ${({ theme }) => theme.colors.blue[5]};
        padding: 0.7rem;
        &:hover {
            color: ${({ theme }) => theme.colors.blue[4]};
            background-color: ${({ theme }) => theme.colors.coolGray[5]};
        }
        &:focus {
            border: 1px solid ${({ theme }) => theme.colors.yellow[5]};
            box-shadow: none;
        }
        &:active {
            color: ${({ theme }) => theme.colors.blue[3]};
            background-color: ${({ theme }) => theme.colors.coolGray[5]};
            border: 1px solid ${({ theme }) => theme.colors.blue[3]};
        }
    }
    
`;
