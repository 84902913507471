import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeContextProvider, ThemeGlobalStyle } from './Contexts/ThemeGlobalAndCtxt';
import { BrowserRouter as Router } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

ReactDOM.render(
    <React.StrictMode>
        <StoreProvider store={store}>
            <ThemeContextProvider>
                <ThemeGlobalStyle />
                <Router>
                    <App />
                </Router>
            </ThemeContextProvider>
        </StoreProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
