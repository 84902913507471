import React from 'react';
import { Spacer } from '../Common/Components/Spacer';

export const FormPOST = () => {
    return (
        <>
            <form action="/Home/LoginGUID" method="POST">
                <label htmlFor="guid">Input EAP guid</label>
                <br />
                <input name="guid" id="say" style={{ boxShadow: 'none', border: 'none' }} />
                <br />
                <br />
                <button>Submit</button>
            </form>
            <Spacer />
            <form action="/Home/LoginGUID" method="POST">
                <label htmlFor="AdvisoryGUID">Input advisory GUID</label>
                <br />
                <input name="AdvisoryGUID" id="other" style={{ boxShadow: 'none', border: 'none' }} />
                <br />
                <br />
                <button>Submit</button>
            </form>
        </>
    );
};
