import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import type { sliceAPI, RootState, NormalizedObjects } from '../store';
import {
    prepareData,
    prepareDataAdvisory,
    SetUserSavedState,
    handleErrorStatus,
    SetAdvisoryFileUpload,
    DeleteAdvisoryFileUpload,
} from '../Common/apiRequests';
import { setApiDateTimeRefresh } from './userSlice';
import dayjs from 'dayjs';
import { updateCurrentListingID } from './listingsSlice';

type normalizedFormData =
    | 'Critical employee'
    | 'Critical operation'
    | 'Medical employee'
    | 'Assembly point'
    | 'Chemical'
    | 'Contact'
    | 'Fire extinguisher employee'
    | 'Advisory contacts'
    | 'Advisory supplemental'
    | 'Advisory Checklist';

interface Iaddress {
    streetAddress: string;
    city: string;
    state: string;
    zip: string;
    siteNickname: string;
}
interface IassemblyPoint {
    name: string;
    description: string;
}
interface IcriticalEmployee {
    name: string;
    operations: NormalizedObjects<string>;
}
export interface IEAPdata {
    //define the nodes to be used for the app's forms
    additionalInfo: {
        additionalInfoChecked: boolean;
        additionalInfoText: string;
    };
    alerts: {
        alertsUsed: {
            verbal: boolean;
            PAsystem: boolean;
            onSiteAlarmSystem: boolean;
            emergencyMassNotification: boolean;
            ICOCnotifications: boolean;
            otherAlert: {
                otherAlertChecked: boolean;
                otherAlertText: string;
            };
        };
        drills: {
            drillsChecked: boolean;
            drillsText: string;
        };
        specificAlarms: {
            fire: string;
            earthquake: string;
            evacuation: string;
        };
        reporting: string;
    };
    basicInfo: {
        companyName: string;
        address: Iaddress;
        companyPlanContacts: NormalizedObjects<{
            name: string;
            title: string;
            phone: string;
            email: string;
            employeeContact: boolean;
        }>;
    };
    chemicals: {
        chemicalsOnsite: boolean;
        chemicalsList: NormalizedObjects<string>;
        properDocumentation: boolean;
        reportingNearMisses: string;
        reportingSpills: string;
    };
    evacuation: {
        policyProcedures: {
            evacuationPolicy: {
                allEmployeesEvacuate: boolean;
                evacuationPolicyDetails: string;
            };
            floorWardens: {
                floorWardensExist: boolean;
                wardensPolicyDetails: string;
            };
        };
        evacuationRoutes: {
            routesDefault: boolean;
            routesDescription: string;
            routesUploadName: string;
        };
        assembly: {
            assemblyPoints: NormalizedObjects<IassemblyPoint>;
            reunificationUploadName: string;
        };
        rollCall: {
            ICOCmanaged: boolean;
            description: string;
        };
    };
    fire: {
        policyProcedures: string;
        portableFireExtinguishers: boolean;
        permissionToUse: 'All' | 'Specific' | 'None';
        permissionAll: boolean;
        permissionSpecific: boolean;
        permissionNone: boolean;
        employeesAllowed: NormalizedObjects<string>;
    };
    playbookInfo: {
        playbookName?: any;
        publishDate: string;
    };
    rolesResponsibilities: {
        criticalEmployeesPermissions: 'All' | 'Specific' | 'None';
        criticalEmployeesPermissionsAll: boolean;
        criticalEmployeesPermissionsSpecific: boolean;
        criticalEmployeesPermissionsNone: boolean;
        criticalEmployeesList: NormalizedObjects<IcriticalEmployee>;
        medicalEmergencyPermissions: 'All' | 'Certified' | 'Specific' | 'None';
        medicalEmergencyPermissionsAll: boolean;
        medicalEmergencyPermissionsCertified: boolean;
        medicalEmergencyPermissionsSpecific: boolean;
        medicalEmergencyPermissionsNone: boolean;
        medicalEmergencyEmployeesList: NormalizedObjects<string>;
    };
    trainingAndReview: {
        trainingFrequency: 'Monthly' | 'Biannually' | 'Annually';
        ICOCtrackTraining: boolean;
        planReviewFrequency: 'Monthly' | 'Biannually' | 'Annually';
        lastReview: string;
    };
}
export interface IAdvisoryData {
    advisoryOverview: {
        startDate: string;
        endDate: string;
        overview: string;
        position: string;
        guidance: string;
        updateSubscribe: {
            updateSubscribeChecked: boolean;
            updateSubscribeRecipient: string;
        };
    };
    advisoryChecklist: {
        checklistProvided: boolean;
        checklist: NormalizedObjects<string>;
    };

    advisoryContacts: NormalizedObjects<{
        name: string;
        title: string;
        phone: string;
        email: string;
    }>;

    advisorySupplementalUploads: NormalizedObjects<{
        fileID: number;
        fileName: string;
        suppliedName: string;
    }>;
}
export interface IformsData extends sliceAPI {
    EAPdata: IEAPdata;
    AdvisoryData: IAdvisoryData;
    cachedData: any;
}
const initialCriticalEmployee: IcriticalEmployee = {
    name: '',
    operations: {
        byID: {
            0: '',
        },
        allIDs: [0],
    },
};
export const initialDataEAP: IEAPdata = {
    additionalInfo: {
        additionalInfoChecked: false,
        additionalInfoText: '',
    },
    alerts: {
        alertsUsed: {
            verbal: false,
            ICOCnotifications: false,
            PAsystem: false,
            onSiteAlarmSystem: false,
            emergencyMassNotification: false,
            otherAlert: {
                otherAlertChecked: false,
                otherAlertText: '',
            },
        },
        drills: {
            drillsChecked: true,
            drillsText: '',
        },
        specificAlarms: {
            fire: '',
            earthquake: '',
            evacuation: '',
        },
        reporting: '',
    },
    basicInfo: {
        companyName: '',
        address: {
            streetAddress: '',
            city: '',
            state: '',
            zip: '',
            siteNickname: '',
        },
        companyPlanContacts: {
            byID: {
                0: {
                    name: '',
                    title: '',
                    phone: '',
                    email: '',
                    employeeContact: true,
                },
            },
            allIDs: [0],
        },
    },
    chemicals: {
        chemicalsOnsite: false,
        chemicalsList: {
            byID: {
                0: '',
            },
            allIDs: [0],
        },
        properDocumentation: false,
        reportingNearMisses: '',
        reportingSpills: '',
    },
    evacuation: {
        policyProcedures: {
            evacuationPolicy: {
                allEmployeesEvacuate: true,
                evacuationPolicyDetails: '',
            },
            floorWardens: {
                floorWardensExist: false,
                wardensPolicyDetails: '',
            },
        },
        evacuationRoutes: {
            routesDefault: true,
            routesDescription: '',
            routesUploadName: '',
        },
        assembly: {
            assemblyPoints: {
                byID: {
                    0: {
                        name: '',
                        description: '',
                    },
                },
                allIDs: [0],
            },
            reunificationUploadName: '',
        },
        rollCall: {
            ICOCmanaged: true,
            description: '',
        },
    },
    fire: {
        policyProcedures: '',
        portableFireExtinguishers: true,
        permissionToUse: 'None',
        permissionAll: false,
        permissionSpecific: false,
        permissionNone: true,
        employeesAllowed: {
            byID: {
                0: '',
            },
            allIDs: [0],
        },
    },
    playbookInfo: {
        playbookName: '',
        publishDate: '',
    },
    rolesResponsibilities: {
        criticalEmployeesPermissions: 'None',
        criticalEmployeesPermissionsAll: false,
        criticalEmployeesPermissionsSpecific: false,
        criticalEmployeesPermissionsNone: true,
        criticalEmployeesList: {
            byID: {
                0: initialCriticalEmployee,
            },
            allIDs: [0],
        },
        medicalEmergencyPermissions: 'None',
        medicalEmergencyPermissionsAll: false,
        medicalEmergencyPermissionsCertified: false,
        medicalEmergencyPermissionsSpecific: false,
        medicalEmergencyPermissionsNone: true,
        medicalEmergencyEmployeesList: {
            byID: {
                0: '',
            },
            allIDs: [0],
        },
    },
    trainingAndReview: {
        trainingFrequency: 'Annually',
        ICOCtrackTraining: true,
        planReviewFrequency: 'Annually',
        lastReview: '',
    },
};
export const initialDataAdvisory: IAdvisoryData = {
    advisoryOverview: {
        startDate: '',
        endDate: '',
        overview: '',
        position: '',
        guidance: '',
        updateSubscribe: {
            updateSubscribeChecked: true,
            updateSubscribeRecipient: '',
        },
    },
    advisoryChecklist: {
        checklistProvided: true,
        checklist: {
            byID: {
                0: '',
            },
            allIDs: [0],
        },
    },
    advisoryContacts: {
        byID: {
            0: {
                name: '',
                title: '',
                phone: '',
                email: '',
            },
        },
        allIDs: [0],
    },
    advisorySupplementalUploads: {
        byID: {
            0: {
                fileID: 0,
                fileName: '',
                suppliedName: '',
            },
        },
        allIDs: [0],
    },
};

const initialState: IformsData = {
    EAPdata: initialDataEAP,
    AdvisoryData: initialDataAdvisory,
    cachedData: false,
    postStatus: 'idle',
    getStatus: 'idle',
    errorMessage: null,
};

export const setEAPDataToDB = createAsyncThunk<any, keyof IEAPdata | 'address' | 'all', { state: RootState }>(
    'Forms Data/setEAPStateToDB',
    async (node, { getState, dispatch, rejectWithValue }) => {
        let state = getState();

        let responseData;
        if (node === 'all') {
            JSON.stringify({
                playbookName: state.listings.data.listingName,
                publishDate: state.formsData.EAPdata.playbookInfo.publishDate,
            });
            responseData = await SetUserSavedState({
                completeYN: false,
                node_json: JSON.stringify({
                    playbookName: state.listings.data.listingName,
                    publishDate: state.formsData.EAPdata.playbookInfo.publishDate,
                }),
                node_path: `$.playbookInfo`,
                listingID: state.listings.data.listingID,
                listing_name: state.listings.data.listingName,
                shared: 'N',
            });
            if (responseData.error) {
                handleErrorStatus(responseData.error, dispatch);
                return rejectWithValue(responseData.error);
            } else {
                await Promise.all([
                    SetUserSavedState({
                        completeYN: false,
                        node_json: JSON.stringify(state.formsData.EAPdata.basicInfo),
                        node_path: `$.basicInfo`,
                        listingID: responseData.value ? parseInt(responseData.value) : 0,
                        listing_name: state.listings.data.listingName,
                        shared: 'N',
                    }),
                    SetUserSavedState({
                        completeYN: false,
                        node_json: JSON.stringify(state.formsData.EAPdata.additionalInfo),
                        node_path: `$.additionalInfo`,
                        listingID: responseData.value ? parseInt(responseData.value) : 0,
                        listing_name: state.listings.data.listingName,
                        shared: 'N',
                    }),
                    SetUserSavedState({
                        completeYN: false,
                        node_json: JSON.stringify(state.formsData.EAPdata.alerts),
                        node_path: `$.alerts`,
                        listingID: responseData.value ? parseInt(responseData.value) : 0,
                        listing_name: state.listings.data.listingName,
                        shared: 'N',
                    }),
                    SetUserSavedState({
                        completeYN: false,
                        node_json: JSON.stringify(state.formsData.EAPdata.chemicals),
                        node_path: `$.chemicals`,
                        listingID: responseData.value ? parseInt(responseData.value) : 0,
                        listing_name: state.listings.data.listingName,
                        shared: 'N',
                    }),
                    SetUserSavedState({
                        completeYN: false,
                        node_json: JSON.stringify(state.formsData.EAPdata.evacuation),
                        node_path: `$.evacuation`,
                        listingID: responseData.value ? parseInt(responseData.value) : 0,
                        listing_name: state.listings.data.listingName,
                        shared: 'N',
                    }),
                    SetUserSavedState({
                        completeYN: false,
                        node_json: JSON.stringify(state.formsData.EAPdata.fire),
                        node_path: `$.fire`,
                        listingID: responseData.value ? parseInt(responseData.value) : 0,
                        listing_name: state.listings.data.listingName,
                        shared: 'N',
                    }),
                    SetUserSavedState({
                        completeYN: false,
                        node_json: JSON.stringify(state.formsData.EAPdata.rolesResponsibilities),
                        node_path: `$.rolesResponsibilities`,
                        listingID: responseData.value ? parseInt(responseData.value) : 0,
                        listing_name: state.listings.data.listingName,
                        shared: 'N',
                    }),
                    SetUserSavedState({
                        completeYN: false,
                        node_json: JSON.stringify(state.formsData.EAPdata.trainingAndReview),
                        node_path: `$.trainingAndReview`,
                        listingID: responseData.value ? parseInt(responseData.value) : 0,
                        listing_name: state.listings.data.listingName,
                        shared: 'N',
                    }),
                    SetUserSavedState({
                        completeYN: false,
                        node_json: JSON.stringify(state.formsData.EAPdata.basicInfo.address),
                        node_path: `$.address`,
                        listingID: responseData.value ? parseInt(responseData.value) : 0,
                        listing_name: state.listings.data.listingName,
                        shared: 'N',
                    }),
                ]);
            }
        } else if (node === 'address') {
            let postDataForAddress = prepareData(state, '$.address', state.formsData.EAPdata.basicInfo.address);
            responseData = await SetUserSavedState(postDataForAddress);
        } else if (node === 'basicInfo') {
            let postData = prepareData(state, `$.${node}`, {
                companyName: state.formsData.EAPdata['basicInfo'].companyName,
                companyPlanContacts: state.formsData.EAPdata['basicInfo'].companyPlanContacts,
            });
            responseData = await SetUserSavedState(postData);
        } else if (node === 'playbookInfo') {
            let postData = prepareData(state, `$.${node}`, {
                playbookName: state.listings.data.listingName,
                publishDate: state.formsData.EAPdata.playbookInfo.publishDate,
            });
            responseData = await SetUserSavedState(postData);
        } else {
            let postData = prepareData(state, `$.${node}`, state.formsData.EAPdata[node]);
            responseData = await SetUserSavedState(postData);
        }

        if (responseData.error) {
            handleErrorStatus(responseData.error, dispatch);
            return rejectWithValue(responseData.error);
        } else {
            dispatch(setApiDateTimeRefresh(dayjs().toISOString()));
            dispatch(updateCurrentListingID(responseData.value ? parseInt(responseData.value) : 0));
            await SetUserSavedState({
                completeYN: false,
                node_json: JSON.stringify({ sections: state.sectionProgress.sections }),
                node_path: `$.sectionProgress`,
                listingID: responseData.value ? parseInt(responseData.value) : 0,
                listing_name: state.listings.data.listingName,
                shared: state.user.shared,
            });
        }

        return responseData;
    },
);
export const setAdvisoryDataToDB = createAsyncThunk<any, keyof IAdvisoryData | 'all', { state: RootState }>(
    'Forms Data/setAdvisoryStateToDB',
    async (node, { getState, dispatch, rejectWithValue }) => {
        let state = getState();
        let responseData;
        if (node === 'all') {
            responseData = await SetUserSavedState({
                node_json: JSON.stringify(state.formsData.AdvisoryData.advisoryOverview),
                node_path: `$.advisoryOverview`,
            });
            if (responseData.error) {
                handleErrorStatus(responseData.error, dispatch);
                return rejectWithValue(responseData.error);
            } else {
                await Promise.all([
                    SetUserSavedState({
                        node_json: JSON.stringify(state.formsData.AdvisoryData.advisoryChecklist),
                        node_path: `$.advisoryChecklist`,
                    }),
                    SetUserSavedState({
                        node_json: JSON.stringify(state.formsData.AdvisoryData.advisoryContacts),
                        node_path: `$.advisoryContacts`,
                    }),
                    SetUserSavedState({
                        node_json: JSON.stringify(state.formsData.AdvisoryData.advisorySupplementalUploads),
                        node_path: `$.advisorySupplementalUploads`,
                    }),
                ]);
            }
        } else {
            let postData = prepareDataAdvisory(state, `$.${node}`, state.formsData.AdvisoryData[node]);
            responseData = await SetUserSavedState(postData);
        }

        if (responseData.error) {
            handleErrorStatus(responseData.error, dispatch);
            return rejectWithValue(responseData.error);
        } else {
            dispatch(setApiDateTimeRefresh(dayjs().toISOString()));
            await SetUserSavedState({
                node_json: JSON.stringify({ sections: state.sectionProgress.sections }),
                node_path: `$.sectionProgress`,
            });
        }

        return responseData;
    },
);

export const setAdvisoryUploadsToDB = createAsyncThunk<
    any,
    { attachmentFiles: Array<File | null>; suppliedNames: Array<string> },
    { state: RootState }
>('Forms Data/setAdvisoryUploadsToDB', async (attachmentsInput, { getState, dispatch, rejectWithValue }) => {
    let attachments = attachmentsInput.attachmentFiles;
    let suppliedNames = attachmentsInput.suppliedNames;

    let supplementalUploadsState = getState().formsData.AdvisoryData.advisorySupplementalUploads;
    let state = getState();

    dispatch(setApiDateTimeRefresh(dayjs().toISOString()));
    await SetUserSavedState({
        node_json: JSON.stringify({ sections: state.sectionProgress.sections }),
        node_path: `$.sectionProgress`,
    });

    for (let i = 0; i < suppliedNames.length; i++) {
        if (attachments && attachments[i]) {
            let promiseReturnID = await SetAdvisoryFileUpload({
                fileID: supplementalUploadsState.byID[supplementalUploadsState.allIDs[i]].fileID,
                //@ts-ignore
                file: attachments[i],
            });
            if (promiseReturnID['error']) {
                console.log(`There was an error on saving file ${i}.`);
                return rejectWithValue(promiseReturnID['error']);
            } else {
                dispatch(
                    updateNormalizedData({
                        node: 'Advisory supplemental',
                        targetIndex: i,
                        value: {
                            fileName: attachments[i]?.name,
                            fileID:
                                promiseReturnID.data === 'Final Return path for error'
                                    ? 0
                                    : parseInt(promiseReturnID.data),
                            suppliedName: suppliedNames[i],
                        },
                    }),
                );
            }
        } else {
            dispatch(
                updateNormalizedData({
                    node: 'Advisory supplemental',
                    targetIndex: i,
                    value: {
                        fileName: supplementalUploadsState.byID[supplementalUploadsState.allIDs[i]].fileName,
                        fileID: supplementalUploadsState.byID[supplementalUploadsState.allIDs[i]].fileID,
                        suppliedName: suppliedNames[i],
                    },
                }),
            );
        }
    }

    let newState = getState();
    let newPostData = prepareData(
        newState,
        `$.advisorySupplementalUploads`,
        newState.formsData.AdvisoryData.advisorySupplementalUploads,
    );
    let newResponseData = await SetUserSavedState(newPostData);
    let cachedData: NormalizedObjects<{
        fileID: number;
        fileName: string;
        suppliedName: string;
    }> = newState.formsData.cachedData;
    let deleteFiles: Array<number> = [];
    cachedData.allIDs.forEach((cachedID) => {
        let foundFile = newState.formsData.AdvisoryData.advisorySupplementalUploads.allIDs.find((index) => {
            return (
                state.formsData.AdvisoryData.advisorySupplementalUploads.byID[index].fileID ===
                cachedData.byID[cachedID].fileID
            );
        });
        if (foundFile === undefined) {
            deleteFiles.push(cachedData.byID[cachedID].fileID);
        }
    });
    deleteFiles.forEach((fileID) => {
        let response = DeleteAdvisoryFileUpload(fileID);
        console.log(response);
        if (response['error']) {
            console.log(`There was an error deleting file ${fileID}.`);
            return rejectWithValue(response['error']);
        }
    });
    return newResponseData;
});

export const formsDataSlice = createSlice({
    name: 'Forms Data',
    initialState,
    reducers: {
        resetFormsDataNode: (state, action: PayloadAction<string>) => {
            if (Object.keys(initialDataEAP).includes(action.payload)) {
                state.EAPdata[action.payload] = initialDataEAP[action.payload];
            } else if (Object.keys(initialDataAdvisory).includes(action.payload)) {
                state.AdvisoryData[action.payload] = initialDataAdvisory[action.payload];
            }
        },
        resetFormsData: (state) => {
            state.EAPdata = initialDataEAP;
            state.AdvisoryData = initialDataAdvisory;
        },
        resetFormsDataPostStatus: (state) => {
            state.postStatus = 'idle';
        },
        updateFormsDataNode: (
            state,
            action: PayloadAction<{ node: keyof IEAPdata | keyof IAdvisoryData; data: any }>,
        ) => {
            if (Object.keys(initialDataEAP).includes(action.payload.node)) {
                state.EAPdata[action.payload.node] = action.payload.data;
            } else if (Object.keys(initialDataAdvisory).includes(action.payload.node)) {
                state.AdvisoryData[action.payload.node] = action.payload.data;
            }
        },
        addNormalizedData: (state, action: PayloadAction<{ node: normalizedFormData; parentIndex?: string }>) => {
            let newID;
            if (action.payload.node === 'Assembly point') {
                newID =
                    state.EAPdata.evacuation.assembly.assemblyPoints.allIDs[
                        state.EAPdata.evacuation.assembly.assemblyPoints.allIDs.length - 1
                    ] + 1;
                state.EAPdata.evacuation.assembly.assemblyPoints.allIDs.push(newID);
                Object.assign(state.EAPdata.evacuation.assembly.assemblyPoints.byID, {
                    [newID]: initialDataEAP.evacuation.assembly.assemblyPoints.byID[0],
                });
            } else if (action.payload.node === 'Chemical') {
                newID =
                    state.EAPdata.chemicals.chemicalsList.allIDs[
                        state.EAPdata.chemicals.chemicalsList.allIDs.length - 1
                    ] + 1;
                state.EAPdata.chemicals.chemicalsList.allIDs.push(newID);
                Object.assign(state.EAPdata.chemicals.chemicalsList.byID, {
                    [newID]: initialDataEAP.chemicals.chemicalsList.byID[0],
                });
            } else if (action.payload.node === 'Contact') {
                newID =
                    state.EAPdata.basicInfo.companyPlanContacts.allIDs[
                        state.EAPdata.basicInfo.companyPlanContacts.allIDs.length - 1
                    ] + 1;
                state.EAPdata.basicInfo.companyPlanContacts.allIDs.push(newID);
                Object.assign(state.EAPdata.basicInfo.companyPlanContacts.byID, {
                    [newID]: initialDataEAP.basicInfo.companyPlanContacts.byID[0],
                });
            } else if (action.payload.node === 'Critical employee') {
                newID =
                    state.EAPdata.rolesResponsibilities.criticalEmployeesList.allIDs[
                        state.EAPdata.rolesResponsibilities.criticalEmployeesList.allIDs.length - 1
                    ] + 1;
                state.EAPdata.rolesResponsibilities.criticalEmployeesList.allIDs.push(newID);
                Object.assign(state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID, {
                    [newID]: initialDataEAP.rolesResponsibilities.criticalEmployeesList.byID[0],
                });
            } else if (action.payload.node === 'Critical operation' && action.payload.parentIndex) {
                newID =
                    state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[parseInt(action.payload.parentIndex)]
                        .operations.allIDs[
                        state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[
                            parseInt(action.payload.parentIndex)
                        ].operations.allIDs.length - 1
                    ] + 1;
                state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[
                    parseInt(action.payload.parentIndex)
                ].operations.allIDs.push(newID);
                Object.assign(
                    state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[parseInt(action.payload.parentIndex)]
                        .operations.byID,
                    { [newID]: initialDataEAP.rolesResponsibilities.criticalEmployeesList.byID[0].operations.byID[0] },
                );
            } else if (action.payload.node === 'Medical employee') {
                newID =
                    state.EAPdata.rolesResponsibilities.medicalEmergencyEmployeesList.allIDs[
                        state.EAPdata.rolesResponsibilities.medicalEmergencyEmployeesList.allIDs.length - 1
                    ] + 1;
                state.EAPdata.rolesResponsibilities.medicalEmergencyEmployeesList.allIDs.push(newID);
                Object.assign(state.EAPdata.rolesResponsibilities.medicalEmergencyEmployeesList.byID, {
                    [newID]: initialDataEAP.rolesResponsibilities.medicalEmergencyEmployeesList.byID[0],
                });
            } else if (action.payload.node === 'Fire extinguisher employee') {
                newID =
                    state.EAPdata.fire.employeesAllowed.allIDs[state.EAPdata.fire.employeesAllowed.allIDs.length - 1] +
                    1;
                state.EAPdata.fire.employeesAllowed.allIDs.push(newID);
                Object.assign(state.EAPdata.fire.employeesAllowed.byID, {
                    [newID]: initialDataEAP.fire.employeesAllowed.byID[0],
                });
            } else if (action.payload.node === 'Advisory contacts') {
                newID =
                    state.AdvisoryData.advisoryContacts.allIDs[state.AdvisoryData.advisoryContacts.allIDs.length - 1] +
                    1;
                state.AdvisoryData.advisoryContacts.allIDs.push(newID);
                Object.assign(state.AdvisoryData.advisoryContacts.byID, {
                    [newID]: initialDataAdvisory.advisoryContacts.byID[0],
                });
            } else if (action.payload.node === 'Advisory supplemental') {
                newID =
                    state.AdvisoryData.advisorySupplementalUploads.allIDs[
                        state.AdvisoryData.advisorySupplementalUploads.allIDs.length - 1
                    ] + 1;
                state.AdvisoryData.advisorySupplementalUploads.allIDs.push(newID);
                Object.assign(state.AdvisoryData.advisorySupplementalUploads.byID, {
                    [newID]: initialDataAdvisory.advisorySupplementalUploads.byID[0],
                });
            } else if (action.payload.node === 'Advisory Checklist') {
                newID =
                    state.AdvisoryData.advisoryChecklist.checklist.allIDs[
                        state.AdvisoryData.advisoryChecklist.checklist.allIDs.length - 1
                    ] + 1;
                state.AdvisoryData.advisoryChecklist.checklist.allIDs.push(newID);
                Object.assign(state.AdvisoryData.advisoryChecklist.checklist.byID, {
                    [newID]: initialDataAdvisory.advisoryChecklist.checklist.byID[0],
                });
            }
        },
        removeNormalizedData: (
            state,
            action: PayloadAction<{ node: normalizedFormData; targetIndex: number; parentIndex?: string }>,
        ) => {
            let targetID;
            if (action.payload.node === 'Assembly point') {
                targetID = state.EAPdata.evacuation.assembly.assemblyPoints.allIDs.splice(
                    action.payload.targetIndex,
                    1,
                )[0];
                delete state.EAPdata.evacuation.assembly.assemblyPoints.byID[targetID];
            } else if (action.payload.node === 'Chemical') {
                targetID = state.EAPdata.chemicals.chemicalsList.allIDs.splice(action.payload.targetIndex, 1)[0];
                delete state.EAPdata.chemicals.chemicalsList.byID[targetID];
            } else if (action.payload.node === 'Contact') {
                targetID = state.EAPdata.basicInfo.companyPlanContacts.allIDs.splice(action.payload.targetIndex, 1)[0];
                delete state.EAPdata.basicInfo.companyPlanContacts.byID[targetID];
            } else if (action.payload.node === 'Critical employee') {
                targetID = state.EAPdata.rolesResponsibilities.criticalEmployeesList.allIDs.splice(
                    action.payload.targetIndex,
                    1,
                )[0];
                delete state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[targetID];
            } else if (action.payload.node === 'Critical operation' && action.payload.parentIndex) {
                targetID = state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[
                    parseInt(action.payload.parentIndex)
                ].operations.allIDs.splice(action.payload.targetIndex, 1)[0];
                delete state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[
                    parseInt(action.payload.parentIndex)
                ].operations.byID[targetID];
            } else if (action.payload.node === 'Medical employee') {
                targetID = state.EAPdata.rolesResponsibilities.medicalEmergencyEmployeesList.allIDs.splice(
                    action.payload.targetIndex,
                    1,
                )[0];
                delete state.EAPdata.rolesResponsibilities.medicalEmergencyEmployeesList.byID[targetID];
            } else if (action.payload.node === 'Fire extinguisher employee') {
                targetID = state.EAPdata.fire.employeesAllowed.allIDs.splice(action.payload.targetIndex, 1)[0];
                delete state.EAPdata.fire.employeesAllowed.byID[targetID];
            } else if (action.payload.node === 'Advisory contacts') {
                targetID = state.AdvisoryData.advisoryContacts.allIDs.splice(action.payload.targetIndex, 1)[0];
                delete state.AdvisoryData.advisoryContacts.byID[targetID];
            } else if (action.payload.node === 'Advisory supplemental') {
                targetID = state.AdvisoryData.advisorySupplementalUploads.allIDs.splice(
                    action.payload.targetIndex,
                    1,
                )[0];
                delete state.AdvisoryData.advisorySupplementalUploads.byID[targetID];
            } else if (action.payload.node === 'Advisory Checklist') {
                targetID = state.AdvisoryData.advisoryChecklist.checklist.allIDs.splice(
                    action.payload.targetIndex,
                    1,
                )[0];
                delete state.AdvisoryData.advisoryChecklist.checklist.byID[targetID];
            }
        },
        updateNormalizedData: (
            state,
            action: PayloadAction<{ node: normalizedFormData; parentIndex?: string; targetIndex: number; value: any }>,
        ) => {
            let targetID;
            if (action.payload.node === 'Assembly point') {
                targetID = state.EAPdata.evacuation.assembly.assemblyPoints.allIDs[action.payload.targetIndex];
                state.EAPdata.evacuation.assembly.assemblyPoints.byID[targetID] = action.payload.value;
            } else if (action.payload.node === 'Chemical') {
                targetID = state.EAPdata.chemicals.chemicalsList.allIDs[action.payload.targetIndex];
                state.EAPdata.chemicals.chemicalsList.byID[targetID] = action.payload.value;
            } else if (action.payload.node === 'Contact') {
                targetID = state.EAPdata.basicInfo.companyPlanContacts.allIDs[action.payload.targetIndex];
                state.EAPdata.basicInfo.companyPlanContacts.byID[targetID] = action.payload.value;
            } else if (action.payload.node === 'Critical employee') {
                targetID = state.EAPdata.rolesResponsibilities.criticalEmployeesList.allIDs[action.payload.targetIndex];
                state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[targetID] = action.payload.value;
            } else if (action.payload.node === 'Critical operation' && action.payload.parentIndex) {
                targetID =
                    state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[parseInt(action.payload.parentIndex)]
                        .operations.allIDs[action.payload.targetIndex];
                state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[
                    parseInt(action.payload.parentIndex)
                ].operations.byID[targetID] = action.payload.value;
            } else if (action.payload.node === 'Medical employee') {
                targetID =
                    state.EAPdata.rolesResponsibilities.medicalEmergencyEmployeesList.allIDs[
                        action.payload.targetIndex
                    ];
                state.EAPdata.rolesResponsibilities.medicalEmergencyEmployeesList.byID[targetID] = action.payload.value;
            } else if (action.payload.node === 'Fire extinguisher employee') {
                targetID = state.EAPdata.fire.employeesAllowed.allIDs[action.payload.targetIndex];
                state.EAPdata.fire.employeesAllowed.byID[targetID] = action.payload.value;
            } else if (action.payload.node === 'Advisory contacts') {
                targetID = state.AdvisoryData.advisoryContacts.allIDs[action.payload.targetIndex];
                state.AdvisoryData.advisoryContacts.byID[targetID] = action.payload.value;
            } else if (action.payload.node === 'Advisory supplemental') {
                targetID = state.AdvisoryData.advisorySupplementalUploads.allIDs[action.payload.targetIndex];
                state.AdvisoryData.advisorySupplementalUploads.byID[targetID] = action.payload.value;
            } else if (action.payload.node === 'Advisory Checklist') {
                targetID = state.AdvisoryData.advisoryChecklist.checklist.allIDs[action.payload.targetIndex];
                state.AdvisoryData.advisoryChecklist.checklist.byID[targetID] = action.payload.value;
            }
        },
        purgeConditionalItems: (state) => {
            if (!state.EAPdata.additionalInfo.additionalInfoChecked) {
                state.EAPdata.additionalInfo.additionalInfoText = '';
            }
            if (!state.EAPdata.alerts.alertsUsed.otherAlert.otherAlertChecked) {
                state.EAPdata.alerts.alertsUsed.otherAlert.otherAlertText = '';
            }
            if (!state.EAPdata.alerts.drills.drillsChecked) {
                state.EAPdata.alerts.drills.drillsText = '';
            }
            if (!state.EAPdata.chemicals.chemicalsOnsite) {
                state.EAPdata.chemicals = initialDataEAP.chemicals;
            }
            if (!state.EAPdata.evacuation.policyProcedures.floorWardens.floorWardensExist) {
                state.EAPdata.evacuation.policyProcedures.floorWardens.wardensPolicyDetails = '';
            }
            if (state.EAPdata.fire.portableFireExtinguishers) {
                if (state.EAPdata.fire.permissionToUse !== 'Specific') {
                    state.EAPdata.fire.employeesAllowed = initialDataEAP.fire.employeesAllowed;
                }
            } else {
                state.EAPdata.fire.permissionToUse = initialDataEAP.fire.permissionToUse;
                state.EAPdata.fire.permissionAll = initialDataEAP.fire.permissionAll;
                state.EAPdata.fire.permissionSpecific = initialDataEAP.fire.permissionSpecific;
                state.EAPdata.fire.permissionNone = initialDataEAP.fire.permissionNone;
                state.EAPdata.fire.employeesAllowed = initialDataEAP.fire.employeesAllowed;
            }
            if (state.EAPdata.rolesResponsibilities.criticalEmployeesPermissions !== 'Specific') {
                state.EAPdata.rolesResponsibilities.criticalEmployeesList =
                    initialDataEAP.rolesResponsibilities.criticalEmployeesList;
            }
            if (state.EAPdata.rolesResponsibilities.medicalEmergencyPermissions !== 'Specific') {
                state.EAPdata.rolesResponsibilities.medicalEmergencyEmployeesList =
                    initialDataEAP.rolesResponsibilities.medicalEmergencyEmployeesList;
            }
            if (!state.AdvisoryData.advisoryChecklist.checklistProvided) {
                state.AdvisoryData.advisoryChecklist.checklist = initialDataAdvisory.advisoryChecklist.checklist;
            }
        },
        purgeEmptyNodes: (state) => {
            let deleteArray: Array<{ item: number; index: number }> = [];
            state.EAPdata.basicInfo.companyPlanContacts.allIDs.forEach((item, index) => {
                if (state.EAPdata.basicInfo.companyPlanContacts.byID[item].name === '') {
                    deleteArray.unshift({ item: item, index: index });
                }
            });
            if (deleteArray.length === state.EAPdata.basicInfo.companyPlanContacts.allIDs.length) {
                state.EAPdata.basicInfo.companyPlanContacts = initialDataEAP.basicInfo.companyPlanContacts;
            } else {
                deleteArray.forEach((entry) => {
                    state.EAPdata.basicInfo.companyPlanContacts.allIDs.splice(entry.index, 1);
                    delete state.EAPdata.basicInfo.companyPlanContacts.byID[entry.item];
                });
            }
            deleteArray = [];
            state.EAPdata.chemicals.chemicalsList.allIDs.forEach((item, index) => {
                if (state.EAPdata.chemicals.chemicalsList.byID[item] === '') {
                    deleteArray.unshift({ item: item, index: index });
                }
            });
            if (deleteArray.length === state.EAPdata.chemicals.chemicalsList.allIDs.length) {
                state.EAPdata.chemicals.chemicalsList = initialDataEAP.chemicals.chemicalsList;
            } else {
                deleteArray.forEach((entry) => {
                    state.EAPdata.chemicals.chemicalsList.allIDs.splice(entry.index, 1);
                    delete state.EAPdata.chemicals.chemicalsList.byID[entry.item];
                });
            }
            deleteArray = [];
            state.EAPdata.evacuation.assembly.assemblyPoints.allIDs.forEach((item, index) => {
                if (state.EAPdata.evacuation.assembly.assemblyPoints.byID[item].name === '') {
                    deleteArray.unshift({ item: item, index: index });
                }
            });
            if (deleteArray.length === state.EAPdata.evacuation.assembly.assemblyPoints.allIDs.length) {
                state.EAPdata.evacuation.assembly.assemblyPoints = initialDataEAP.evacuation.assembly.assemblyPoints;
            } else {
                deleteArray.forEach((entry) => {
                    state.EAPdata.evacuation.assembly.assemblyPoints.allIDs.splice(entry.index, 1);
                    delete state.EAPdata.evacuation.assembly.assemblyPoints.byID[entry.item];
                });
            }
            deleteArray = [];
            state.EAPdata.fire.employeesAllowed.allIDs.forEach((item, index) => {
                if (state.EAPdata.fire.employeesAllowed.byID[item] === '') {
                    deleteArray.unshift({ item: item, index: index });
                }
            });
            if (deleteArray.length === state.EAPdata.fire.employeesAllowed.allIDs.length) {
                state.EAPdata.fire.employeesAllowed = initialDataEAP.fire.employeesAllowed;
            } else {
                deleteArray.forEach((entry) => {
                    state.EAPdata.fire.employeesAllowed.allIDs.splice(entry.index, 1);
                    delete state.EAPdata.fire.employeesAllowed.byID[entry.item];
                });
            }
            let deleteArray1: Array<{ item: number; index: number }> = [];
            state.EAPdata.rolesResponsibilities.criticalEmployeesList.allIDs.forEach((item, index) => {
                if (state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[item].name === '') {
                    deleteArray1.unshift({ item: item, index: index });
                } else {
                    let deleteArray2: Array<{ item: number; index: number }> = [];
                    state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[item].operations.allIDs.forEach(
                        (item2, index2) => {
                            if (
                                state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[item].operations.byID[
                                    item2
                                ] === ''
                            ) {
                                deleteArray2.unshift({ item: item2, index: index2 });
                            }
                        },
                    );
                    if (
                        deleteArray2.length ===
                        state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[item].operations.allIDs.length
                    ) {
                        state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[item].operations =
                            initialDataEAP.rolesResponsibilities.criticalEmployeesList.byID[0].operations;
                    } else {
                        deleteArray2.forEach((entry) => {
                            state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[
                                item
                            ].operations.allIDs.splice(entry.index, 1);
                            delete state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[item].operations.byID[
                                entry.item
                            ];
                        });
                    }
                }
            });
            if (deleteArray1.length === state.EAPdata.rolesResponsibilities.criticalEmployeesList.allIDs.length) {
                state.EAPdata.rolesResponsibilities.criticalEmployeesList =
                    initialDataEAP.rolesResponsibilities.criticalEmployeesList;
            } else {
                deleteArray1.forEach((entry) => {
                    state.EAPdata.rolesResponsibilities.criticalEmployeesList.allIDs.splice(entry.index, 1);
                    delete state.EAPdata.rolesResponsibilities.criticalEmployeesList.byID[entry.item];
                });
            }

            deleteArray = [];
            state.EAPdata.rolesResponsibilities.medicalEmergencyEmployeesList.allIDs.forEach((item, index) => {
                if (state.EAPdata.rolesResponsibilities.medicalEmergencyEmployeesList.byID[item] === '') {
                    deleteArray.unshift({ item: item, index: index });
                }
            });
            if (
                deleteArray.length === state.EAPdata.rolesResponsibilities.medicalEmergencyEmployeesList.allIDs.length
            ) {
                state.EAPdata.rolesResponsibilities.medicalEmergencyEmployeesList =
                    initialDataEAP.rolesResponsibilities.medicalEmergencyEmployeesList;
            } else {
                deleteArray.forEach((entry) => {
                    state.EAPdata.rolesResponsibilities.medicalEmergencyEmployeesList.allIDs.splice(entry.index, 1);
                    delete state.EAPdata.rolesResponsibilities.medicalEmergencyEmployeesList.byID[entry.item];
                });
            }

            deleteArray = [];
            state.AdvisoryData.advisoryContacts.allIDs.forEach((item, index) => {
                if (state.AdvisoryData.advisoryContacts.byID[item].name === '') {
                    deleteArray.unshift({ item: item, index: index });
                }
            });
            if (deleteArray.length === state.AdvisoryData.advisoryContacts.allIDs.length) {
                state.AdvisoryData.advisoryContacts = initialDataAdvisory.advisoryContacts;
            } else {
                deleteArray.forEach((entry) => {
                    state.AdvisoryData.advisoryContacts.allIDs.splice(entry.index, 1);
                    delete state.AdvisoryData.advisoryContacts.byID[entry.item];
                });
            }

            deleteArray = [];
            state.AdvisoryData.advisorySupplementalUploads.allIDs.forEach((item, index) => {
                if (state.AdvisoryData.advisorySupplementalUploads.byID[item].fileName === '') {
                    deleteArray.unshift({ item: item, index: index });
                }
            });
            if (deleteArray.length === state.AdvisoryData.advisorySupplementalUploads.allIDs.length) {
                state.AdvisoryData.advisorySupplementalUploads = initialDataAdvisory.advisorySupplementalUploads;
            } else {
                deleteArray.forEach((entry) => {
                    state.AdvisoryData.advisorySupplementalUploads.allIDs.splice(entry.index, 1);
                    delete state.AdvisoryData.advisorySupplementalUploads.byID[entry.item];
                });
            }

            deleteArray = [];
            state.AdvisoryData.advisoryChecklist.checklist.allIDs.forEach((item, index) => {
                if (state.AdvisoryData.advisoryChecklist.checklist.byID[item] === '') {
                    deleteArray.unshift({ item: item, index: index });
                }
            });
            if (deleteArray.length === state.AdvisoryData.advisoryChecklist.checklist.allIDs.length) {
                state.AdvisoryData.advisoryChecklist.checklist = initialDataAdvisory.advisoryChecklist.checklist;
            } else {
                deleteArray.forEach((entry) => {
                    state.AdvisoryData.advisoryChecklist.checklist.allIDs.splice(entry.index, 1);
                    delete state.AdvisoryData.advisoryChecklist.checklist.byID[entry.item];
                });
            }
        },
        setCachedData: (state, action: PayloadAction<any>) => {
            state.cachedData = action.payload;
        },
        updateAddress: (state, action: PayloadAction<Iaddress>) => {
            state.EAPdata.basicInfo.address = action.payload;
        },
        resetAddress: (state) => {
            state.EAPdata.basicInfo.address = initialDataEAP.basicInfo.address;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setEAPDataToDB.pending, (state) => {
                state.postStatus = 'pending';
                state.errorMessage = null;
            })
            .addCase(setEAPDataToDB.fulfilled, (state) => {
                state.postStatus = 'succeeded';
            })
            .addCase(setEAPDataToDB.rejected, (state, action) => {
                state.postStatus = 'failed';
                if (action.payload) {
                    state.errorMessage = action.payload;
                } else {
                    state.errorMessage = action.error.message;
                }
            })
            .addCase(setAdvisoryDataToDB.pending, (state) => {
                state.postStatus = 'pending';
                state.errorMessage = null;
            })
            .addCase(setAdvisoryDataToDB.fulfilled, (state) => {
                state.postStatus = 'succeeded';
            })
            .addCase(setAdvisoryDataToDB.rejected, (state, action) => {
                state.postStatus = 'failed';
                if (action.payload) {
                    state.errorMessage = action.payload;
                } else {
                    state.errorMessage = action.error.message;
                }
            })
            .addCase(setAdvisoryUploadsToDB.pending, (state) => {
                state.postStatus = 'pending';
                state.errorMessage = null;
            })
            .addCase(setAdvisoryUploadsToDB.fulfilled, (state) => {
                state.postStatus = 'succeeded';
            })
            .addCase(setAdvisoryUploadsToDB.rejected, (state, action) => {
                state.postStatus = 'failed';
                if (action.payload) {
                    state.errorMessage = action.payload;
                } else {
                    state.errorMessage = action.error.message;
                }
            });
    },
});

export const {
    resetFormsDataNode,
    updateFormsDataNode,
    resetFormsDataPostStatus,
    purgeConditionalItems,
    purgeEmptyNodes,
    addNormalizedData,
    removeNormalizedData,
    updateNormalizedData,
    resetFormsData,
    updateAddress,
    resetAddress,
    setCachedData,
} = formsDataSlice.actions;
export const selectAdditionalInfo = (state: RootState) => state.formsData.EAPdata.additionalInfo;
export const selectFormsData = (state: RootState) => state.formsData.EAPdata;
export const selectAdvisoryData = (state: RootState) => state.formsData.AdvisoryData;
export const selectFormsDataPostStatus = (state: RootState) => state.formsData.postStatus;
export const selectCachedData = (state: RootState) => state.formsData.cachedData;

export default formsDataSlice.reducer;
