import React, { useEffect } from 'react';
import { styled } from '../Contexts/ThemeGlobalAndCtxt';

import { useAppSelector, useAppDispatch } from '../Common/Hooks/reactReduxHooks';
import { selectHelpPane, toggleHelpPane } from '../ReduxSlices/sectionProgressSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StyledDivLine } from '../Common/Components/Spacer';
import { RFC } from '../Common/Components/FlexContainers';
import { faArrowToRight } from '@fortawesome/pro-light-svg-icons';
import Slide from '@mui/material/Slide';
import { IreturnDataValue } from '../Common/apiRequests';
import sanitizeHtml from 'sanitize-html';

export const HelpPane = ({ knowledgeBase }: { knowledgeBase: Array<IreturnDataValue> | undefined }) => {
    const dispatch = useAppDispatch();
    let helpPaneToggled = useAppSelector(selectHelpPane);

    useEffect(() => {
        if (helpPaneToggled) {
            let arrowIcon = document.getElementById('arrowIcon');
            if (arrowIcon) {
                arrowIcon.focus();
            }
        }
    }, [helpPaneToggled]);

    return (
        <Slide direction="left" in={helpPaneToggled} mountOnEnter unmountOnExit>
            <StyledHelpPane>
                <RFC axis="column" align="flex-start">
                    <RFC axis="row" justify="center" css="position: relative; padding: 0.7rem; width: 100%;">
                        <div
                            className="fontAwesomeCircleWrapper"
                            tabIndex={0}
                            id="arrowIcon"
                            style={{
                                position: 'absolute',
                                left: '0',
                                top: '0',
                                padding: '0.7rem 0.7rem 0.6rem 0.7rem',
                            }}
                            onClick={() => dispatch(toggleHelpPane())}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    dispatch(toggleHelpPane());
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowToRight} style={{ color: 'inherit' }} />
                        </div>
                        <h3>FAQs</h3>
                    </RFC>
                    <div>
                        {knowledgeBase &&
                            knowledgeBase
                                .filter((knowledgeItem) => knowledgeItem.Location.includes('FAQ'))
                                .sort((a, b) => a.seq - b.seq)
                                .map((FAQ, index) => {
                                    let itemArray = FAQ.content
                                        .replaceAll('<div>', '')
                                        .replaceAll('</div>', '')
                                        .split('<hr />');
                                    return (
                                        <React.Fragment key={index}>
                                            <details>
                                                <summary>&nbsp;{itemArray[0]}</summary>
                                                <br />
                                                <StyledDivLine color="dark" />
                                                <br />
                                                <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(itemArray[1]) }} />
                                            </details>
                                            <br />
                                        </React.Fragment>
                                    );
                                })}
                    </div>
                </RFC>
            </StyledHelpPane>
        </Slide>
    );
};

const StyledHelpPane = styled.div`
    position: fixed;
    text-align: center;
    z-index: 1;
    top: 0;
    padding: 65px 0.5rem 0.5rem;
    right: 0;
    min-width: 200px;
    width: 20%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    box-shadow: -1.5px 0 7px 1px ${({ theme }) => theme.colors.blueGray[8]};
    background-color: ${({ theme }) => theme.colors.blueGray[9]};
    @media ${({ theme }) => theme.breakpoints.mediaQueries.laptop} {
        padding-top: 75px;
    }
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`;
