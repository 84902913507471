import React from 'react';
import { useTheme } from 'styled-components';
import { styled } from '../../Contexts/ThemeGlobalAndCtxt';
import { Theme, ThemeType } from '../../Contexts/ThemeGlobalAndCtxt';
import { RFC } from './FlexContainers';

interface IProps {
    direction?: 'horizontal' | 'vertical' | 'block';
    spaceParam?: keyof typeof Theme.spacer.sizes | number;
    verticalHalfHorizontal?: boolean;
    testing?: boolean;
}

export const Spacer = ({
    direction = 'block',
    spaceParam = 'medium',
    testing = true,
    verticalHalfHorizontal = false,
}: IProps) => {
    // @ts-ignore
    const theme: ThemeType = useTheme();
    if (!theme.testing) {
        testing = false;
    }
    let horizontalSpace: string =
        typeof spaceParam !== typeof 'string' ? `${spaceParam}px` : `${theme.spacer.sizes[spaceParam]}px`;
    let verticalSpace: string;
    if (verticalHalfHorizontal) {
        verticalSpace =
            // @ts-ignore
            typeof spaceParam !== typeof 'string' ? `${spaceParam / 2}px` : `${theme.spacer.sizes[spaceParam] / 2}px`;
    } else {
        // @ts-ignore
        verticalSpace = horizontalSpace;
    }
    return (
        <span
            style={
                direction === 'horizontal'
                    ? {
                          display: 'block',
                          alignSelf: 'center',
                          justifySelf: 'center',
                          height: '0px',
                          minHeight: '0px',
                          width: horizontalSpace,
                          minWidth: horizontalSpace,
                          border: testing ? '1px solid orange' : '1px solid transparent',
                      }
                    : direction === 'vertical'
                    ? {
                          display: 'block',
                          alignSelf: 'center',
                          justifySelf: 'center',
                          height: verticalSpace,
                          minHeight: verticalSpace,
                          width: '0px',
                          minWidth: '0px',
                          border: testing ? '1px solid orange' : '1px solid transparent',
                      }
                    : {
                          display: 'block',
                          alignSelf: 'center',
                          justifySelf: 'center',
                          height: verticalSpace,
                          minHeight: verticalSpace,
                          width: horizontalSpace,
                          minWidth: horizontalSpace,
                          border: testing ? '1px solid orange' : '1px solid transparent',
                      }
            }
        ></span>
    );
};

export const HorizontalDivider = () => {
    return (
        <RFC axis="column" align="center" justify="center">
            <Spacer spaceParam="large" />
            <StyledDivLine />
            <Spacer spaceParam="large" />
        </RFC>
    );
};

interface IstyledDiv {
    color?: 'light' | 'medium' | 'dark';
}
export const StyledDivLine = styled.div<IstyledDiv>`
    width: 100%;
    border-bottom: solid 1px
        ${({ theme, color }) =>
            color === 'light'
                ? theme.colors.coolGray[5]
                : color === 'medium'
                ? theme.colors.coolGray[3]
                : color === 'dark'
                ? theme.colors.blueGray[2]
                : theme.colors.coolGray[5]};
    height: 1px;
`;
