import React, { useEffect, useState, useRef } from 'react';
import { useExitPrompt } from './Common/Hooks/customHooks';
import { useAppDispatch, useAppSelector } from './Common/Hooks/reactReduxHooks';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { styled } from './Contexts/ThemeGlobalAndCtxt';

import { getKnowledgeBase, IreturnDataValue } from './Common/apiRequests';
import {
    selectUnSavedFields,
    setUnSavedFields,
    getEAPStateFromDB,
    selectAuthExpired,
    selectAPIGetStatus,
    getAppDataFromDB,
    selectAppDataGetStatus,
    selectApiDateTimeRefresh,
    setComplete,
    userAuthRefresh,
    selectAppError,
    selectAppType,
    getAdvisoryStateFromDB,
} from './ReduxSlices/userSlice';
import { selectIsProcessComplete, selectSectionStatuses } from './ReduxSlices/sectionProgressSlice';
import {
    selectCurrentListingID,
    selectCurrentListingName,
    updateCurrentListingID,
    updateCurrentListingName,
} from './ReduxSlices/listingsSlice';
import { AppHeader } from './Pages/AppHeader';
import { HomePage } from './Pages/HomePage';
import { HelpPane } from './Pages/HelpPane';
import { WhatYouNeed } from './Pages/WhatYouNeed';
import { NextSteps } from './Pages/NextSteps';
import { SubSectionsWrapper } from './Pages/SubSectionsWrapper';
import { BasicInfo, RolesResponsibilities, TrainingReview, AdditionalInfo } from './Pages/CompanyInfoSubSections';
import { Alerts, Evacuation, Fire, Chemicals } from './Pages/PoliciesSubSections';
import {
    AdvisoryOverview,
    AdvisoryChecklist,
    AdvisoryContacts,
    AdvisorySupplemental,
    AdvisoryBuild,
} from './Pages/AdvisorySection';
import { PlaybookInfo } from './Pages/PlaybookInfo';
import { EAPReview } from './Pages/EAPReview';
import { AdvisoryReview } from './Pages/AdvisoryReview';
import { Login } from './Pages/Login';
import { FormPOST } from './Pages/FormPOST';
import { Error } from './Pages/Error';
import { Listings } from './Pages/Listings';
import { ResponsiveFlexContainer } from './Common/Components/FlexContainers';
import { StyledSnackbarConfirmButton } from './Common/Components/CommonStyled';
import Snackbar from '@mui/material/Snackbar';

export function App() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setShowExitPrompt] = useExitPrompt(false);
    const [snackbarWarning, setSnackbarWarning] = useState('');
    const [knowledgeBase, setKnowledgeBase] = useState<Array<IreturnDataValue> | undefined>(undefined);

    const dispatch = useAppDispatch();
    const isComplete = useAppSelector(selectIsProcessComplete);
    const unSavedFields = useAppSelector(selectUnSavedFields);
    const pathname = useLocation().pathname;
    const history = useHistory();
    const appDataGetStatus = useAppSelector(selectAppDataGetStatus);
    const apiGetStatus = useAppSelector(selectAPIGetStatus);
    const apiDateTimeRefresh = useAppSelector(selectApiDateTimeRefresh);
    const authExpired = useAppSelector(selectAuthExpired);
    const currentListingID = useAppSelector(selectCurrentListingID);
    const currentListingName = useAppSelector(selectCurrentListingName);
    const sectionStatuses = useAppSelector(selectSectionStatuses);
    const appError = useAppSelector(selectAppError);
    let localStorageCheck = useRef<boolean>(false);
    const appDataCheck = useRef<boolean>(false);
    const appType = useAppSelector(selectAppType);
    let id1 = useRef<NodeJS.Timeout | null>(null);
    let id2 = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        setShowExitPrompt(unSavedFields);
        return () => {
            setShowExitPrompt(false);
        };
    }, [unSavedFields, setShowExitPrompt]);

    useEffect(() => {
        if (isComplete) {
            dispatch(setComplete());
        }
    }, [isComplete, dispatch]);

    useEffect(() => {
        dispatch(setUnSavedFields(false));
    }, [pathname, dispatch]);

    //ADVISORY: reuse this frontend storage for advisory playbook
    useEffect(() => {
        if (!localStorageCheck.current && appDataGetStatus === 'succeeded') {
            if (appType === 'EAP') {
                let localAppType = localStorage.getItem('appType') === null ? '' : localStorage.getItem('appType');
                let localStorageListingID;
                let localStorageListingName;
                if (localAppType !== appType) {
                    localStorageListingID = 0;
                    localStorageListingName = '';
                } else {
                    localStorageListingID =
                        //@ts-ignore
                        localStorage.getItem('listingID') === null ? 0 : parseInt(localStorage.getItem('listingID'));
                    localStorageListingName =
                        localStorage.getItem('listingName') === null ? '' : localStorage.getItem('listingName');
                }
                if (
                    !(pathname === '/listings' || pathname === '/formpost' || pathname === '/whatyouneed') &&
                    localStorageListingID === 0 &&
                    appType === 'EAP'
                ) {
                    dispatch(updateCurrentListingID(localStorageListingID));
                    //@ts-ignore
                    dispatch(updateCurrentListingName(localStorageListingName));
                    history.push('/listings');
                } else {
                    dispatch(getEAPStateFromDB(localStorageListingID));
                    localStorage.setItem('listingID', localStorageListingID.toString());
                    localStorage.setItem('listingName', localStorageListingName);
                }
            } else if (appType === 'Advisory') {
                dispatch(updateCurrentListingID(0));
                //@ts-ignore
                dispatch(updateCurrentListingName(''));
                localStorage.setItem('listingID', '0');
                localStorage.setItem('listingName', '');
                dispatch(getAdvisoryStateFromDB());
            }
            localStorage.setItem('appType', appType);

            localStorageCheck.current = true;
        }
    }, [dispatch, pathname, history, appDataGetStatus, appType]);

    useEffect(() => {
        if (appDataGetStatus === 'idle' && pathname !== '/formpost') {
            dispatch(getAppDataFromDB());
            appDataCheck.current = false;
        } else if (appDataGetStatus === 'succeeded' && !appDataCheck.current) {
            appDataCheck.current = true;
        }
    }, [dispatch, appDataGetStatus, appType, pathname]);

    //ADVISORY: only use this after we have the app type
    useEffect(() => {
        if (appDataGetStatus === 'succeeded') {
            let knowledge = getKnowledgeBase();
            knowledge
                .then((valueObject) => {
                    let knowledgeFiltered = valueObject.value
                        ?.filter((item) => {
                            if (appType === 'Advisory') {
                                return ['Advisory_FAQ', 'Advisory_Intro', 'Advisory_NextSteps'].includes(item.Location);
                            } else {
                                return ['EAP_FAQ', 'EAP_Intro', 'EAP_NextSteps'].includes(item.Location);
                            }
                        })
                        .filter((item) => {
                            return item.isActiveYN === 'Y';
                        });
                    setKnowledgeBase(knowledgeFiltered);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [appDataGetStatus, appType]);

    useEffect(() => {
        if (localStorageCheck.current) {
            localStorage.setItem('listingID', currentListingID.toString());
        }
    }, [currentListingID, dispatch]);
    useEffect(() => {
        if (localStorageCheck.current) {
            localStorage.setItem('listingName', currentListingName);
        }
    }, [currentListingName, dispatch]);

    useEffect(() => {
        setSnackbarWarning('');
        if (!authExpired) {
            if (id1.current) {
                clearTimeout(id1.current);
            }
            if (id2.current) {
                clearTimeout(id2.current);
            }
            id1.current = setTimeout(() => {
                setSnackbarWarning('Are you still working? Session will expire in 5 minutes!');
            }, 1000 * 60 * 55);
            id2.current = setTimeout(() => {
                dispatch(userAuthRefresh());
            }, 1000 * 60 * 62);
        }
    }, [apiDateTimeRefresh, dispatch, authExpired]);

    useEffect(() => {
        if (authExpired && pathname !== '/formpost') {
            history.push('/error/401');
        }
    }, [authExpired, history, pathname]);

    useEffect(() => {
        if (appError) {
            history.push(`/error/${appError}`);
        }
    }, [appError, history]);

    return (
        <>
            <AppDiv mobileFlexDirection="column" keepFlexDirection={true} justifyContent="flex-start">
                <AppHeader />

                <Switch>
                    <Route path="/error/:errorNumber">
                        <Error />
                    </Route>
                    <Route exact path="/whatyouneed">
                        <WhatYouNeed />
                    </Route>
                    {/* ADVISORY need the custom nextsteps page for advisory */}
                    <Route exact path="/nextsteps">
                        {apiGetStatus === 'succeeded' &&
                        appType === 'Advisory' &&
                        sectionStatuses[5] !== 'completed' ? (
                            <Redirect to={'/advisory/build'} />
                        ) : apiGetStatus === 'succeeded' && appType === 'EAP' && sectionStatuses[3] !== 'completed' ? (
                            <Redirect to={'/playbooks'} />
                        ) : (
                            <NextSteps />
                        )}
                    </Route>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="/formpost">
                        <FormPOST />
                    </Route>
                    {/* /* ADVISORY V2: unblock listings */}
                    {appType !== 'Advisory' && (
                        <Route exact path="/listings">
                            <Listings />
                        </Route>
                    )}
                    <Route exact path="/home">
                        {appType === 'EAP' && <HomePage />}
                        {appType === 'Advisory' && appDataGetStatus === 'succeeded' && (
                            <Redirect to="/advisory/section1" />
                        )}
                    </Route>
                    {appDataGetStatus !== 'succeeded' && (
                        <Route path="/">
                            <>Loading...</>
                        </Route>
                    )}
                    <Route exact path="/company-information">
                        <Redirect to="/company-information/section1" />
                    </Route>
                    ,
                    <Route exact path="/policies">
                        <Redirect to="/policies/section1" />
                    </Route>
                    ,
                    <Route path="/company-information">
                        {appType === 'EAP' ? (
                            <SubSectionsWrapper
                                sectionIndex={0}
                                childrenComponents={[BasicInfo, RolesResponsibilities, TrainingReview, AdditionalInfo]}
                            />
                        ) : (
                            <Redirect to="/advisory/section1" />
                        )}
                    </Route>
                    ,
                    <Route path="/policies">
                        {appType === 'EAP' ? (
                            <SubSectionsWrapper
                                sectionIndex={1}
                                childrenComponents={[Alerts, Evacuation, Fire, Chemicals]}
                            />
                        ) : (
                            <Redirect to="/advisory/section1" />
                        )}
                    </Route>
                    ,
                    <Route path="/review">
                        {apiGetStatus === 'succeeded' && true && (
                            <Route path="/review/build">
                                <Redirect to={appType === 'Advisory' ? '/advisory/section1' : '/home'} />
                            </Route>
                        )}
                        {appType === 'EAP' ? (
                            <SubSectionsWrapper sectionIndex={2} childrenComponents={[EAPReview]} />
                        ) : (
                            <Redirect to="/advisory/section1" />
                        )}
                    </Route>
                    ,
                    <Route path="/playbooks">
                        {appType === 'EAP' ? (
                            <SubSectionsWrapper sectionIndex={3} childrenComponents={[PlaybookInfo]} />
                        ) : (
                            <Redirect to="/advisory/section1" />
                        )}
                        {apiGetStatus === 'succeeded' && sectionStatuses[2] !== 'completed' && (
                            <Route path="/playbooks">
                                <Redirect to="/home" />
                            </Route>
                        )}
                    </Route>
                    <Route exact path="/advisory">
                        <Redirect to="/advisory/section1" />
                    </Route>
                    ,
                    <Route path="/advisory">
                        {appType === 'Advisory' ? (
                            <SubSectionsWrapper
                                sectionIndex={0}
                                childrenComponents={[
                                    AdvisoryOverview,
                                    AdvisoryChecklist,
                                    AdvisoryContacts,
                                    AdvisorySupplemental,
                                    AdvisoryReview,
                                    AdvisoryBuild,
                                ]}
                            />
                        ) : (
                            <Redirect to="/home" />
                        )}
                        {apiGetStatus === 'succeeded' && sectionStatuses[4] !== 'completed' && (
                            <Route path="/advisory/build">
                                <Redirect to={appType === 'Advisory' ? '/advisory/section1' : '/home'} />
                            </Route>
                        )}
                    </Route>
                </Switch>
            </AppDiv>
            <HelpPane knowledgeBase={knowledgeBase} />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarWarning ? true : false}
                message={snackbarWarning}
                action={
                    <StyledSnackbarConfirmButton
                        onClick={() => {
                            dispatch(userAuthRefresh());
                        }}
                    >
                        Yes
                    </StyledSnackbarConfirmButton>
                }
            />
        </>
    );
}

const AppDiv = styled(ResponsiveFlexContainer)`
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;
`;
