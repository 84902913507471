import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { SetEAPFileUpload } from '../Common/apiRequests';
import { useAppSelector } from '../Common/Hooks/reactReduxHooks';
import { useSectionsQualityOfLife } from '../Common/Hooks/customHooks';
import { selectUserEmail, setUnSavedFields } from '../ReduxSlices/userSlice';

import {
    setEAPDataToDB,
    selectFormsData,
    addNormalizedData,
    removeNormalizedData,
    updateNormalizedData,
    updateFormsDataNode,
    purgeConditionalItems,
} from '../ReduxSlices/formsData';
import { selectCurrentListingID } from '../ReduxSlices/listingsSlice';
import { updateSectionProgress } from '../ReduxSlices/sectionProgressSlice';
import { FormWrapper } from '../Common/Components/FormWrapper';
import { Spacer } from '../Common/Components/Spacer';
import { RFC } from '../Common/Components/FlexContainers';
import { SuccessAlert } from '../Common/Components/SuccessAlert';
import { StyledSaveButton, SelectStyles, fireExtinguisherOptions } from '../Common/Components/CommonStyled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Select from 'react-select';
import { isValueXSSSafe } from '../Common/Security/IcoSanitize';
import { useSelector } from 'react-redux';

export const Alerts = () => {
    const formData = useAppSelector(selectFormsData);
    const {
        sectionIndex,
        subSectionIndex,
        subSectionTitle,
        dispatch,
        apiGetStatus,
        unSavedFields,
        sectionStatus,
        theme,
        apiPostStatus,
        laptop,
    } = useSectionsQualityOfLife();

    const setInitialValues = useRef(false);
    const { register, watch, handleSubmit, setFocus, formState, getValues, setValue } = useForm({ mode: 'onSubmit' });
    const inputsWatch = watch();

    const userEmail = useSelector(selectUserEmail);

    useEffect(() => {
        if (!setInitialValues.current && apiGetStatus === 'succeeded') {
            try {
                setTimeout(() => {
                    setValue('verbal', formData.alerts.alertsUsed.verbal, { shouldValidate: false });
                    setValue('PAsystem', formData.alerts.alertsUsed.PAsystem, { shouldValidate: false });
                    setValue('onSite', formData.alerts.alertsUsed.onSiteAlarmSystem, { shouldValidate: false });
                    setValue('mass', formData.alerts.alertsUsed.emergencyMassNotification, { shouldValidate: false });
                    setValue('ICO', formData.alerts.alertsUsed.ICOCnotifications, { shouldValidate: false });
                    setValue('other', formData.alerts.alertsUsed.otherAlert.otherAlertChecked, {
                        shouldValidate: false,
                    });
                    setValue('otherText', formData.alerts.alertsUsed.otherAlert.otherAlertText, {
                        shouldValidate: false,
                    });
                    setValue('fire', formData.alerts.specificAlarms.fire, { shouldValidate: false });
                    setValue('earthquake', formData.alerts.specificAlarms.earthquake, { shouldValidate: false });
                    setValue('evacuation', formData.alerts.specificAlarms.evacuation, { shouldValidate: false });
                    setValue('drillsChecked', formData.alerts.drills.drillsChecked ? 'Yes' : 'No', {
                        shouldValidate: false,
                    });
                    setValue('drillsText', formData.alerts.drills.drillsText, { shouldValidate: false });
                    setValue('reporting', formData.alerts.reporting, { shouldValidate: false });
                    setInitialValues.current = true;
                }, 0);
            } catch {
                console.log('setValue failed, nodes not loaded quick enough?');
            }
        }
    }, [setFocus, apiGetStatus, formData.alerts, setValue, formState.errors]);
    useEffect(() => {
        if (!unSavedFields && setInitialValues.current) {
            if (
                (inputsWatch['verbal'] !== undefined && inputsWatch['verbal'] !== formData.alerts.alertsUsed.verbal) ||
                (inputsWatch['PAsystem'] !== undefined &&
                    inputsWatch['PAsystem'] !== formData.alerts.alertsUsed.PAsystem) ||
                (inputsWatch['onSite'] !== undefined &&
                    inputsWatch['onSite'] !== formData.alerts.alertsUsed.onSiteAlarmSystem) ||
                (inputsWatch['mass'] !== undefined &&
                    inputsWatch['mass'] !== formData.alerts.alertsUsed.emergencyMassNotification) ||
                (inputsWatch['ICO'] !== undefined &&
                    inputsWatch['ICO'] !== formData.alerts.alertsUsed.ICOCnotifications) ||
                (inputsWatch['other'] !== undefined &&
                    inputsWatch['other'] !== formData.alerts.alertsUsed.otherAlert.otherAlertChecked) ||
                (inputsWatch['otherText'] !== undefined &&
                    inputsWatch['otherText'] !== formData.alerts.alertsUsed.otherAlert.otherAlertText) ||
                (inputsWatch['fire'] !== undefined && inputsWatch['fire'] !== formData.alerts.specificAlarms.fire) ||
                (inputsWatch['earthquake'] !== undefined &&
                    inputsWatch['earthquake'] !== formData.alerts.specificAlarms.earthquake) ||
                (inputsWatch['evacuation'] !== undefined &&
                    inputsWatch['evacuation'] !== formData.alerts.specificAlarms.evacuation) ||
                (inputsWatch['drillsChecked'] !== undefined &&
                    (inputsWatch['drillsChecked'] === 'Yes') !== formData.alerts.drills.drillsChecked) ||
                (inputsWatch['drillsText'] !== undefined &&
                    inputsWatch['drillsText'] !== formData.alerts.drills.drillsText) ||
                (inputsWatch['reporting'] !== undefined && inputsWatch['reporting'] !== formData.alerts.reporting)
            ) {
                dispatch(setUnSavedFields(true));
            }
        }
    }, [inputsWatch, unSavedFields, setInitialValues, dispatch, formData.alerts]);

    const onSubmit = (data: any) => {
        const alertsUpdateData = {
            alertsUsed: {
                verbal: getValues('verbal'),
                PAsystem: getValues('PAsystem'),
                onSiteAlarmSystem: getValues('onSite'),
                emergencyMassNotification: getValues('mass'),
                ICOCnotifications: getValues('ICO'),
                otherAlert: {
                    otherAlertChecked: getValues('other'),
                    otherAlertText: getValues('otherText'),
                },
            },
            specificAlarms: {
                fire: getValues('fire'),
                earthquake: getValues('earthquake'),
                evacuation: getValues('evacuation'),
            },
            drills: {
                drillsChecked: getValues('drillsChecked') === 'Yes' ? true : false,
                drillsText: getValues('drillsText'),
            },
            reporting: getValues('reporting'),
        };
        dispatch(updateFormsDataNode({ node: 'alerts', data: alertsUpdateData }));
        dispatch(purgeConditionalItems());
        dispatch(
            updateSectionProgress({
                sectionIndex: sectionIndex,
                subSectionIndex: subSectionIndex,
                status: 'completed',
            }),
        );
        dispatch(setEAPDataToDB('alerts'));
        dispatch(setUnSavedFields(false));
    };

    return (
        <RFC axis="column" justify="flex-start" align="center">
            <FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                formWidth="70%"
                formMinWidth="350px"
                formMaxWidth="900px"
                inputWidth="100%"
                status={apiPostStatus}
            >
                <RFC axis="row" justify="space-between" css="width: 100%;">
                    <h2 style={{ alignSelf: 'left' }}>{subSectionTitle}</h2>
                    {sectionStatus === 'completed' && (
                        <>
                            <Spacer spaceParam="large" />
                            {unSavedFields ? (
                                <h3 style={{ color: theme.colors.red[6] }}>Unsaved Changes</h3>
                            ) : (
                                <h3 style={{ color: theme.colors.cyan[5] }}>Complete</h3>
                            )}
                        </>
                    )}
                </RFC>
                <Spacer spaceParam="large" />

                <label>In an emergency, how are employees alerted? Check all that apply:</label>
                <Spacer spaceParam="large" />
                <RFC axis="row" justify="flex-start">
                    <Spacer spaceParam="large" />
                    <RFC axis="column" basis="100%" align="flex-start">
                        <label className="checkbox_label">
                            Verbal Alerts
                            <input type="checkbox" className="checkbox_input" {...register('verbal', {})} />
                            <span className="checkbox_span"></span>
                        </label>

                        <Spacer spaceParam="small" />
                        <label className="checkbox_label">
                            PA System
                            <input type="checkbox" className="checkbox_input" {...register('PAsystem', {})} />
                            <span className="checkbox_span"></span>
                        </label>

                        <Spacer spaceParam="small" />
                        <label className="checkbox_label">
                            On site alarms
                            <input type="checkbox" className="checkbox_input" {...register('onSite', {})} />
                            <span className="checkbox_span"></span>
                        </label>

                        <Spacer spaceParam="small" />
                        <label className="checkbox_label">
                            Mass notifications
                            <input type="checkbox" className="checkbox_input" {...register('mass', {})} />
                            <span className="checkbox_span"></span>
                        </label>

                        <Spacer spaceParam="small" />
                        <label className="checkbox_label">
                            In Case Of Crisis Notifications
                            <input type="checkbox" className="checkbox_input" {...register('ICO', {})} />
                            <span className="checkbox_span"></span>
                        </label>

                        <Spacer spaceParam="small" />
                        <label className="checkbox_label">
                            Other alerts? {inputsWatch['other'] ? `(Please describe)` : ``}
                            <input type="checkbox" className="checkbox_input" {...register('other', {})} />
                            <span className="checkbox_span"></span>
                        </label>

                        <RFC axis="column" align="flex-start" css={inputsWatch['other'] ? '' : 'display: none;'}>
                            <Spacer spaceParam="small" />
                            <textarea
                                {...register('otherText', {
                                    validate: {
                                        maxLength: (value) =>
                                            value.length < 1000000 || 'Length must be < 1,000,000 characters',
                                        isSafe: (value) => {
                                            return isValueXSSSafe(value, 'Policies other Alerts', userEmail)
                                                ? true
                                                : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                        },
                                    },
                                })}
                            />
                            {formState.errors['otherText'] && formState.errors['otherText'].type && (
                                <>
                                    <Spacer direction="vertical" spaceParam="small" />
                                    <p>{formState.errors['otherText'].message}</p>
                                    <Spacer direction="vertical" spaceParam="small" />
                                </>
                            )}
                        </RFC>
                    </RFC>
                </RFC>

                <Spacer spaceParam="large" />
                <Spacer spaceParam="large" />
                <RFC axis="row" justify="flex-start" align="flex-start" className="input_label_wrapper">
                    <textarea
                        {...register('fire', {
                            validate: {
                                required: (value) => (value && value.length > 0) || 'Must provide this description',
                                maxLength: (value) => value.length < 1000000 || 'Length must be < 1,000,000 characters',
                                notBlankSpaces: (value) => {
                                    let regex = new RegExp(/.*\S.*/);
                                    let notOnlyBlanks = false;
                                    if (regex.exec(value)) {
                                        notOnlyBlanks = true;
                                    }
                                    return (
                                        notOnlyBlanks || value.length === 0 || 'Operation cannot be only blank spaces'
                                    );
                                },
                                isSafe: (value) => {
                                    return isValueXSSSafe(value, 'Fire Emergency Alarm', userEmail)
                                        ? true
                                        : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                },
                            },
                        })}
                    />
                    <label
                        htmlFor={'fire'}
                        className={inputsWatch['fire'] ? 'input_label_transitioned' : ''}
                        onClick={(e) => setFocus('fire')}
                    >
                        Identify and describe the specific alarm used for a fire emergency
                    </label>
                </RFC>
                {formState.errors['fire'] && formState.errors['fire'].type && (
                    <>
                        <Spacer direction="vertical" spaceParam="small" />
                        <p>{formState.errors['fire'].message}</p>
                        <Spacer direction="vertical" spaceParam="small" />
                    </>
                )}

                <Spacer spaceParam="large" />
                <Spacer spaceParam="large" />
                <RFC axis="row" justify="flex-start" align="flex-start" className="input_label_wrapper">
                    <textarea
                        {...register('earthquake', {
                            validate: {
                                required: (value) => (value && value.length > 0) || 'Must provide this description',
                                maxLength: (value) => value.length < 1000000 || 'Length must be < 1,000,000 characters',
                                notBlankSpaces: (value) => {
                                    let regex = new RegExp(/.*\S.*/);
                                    let notOnlyBlanks = false;
                                    if (regex.exec(value)) {
                                        notOnlyBlanks = true;
                                    }
                                    return (
                                        notOnlyBlanks || value.length === 0 || 'Operation cannot be only blank spaces'
                                    );
                                },
                                isSafe: (value) => {
                                    return isValueXSSSafe(value, 'Earthquake Alarm', userEmail)
                                        ? true
                                        : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                },
                            },
                        })}
                    />
                    <label
                        htmlFor={'earthquake'}
                        className={inputsWatch['earthquake'] ? 'input_label_transitioned' : ''}
                        onClick={(e) => setFocus('earthquake')}
                    >
                        Identify and describe the specific alarm used for an earthquake emergency
                    </label>
                </RFC>
                {formState.errors['earthquake'] && formState.errors['earthquake'].type && (
                    <>
                        <Spacer direction="vertical" spaceParam="small" />
                        <p>{formState.errors['earthquake'].message}</p>
                        <Spacer direction="vertical" spaceParam="small" />
                    </>
                )}

                <Spacer spaceParam="large" />
                <Spacer spaceParam="large" />
                <RFC axis="row" justify="flex-start" align="flex-start" className="input_label_wrapper">
                    <textarea
                        {...register('evacuation', {
                            validate: {
                                required: (value) => (value && value.length > 0) || 'Must provide this description',
                                maxLength: (value) => value.length < 1000000 || 'Length must be < 1,000,000 characters',
                                notBlankSpaces: (value) => {
                                    let regex = new RegExp(/.*\S.*/);
                                    let notOnlyBlanks = false;
                                    if (regex.exec(value)) {
                                        notOnlyBlanks = true;
                                    }
                                    return (
                                        notOnlyBlanks || value.length === 0 || 'Operation cannot be only blank spaces'
                                    );
                                },
                                isSafe: (value) => {
                                    return isValueXSSSafe(value, 'Evacuation Emergency', userEmail)
                                        ? true
                                        : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                },
                            },
                        })}
                    />
                    <label
                        htmlFor={'evacuation'}
                        className={inputsWatch['evacuation'] ? 'input_label_transitioned' : ''}
                        onClick={(e) => setFocus('evacuation')}
                    >
                        Identify and describe the specific alarm used for an evacuation emergency
                    </label>
                </RFC>
                {formState.errors['evacuation'] && formState.errors['evacuation'].type && (
                    <>
                        <Spacer direction="vertical" spaceParam="small" />
                        <p>{formState.errors['evacuation'].message}</p>
                        <Spacer direction="vertical" spaceParam="small" />
                    </>
                )}

                <Spacer spaceParam="large" />
                <label>Do you provide drills and training to employees for each emergency alert?</label>
                <Spacer spaceParam="medium" />
                <RFC axis="row" justify="flex-start">
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" className="radioButtonContainer">
                        <label>
                            <input {...register('drillsChecked')} type="radio" value="Yes" defaultChecked /> Yes
                        </label>
                        <Spacer spaceParam="small" />
                        <label>
                            <input {...register('drillsChecked')} type="radio" value="No" /> No
                        </label>
                    </RFC>
                </RFC>

                <RFC
                    axis="column"
                    align="flex-start"
                    css={inputsWatch['drillsChecked'] === 'No' ? 'display: none;' : ''}
                >
                    <Spacer spaceParam="large" />
                    <Spacer spaceParam="large" />
                    <RFC axis="row" justify="flex-start" align="flex-start" className="input_label_wrapper">
                        <textarea
                            {...register('drillsText', {
                                validate: {
                                    required: (value) =>
                                        inputsWatch['drillsChecked'] === 'No' ||
                                        (value && value.length > 0) ||
                                        'Must provide this description',
                                    maxLength: (value) =>
                                        inputsWatch['drillsChecked'] === 'No' ||
                                        value.length < 1000000 ||
                                        'Length must be < 1,000,000 characters',
                                    notBlankSpaces: (value) => {
                                        let regex = new RegExp(/.*\S.*/);
                                        let notOnlyBlanks = false;
                                        if (regex.exec(value)) {
                                            notOnlyBlanks = true;
                                        }
                                        return (
                                            inputsWatch['drillsChecked'] === 'No' ||
                                            notOnlyBlanks ||
                                            value.length === 0 ||
                                            'Operation cannot be only blank spaces'
                                        );
                                    },
                                    isSafe: (value) => {
                                        return isValueXSSSafe(value, 'Employee Training and drills', userEmail)
                                            ? true
                                            : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                    },
                                },
                            })}
                        />
                        <label
                            htmlFor={'drillsText'}
                            className={inputsWatch['drillsText'] ? 'input_label_transitioned' : ''}
                            onClick={(e) => setFocus('drillsText')}
                        >
                            Provide more information about your training and drills
                        </label>
                    </RFC>
                    {formState.errors['drillsText'] && formState.errors['drillsText'].type && (
                        <>
                            <Spacer direction="vertical" spaceParam="small" />
                            <p>{formState.errors['drillsText'].message}</p>
                            <Spacer direction="vertical" spaceParam="small" />
                        </>
                    )}
                </RFC>

                <Spacer spaceParam="large" />
                <Spacer spaceParam="large" />
                <RFC axis="row" justify="flex-start" align="flex-start" className="input_label_wrapper">
                    <textarea
                        {...register('reporting', {
                            validate: {
                                required: (value) => (value && value.length > 0) || 'Must provide this description',
                                maxLength: (value) => value.length < 1000000 || 'Length must be < 1,000,000 characters',
                                notBlankSpaces: (value) => {
                                    let regex = new RegExp(/.*\S.*/);
                                    let notOnlyBlanks = false;
                                    if (regex.exec(value)) {
                                        notOnlyBlanks = true;
                                    }
                                    return (
                                        notOnlyBlanks || value.length === 0 || 'Operation cannot be only blank spaces'
                                    );
                                },
                                isSafe: (value) => {
                                    return isValueXSSSafe(value, 'Employee procedures to report emergency', userEmail)
                                        ? true
                                        : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                },
                            },
                        })}
                    />
                    <label
                        htmlFor={'reporting'}
                        className={inputsWatch['reporting'] ? 'input_label_transitioned' : ''}
                        onClick={(e) => setFocus('reporting')}
                    >
                        What procedures should employees follow to report an emergency
                    </label>
                </RFC>
                {formState.errors['reporting'] && formState.errors['reporting'].type && (
                    <>
                        <Spacer direction="vertical" spaceParam="small" />
                        <p>{formState.errors['reporting'].message}</p>
                        <Spacer direction="vertical" spaceParam="small" />
                    </>
                )}

                <Spacer direction="vertical" spaceParam="large" />
                <RFC axis="column" justify="flex-start" align="flex-end" alignLaptop="center" css="width: 100%;">
                    {(apiPostStatus === 'succeeded' || laptop) && (
                        <RFC
                            axis="row"
                            basis="75%"
                            align="center"
                            alignLaptop="center"
                            justify="flex-end"
                            justifyLaptop="flex-end"
                            css={!laptop ? 'padding-top: 1em;' : ''}
                        >
                            <SuccessAlert active={apiPostStatus === 'succeeded'} style={{ alignSelf: 'right' }}>
                                Successfully Saved!
                            </SuccessAlert>
                            <Spacer spaceParam="medium" direction="horizontal" />
                        </RFC>
                    )}
                    <RFC axis="row" justify="flex-end" basis="25%">
                        <StyledSaveButton
                            type="submit"
                            postStatus={apiPostStatus}
                            noChanges={!unSavedFields}
                            onClick={(e) => {
                                if (apiPostStatus !== 'idle') {
                                    e.preventDefault();
                                }
                            }}
                        >
                            Save
                        </StyledSaveButton>
                    </RFC>
                </RFC>
            </FormWrapper>
        </RFC>
    );
};

export const Evacuation = () => {
    const evacuationData = useAppSelector(selectFormsData).evacuation;
    const {
        sectionIndex,
        subSectionIndex,
        subSectionTitle,
        dispatch,
        apiGetStatus,
        unSavedFields,
        sectionStatus,
        theme,
        apiPostStatus,
        laptop,
    } = useSectionsQualityOfLife();
    const listingID = useAppSelector(selectCurrentListingID);
    const [routesFile, setRoutesFile] = useState<File | null>();
    const [routesFileSelected, setRoutesFileSelected] = useState(false);

    const [reunificationFile, setReunificationFile] = useState<File | null>();
    const [reunificationFileSelected, setReunificationFileSelected] = useState(false);

    const setInitialValues = useRef(false);

    const deleteRef = useRef<HTMLButtonElement>(null);

    const [removeAssemblyPointAnchorEl, setRemoveAssemblyPointAnchorEl] = useState(null);
    const [removeAssemblyPointPopperToggled, setRemoveAssemblyPointPopperToggled] = useState(false);

    const [assemblyPointRemovalTarget, setAssemblyPointRemovalTarget] = useState<{
        index: number;
        registeredField1: string;
        registeredField2: string;
    } | null>(null);
    const [addedAssemblyPoint, setAddedAssemblyPoint] = useState(false);

    const { register, handleSubmit, setFocus, formState, getValues, setValue, unregister, watch } = useForm({
        mode: 'onSubmit',
    });

    const userEmail = useSelector(selectUserEmail);

    const inputsWatch = watch();
    useEffect(() => {
        if (!setInitialValues.current && apiGetStatus === 'succeeded') {
            setTimeout(() => {
                evacuationData.assembly.assemblyPoints.allIDs.forEach((id) => {
                    setValue(`assemblyPoint${id}name`, evacuationData.assembly.assemblyPoints.byID[id].name);
                    setValue(
                        `assemblyPoint${id}description`,
                        evacuationData.assembly.assemblyPoints.byID[id].description,
                    );
                });
                setValue(
                    'allEmployeesEvacuate',
                    evacuationData.policyProcedures.evacuationPolicy.allEmployeesEvacuate ? 'Yes' : 'No',
                );
                setValue('evacuationPolicy', evacuationData.policyProcedures.evacuationPolicy.evacuationPolicyDetails);
                setValue('floorWardens', evacuationData.policyProcedures.floorWardens.floorWardensExist ? 'Yes' : 'No');
                setValue('floorWardensPolicy', evacuationData.policyProcedures.floorWardens.wardensPolicyDetails);
                setValue('routesDefault', evacuationData.evacuationRoutes.routesDefault ? 'Yes' : 'No');
                setValue('routes', evacuationData.evacuationRoutes.routesDescription);
                setValue('rollCallDescription', evacuationData.rollCall.description);
                setValue('ICOCmanagedRollCall', evacuationData.rollCall.ICOCmanaged ? 'Yes' : 'No');
                setInitialValues.current = true;
            }, 0);
        }
    }, [setFocus, apiGetStatus, evacuationData, setValue, formState.errors]);
    useEffect(() => {
        if (!unSavedFields && setInitialValues.current) {
            if (
                (inputsWatch['allEmployeesEvacuate'] !== undefined &&
                    (inputsWatch['allEmployeesEvacuate'] === 'Yes') !==
                        evacuationData.policyProcedures.evacuationPolicy.allEmployeesEvacuate) ||
                (inputsWatch['evacuationPolicy'] !== undefined &&
                    inputsWatch['evacuationPolicy'] !==
                        evacuationData.policyProcedures.evacuationPolicy.evacuationPolicyDetails) ||
                (inputsWatch['floorWardens'] !== undefined &&
                    (inputsWatch['floorWardens'] === 'Yes') !==
                        evacuationData.policyProcedures.floorWardens.floorWardensExist) ||
                (inputsWatch['floorWardensPolicy'] !== undefined &&
                    inputsWatch['floorWardensPolicy'] !==
                        evacuationData.policyProcedures.floorWardens.wardensPolicyDetails) ||
                (inputsWatch['routesDefault'] !== undefined &&
                    (inputsWatch['routesDefault'] === 'Yes') !== evacuationData.evacuationRoutes.routesDefault) ||
                (inputsWatch['routes'] !== undefined &&
                    inputsWatch['routes'] !== evacuationData.evacuationRoutes.routesDescription) ||
                (inputsWatch['rollCallDescription'] !== undefined &&
                    inputsWatch['rollCallDescription'] !== evacuationData.rollCall.description) ||
                (inputsWatch['ICOCmanagedRollCall'] !== undefined &&
                    (inputsWatch['ICOCmanagedRollCall'] === 'Yes') !== evacuationData.rollCall.ICOCmanaged)
            ) {
                dispatch(setUnSavedFields(true));
            }
            evacuationData.assembly.assemblyPoints.allIDs.forEach((id) => {
                if (
                    (inputsWatch[`assemblyPoint${id}name`] !== undefined &&
                        inputsWatch[`assemblyPoint${id}name`] !==
                            evacuationData.assembly.assemblyPoints.byID[id].name) ||
                    (inputsWatch[`assemblyPoint${id}description`] !== undefined &&
                        inputsWatch[`assemblyPoint${id}description`] !==
                            evacuationData.assembly.assemblyPoints.byID[id].description)
                ) {
                    dispatch(setUnSavedFields(true));
                }
            });
        }
    }, [inputsWatch, unSavedFields, setInitialValues, dispatch, evacuationData]);

    useEffect(() => {
        if (addedAssemblyPoint) {
            setFocus(
                `assemblyPoint${
                    evacuationData.assembly.assemblyPoints.allIDs[
                        evacuationData.assembly.assemblyPoints.allIDs.length - 1
                    ]
                }name`,
            );
            setAddedAssemblyPoint(false);
        }
    }, [evacuationData, setFocus, addedAssemblyPoint]);

    useLayoutEffect(() => {
        if (removeAssemblyPointPopperToggled) {
            //@ts-ignore
            setTimeout(() => deleteRef.current.focus(), 0);
        }
    }, [removeAssemblyPointPopperToggled]);

    const onSubmit = (data: any) => {
        if (routesFileSelected && routesFile) {
            SetEAPFileUpload({ whichFile: 'routes', listingID: listingID, file: routesFile });
        }
        if (reunificationFileSelected && reunificationFile) {
            SetEAPFileUpload({ whichFile: 'reunification', listingID: listingID, file: reunificationFile });
        }
        const evacuationUploadData = {
            evacuationRoutes: {
                routesDefault: getValues('routesDefault') === 'Yes' ? true : false,
                routesDescription: getValues('routes'),
                routesUploadName:
                    routesFileSelected && routesFile
                        ? routesFile.name
                        : evacuationData.evacuationRoutes.routesUploadName,
            },
            policyProcedures: {
                evacuationPolicy: {
                    allEmployeesEvacuate: getValues('allEmployeesEvacuate') === 'Yes' ? true : false,
                    evacuationPolicyDetails: getValues('evacuationPolicy'),
                },
                floorWardens: {
                    floorWardensExist: getValues('floorWardens') === 'Yes' ? true : false,
                    wardensPolicyDetails: getValues('floorWardensPolicy'),
                },
            },
            assembly: {
                assemblyPoints: evacuationData.assembly.assemblyPoints,
                reunificationUploadName:
                    reunificationFileSelected && reunificationFile
                        ? reunificationFile.name
                        : evacuationData.assembly.reunificationUploadName,
            },
            rollCall: {
                description: getValues('rollCallDescription'),
                ICOCmanaged: getValues('ICOCmanagedRollCall') === 'Yes' ? true : false,
            },
        };
        dispatch(updateFormsDataNode({ node: 'evacuation', data: evacuationUploadData }));
        for (let i = 0; i < evacuationData.assembly.assemblyPoints.allIDs.length; i++) {
            dispatch(
                updateNormalizedData({
                    node: 'Assembly point',
                    targetIndex: i,
                    value: {
                        name: getValues(`assemblyPoint${evacuationData.assembly.assemblyPoints.allIDs[i]}name`),
                        description: getValues(
                            `assemblyPoint${evacuationData.assembly.assemblyPoints.allIDs[i]}description`,
                        ),
                    },
                }),
            );
        }
        dispatch(purgeConditionalItems());
        dispatch(
            updateSectionProgress({
                sectionIndex: sectionIndex,
                subSectionIndex: subSectionIndex,
                status: 'completed',
            }),
        );
        dispatch(setEAPDataToDB('evacuation'));
        dispatch(setUnSavedFields(false));
    };

    const handleRemoveAssemblyPointToggle = (event: any) => {
        setRemoveAssemblyPointAnchorEl(removeAssemblyPointAnchorEl ? null : event.currentTarget);
        setRemoveAssemblyPointPopperToggled((prev) => !prev);
    };
    const removeAssemblyPointAnchorElID = removeAssemblyPointPopperToggled ? 'transitions-popper' : undefined;

    let assemblyPointsElements: JSX.Element[] = [];
    //Assembly points
    for (let i = 0; i < evacuationData.assembly.assemblyPoints.allIDs.length; i++) {
        let registeredField = `assemblyPoint${evacuationData.assembly.assemblyPoints.allIDs[i]}name`;
        let registeredField2 = `assemblyPoint${evacuationData.assembly.assemblyPoints.allIDs[i]}description`;

        assemblyPointsElements.push(
            <React.Fragment key={i}>
                <Spacer direction="vertical" spaceParam="large" />
                <RFC axis="row" justify="space-between">
                    <label htmlFor={registeredField}>
                        {evacuationData.assembly.assemblyPoints.allIDs.length === 1
                            ? 'Assembly Point'
                            : `Assembly Point #${i + 1}`}
                    </label>
                    {evacuationData.assembly.assemblyPoints.allIDs.length > 1 && (
                        <>
                            <div
                                className="fontAwesomeCircleWrapper"
                                aria-describedby={removeAssemblyPointAnchorElID}
                                tabIndex={0}
                                onClick={(e) => {
                                    handleRemoveAssemblyPointToggle(e);
                                    setAssemblyPointRemovalTarget({
                                        index: i,
                                        registeredField1: registeredField,
                                        registeredField2: registeredField2,
                                    });
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleRemoveAssemblyPointToggle(e);
                                        setAssemblyPointRemovalTarget({
                                            index: i,
                                            registeredField1: registeredField,
                                            registeredField2: registeredField2,
                                        });
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'inherit' }} />
                            </div>
                            <Popper
                                id={removeAssemblyPointAnchorElID}
                                open={removeAssemblyPointPopperToggled}
                                anchorEl={removeAssemblyPointAnchorEl}
                                transition
                            >
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={0}>
                                        <RFC
                                            axis="column"
                                            css={`
                                                background: ${theme.colors.blueGray[10]};
                                                padding: 1rem;
                                                width: 100%;
                                                max-width: 300px;
                                                border-radius: 1rem 1rem 1rem 1rem;
                                            `}
                                        >
                                            <h4>Remove Assembly Point</h4>
                                            <Spacer spaceParam="medium" />
                                            <span>
                                                Are you sure you want to remove this assembly point? This action cannot
                                                be undone.
                                            </span>
                                            <Spacer spaceParam="medium" />
                                            <RFC axis="row" justify="space-around">
                                                <button
                                                    className="button-tertiary"
                                                    onClick={(e) => handleRemoveAssemblyPointToggle(e)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="button-delete"
                                                    ref={deleteRef}
                                                    onClick={(e) => {
                                                        setTimeout(() => {
                                                            dispatch(
                                                                removeNormalizedData({
                                                                    node: 'Assembly point',
                                                                    //@ts-ignore
                                                                    targetIndex: assemblyPointRemovalTarget.index,
                                                                }),
                                                            );
                                                            unregister([
                                                                //@ts-ignore
                                                                assemblyPointRemovalTarget?.registeredField1,
                                                                //@ts-ignore
                                                                assemblyPointRemovalTarget?.registeredField2,
                                                            ]);
                                                            //@ts-ignore
                                                            if (assemblyPointRemovalTarget.index > 0) {
                                                                setFocus(
                                                                    `assemblyPoint${
                                                                        evacuationData.assembly.assemblyPoints.allIDs[
                                                                            //@ts-ignore
                                                                            assemblyPointRemovalTarget.index - 1
                                                                        ]
                                                                    }name`,
                                                                );
                                                            } else {
                                                                setFocus(
                                                                    `assemblyPoint${
                                                                        evacuationData.assembly.assemblyPoints.allIDs[
                                                                            //@ts-ignore
                                                                            assemblyPointRemovalTarget.index - 1
                                                                        ]
                                                                    }name`,
                                                                );
                                                            }
                                                        }, 350);
                                                        handleRemoveAssemblyPointToggle(e);
                                                    }}
                                                >
                                                    Confirm
                                                </button>
                                            </RFC>
                                        </RFC>
                                    </Fade>
                                )}
                            </Popper>
                        </>
                    )}
                </RFC>

                <Spacer spaceParam="large" />
                <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                    <label
                        htmlFor={registeredField}
                        className={inputsWatch[registeredField] ? 'input_label_transitioned' : ''}
                        onClick={(e) => setFocus(registeredField)}
                    >
                        Name
                    </label>
                    <input
                        className="predetermined-size-33"
                        {...register(registeredField, {
                            validate: {
                                required: (value) => (value && value.length > 0) || 'Must provide name',
                                maxLength: (value) =>
                                    value.length === 0 || value.length < 1000 || 'Length must be < 1000 characters',
                                notBlankSpaces: (value) => {
                                    let regex = new RegExp(/.*\S.*/);
                                    let notOnlyBlanks = false;
                                    if (regex.exec(value)) {
                                        notOnlyBlanks = true;
                                    }
                                    return notOnlyBlanks || value.length === 0 || 'Name cannot be only blank spaces';
                                },
                                isSafe: (value) => {
                                    return isValueXSSSafe(value, 'Assembly point name', userEmail)
                                        ? true
                                        : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                },
                            },
                        })}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                setFocus(registeredField2);
                            }
                        }}
                    />
                </RFC>
                {formState.errors[registeredField] && formState.errors[registeredField].type && (
                    <>
                        <Spacer direction="vertical" spaceParam="small" />
                        <p>{formState.errors[registeredField].message}</p>
                        <Spacer direction="vertical" spaceParam="small" />
                    </>
                )}

                <Spacer spaceParam="large" />
                <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                    <textarea
                        {...register(registeredField2, {
                            validate: {
                                required: (value) =>
                                    (value && value.length > 0) || 'Must provide description of assembly point',
                                maxLength: (value) => value.length < 1000000 || 'Length must be < 1,000,000 characters',
                                notBlankSpaces: (value) => {
                                    let regex = new RegExp(/.*\S.*/);
                                    let notOnlyBlanks = false;
                                    if (regex.exec(value)) {
                                        notOnlyBlanks = true;
                                    }
                                    return (
                                        notOnlyBlanks || value.length === 0 || 'Description cannot be only blank spaces'
                                    );
                                },
                                isSafe: (value) => {
                                    return isValueXSSSafe(value, 'Assembly Description', userEmail)
                                        ? true
                                        : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                },
                            },
                        })}
                    />
                    <label
                        htmlFor={registeredField2}
                        className={inputsWatch[registeredField2] ? 'input_label_transitioned' : ''}
                        onClick={(e) => setFocus(registeredField2)}
                    >
                        Description
                    </label>
                </RFC>
                {formState.errors[registeredField2] && formState.errors[registeredField2].type && (
                    <>
                        <Spacer direction="vertical" spaceParam="small" />
                        <p>{formState.errors[registeredField2].message}</p>
                        <Spacer direction="vertical" spaceParam="small" />
                    </>
                )}

                {i === evacuationData.assembly.assemblyPoints.allIDs.length - 1 && (
                    <>
                        <Spacer spaceParam="large" direction="vertical" />
                        <button
                            type="button"
                            className="button-secondary"
                            onClick={() => {
                                dispatch(addNormalizedData({ node: 'Assembly point' }));
                                setAddedAssemblyPoint(true);
                            }}
                        >
                            Add another assembly point?
                        </button>
                    </>
                )}
            </React.Fragment>,
        );
    }

    return (
        <RFC axis="column" justify="flex-start" align="center">
            <FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                formWidth="70%"
                formMinWidth="350px"
                formMaxWidth="900px"
                inputWidth="100%"
                status={apiPostStatus}
            >
                <RFC axis="row" justify="space-between" css="width: 100%;">
                    <h2 style={{ alignSelf: 'left' }}>{subSectionTitle}</h2>
                    {sectionStatus === 'completed' && (
                        <>
                            <Spacer spaceParam="large" />
                            {unSavedFields ? (
                                <h3 style={{ color: theme.colors.red[6] }}>Unsaved Changes</h3>
                            ) : (
                                <h3 style={{ color: theme.colors.cyan[5] }}>Complete</h3>
                            )}
                        </>
                    )}
                </RFC>

                <Spacer spaceParam="large" />
                <label>
                    Does the following accurately state your emergency evacuation policy? “In the event of an emergency,
                    ALL employees should evacuate immediately.”
                </label>
                <Spacer spaceParam="medium" />
                <RFC axis="row" justify="flex-start">
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" className="radioButtonContainer">
                        <label>
                            <input {...register('allEmployeesEvacuate')} type="radio" value="Yes" defaultChecked /> Yes
                        </label>
                        <Spacer spaceParam="small" />
                        <label>
                            <input {...register('allEmployeesEvacuate')} type="radio" value="No" /> No
                        </label>
                    </RFC>
                </RFC>

                <Spacer spaceParam="large" />
                <RFC axis="row" justify="flex-start" align="flex-start" className="input_label_wrapper">
                    <textarea
                        {...register('evacuationPolicy', {
                            validate: {
                                required: (value) =>
                                    inputsWatch['allEmployeesEvacuate'] === 'Yes' ||
                                    (value && value.length > 0) ||
                                    'Must provide this description',
                                maxLength: (value) => value.length < 1000000 || 'Length must be < 1,000,000 characters',
                                notBlankSpaces: (value) => {
                                    let regex = new RegExp(/.*\S.*/);
                                    let notOnlyBlanks = false;
                                    if (regex.exec(value)) {
                                        notOnlyBlanks = true;
                                    }
                                    return (
                                        inputsWatch['allEmployeesEvacuate'] === 'Yes' ||
                                        notOnlyBlanks ||
                                        value.length === 0 ||
                                        'Operation cannot be only blank spaces'
                                    );
                                },
                                isSafe: (value) => {
                                    return isValueXSSSafe(value, 'Emergency Evacuation Policy', userEmail)
                                        ? true
                                        : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                },
                            },
                        })}
                    />
                    <label
                        htmlFor={'evacuationPolicy'}
                        className={inputsWatch['evacuationPolicy'] ? 'input_label_transitioned' : ''}
                        onClick={(e) => setFocus('evacuationPolicy')}
                    >
                        {`Provide ${
                            inputsWatch['allEmployeesEvacuate'] === 'Yes' ? `any additional ` : ``
                        }information about your evacuation policies`}
                    </label>
                </RFC>
                {formState.errors['evacuationPolicy'] && formState.errors['evacuationPolicy'].type && (
                    <>
                        <Spacer direction="vertical" spaceParam="small" />
                        <p>{formState.errors['evacuationPolicy'].message}</p>
                        <Spacer direction="vertical" spaceParam="small" />
                    </>
                )}

                <Spacer spaceParam="large" />
                <label>
                    Do you assign Floor Wardens at this location to help manage safe and effective evacuation?
                </label>
                <Spacer spaceParam="medium" />
                <RFC axis="row" justify="flex-start">
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" className="radioButtonContainer">
                        <label>
                            <input {...register('floorWardens')} type="radio" value="Yes" /> Yes
                        </label>
                        <Spacer spaceParam="small" />
                        <label>
                            <input {...register('floorWardens')} type="radio" value="No" defaultChecked /> No
                        </label>
                    </RFC>
                </RFC>

                <RFC
                    axis="column"
                    align="flex-start"
                    css={inputsWatch['floorWardens'] === 'No' ? 'display: none;' : ''}
                >
                    <Spacer spaceParam="large" />
                    <RFC axis="row" justify="flex-start" align="flex-start" className="input_label_wrapper">
                        <textarea
                            {...register('floorWardensPolicy', {
                                validate: {
                                    required: (value) =>
                                        inputsWatch['floorWardens'] === 'No' ||
                                        (value && value.length > 0) ||
                                        'Must provide this description',
                                    maxLength: (value) =>
                                        inputsWatch['floorWardens'] === 'No' ||
                                        value.length < 1000000 ||
                                        'Length must be < 1,000,000 characters',
                                    notBlankSpaces: (value) => {
                                        let regex = new RegExp(/.*\S.*/);
                                        let notOnlyBlanks = false;
                                        if (regex.exec(value)) {
                                            notOnlyBlanks = true;
                                        }
                                        return (
                                            inputsWatch['floorWardens'] === 'No' ||
                                            notOnlyBlanks ||
                                            value.length === 0 ||
                                            'Operation cannot be only blank spaces'
                                        );
                                    },
                                    isSafe: (value) => {
                                        return isValueXSSSafe(value, 'Floor wardens responsibilities', userEmail)
                                            ? true
                                            : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                    },
                                },
                            })}
                        />
                        <label
                            htmlFor={'floorWardensPolicy'}
                            className={inputsWatch['floorWardensPolicy'] ? 'input_label_transitioned' : ''}
                            onClick={(e) => setFocus('floorWardensPolicy')}
                        >
                            Please describe each floor warden's responsibilities
                        </label>
                    </RFC>
                    {formState.errors['floorWardensPolicy'] && formState.errors['floorWardensPolicy'].type && (
                        <>
                            <Spacer direction="vertical" spaceParam="small" />
                            <p>{formState.errors['floorWardensPolicy'].message}</p>
                            <Spacer direction="vertical" spaceParam="small" />
                        </>
                    )}
                </RFC>

                <Spacer spaceParam="large" />
                <label>
                    Does the following accurately state your policy regarding evacuation routes? “In the event of an
                    emergency, ALL employees should evacuate through the nearest available marked exit.”
                </label>
                <Spacer spaceParam="medium" />
                <RFC axis="row" justify="flex-start">
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" className="radioButtonContainer">
                        <label>
                            <input {...register('routesDefault')} type="radio" value="Yes" defaultChecked /> Yes
                        </label>
                        <Spacer spaceParam="small" />
                        <label>
                            <input {...register('routesDefault')} type="radio" value="No" /> No
                        </label>
                    </RFC>
                </RFC>

                <Spacer spaceParam="large" />
                <RFC axis="row" justify="flex-start" align="flex-start" className="input_label_wrapper">
                    <textarea
                        {...register('routes', {
                            validate: {
                                required: (value) =>
                                    inputsWatch['routesDefault'] === 'Yes' ||
                                    (value && value.length > 0) ||
                                    'Must provide this description',
                                maxLength: (value) => value.length < 1000000 || 'Length must be < 1,000,000 characters',
                                notBlankSpaces: (value) => {
                                    let regex = new RegExp(/.*\S.*/);
                                    let notOnlyBlanks = false;
                                    if (regex.exec(value)) {
                                        notOnlyBlanks = true;
                                    }
                                    return (
                                        inputsWatch['routesDefault'] === 'Yes' ||
                                        notOnlyBlanks ||
                                        value.length === 0 ||
                                        'Operation cannot be only blank spaces'
                                    );
                                },
                                isSafe: (value) => {
                                    return isValueXSSSafe(value, 'Evacuation Map', userEmail)
                                        ? true
                                        : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                },
                            },
                        })}
                    />
                    <label
                        htmlFor={'routes'}
                        className={inputsWatch['routes'] ? 'input_label_transitioned' : ''}
                        onClick={(e) => setFocus('routes')}
                    >
                        {`Provide ${
                            inputsWatch['routesDefault'] === 'Yes' ? `any additional ` : ``
                        }information about your evacuation routes`}
                    </label>
                </RFC>
                {formState.errors['routes'] && formState.errors['routes'].type && (
                    <>
                        <Spacer direction="vertical" spaceParam="small" />
                        <p>{formState.errors['routes'].message}</p>
                        <Spacer direction="vertical" spaceParam="small" />
                    </>
                )}

                <Spacer spaceParam="large" />
                {evacuationData.evacuationRoutes.routesUploadName ? (
                    <label htmlFor="routesFile" className="file">
                        Previously uploaded evacuation map: &nbsp;&nbsp;
                        <strong>{evacuationData.evacuationRoutes.routesUploadName}</strong>
                    </label>
                ) : (
                    <label htmlFor="routesFile">
                        Optionally, can you provide an evacuation map that highlights your routes and exits?
                    </label>
                )}
                <input
                    type="file"
                    name="routesFile"
                    id="routesFile"
                    accept=".pdf, .doc, .png, .jpg, .jpeg, .gif, .tif, .tiff, .docx"
                    onChange={(e) => {
                        if (e.target && e.target.files && e.target.files[0]) {
                            setRoutesFile(e.target.files[0]);
                            setRoutesFileSelected(true);
                        }
                    }}
                />

                <Spacer spaceParam="large" />
                <Spacer spaceParam="large" />
                <h4>List the designated Assembly Areas used at this location during an evacuation</h4>

                <RFC axis="row" justify="flex-start">
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" basis="100%">
                        {assemblyPointsElements}
                    </RFC>
                </RFC>

                <Spacer spaceParam="large" />
                {evacuationData.assembly.reunificationUploadName ? (
                    <label htmlFor="reunificationFile" className="file">
                        Previous diagram of assembly points: &nbsp;&nbsp;
                        <strong>{evacuationData.assembly.reunificationUploadName}</strong>
                    </label>
                ) : (
                    <label htmlFor="reunificationFile">Do you have a diagram of assembly points to upload?</label>
                )}
                <input
                    type="file"
                    name="reunificationFile"
                    id="reunificationFile"
                    accept=".pdf, .doc, .png, .jpg, .jpeg, .gif, .tif, .tiff, .docx"
                    onChange={(e) => {
                        if (e.target && e.target.files && e.target.files[0]) {
                            setReunificationFile(e.target.files[0]);
                            setReunificationFileSelected(true);
                        }
                    }}
                />

                <Spacer spaceParam="large" />
                <Spacer spaceParam="medium" />
                <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                    <textarea
                        {...register('rollCallDescription', {
                            validate: {
                                required: (value) => (value && value.length > 0) || 'Must provide this information',
                                maxLength: (value) => value.length < 1000000 || 'Length must be < 1,000,000 characters',
                                notBlankSpaces: (value) => {
                                    let regex = new RegExp(/.*\S.*/);
                                    let notOnlyBlanks = false;
                                    if (regex.exec(value)) {
                                        notOnlyBlanks = true;
                                    }
                                    return (
                                        notOnlyBlanks || value.length === 0 || 'Description cannot be only blank spaces'
                                    );
                                },
                                isSafe: (value) => {
                                    return isValueXSSSafe(value, 'Accounting for employees', userEmail)
                                        ? true
                                        : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                },
                            },
                        })}
                    />
                    <label
                        htmlFor={'rollCallDescription'}
                        className={inputsWatch['rollCallDescription'] ? 'input_label_transitioned' : ''}
                        onClick={(e) => setFocus('rollCallDescription')}
                    >
                        Once gathered at the assembly point after evacuation, describe how you account for your
                        employees
                    </label>
                </RFC>
                {formState.errors['rollCallDescription'] && formState.errors['rollCallDescription'].type && (
                    <>
                        <Spacer direction="vertical" spaceParam="small" />
                        <p>{formState.errors['rollCallDescription'].message}</p>
                        <Spacer direction="vertical" spaceParam="small" />
                    </>
                )}

                <Spacer spaceParam="large" />
                <label>Will In Case of Crisis manage the roll call procedure?</label>
                <Spacer spaceParam="medium" />
                <RFC axis="row" justify="flex-start">
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" className="radioButtonContainer">
                        <label>
                            <input {...register('ICOCmanagedRollCall')} type="radio" value="Yes" defaultChecked /> Yes
                        </label>
                        <Spacer spaceParam="small" />
                        <label>
                            <input {...register('ICOCmanagedRollCall')} type="radio" value="No" /> No
                        </label>
                    </RFC>
                </RFC>

                <Spacer direction="vertical" spaceParam="large" />
                <RFC axis="column" justify="flex-start" align="flex-end" alignLaptop="center" css="width: 100%;">
                    {(apiPostStatus === 'succeeded' || laptop) && (
                        <RFC
                            axis="row"
                            basis="75%"
                            align="center"
                            alignLaptop="center"
                            justify="flex-end"
                            justifyLaptop="flex-end"
                            css={!laptop ? 'padding-top: 1em;' : ''}
                        >
                            <SuccessAlert active={apiPostStatus === 'succeeded'} style={{ alignSelf: 'right' }}>
                                Successfully Saved!
                            </SuccessAlert>
                            <Spacer spaceParam="medium" direction="horizontal" />
                        </RFC>
                    )}
                    <RFC axis="row" justify="flex-end" basis="25%">
                        <StyledSaveButton
                            type="submit"
                            postStatus={apiPostStatus}
                            noChanges={!unSavedFields}
                            onClick={(e) => {
                                if (apiPostStatus !== 'idle') {
                                    e.preventDefault();
                                }
                            }}
                        >
                            Save
                        </StyledSaveButton>
                    </RFC>
                </RFC>
            </FormWrapper>
        </RFC>
    );
};

export const Fire = () => {
    const fireData = useAppSelector(selectFormsData).fire;
    const {
        sectionIndex,
        subSectionIndex,
        subSectionTitle,
        dispatch,
        apiGetStatus,
        unSavedFields,
        sectionStatus,
        theme,
        apiPostStatus,
        laptop,
    } = useSectionsQualityOfLife();

    const setInitialValues = useRef(false);

    const [addedEmployeeAllowed, setAddedEmployeeAllowed] = useState(false);

    const { register, watch, handleSubmit, setFocus, formState, getValues, setValue, unregister, control } = useForm({
        mode: 'onSubmit',
    });

    const inputsWatch = watch();

    const userEmail = useSelector(selectUserEmail);
    useEffect(() => {
        if (!setInitialValues.current && apiGetStatus === 'succeeded') {
            setTimeout(() => {
                setValue('policyProcedures', fireData.policyProcedures);
                setValue('extinguishers', fireData.portableFireExtinguishers ? 'Yes' : 'No');
                setValue(
                    'permission',
                    fireExtinguisherOptions.find((item) => item.value === fireData.permissionToUse),
                );
                fireData.employeesAllowed.allIDs.forEach((id) => {
                    setValue(`employeeAllowed${id}`, fireData.employeesAllowed.byID[id]);
                });
                setInitialValues.current = true;
            }, 0);
        }
    }, [apiGetStatus, fireData, setValue, formState.errors]);
    useEffect(() => {
        if (!unSavedFields && setInitialValues.current) {
            if (
                (inputsWatch['extinguishers'] !== undefined &&
                    (inputsWatch['extinguishers'] === 'Yes') !== fireData.portableFireExtinguishers) ||
                (inputsWatch['policyProcedures'] !== undefined &&
                    inputsWatch['policyProcedures'] !== fireData.policyProcedures) ||
                (inputsWatch['permission'] !== undefined &&
                    inputsWatch['permission'].value !== fireData.permissionToUse)
            ) {
                dispatch(setUnSavedFields(true));
            }
            fireData.employeesAllowed.allIDs.forEach((id) => {
                if (
                    inputsWatch[`employeeAllowed${id}`] !== undefined &&
                    inputsWatch[`employeeAllowed${id}`] !== fireData.employeesAllowed.byID[id]
                ) {
                    dispatch(setUnSavedFields(true));
                }
            });
        }
    }, [inputsWatch, unSavedFields, setInitialValues, dispatch, fireData]);

    useEffect(() => {
        if (addedEmployeeAllowed && fireData.employeesAllowed.allIDs[fireData.employeesAllowed.allIDs.length - 1]) {
            setFocus(`employeeAllowed${fireData.employeesAllowed.allIDs[fireData.employeesAllowed.allIDs.length - 1]}`);
            setAddedEmployeeAllowed(false);
        }
    }, [fireData.employeesAllowed, setFocus, addedEmployeeAllowed]);

    const onSubmit = (data: any) => {
        const fireUpdateData = {
            policyProcedures: getValues('policyProcedures'),
            portableFireExtinguishers: getValues('extinguishers') === 'Yes' ? true : false,
            permissionToUse: getValues('permission')?.value,
            permissionAll: getValues('extinguishers') === 'Yes' ? getValues('permission')?.value === 'All' : false,
            permissionSpecific:
                getValues('extinguishers') === 'Yes' ? getValues('permission')?.value === 'Specific' : false,
            permissionNone: getValues('extinguishers') === 'Yes' ? getValues('permission')?.value === 'None' : false,
            employeesAllowed: fireData.employeesAllowed,
        };
        dispatch(updateFormsDataNode({ node: 'fire', data: fireUpdateData }));
        if (inputsWatch['extinguishers'] === 'Yes' && inputsWatch['permission'].value === 'Specific') {
            for (let i = 0; i < fireData.employeesAllowed.allIDs.length; i++) {
                dispatch(
                    updateNormalizedData({
                        node: 'Fire extinguisher employee',
                        targetIndex: i,
                        value: getValues(`employeeAllowed${fireData.employeesAllowed.allIDs[i]}`),
                    }),
                );
            }
        }
        dispatch(purgeConditionalItems());
        dispatch(
            updateSectionProgress({
                sectionIndex: sectionIndex,
                subSectionIndex: subSectionIndex,
                status: 'completed',
            }),
        );
        dispatch(setEAPDataToDB('fire'));
        dispatch(setUnSavedFields(false));
    };

    let mappedFormElements: JSX.Element[] = [];

    //mapped normalized fields inputs
    for (let i = 0; i < fireData.employeesAllowed.allIDs.length; i++) {
        let registeredField = `employeeAllowed${fireData.employeesAllowed.allIDs[i]}`;

        mappedFormElements.push(
            <React.Fragment key={i}>
                <Spacer direction="vertical" spaceParam="large" />
                <RFC
                    axis="row"
                    justify="flex-start"
                    className={
                        fireData.employeesAllowed.allIDs.length === 1
                            ? 'input_label_wrapper'
                            : 'input_label_wrapper_with_icon'
                    }
                >
                    <label
                        htmlFor={registeredField}
                        className={inputsWatch[registeredField] ? 'input_label_transitioned' : ''}
                        onClick={(e) => setFocus(registeredField)}
                    >
                        {fireData.employeesAllowed.allIDs.length === 1
                            ? `Name of employee`
                            : `Name of employee #${i + 1}`}
                    </label>
                    <input
                        className="predetermined-size-33"
                        {...register(registeredField, {
                            validate: {
                                required: (value) =>
                                    (inputsWatch['permission'] && inputsWatch['permission'].value !== 'Specific') ||
                                    inputsWatch['extinguishers'] === 'No' ||
                                    (value && value.length > 0) ||
                                    'Must provide name',
                                maxLength: (value) =>
                                    (inputsWatch['permission'] && inputsWatch['permission'].value !== 'Specific') ||
                                    inputsWatch['extinguishers'] === 'No' ||
                                    value.length === 0 ||
                                    value.length < 1000 ||
                                    'Length must be < 1000 characters',
                                notBlankSpaces: (value) => {
                                    let regex = new RegExp(/.*\S.*/);
                                    let notOnlyBlanks = false;
                                    if (regex.exec(value)) {
                                        notOnlyBlanks = true;
                                    }
                                    return (
                                        (inputsWatch['permission'] && inputsWatch['permission'].value !== 'Specific') ||
                                        inputsWatch['extinguishers'] === 'No' ||
                                        notOnlyBlanks ||
                                        value.length === 0 ||
                                        'Name cannot be only blank spaces'
                                    );
                                },
                                isSafe: (value) => {
                                    return isValueXSSSafe(value, 'Employee name for fire management', userEmail)
                                        ? true
                                        : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                },
                            },
                        })}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        }}
                    />
                    {fireData.employeesAllowed.allIDs.length > 1 && (
                        <>
                            <div
                                className="fontAwesomeCircleWrapper"
                                tabIndex={0}
                                onClick={() => {
                                    dispatch(
                                        removeNormalizedData({ node: 'Fire extinguisher employee', targetIndex: i }),
                                    );
                                    unregister(registeredField);
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        dispatch(
                                            removeNormalizedData({
                                                node: 'Fire extinguisher employee',
                                                targetIndex: i,
                                            }),
                                        );
                                        unregister(registeredField);
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} style={{ color: 'inherit' }} />
                            </div>
                        </>
                    )}
                </RFC>
                {formState.errors[registeredField] && formState.errors[registeredField].type && (
                    <>
                        <Spacer direction="vertical" spaceParam="small" />
                        <p>{formState.errors[registeredField].message}</p>
                        <Spacer direction="vertical" spaceParam="small" />
                    </>
                )}

                {i === fireData.employeesAllowed.allIDs.length - 1 && (
                    <>
                        <Spacer spaceParam="medium" />
                        <button
                            type="button"
                            className="button-secondary"
                            onClick={() => {
                                dispatch(addNormalizedData({ node: 'Fire extinguisher employee' }));
                                setAddedEmployeeAllowed(true);
                            }}
                        >
                            Add another employee?
                        </button>
                    </>
                )}
            </React.Fragment>,
        );
    }
    return (
        <RFC axis="column" justify="flex-start" align="center">
            <FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                formWidth="70%"
                formMinWidth="350px"
                formMaxWidth="900px"
                inputWidth="100%"
                status={apiPostStatus}
            >
                <RFC axis="row" justify="space-between" css="width: 100%;">
                    <h2 style={{ alignSelf: 'left' }}>{subSectionTitle}</h2>
                    {sectionStatus === 'completed' && (
                        <>
                            <Spacer spaceParam="large" />
                            {unSavedFields ? (
                                <h3 style={{ color: theme.colors.red[6] }}>Unsaved Changes</h3>
                            ) : (
                                <h3 style={{ color: theme.colors.cyan[5] }}>Complete</h3>
                            )}
                        </>
                    )}
                </RFC>

                <Spacer spaceParam="large" />
                <Spacer spaceParam="medium" />
                <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                    <textarea
                        {...register('policyProcedures', {
                            validate: {
                                required: (value) => (value && value.length > 0) || 'Must provide this information',
                                maxLength: (value) => value.length < 1000000 || 'Length must be < 1,000,000 characters',
                                notBlankSpaces: (value) => {
                                    let regex = new RegExp(/.*\S.*/);
                                    let notOnlyBlanks = false;
                                    if (regex.exec(value)) {
                                        notOnlyBlanks = true;
                                    }
                                    return (
                                        notOnlyBlanks || value.length === 0 || 'Description cannot be only blank spaces'
                                    );
                                },
                                isSafe: (value) => {
                                    return isValueXSSSafe(value, 'Fire emergency employee steps', userEmail)
                                        ? true
                                        : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                },
                            },
                        })}
                    />
                    <label
                        htmlFor={'policyProcedures'}
                        className={inputsWatch['policyProcedures'] ? 'input_label_transitioned' : ''}
                        onClick={(e) => setFocus('policyProcedures')}
                    >
                        What specific steps should an employee follow in the event of a fire emergency?
                    </label>
                </RFC>
                {formState.errors['policyProcedures'] && formState.errors['policyProcedures'].type && (
                    <>
                        <Spacer direction="vertical" spaceParam="small" />
                        <p>{formState.errors['policyProcedures'].message}</p>
                        <Spacer direction="vertical" spaceParam="small" />
                    </>
                )}

                <Spacer spaceParam="large" />
                <label>Do you have portable fire extinguishers on site?</label>
                <Spacer spaceParam="medium" />
                <RFC axis="row" justify="flex-start">
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" className="radioButtonContainer">
                        <label>
                            <input {...register('extinguishers')} type="radio" value="Yes" defaultChecked /> Yes
                        </label>
                        <Spacer spaceParam="small" />
                        <label>
                            <input {...register('extinguishers')} type="radio" value="No" /> No
                        </label>
                    </RFC>
                </RFC>

                <RFC
                    axis="column"
                    align="flex-start"
                    css={inputsWatch['extinguishers'] === 'No' ? 'display: none;' : ''}
                >
                    <Spacer spaceParam="large" />
                    <label>In the event of a fire, who is permitted to use portable fire extinguishers?</label>
                    <Spacer spaceParam="medium" />
                    <Controller
                        name="permission"
                        control={control}
                        defaultValue={{ value: 'pre-load', label: '...loading' }}
                        render={({ field: { onChange, value } }) => (
                            <Select
                                value={value}
                                options={fireExtinguisherOptions}
                                styles={SelectStyles}
                                onChange={onChange}
                                borderColor={theme.colors.blueGray[9]}
                                borderColorFocus={theme.colors.blue[5]}
                                backgroundFocus={theme.colors.yellow[10]}
                                selectedColor={theme.colors.blueGray[1]}
                                theme={(oldTheme) => ({
                                    ...oldTheme,
                                    borderRadius: 0,
                                    colors: {
                                        ...oldTheme.colors,
                                        primary25: theme.colors.blue[8],
                                        primary: theme.colors.blue[5],
                                    },
                                })}
                            />
                        )}
                    />
                </RFC>

                <RFC
                    axis="column"
                    align="flex-start"
                    css={
                        inputsWatch['permission'] &&
                        inputsWatch['permission'].value === 'Specific' &&
                        inputsWatch['extinguishers'] === 'Yes'
                            ? ''
                            : 'display: none;'
                    }
                >
                    <RFC axis="row" justify="flex-start">
                        <Spacer spaceParam="large" />
                        <RFC axis="column" align="flex-start" basis="100%">
                            {mappedFormElements}
                        </RFC>
                    </RFC>
                </RFC>

                <Spacer direction="vertical" spaceParam="large" />
                <RFC axis="column" justify="flex-start" align="flex-end" alignLaptop="center" css="width: 100%;">
                    {(apiPostStatus === 'succeeded' || laptop) && (
                        <RFC
                            axis="row"
                            basis="75%"
                            align="center"
                            alignLaptop="center"
                            justify="flex-end"
                            justifyLaptop="flex-end"
                            css={!laptop ? 'padding-top: 1em;' : ''}
                        >
                            <SuccessAlert active={apiPostStatus === 'succeeded'} style={{ alignSelf: 'right' }}>
                                Successfully Saved!
                            </SuccessAlert>
                            <Spacer spaceParam="medium" direction="horizontal" />
                        </RFC>
                    )}
                    <RFC axis="row" justify="flex-end" basis="25%">
                        <StyledSaveButton
                            type="submit"
                            postStatus={apiPostStatus}
                            noChanges={!unSavedFields}
                            onClick={(e) => {
                                if (apiPostStatus !== 'idle') {
                                    e.preventDefault();
                                }
                            }}
                        >
                            Save
                        </StyledSaveButton>
                    </RFC>
                </RFC>
            </FormWrapper>
        </RFC>
    );
};

export const Chemicals = () => {
    const chemicalsData = useAppSelector(selectFormsData).chemicals;
    const {
        sectionIndex,
        subSectionIndex,
        subSectionTitle,
        dispatch,
        apiGetStatus,
        unSavedFields,
        sectionStatus,
        theme,
        apiPostStatus,
        laptop,
    } = useSectionsQualityOfLife();

    const setInitialValues = useRef(false);

    const [addedChemical, setAddedChemical] = useState(false);

    const { register, watch, handleSubmit, setFocus, formState, getValues, setValue, unregister } = useForm({
        mode: 'onSubmit',
    });

    const userEmail = useSelector(selectUserEmail);

    const inputsWatch = watch();
    useEffect(() => {
        if (!setInitialValues.current && apiGetStatus === 'succeeded') {
            setTimeout(() => {
                setValue('chemicalsOnsite', chemicalsData.chemicalsOnsite ? 'Yes' : 'No');
                setValue('properDoc', chemicalsData.properDocumentation ? 'Yes' : 'No');
                setValue('reportingNearMisses', chemicalsData.reportingNearMisses);
                setValue('reportingSpills', chemicalsData.reportingSpills);
                chemicalsData.chemicalsList.allIDs.forEach((id) => {
                    setValue(`chemical${id}`, chemicalsData.chemicalsList.byID[id]);
                });
                setInitialValues.current = true;
            }, 0);
        }
    }, [apiGetStatus, chemicalsData, setValue, formState.errors]);
    useEffect(() => {
        if (!unSavedFields && setInitialValues.current) {
            if (
                (inputsWatch['chemicalsOnsite'] !== undefined &&
                    (inputsWatch['chemicalsOnsite'] === 'Yes') !== chemicalsData.chemicalsOnsite) ||
                (inputsWatch['properDoc'] !== undefined &&
                    (inputsWatch['properDoc'] === 'Yes') !== chemicalsData.properDocumentation) ||
                (inputsWatch['reportingNearMisses'] !== undefined &&
                    inputsWatch['reportingNearMisses'] !== chemicalsData.reportingNearMisses) ||
                (inputsWatch['reportingSpills'] !== undefined &&
                    inputsWatch['reportingSpills'] !== chemicalsData.reportingSpills)
            ) {
                dispatch(setUnSavedFields(true));
            }
            chemicalsData.chemicalsList.allIDs.forEach((id) => {
                if (
                    inputsWatch[`chemical${id}`] !== undefined &&
                    inputsWatch[`chemical${id}`] !== chemicalsData.chemicalsList.byID[id]
                ) {
                    dispatch(setUnSavedFields(true));
                }
            });
        }
    }, [inputsWatch, unSavedFields, setInitialValues, dispatch, chemicalsData]);

    useEffect(() => {
        if (addedChemical && chemicalsData.chemicalsList.allIDs[chemicalsData.chemicalsList.allIDs.length - 1]) {
            setFocus(`chemical${chemicalsData.chemicalsList.allIDs[chemicalsData.chemicalsList.allIDs.length - 1]}`);
            setAddedChemical(false);
        }
    }, [chemicalsData.chemicalsList, setFocus, addedChemical]);

    const onSubmit = (data: any) => {
        let chemicalsUpdate: typeof chemicalsData = {
            chemicalsOnsite: getValues('chemicalsOnsite') === 'Yes' ? true : false,
            chemicalsList: chemicalsData.chemicalsList,
            properDocumentation: getValues('properDoc') === 'Yes' ? true : false,
            reportingNearMisses: getValues('reportingNearMisses'),
            reportingSpills: getValues('reportingSpills'),
        };
        dispatch(updateFormsDataNode({ node: 'chemicals', data: chemicalsUpdate }));
        if (inputsWatch['chemicalsOnsite'] === 'Yes') {
            for (let i = 0; i < chemicalsData.chemicalsList.allIDs.length; i++) {
                dispatch(
                    updateNormalizedData({
                        node: 'Chemical',
                        targetIndex: i,
                        value: getValues(`chemical${chemicalsData.chemicalsList.allIDs[i]}`),
                    }),
                );
            }
        }
        dispatch(purgeConditionalItems());
        dispatch(
            updateSectionProgress({
                sectionIndex: sectionIndex,
                subSectionIndex: subSectionIndex,
                status: 'completed',
            }),
        );
        dispatch(setEAPDataToDB('chemicals'));
        dispatch(setUnSavedFields(false));
    };
    let mappedFormElements: JSX.Element[] = [];
    //mapped normalized fields inputs
    for (let i = 0; i < chemicalsData.chemicalsList.allIDs.length; i++) {
        let registeredField = `chemical${chemicalsData.chemicalsList.allIDs[i]}`;

        mappedFormElements.push(
            <React.Fragment key={i}>
                <Spacer spaceParam="large" />
                <RFC axis="row" justify="flex-start">
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start">
                        <RFC
                            axis="row"
                            justify="flex-start"
                            className={
                                chemicalsData.chemicalsList.allIDs.length > 1
                                    ? 'input_label_wrapper_with_icon'
                                    : 'input_label_wrapper'
                            }
                        >
                            <label
                                htmlFor={registeredField}
                                className={inputsWatch[registeredField] ? 'input_label_transitioned' : ''}
                                onClick={(e) => setFocus(registeredField)}
                            >
                                {chemicalsData.chemicalsList.allIDs.length === 1
                                    ? `Name of chemical`
                                    : `Name of chemical #${i + 1}`}
                            </label>
                            <input
                                {...register(registeredField, {
                                    validate: {
                                        required: (value) =>
                                            inputsWatch['chemicalsOnsite'] === 'No' ||
                                            (value && value.length > 0) ||
                                            'Must provide name',
                                        maxLength: (value) =>
                                            inputsWatch['chemicalsOnsite'] === 'No' ||
                                            value.length === 0 ||
                                            value.length < 1000 ||
                                            'Length must be < 1000 characters',
                                        notBlankSpaces: (value) => {
                                            let regex = new RegExp(/.*\S.*/);
                                            let notOnlyBlanks = false;
                                            if (regex.exec(value)) {
                                                notOnlyBlanks = true;
                                            }
                                            return (
                                                inputsWatch['chemicalsOnsite'] === 'No' ||
                                                notOnlyBlanks ||
                                                value.length === 0 ||
                                                'Name cannot be only blank spaces'
                                            );
                                        },
                                        isSafe: (value) => {
                                            return isValueXSSSafe(value, 'Name of chemicals', userEmail)
                                                ? true
                                                : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                        },
                                    },
                                })}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            {chemicalsData.chemicalsList.allIDs.length > 1 && (
                                <>
                                    <div
                                        className="fontAwesomeCircleWrapper"
                                        tabIndex={0}
                                        onClick={() => {
                                            dispatch(removeNormalizedData({ node: 'Chemical', targetIndex: i }));
                                            unregister(registeredField);
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                dispatch(removeNormalizedData({ node: 'Chemical', targetIndex: i }));
                                                unregister(registeredField);
                                            }
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} style={{ color: 'inherit' }} />
                                    </div>
                                </>
                            )}
                        </RFC>
                        {formState.errors[registeredField] && formState.errors[registeredField].type && (
                            <>
                                <Spacer direction="vertical" spaceParam="small" />
                                <p>{formState.errors[registeredField].message}</p>
                                <Spacer direction="vertical" spaceParam="small" />
                            </>
                        )}

                        {i === chemicalsData.chemicalsList.allIDs.length - 1 && (
                            <>
                                {' '}
                                <Spacer spaceParam="medium" direction="vertical" />
                                <button
                                    type="button"
                                    className="button-secondary"
                                    onClick={() => {
                                        dispatch(addNormalizedData({ node: 'Chemical' }));
                                        setAddedChemical(true);
                                    }}
                                >
                                    Add another chemical?
                                </button>{' '}
                            </>
                        )}
                    </RFC>
                    <Spacer spaceParam="large" />
                </RFC>
            </React.Fragment>,
        );
    }
    //text fields
    mappedFormElements.push(
        <React.Fragment key={-1}>
            <Spacer spaceParam="large" />
            <Spacer spaceParam="medium" />
            <label>Do you have the proper documentation onsite to the SDS for each chemical?</label>
            <Spacer spaceParam="medium" />
            <RFC axis="row" justify="flex-start">
                <Spacer spaceParam="large" />
                <RFC axis="column" align="flex-start" className="radioButtonContainer">
                    <label>
                        <input {...register('properDoc')} type="radio" value="Yes" defaultChecked /> Yes
                    </label>
                    <Spacer spaceParam="small" />
                    <label>
                        <input {...register('properDoc')} type="radio" value="No" /> No
                    </label>
                </RFC>
            </RFC>

            <Spacer spaceParam="large" />
            <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                <textarea
                    {...register('reportingNearMisses', {
                        validate: {
                            required: (value) =>
                                inputsWatch['chemicalsOnsite'] === 'No' ||
                                (value && value.length > 0) ||
                                'Must describe process for reporting near misses',
                            maxLength: (value) =>
                                inputsWatch['chemicalsOnsite'] === 'No' ||
                                value.length < 1000000 ||
                                'Length must be < 1,000,000 characters',
                            notBlankSpaces: (value) => {
                                let regex = new RegExp(/.*\S.*/);
                                let notOnlyBlanks = false;
                                if (regex.exec(value)) {
                                    notOnlyBlanks = true;
                                }
                                if (regex.exec(chemicalsData.reportingNearMisses)) {
                                    notOnlyBlanks = true;
                                }
                                return (
                                    inputsWatch['chemicalsOnsite'] === 'No' ||
                                    notOnlyBlanks ||
                                    value.length === 0 ||
                                    'Description of near misses cannot be only blank spaces'
                                );
                            },
                            isSafe: (value) => {
                                return isValueXSSSafe(value, 'Near Misses', userEmail)
                                    ? true
                                    : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                            },
                        },
                    })}
                />
                <label
                    htmlFor={'reportingNearMisses'}
                    className={inputsWatch['reportingNearMisses'] ? 'input_label_transitioned' : ''}
                    onClick={(e) => setFocus('reportingNearMisses')}
                >
                    What is the process for reporting near misses?
                </label>
            </RFC>
            {formState.errors['reportingNearMisses'] && formState.errors['reportingNearMisses'].type && (
                <>
                    <Spacer direction="vertical" spaceParam="small" />
                    <p>{formState.errors['reportingNearMisses'].message}</p>
                    <Spacer direction="vertical" spaceParam="small" />
                </>
            )}

            <Spacer spaceParam="large" />
            <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                <textarea
                    {...register('reportingSpills', {
                        validate: {
                            required: (value) =>
                                inputsWatch['chemicalsOnsite'] === 'No' ||
                                (value && value.length > 0) ||
                                'Must describe process for reporting chemical spills',
                            maxLength: (value) =>
                                inputsWatch['chemicalsOnsite'] === 'No' ||
                                value.length < 1000000 ||
                                'Length must be < 1,000,000 characters',
                            notBlankSpaces: (value) => {
                                let regex = new RegExp(/.*\S.*/);
                                let notOnlyBlanks = false;
                                if (regex.exec(value)) {
                                    notOnlyBlanks = true;
                                }
                                if (regex.exec(chemicalsData.reportingSpills)) {
                                    notOnlyBlanks = true;
                                }
                                return (
                                    inputsWatch['chemicalsOnsite'] === 'No' ||
                                    notOnlyBlanks ||
                                    value.length === 0 ||
                                    'Description of near misses cannot be only blank spaces'
                                );
                            },
                            isSafe: (value) => {
                                return isValueXSSSafe(value, 'Chemical Spill process', userEmail)
                                    ? true
                                    : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                            },
                        },
                    })}
                />
                <label
                    htmlFor={'reportingSpills'}
                    className={inputsWatch['reportingSpills'] ? 'input_label_transitioned' : ''}
                    onClick={(e) => setFocus('reportingSpills')}
                >
                    What is the process for reporting spills?
                </label>
            </RFC>
            {formState.errors['reportingSpills'] && formState.errors['reportingSpills'].type && (
                <>
                    <Spacer direction="vertical" spaceParam="small" />
                    <p>{formState.errors['reportingSpills'].message}</p>
                    <Spacer direction="vertical" spaceParam="small" />
                </>
            )}
        </React.Fragment>,
    );
    return (
        <RFC axis="column" justify="flex-start" align="center">
            <FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                formWidth="70%"
                formMinWidth="350px"
                formMaxWidth="900px"
                inputWidth="100%"
                status={apiPostStatus}
            >
                <RFC axis="row" justify="space-between" css="width: 100%;">
                    <h2 style={{ alignSelf: 'left' }}>{subSectionTitle}</h2>
                    {sectionStatus === 'completed' && (
                        <>
                            <Spacer spaceParam="large" />
                            {unSavedFields ? (
                                <h3 style={{ color: theme.colors.red[6] }}>Unsaved Changes</h3>
                            ) : (
                                <h3 style={{ color: theme.colors.cyan[5] }}>Complete</h3>
                            )}
                        </>
                    )}
                </RFC>

                <Spacer spaceParam="large" />
                <label>Do you have chemicals onsite?</label>
                <Spacer spaceParam="medium" />
                <RFC axis="row" justify="flex-start">
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" className="radioButtonContainer">
                        <label>
                            <input {...register('chemicalsOnsite')} type="radio" value="Yes" /> Yes
                        </label>
                        <Spacer spaceParam="small" />
                        <label>
                            <input {...register('chemicalsOnsite')} type="radio" value="No" defaultChecked /> No
                        </label>
                    </RFC>
                </RFC>

                <RFC
                    axis="column"
                    align="flex-start"
                    css={inputsWatch['chemicalsOnsite'] === 'Yes' ? '' : 'display: none;'}
                >
                    {mappedFormElements}
                </RFC>

                <Spacer direction="vertical" spaceParam="large" />
                <RFC axis="column" justify="flex-start" align="flex-end" alignLaptop="center" css="width: 100%;">
                    {(apiPostStatus === 'succeeded' || laptop) && (
                        <RFC
                            axis="row"
                            basis="75%"
                            align="center"
                            alignLaptop="center"
                            justify="flex-end"
                            justifyLaptop="flex-end"
                            css={!laptop ? 'padding-top: 1em;' : ''}
                        >
                            <SuccessAlert active={apiPostStatus === 'succeeded'} style={{ alignSelf: 'right' }}>
                                Successfully Saved!
                            </SuccessAlert>
                            <Spacer spaceParam="medium" direction="horizontal" />
                        </RFC>
                    )}
                    <RFC axis="row" justify="flex-end" basis="25%">
                        <StyledSaveButton
                            type="submit"
                            postStatus={apiPostStatus}
                            noChanges={!unSavedFields}
                            onClick={(e) => {
                                if (apiPostStatus !== 'idle') {
                                    e.preventDefault();
                                }
                            }}
                        >
                            Save
                        </StyledSaveButton>
                    </RFC>
                </RFC>
            </FormWrapper>
        </RFC>
    );
};
