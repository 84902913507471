import React from 'react';

import { createGlobalStyle } from 'styled-components';
import { ThemeType } from '../../Contexts/ThemeGlobalAndCtxt';

import { ResponsiveFlexContainer } from './FlexContainers';

interface IProps {
    inputWidth: string;
    inputMaxWidth?: string;
    inputMinWidth?: string;
    formWidth: string;
    formMinWidth: string;
    formMaxWidth: string;
    onSubmit: (() => void) | ((e: any) => void);
    children: any;
    status?: 'succeeded' | 'failed' | 'pending' | 'idle';
    direction?: string;
}

export const FormWrapper = ({
    children,
    inputWidth,
    inputMaxWidth,
    inputMinWidth,
    formWidth,
    formMinWidth,
    formMaxWidth,
    onSubmit,
    status = 'idle',
}: IProps) => {
    // @ts-ignore

    return (
        <>
            <FormWrapperStyles
                formWidth={formWidth}
                formMinWidth={formMinWidth}
                formMaxWidth={formMaxWidth}
                inputWidth={inputWidth}
                inputMaxWidth={inputMaxWidth}
                inputMinWidth={inputMinWidth}
                status={status}
            />
            <form onSubmit={onSubmit}>
                <ResponsiveFlexContainer mobileFlexDirection="column" keepFlexDirection alignItems="flex-start">
                    {children}
                </ResponsiveFlexContainer>
            </form>
        </>
    );
};

interface IFormProps {
    formWidth: string;
    formMinWidth: string;
    formMaxWidth: string;
    inputWidth: string;
    inputMaxWidth: string | undefined;
    inputMinWidth: string | undefined;
    status: 'succeeded' | 'failed' | 'pending' | 'idle';
}
const FormWrapperStyles = createGlobalStyle<{ theme: ThemeType } & IFormProps>`

    p {
        color: ${({ theme }) => theme.colors.red[7]};
        margin: 0;
        font-size: 0.875rem;
        &::before {
            display: inline;
            content: "⚠ ";
        }
    }

    form {
        padding: 0.875rem;
        border-radius: .875rem 0 .875rem 0;
        width: ${({ formWidth }) => formWidth};
        min-width: ${({ formMinWidth }) => formMinWidth};
        max-width: ${({ formMaxWidth }) => formMaxWidth};
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow: 2px 2px 5px 1px ${({ theme, status }) =>
            status === 'succeeded'
                ? theme.colors.cyan[7]
                : status === 'failed'
                ? theme.colors.red[7]
                : theme.colors.blue[8]};
    }

    input, select, textarea {
        font-family: Roboto, Arial, sans-serif;
        display: inline-block;
        width: ${({ inputWidth }) => inputWidth};
        ${({ inputMinWidth }) => (inputMinWidth ? `min-width: ${inputMinWidth};` : '')}
        ${({ inputMaxWidth }) => (inputMaxWidth ? `max-width: ${inputMaxWidth};` : '')}
        color: ${({ theme }) => theme.colors.blueGray[1]};
        border: none;
        border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray[9]};
        padding: 0.3rem 0.3rem;
        font-size: 1rem;
        font-weight: 400;
        margin: 0;
        background-color: ${({ theme }) => theme.colors.white};
        letter-spacing: 0.08rem;
        &:hover {
            border-bottom: 1px solid ${({ theme }) => theme.colors.blue[5]};
        }
        &:focus {
            border-radius: 1px;
            border-bottom: 1px solid ${({ theme }) => theme.colors.blue[5]};
            box-shadow: none;
            background-color: ${({ theme }) => theme.colors.yellow[10]};
        }
        &:disabled {
            opacity: 0.4;
        }
    }
    select::after {
        background-color: ${({ theme }) => theme.colors.coolGray[10]}
    }
    input[type="radio"] {
        display: inline;
        width: 20px;
        max-width: 20px;
        min-width: 20px;
        text-align: start;
    }
    input[type="number"] {
        width: 300px;
        &:focus, &.number_input_hasValue {
            width: 100px;
        }
    }
    input {
        padding: 0.3rem 0rem;
    }
    .predetermined-size-33 {
        width: 33%;
        min-width: 225px;
    }
    .predetermined-size-66 {
        width: 66%;
        min-width: 400px;
    }
    textarea {
        border: 1px solid ${({ theme }) => theme.colors.blueGray[9]};
        min-height: 4.5em;
        min-width: ${({ inputWidth }) => inputWidth};
        max-width: ${({ inputWidth }) => inputWidth};
        &:focus {
            border-radius: 1px;
        }
    }

    .existing-data-placeholder {
        &&::placeholder {
            color: ${({ theme }) => theme.colors.blueGray[3]};
            font-style: italic;
        }
    }

    .reset-button {
        background: ${({ theme }) => theme.colors.nuetralGray[3]};
        color: ${({ theme }) => theme.colors.blueGray[10]};
        text-transform: uppercase;
        border: none;
        width: fit-content;
        letter-spacing: .3em;
        padding: .7em 1em;
        font-size: 0.875rem;
        font-weight: 300;
        &:hover {
            background: ${({ theme }) => theme.colors.nuetralGray[2]};
        }
        -webkit-appearance: none;
    }

    input[type="button"] {
        -webkit-appearance: none;
    }

    

    .button-delete {
        border: solid 1px ${({ theme }) => theme.colors.red[7]};
        background-color: transparent;
        color: ${({ theme }) => theme.colors.red[7]};
        font-size: .7rem;
        &:hover {
            background-color: transparent;
            color: ${({ theme }) => theme.colors.red[4]};
            border: solid 1px ${({ theme }) => theme.colors.red[4]};
        }
        &:focus {
            color: ${({ theme }) => theme.colors.red[4]};
            border: solid 1px ${({ theme }) => theme.colors.yellow[5]};
            box-shadow: none;
        }
        &:active {
            color: ${({ theme }) => theme.colors.red[4]};
            border: solid 1px ${({ theme }) => theme.colors.red[4]};
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.red[4]};
        }
    }

    .checkbox_label {
        display: inline-block;
        position: relative;
        height: 1rem;
        line-height: 1rem;
        padding-left: 1.5rem;
        cursor: pointer;
        
    }

    .checkbox_label .checkbox_input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkbox_label .checkbox_input:focus ~ .checkbox_span {
        box-shadow: 0 0 0 1pt ${({ theme }) => theme.colors.yellow[5]};
    }

    .checkbox_label .checkbox_span {
        position: absolute;
        left: 0;
        top: 0;
        height: 1em;
        width: 1em;
        background-color: ${({ theme }) => theme.colors.blueGray[7]};
    }

    .checkbox_label:hover .checkbox_input ~ .checkbox_span {
        background-color: ${({ theme }) => theme.colors.blueGray[5]};
    }

    .checkbox_label .checkbox_input:checked ~ .checkbox_span {
        background-color: ${({ theme }) => theme.colors.blue[5]};
    }

    .checkbox_label:hover .checkbox_input:checked ~ .checkbox_span {
        background-color: ${({ theme }) => theme.colors.blue[3]};
    }

    .checkbox_label .checkbox_span:after {
        content: "";
        position: absolute;
        display: none;
    }

    .checkbox_label .checkbox_input:checked ~ .checkbox_span:after {
        display: block;
    }

    .checkbox_label .checkbox_span:after {
        left: 0.24rem;
        width: 0.45rem;
        height: 0.65rem;
        border: solid ${({ theme }) => theme.colors.blueGray[10]};
        border-width: 0 0.15rem 0.15rem 0;
        -webkit-transform: rotate(40deg);
        -ms-transform: rotate(40deg);
        transform: rotate(40deg);
    }

    label {
        z-index: 0;
        text-align: left;
        display: inline-block;
        color: ${({ theme }) => theme.colors.blueGray[6]};
        font-size: 0.9rem;
    }

    .input_label_wrapper, .input_label_wrapper_with_icon {
        width: 100%;
        min-width: 100%;
        position: relative;
        padding-top: 0.7rem;
        letter-spacing: 0.05rem;
        @media ${({ theme }) => theme.breakpoints.mediaQueries.tablet} {
            letter-spacing: 0.08rem;
        }
    }

    .input_label_wrapper > label, .input_label_wrapper_with_icon > label {
        position: absolute;
        transition: position .2s linear;
        cursor: text;
        left: 0;
    }
    .input_label_wrapper > textarea ~ label {
        left: 0.5rem;
    }
    .input_label_wrapper > label {
        top: 1rem;
    }
    .input_label_wrapper_with_icon > label {
        bottom: .7rem;
    }
    .input_label_wrapper:focus-within label,
    .input_label_wrapper > .input_label_transitioned,
    .input_label_wrapper_with_icon:focus-within label,
    .input_label_wrapper_with_icon > .input_label_transitioned {
        position: absolute;
        transition: all .2s linear;
        font-size: 0.7rem;
        left: 0;
    }
    .input_label_wrapper:focus-within label, .input_label_wrapper > .input_label_transitioned {
        top: -0.3rem;
    }
    .input_label_wrapper > .input_label_indentLeft {
        left: 2rem;
    }
    .input_label_wrapper:focus-within textarea ~ label, .input_label_wrapper > textarea ~ .input_label_transitioned {
        top: -0.5rem;
    }
    .input_label_wrapper_with_icon:focus-within label, .input_label_wrapper_with_icon > .input_label_transitioned {
        bottom: 2.6rem;
    }
    .input_label_wrapper:hover input {
        border-bottom: 1px solid ${({ theme }) => theme.colors.blue[5]};
    }
    .input_icon_repositioned {
        position: absolute;
        right: 0rem;
        top: -1.5rem;
    }

    label.file {
        color: ${({ theme }) => theme.colors.blueGray[1]};
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.08rem;
    }
    .radioButtonContainer {
        max-width: 60px;
        &:focus-within {
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.yellow[5]};
        }
    }

    .half-width {
        width: 50%;
        max-width: 50%;
        min-width: 50%;
    }
`;
