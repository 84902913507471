import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../Common/Hooks/reactReduxHooks';
import { useSectionsQualityOfLife } from '../Common/Hooks/customHooks';
import { setEAPDataToDB, updateFormsDataNode } from '../ReduxSlices/formsData';
import {
    PublishPlanToDB,
    selectPubTitle,
    selectShared,
    updateShared,
    selectPublishPostStatus,
    resetPublishPostStatus,
    selectUserEmail,
} from '../ReduxSlices/userSlice';
import { updateSectionProgress } from '../ReduxSlices/sectionProgressSlice';

import { FormWrapper } from '../Common/Components/FormWrapper';
import { Spacer } from '../Common/Components/Spacer';
import { RFC } from '../Common/Components/FlexContainers';
import { StyledSaveButton, StyledBackdrop, StyledPromptForm } from '../Common/Components/CommonStyled';
import dayjs from 'dayjs';
import Slide from '@mui/material/Slide';
import {
    checkListingUniqueName,
    selectCurrentListingID,
    selectCurrentListingName,
    updateCurrentListingName,
    selectUniqueNameInfo,
    resetUniqueNameStatus,
} from '../ReduxSlices/listingsSlice';
import { isValueXSSSafe } from '../Common/Security/IcoSanitize';

export const PlaybookInfo = () => {
    const playbookName = useAppSelector(selectCurrentListingName);
    const shared = useAppSelector(selectShared);
    const listingID = useAppSelector(selectCurrentListingID);
    const listingStatus = useAppSelector(selectUniqueNameInfo);
    const publishPostStatus = useAppSelector(selectPublishPostStatus);
    const pubTitle = useAppSelector(selectPubTitle);
    const [editWarning, setEditWarning] = useState(false);
    const {
        sectionIndex,
        subSectionIndex,
        subSectionTitle,
        dispatch,
        apiGetStatus,
        sectionStatuses,
        theme,
        apiPostStatus,
        timerID,
        history,
        sections,
    } = useSectionsQualityOfLife();

    const setInitialValues = useRef(false);
    const publishedRef = useRef(false);

    const { register, handleSubmit, setFocus, formState, getValues, setValue, setError } = useForm({
        mode: 'onSubmit',
    });

    const userEmail = useAppSelector(selectUserEmail);

    useEffect(() => {
        if (!setInitialValues.current && apiGetStatus === 'succeeded') {
            try {
                setTimeout(() => {
                    if (playbookName) {
                        setValue('playbookName', playbookName, { shouldValidate: false });
                    }
                    setValue('shared', shared);
                }, 0);
            } catch {
                console.log('setValue failed, nodes not loaded quick enough?');
            }
            setInitialValues.current = true;
        }
    }, [setFocus, playbookName, apiGetStatus, setValue, shared, formState.errors]);
    //TODO I need to verify unique playbook name here
    useEffect(() => {
        if (listingStatus.status === 'succeeded') {
            const playbookDataUpdate = {
                playbookName: getValues('playbookName'),
                publishDate: dayjs().format('DD-MMM-YYYY').toString(),
            };
            dispatch(updateCurrentListingName(getValues('playbookName')));
            dispatch(updateFormsDataNode({ node: 'playbookInfo', data: playbookDataUpdate }));
            dispatch(updateShared(getValues('shared')));
            dispatch(
                updateSectionProgress({
                    sectionIndex: sectionIndex,
                    subSectionIndex: subSectionIndex,
                    status: 'completed',
                }),
            );
            dispatch(setEAPDataToDB('all'));
            dispatch(resetUniqueNameStatus());
        } else if (listingStatus.status === 'failed') {
            setError('playbookName', { type: 'uniqueName', message: listingStatus.error });
            dispatch(resetUniqueNameStatus());
        }
    }, [listingStatus, dispatch, setError, sectionIndex, subSectionIndex, getValues]);

    useEffect(() => {
        if (apiPostStatus === 'succeeded' && !publishedRef.current) {
            dispatch(PublishPlanToDB());
            publishedRef.current = true;
        }
        if (timerID) {
            clearTimeout(timerID);
        }
    }, [dispatch, apiPostStatus, timerID, publishPostStatus]);
    useEffect(() => {
        let nextUrl: string = '';
        if (sections[sectionIndex].subSections[subSectionIndex].nextURL) {
            //@ts-ignore
            nextUrl = sections[sectionIndex].subSections[subSectionIndex].nextURL;
        } else {
            nextUrl = '/home';
        }
        if (publishPostStatus === 'succeeded') {
            setTimeout(() => {
                history.push(nextUrl);
            }, 1000);
        }
    }, [publishPostStatus, dispatch, history, sections, sectionIndex, subSectionIndex]);

    useEffect(() => {
        return () => {
            setTimeout(() => {
                dispatch(resetPublishPostStatus());
            }, 1000);
        };
    }, [dispatch]);

    const onSubmit = (data: any) => {
        if (pubTitle) {
            setEditWarning(true);
        } else {
            dispatch(
                checkListingUniqueName({
                    listingID: listingID,
                    listingName: getValues('playbookName'),
                    userEmail: userEmail,
                }),
            );
        }
    };

    return (
        <RFC axis="column" justify="flex-start" align="center">
            {sectionStatuses[3] === 'completed' && publishPostStatus === 'succeeded' && (
                <>
                    <h2 style={{ color: theme.colors.cyan[5] }}>Your playbook has been successfully created!</h2>
                    <Spacer spaceParam="large" />
                </>
            )}
            {sectionStatuses[3] === 'completed' && publishPostStatus === 'failed' && (
                <>
                    <h2 style={{ color: theme.colors.red[7] }}>There was an error building your playbook!</h2>
                    <Spacer spaceParam="large" />
                </>
            )}
            <FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                formWidth="60%"
                formMinWidth="350px"
                formMaxWidth="700px"
                inputWidth="100%"
                status={publishPostStatus}
            >
                <RFC axis="row" justify="space-between" css="width: 100%;">
                    <h2 style={{ alignSelf: 'left' }}>{subSectionTitle}</h2>
                </RFC>

                <Spacer spaceParam="large" />

                <span>
                    In this final step, provide a name for your playbook and click Create. Your new playbook will then
                    be sent to the In Case of Crisis Online Portal to be published for download.
                </span>
                <Spacer spaceParam="large" />
                <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                    <label
                        htmlFor={'playbookName'}
                        className={'input_label_transitioned'}
                        onClick={(e) => setFocus('playbookName')}
                    >
                        Name of the Playbook (e.g. Emergency Operations Plan for Acme Corp)
                    </label>
                    <input
                        {...register('playbookName', {
                            validate: {
                                required: (value) => (value && value.length > 0) || 'Must provide a playbook name',
                                minLength: (value) =>
                                    value.length === 0 ||
                                    value.length > 5 ||
                                    'We recommend a slightly longer name (> 5 characters)',
                                notBlankSpaces: (value) => {
                                    let regex = new RegExp(/.*\S.*/);
                                    let notOnlyBlanks = false;
                                    if (regex.exec(value)) {
                                        notOnlyBlanks = true;
                                    }
                                    return (
                                        notOnlyBlanks || value.length === 0 || 'Description cannot be only blank spaces'
                                    );
                                },
                                isSafe: (value) => {
                                    return isValueXSSSafe(value, 'Other Alerts', userEmail)
                                        ? true
                                        : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                },
                            },
                        })}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        }}
                    />
                </RFC>
                {formState.errors['playbookName'] && formState.errors['playbookName'].type && (
                    <>
                        <Spacer direction="vertical" spaceParam="small" />
                        <p>{formState.errors['playbookName'].message}</p>
                        <Spacer direction="vertical" spaceParam="small" />
                    </>
                )}

                <Spacer spaceParam="large" />
                <h3>Share EAP data with Sub-Organization</h3>
                <Spacer spaceParam="medium" />
                <label>
                    I would like to share this EAP data in our organization. This includes allowing access to
                    administrators from suborgs within our account.
                </label>
                <Spacer spaceParam="medium" />
                <RFC axis="row" justify="flex-start">
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" className="radioButtonContainer">
                        <label>
                            <input {...register('shared')} type="radio" value="Y" /> Yes
                        </label>
                        <Spacer spaceParam="small" />
                        <label>
                            <input {...register('shared')} type="radio" value="N" defaultChecked /> No
                        </label>
                    </RFC>
                </RFC>

                <Spacer direction="vertical" spaceParam="large" />
                <RFC axis="row" justify="center" basis="100%">
                    <StyledSaveButton
                        large
                        type="submit"
                        disabled={apiPostStatus !== 'idle' ? true : false}
                        postStatus={apiPostStatus}
                        onClick={(e) => {
                            if (apiPostStatus !== 'idle') {
                                e.preventDefault();
                            }
                        }}
                    >
                        {sectionStatuses[3] === 'completed' ? 'Regenerate' : 'Create'}
                    </StyledSaveButton>
                </RFC>
            </FormWrapper>
            {editWarning && <StyledBackdrop onClick={() => setEditWarning(false)} />}
            <Slide in={editWarning} direction="down" mountOnEnter unmountOnExit>
                <StyledPromptForm>
                    <FormWrapper
                        formWidth="100%"
                        formMinWidth="350px"
                        formMaxWidth="700px"
                        inputWidth="80%"
                        status={listingStatus.status}
                        onSubmit={(e) => {
                            e.preventDefault();
                            setEditWarning(false);
                            dispatch(
                                checkListingUniqueName({
                                    listingID: listingID,
                                    listingName: getValues('playbookName'),
                                    userEmail: userEmail,
                                }),
                            );
                        }}
                    >
                        <RFC axis="column" align="center" css="padding: 0.8rem;">
                            <p style={{ fontSize: '1rem' }}>
                                Are you sure you want to build? This action will overwrite any changes previously made
                                to this playbook on the Online Portal.
                            </p>
                            <Spacer spaceParam="large" />
                            <RFC axis="row" justify="center">
                                <button
                                    className="button-secondary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setEditWarning(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <Spacer spaceParam="medium" />
                                <button>Continue</button>
                            </RFC>
                        </RFC>
                    </FormWrapper>
                </StyledPromptForm>
            </Slide>
        </RFC>
    );
};
