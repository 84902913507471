import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState, sliceAPI } from '../store';
import {
    GetUserSavedState,
    GetAppData,
    AuthRefresh,
    handleErrorStatus,
    PublishPlan,
    GetPDF,
} from '../Common/apiRequests';
import { updateAllSectionsProgress, resetSectionProgress, updateSectionProgress } from './sectionProgressSlice';

import {
    resetFormsData,
    updateFormsDataNode,
    resetFormsDataNode,
    initialDataEAP,
    updateAddress,
    resetAddress,
    initialDataAdvisory,
} from './formsData';
import dayjs from 'dayjs';
import { updateCurrentListingName } from './listingsSlice';
interface IAppReturnData {
    Environment: any;
    BuildInformation: any;
    BuildCreated: any;
    AppVersion: any;
}
interface IeapReturnData extends IAppReturnData {
    EmailAddress: any;
    curOrgString: any;
}
interface IAdvisoryReturnData extends IAppReturnData {
    stakeholder: any;
    issue: any;
}

interface IUser extends sliceAPI {
    appData: {
        returnData: IAdvisoryReturnData | IeapReturnData | '';
        appType: 'EAP' | 'Advisory' | '';
    };
    completeYN: boolean;
    shared: 'Y' | 'N';
    showWhatYouNeed: boolean;
    unSavedFields: boolean;
    appDataStatus: 'idle' | 'pending' | 'failed' | 'succeeded';
    publishPostStatus: 'idle' | 'pending' | 'failed' | 'succeeded';
    apiDateTimeRefresh: string;
    authExpired: boolean;
    pubTitle: string;
    appError: number;
}

const initialState: IUser = {
    appData: {
        returnData: '',
        appType: '',
    },
    completeYN: false,
    shared: 'N',
    postStatus: 'idle',
    publishPostStatus: 'idle',
    getStatus: 'idle',
    errorMessage: null,
    showWhatYouNeed: false,
    unSavedFields: false,
    appDataStatus: 'idle',
    apiDateTimeRefresh: '',
    authExpired: false,
    pubTitle: '',
    appError: 0,
};

export const getEAPStateFromDB = createAsyncThunk<any, number, { state: RootState }>(
    'user/getEAPStateFromDB',
    async (ID, { dispatch, rejectWithValue }) => {
        const responseData = await GetUserSavedState(ID);
        if (responseData.error) {
            handleErrorStatus(responseData.error, dispatch);
            return rejectWithValue(responseData.error);
        } else {
            dispatch(setApiDateTimeRefresh(dayjs().toISOString()));
        }

        if (responseData === 'No data returned') {
            dispatch(resetFormsData());
            dispatch(resetSectionProgress('EAP'));
        } else {
            for (let node of Object.keys(initialDataEAP)) {
                if (responseData['OEAP_json'][node]) {
                    // check for legacy data structure
                    if (
                        node === 'chemicals' &&
                        responseData['OEAP_json'][node] &&
                        responseData['OEAP_json'][node]['chemicalsOnsite'] === true
                    ) {
                        let chemicalData = responseData['OEAP_json'][node];
                        responseData['OEAP_json'][node]['chemicalsList']['allIDs'].forEach((chemicalID) => {
                            if (
                                typeof responseData['OEAP_json'][node]['chemicalsList']['byID'][chemicalID] !== 'string'
                            ) {
                                chemicalData['chemicalsList']['byID'][chemicalID] =
                                    responseData['OEAP_json'][node]['chemicalsList']['byID'][chemicalID][
                                        'chemicalName'
                                    ];
                            }
                        });
                        dispatch(updateFormsDataNode({ node: node, data: chemicalData }));
                    } else {
                        // @ts-ignore
                        dispatch(updateFormsDataNode({ node: node, data: responseData['OEAP_json'][node] }));
                    }
                } else {
                    dispatch(resetFormsDataNode(node));
                }
            }
            if (responseData['OEAP_json'].sectionProgress) {
                dispatch(updateAllSectionsProgress(responseData['OEAP_json'].sectionProgress));
            } else {
                dispatch(resetSectionProgress('EAP'));
            }
            if (responseData['OEAP_json'].address) {
                dispatch(updateAddress(responseData['OEAP_json'].address));
            } else {
                dispatch(resetAddress());
            }
            if (responseData['meta'].shared) {
                dispatch(updateShared(responseData['meta'].shared));
            } else {
                dispatch(updateShared('N'));
            }
            if (responseData['meta'].pubTitle) {
                dispatch(updateCurrentListingName(responseData['meta'].pubTitle));
                dispatch(updatePubTitle(responseData['meta'].pubTitle));
            } else {
                dispatch(updateSectionProgress({ sectionIndex: 3, subSectionIndex: 0, status: 'not started' }));
                dispatch(updateFormsDataNode({ node: 'playbookInfo', data: { playbookName: '', publishDate: '' } }));
                dispatch(updatePubTitle(''));
            }
        }

        return responseData;
    },
);

export const getAdvisoryStateFromDB = createAsyncThunk<any, void, { state: RootState }>(
    'user/getAdvisoryStateFromDB',
    async (_, { dispatch, rejectWithValue }) => {
        const responseData = await GetUserSavedState(0);
        if (responseData.error) {
            handleErrorStatus(responseData.error, dispatch);
            return rejectWithValue(responseData.error);
        } else {
            dispatch(setApiDateTimeRefresh(dayjs().toISOString()));
        }

        if (responseData === 'No data returned') {
            dispatch(resetFormsData());
            dispatch(resetSectionProgress('Advisory'));
        } else {
            for (let node of Object.keys(initialDataAdvisory)) {
                if (responseData['json'][node]) {
                    // check for legacy data structure
                    // @ts-ignore
                    dispatch(updateFormsDataNode({ node: node, data: responseData['json'][node] }));
                } else {
                    dispatch(resetFormsDataNode(node));
                }
            }
            if (responseData['json'].sectionProgress) {
                dispatch(updateAllSectionsProgress(responseData['json'].sectionProgress));
            } else {
                dispatch(resetSectionProgress('Advisory'));
            }
            if (responseData['meta'] && responseData['meta'].pubTitle) {
                dispatch(updatePubTitle(responseData['meta'].pubTitle));
            } else {
                dispatch(updateSectionProgress({ sectionIndex: 0, subSectionIndex: 5, status: 'not started' }));
                dispatch(updatePubTitle(''));
            }
        }
        return responseData;
    },
);

export const getAppDataFromDB = createAsyncThunk<any, void, { state: RootState }>(
    'user/getAppDataFromDB',
    async (_, { rejectWithValue, dispatch }) => {
        const responseData = await GetAppData();

        if (responseData.error) {
            handleErrorStatus(responseData.error, dispatch);
            return rejectWithValue(responseData.error);
        } else {
            dispatch(setApiDateTimeRefresh(dayjs().toISOString()));
        }

        if (responseData.error) {
            return rejectWithValue(responseData.error);
        }
        dispatch(resetSectionProgress(responseData['appType']));
        document.getElementsByTagName('title')[0].text = responseData['appType'] + ' Tool';
        return responseData;
    },
);

export const PublishPlanToDB = createAsyncThunk<any, void, { state: RootState }>(
    'user/publishPlan',
    async (_, { getState, dispatch, rejectWithValue }) => {
        let state = getState();
        const result = await PublishPlan(state.listings.data.listingID);
        if (result.error) {
            handleErrorStatus(result.error, dispatch);
            return rejectWithValue(result.error);
        } else {
            dispatch(setApiDateTimeRefresh(dayjs().toISOString()));
        }
        return result;
    },
);

export const userAuthRefresh = createAsyncThunk<any, void, { state: RootState }>(
    'user/AuthRefresh',
    async (_, { dispatch, rejectWithValue }) => {
        const responseData = await AuthRefresh();
        if (responseData.error) {
            handleErrorStatus(responseData.error, dispatch);
            return rejectWithValue(responseData.error);
        } else {
            dispatch(setApiDateTimeRefresh(dayjs().toISOString()));
        }
    },
);

export const ExportPDF = createAsyncThunk<any, { name: string; html: string }, { state: RootState }>(
    'user/ExportPDF',
    async (fileInfo, { dispatch, rejectWithValue }) => {
        const responseData = await GetPDF(fileInfo);
        if (responseData.error) {
            handleErrorStatus(responseData.error, dispatch);
            return rejectWithValue(responseData.error);
        } else {
            dispatch(setApiDateTimeRefresh(dayjs().toISOString()));
        }
    },
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetPostStatus: (state) => {
            state.postStatus = 'idle';
        },
        resetAPIGetStatus: (state) => {
            state.getStatus = 'idle';
        },
        resetPublishPostStatus: (state) => {
            state.publishPostStatus = 'idle';
        },
        toggleShowWhatYouNeed: (state) => {
            state.showWhatYouNeed = !state.showWhatYouNeed;
        },
        setUnSavedFields: (state, action: PayloadAction<boolean>) => {
            state.unSavedFields = action.payload;
        },
        setComplete: (state) => {
            state.completeYN = true;
        },
        setApiDateTimeRefresh: (state, action: PayloadAction<string>) => {
            state.apiDateTimeRefresh = action.payload;
        },
        setAuthExpired: (state) => {
            state.authExpired = true;
        },
        updateShared: (state, action: PayloadAction<'Y' | 'N'>) => {
            state.shared = action.payload;
        },
        updatePubTitle: (state, action: PayloadAction<string>) => {
            state.pubTitle = action.payload;
        },
        updateAppError: (state, action: PayloadAction<number>) => {
            state.appError = action.payload;
        },
        resetAppError: (state) => {
            state.appError = 0;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEAPStateFromDB.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getEAPStateFromDB.fulfilled, (state) => {
                state.getStatus = 'succeeded';
            })
            .addCase(getEAPStateFromDB.rejected, (state, action) => {
                if (action.payload) {
                    state.errorMessage = action.payload;
                } else {
                    state.errorMessage = action.error.message;
                }
                state.getStatus = 'failed';
            })
            .addCase(getAppDataFromDB.pending, (state) => {
                state.appDataStatus = 'pending';
            })
            .addCase(getAppDataFromDB.fulfilled, (state, action: PayloadAction<any>) => {
                state.appDataStatus = 'succeeded';
                state.appData = action.payload;
            })
            .addCase(getAppDataFromDB.rejected, (state, action) => {
                if (action.payload) {
                    state.errorMessage = action.payload;
                } else {
                    state.errorMessage = action.error.message;
                }
                state.appDataStatus = 'failed';
            })
            .addCase(userAuthRefresh.rejected, (state, action) => {
                if (action.payload) {
                    state.errorMessage = action.payload;
                } else {
                    state.errorMessage = action.error.message;
                }
                state.authExpired = true;
            })
            .addCase(PublishPlanToDB.fulfilled, (state) => {
                state.publishPostStatus = 'succeeded';
            })
            .addCase(PublishPlanToDB.rejected, (state, action) => {
                if (action.payload) {
                    state.errorMessage = action.payload;
                } else {
                    state.errorMessage = action.error.message;
                }
                state.publishPostStatus = 'failed';
            })
            .addCase(getAdvisoryStateFromDB.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getAdvisoryStateFromDB.fulfilled, (state) => {
                state.getStatus = 'succeeded';
            })
            .addCase(getAdvisoryStateFromDB.rejected, (state, action) => {
                if (action.payload) {
                    state.errorMessage = action.payload;
                } else {
                    state.errorMessage = action.error.message;
                }
                state.getStatus = 'failed';
            });
    },
});

export const {
    resetPostStatus,
    resetAPIGetStatus,
    toggleShowWhatYouNeed,
    setComplete,
    setUnSavedFields,
    setApiDateTimeRefresh,
    setAuthExpired,
    updateShared,
    updatePubTitle,
    resetPublishPostStatus,
    updateAppError,
    resetAppError,
} = userSlice.actions;
export const selectUserEmail = (state: RootState) => (state.user.appData.returnData as IeapReturnData).EmailAddress;
export const selectAPIPostStatus = (state: RootState) => state.user.postStatus;
export const selectAPIGetStatus = (state: RootState) => state.user.getStatus;
export const selectAppDataGetStatus = (state: RootState) => state.user.appDataStatus;
export const selectAppData = (state: RootState) => state.user.appData;
export const selectAppType = (state: RootState) => state.user.appData.appType;
export const selectShowWhatYouNeed = (state: RootState) => state.user.showWhatYouNeed;
export const selectUnSavedFields = (state: RootState) => state.user.unSavedFields;
export const selectApiDateTimeRefresh = (state: RootState) => state.user.apiDateTimeRefresh;
export const selectAuthExpired = (state: RootState) => state.user.authExpired;
export const selectError = (state: RootState) => state.user.errorMessage;
export const selectShared = (state: RootState) => state.user.shared;
export const selectPubTitle = (state: RootState) => state.user.pubTitle;
export const selectPublishPostStatus = (state: RootState) => state.user.publishPostStatus;
export const selectAppError = (state: RootState) => state.user.appError;

export default userSlice.reducer;
