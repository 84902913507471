import { styled } from '../../Contexts/ThemeGlobalAndCtxt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface ISaveButton {
    postStatus: 'idle' | 'pending' | 'succeeded' | 'failed';
    large?: undefined | true;
    noChanges?: boolean;
}
export const StyledSaveButton = styled.button<ISaveButton>`
    && {
        ${({ postStatus, theme }) => (postStatus !== 'idle' ? `background-color: ${theme.colors.blue[2]};` : '')}
        ${({ large }) => (large ? `font-size: 0.8rem;` : '')}
        &:active {
            ${({ postStatus, theme }) =>
                postStatus !== 'idle'
                    ? `box-shadow: 0 0 0 1px ${theme.colors.yellow[5]}; background-color: ${theme.colors.blue[2]}`
                    : ''}
        }
        &:hover {
            ${({ postStatus, theme }) => (postStatus !== 'idle' ? `background-color: ${theme.colors.blue[2]}` : '')}
        }
        &:disabled {
            color: ${({ theme }) => theme.colors.nuetralGray[5]};
            background-color: ${({ theme }) => theme.colors.nuetralGray[3]};
            &:hover {
                background-color: ${({ theme }) => theme.colors.nuetralGray[3]};
            }
        }
        ${({ noChanges, theme, postStatus }) =>
            postStatus === 'idle' && noChanges
                ? `
            background-color: ${theme.colors.white};
            color: ${theme.colors.blue[5]};
            box-shadow: 0 0 0 1px ${theme.colors.blue[5]};
            &:hover {
                color: ${theme.colors.blue[2]};
                box-shadow: 0 0 0 1px ${theme.colors.blue[2]};
            }
            &:active {
                color: ${theme.colors.blue[2]};
                box-shadow: 0 0 0 2px ${theme.colors.blue[2]};
            }
        `
                : ''}
    }
`;
export const SelectStyles = {
    input: (provided, state) => ({
        ...provided,
        minWidth: '100%',
        width: '100%',
        alignItems: 'left',
        justifyContent: 'flex-start',
        textAlign: 'left',
        padding: '0.3rem 0.3rem 0.4rem 0',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: '0rem',
        minHeight: '100%',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: '0rem',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: state.selectProps.selectedColor,
    }),
    control: (provided, state) => ({
        ...provided,
        minWidth: '100%',
        width: '100%',
        border: 'none',
        borderBottom: state.isFocused
            ? `1px solid ${state.selectProps.borderColorFocus}`
            : `1px solid ${state.selectProps.borderColor}`,
        boxShadow: 'none',
        backgroundColor: state.isFocused ? `${state.selectProps.backgroundFocus}` : 'none',
        minHeight: '100%',
        alignItems: 'flex-end',
        '&:hover': {
            border: 'none',
            borderBottom: `1px solid ${state.selectProps.borderColorFocus}`,
            boxShadow: 'none',
        },
    }),
    indicatorSeparator: () => ({
        border: 'none',
    }),
    container: (provided, state) => ({
        ...provided,
        minWidth: '100%',
        width: '100%',
        alignContent: 'flex-end',
        border: 'none',
    }),
};

export const StyledSnackbarConfirmButton = styled.button`
    background-color: ${({ theme }) => theme.colors.blueGray[10]};
    color: ${({ theme }) => theme.colors.coolGray[1]};
    &:hover {
        background-color: ${({ theme }) => theme.colors.blueGray[8]};
        color: ${({ theme }) => theme.colors.coolGray[1]};
    }
`;
export const StyledBackdrop = styled.div`
    width: 100%;
    height: 100%;
    z-index: 100;
    background: ${({ theme }) => theme.colors.blue[9]};
    opacity: 0.3;
    position: fixed;
    top: 0;
    left: 0;
`;
export const StyledPromptForm = styled.div`
    border: none;
    background-color: transparent;
    width: 40%;
    max-width: 500px;
    min-width: 300px;
    z-index: 101;
    position: fixed;
    top: 14rem;
    margin: 0 auto;
`;

export const states = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
];

export const criticalEmployeesOptions = [
    { value: 'All', label: 'All employees' },
    { value: 'Specific', label: 'Specific employees' },
    { value: 'None', label: 'Employees are not permitted to delay evacuation for any reason' },
];
export const medicalEmployeesOptions = [
    { value: 'All', label: 'All employees' },
    { value: 'Certified', label: 'Employees with a current First Aid certification' },
    { value: 'Specific', label: 'Only designated employees have permission' },
    {
        value: 'None',
        label: 'Employees are not permitted to administer CPR, operate AEDs, or perform other medical duties',
    },
];
export const fireExtinguisherOptions = [
    { value: 'All', label: 'All employees have permission' },
    { value: 'Specific', label: 'Only designated employees have permission' },
    { value: 'None', label: 'Employees are not permitted to use the portable fire extinguishers' },
];
export const frequencyOptions = [
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Biannually', label: 'Biannually (twice a year)' },
    { value: 'Annually', label: 'Annually' },
];
