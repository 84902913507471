import { styled } from '../../Contexts/ThemeGlobalAndCtxt';

interface IStyledProps {
    flexBasis?: string;
    mobileFlexDirection: 'row' | 'column';
    keepFlexDirection: boolean;
    justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    justifyMobileContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    justifyLaptopContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    alignItems?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    customDivStyle?: string;
    customBreakpoint?: number;
    outline?: true;
    ref?: any;
}

export const ResponsiveFlexContainer = styled.div<IStyledProps>`
    display: flex;
    flex-direction: ${(props) => props.mobileFlexDirection};
    flex-wrap: nowrap;
    justify-content: ${(props) =>
        props.justifyMobileContent
            ? props.justifyMobileContent
            : props.justifyContent
            ? props.justifyContent
            : 'center'};
    flex-basis: ${(props) => (props.flexBasis ? props.flexBasis : '100%')};
    text-align: center;
    ${(props) => (props.alignItems ? `align-items: ${props.alignItems};` : '')}

    border: ${(props) => (props.outline ? '1px solid red' : '')};

    @media ${(props) =>
            !props.customBreakpoint
                ? props.theme.breakpoints.mediaQueries.laptop
                : `(min-width: ${props.customBreakpoint}px)`} {
        flex-direction: ${({ mobileFlexDirection, keepFlexDirection }) =>
            !keepFlexDirection ? (mobileFlexDirection === 'row' ? 'column' : 'row') : mobileFlexDirection};
        justify-content: ${(props) =>
            props.justifyLaptopContent
                ? props.justifyLaptopContent
                : props.justifyContent
                ? props.justifyContent
                : 'center'};
    }

    ${(props) => (props.customDivStyle ? props.customDivStyle : '')}
`;
export interface IStyledPropsShortHand {
    basis?: string;
    axis: 'row' | 'column';
    flip?: undefined | true;
    justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    justifyMobile?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    justifyLaptop?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    align?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    alignMobile?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    alignLaptop?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    css?: string;
    breakpoint?: number;
    ref?: any;
    pathname?: string;
}

export const RFC = styled.div<IStyledPropsShortHand>`
    width: 100%;
    display: flex;
    flex-direction: ${(props) => props.axis};
    flex-wrap: nowrap;
    justify-content: ${(props) =>
        props.justifyMobile ? props.justifyMobile : props.justify ? props.justify : 'center'};
    align-items: ${(props) => (props.alignMobile ? props.alignMobile : props.align ? props.align : 'center')};
    flex-basis: ${(props) => (props.basis ? props.basis : '100%')};
    text-align: center;
    ${(props) => (props.align ? `align-items: ${props.align};` : '')}

    @media ${(props) =>
        !props.breakpoint ? props.theme.breakpoints.mediaQueries.laptop : `(min-width: ${props.breakpoint}px)`} {
        justify-content: ${(props) =>
            props.justifyLaptop ? props.justifyLaptop : props.justify ? props.justify : 'center'};
        align-items: ${(props) => (props.alignLaptop ? props.alignLaptop : props.align ? props.align : 'center')};
        flex-direction: ${({ axis, flip }) => (flip ? (axis === 'row' ? 'column' : 'row') : axis)};
    }

    ${(props) => (props.css ? props.css : '')}
`;
interface IFlexDiv {
    backgroundColor?: string;
    children: any;
    style?: object;
}
export const FlexDiv = ({ children, backgroundColor = 'inherit', style = {} }: IFlexDiv) => {
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                flex: 1,
                overflow: 'auto',
                backgroundColor: backgroundColor,
                ...style,
            }}
        >
            {children}
        </div>
    );
};
