import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../Common/Hooks/reactReduxHooks';

import { ThemeType } from '../Contexts/ThemeGlobalAndCtxt';
import { useTheme } from 'styled-components';
import { useMediaQuery } from '@react-hook/media-query';

import { useHistory, useLocation } from 'react-router-dom';
import { Spacer, StyledDivLine } from '../Common/Components/Spacer';
import { styled } from '../Contexts/ThemeGlobalAndCtxt';
import { RFC } from '../Common/Components/FlexContainers';

import iccLogoPath from '../Assets/ICC_Logo_Vector_Arrows-01.svg';
import iccLogoLongPath from '../Assets/ICC - Lato.svg';
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';
import { ResponsiveFlexContainer } from '../Common/Components/FlexContainers';
import { selectAppData } from '../ReduxSlices/userSlice';
import { toggleHelpPane, selectHelpPane } from '../ReduxSlices/sectionProgressSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faTools } from '@fortawesome/pro-duotone-svg-icons';
import dayjs from 'dayjs';

export const AppHeader = () => {
    const [userOrgDetails, setUserOrgDetails] = useState(false);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const appData = useAppSelector(selectAppData);
    const helpPaneToggled = useAppSelector(selectHelpPane);
    const helpPaneToggledFirst = useRef(false);
    const userOrgDetailsToggledFirst = useRef(false);
    let location = useLocation();
    //@ts-ignore
    const theme: ThemeType = useTheme();
    const laptop = useMediaQuery(theme.breakpoints.mediaQueries.laptop);

    const navHome = () => {
        if (location.pathname !== '/home' && location.pathname !== '/listings') {
            history.push('/home');
        }
    };
    const navListings = () => {
        if (location.pathname !== '/listings') {
            history.push('/listings');
        }
    };
    useEffect(() => {
        if (helpPaneToggled) {
            helpPaneToggledFirst.current = true;
        } else {
            if (helpPaneToggledFirst.current) {
                document.getElementById('helpPane')?.focus();
            }
        }
    }, [helpPaneToggled]);
    useEffect(() => {
        if (userOrgDetails) {
            userOrgDetailsToggledFirst.current = true;
            document.getElementById('orgDetailsReturn')?.focus();
        } else {
            if (userOrgDetailsToggledFirst.current) {
                document.getElementById('orgDetails')?.focus();
            }
        }
    }, [userOrgDetails]);

    return (
        <div style={{ width: '100%' }}>
            <StyledAppBar mobileFlexDirection="row" keepFlexDirection={true}>
                <StyledICCLogoTitle
                    basis="15%"
                    axis="row"
                    justify="flex-start"
                    align="center"
                    onClick={() => navHome()}
                >
                    <StyledIMG alt="Logo" src={iccLogoPath} height={laptop ? 45 : 35} width={laptop ? 45 : 35} />
                    <Spacer direction="horizontal" spaceParam={12} />
                    <STYLED_H1>{appData.appType === 'EAP' ? 'EAP Tool' : 'Advisory Tool'}</STYLED_H1>
                </StyledICCLogoTitle>
                <ResponsiveFlexContainer
                    flexBasis="100%"
                    mobileFlexDirection="row"
                    keepFlexDirection={true}
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    {appData.appType !== 'Advisory' && (
                        <>
                            <div
                                className="fontAwesomeCircleWrapper"
                                tabIndex={0}
                                onClick={() => navListings()}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        navListings();
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faTools} style={{ color: 'inherit' }} />
                            </div>
                            <Spacer spaceParam="medium" direction="horizontal" />
                        </>
                    )}

                    <div
                        className="fontAwesomeCircleWrapper"
                        tabIndex={0}
                        id="helpPane"
                        style={{ padding: '0.7rem 0.7rem 0.6rem 0.7rem' }}
                        onClick={() => dispatch(toggleHelpPane())}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                dispatch(toggleHelpPane());
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faQuestionCircle} style={{ color: 'inherit' }} />
                    </div>

                    <Spacer spaceParam="medium" direction="horizontal" />
                    <div
                        className="fontAwesomeCircleWrapper"
                        tabIndex={0}
                        id="orgDetails"
                        style={{ padding: '0.7rem 0.7rem 0.6rem 0.7rem' }}
                        onClick={() => setUserOrgDetails((prev) => !prev)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                setUserOrgDetails((prev) => !prev);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faUserCircle} style={{ color: 'inherit' }} />
                    </div>
                </ResponsiveFlexContainer>
            </StyledAppBar>
            <StyledBackdrop open={userOrgDetails} onClick={() => setUserOrgDetails((prev) => !prev)} />
            <Slide direction="left" in={userOrgDetails} mountOnEnter unmountOnExit>
                <StyledOrgDetailsPane>
                    <RFC
                        axis="column"
                        align="flex-start"
                        css="display: inline-block; max-width: 100%; overflow-wrap: break-word;"
                    >
                        <RFC axis="row" justify="space-between" align="center" css="width: 100%; max-width: 100%;">
                            <StyledIMG alt="Logo" src={iccLogoLongPath} height={laptop ? 55 : 45} />
                            <div
                                className="fontAwesomeCircleWrapper"
                                tabIndex={0}
                                id="orgDetailsReturn"
                                onClick={() => setUserOrgDetails((prev) => !prev)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        setUserOrgDetails((prev) => !prev);
                                    }
                                }}
                                onBlur={() => {
                                    if (userOrgDetails) {
                                        document.getElementById('orgDetailsReturn')?.focus();
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faUserCircle} style={{ color: 'inherit' }} />
                            </div>
                        </RFC>

                        <StyledDivLine color="light" />
                        <br />

                        <StyledHeaderParagraph>
                            Logged in as:{' '}
                            {appData.returnData && (
                                <>
                                    <b>{appData.returnData['EmailAddress']}</b>
                                </>
                            )}
                        </StyledHeaderParagraph>
                        <Spacer spaceParam="medium" />
                        <StyledHeaderParagraph>
                            Organization:{' '}
                            {appData.returnData && (
                                <>
                                    <b>{appData.returnData['curOrgString']}</b>
                                </>
                            )}
                        </StyledHeaderParagraph>

                        <br />
                        <StyledDivLine color="light" />
                        <br />
                        <Spacer spaceParam="medium" />

                        {appData.returnData && (
                            <StyledOrgDetailsMetaData>
                                <div style={{ textAlign: 'left' }}>{appData.returnData['BuildInformation']}</div>
                                <br />
                                <div style={{ textAlign: 'left' }}>Version {appData.returnData['AppVersion']}</div>
                                <br />
                                <div style={{ textAlign: 'left' }}>
                                    {dayjs(appData.returnData['BuildCreated']).format('DD-MMM-YYYY')}
                                    {dayjs().diff(dayjs(appData.returnData['BuildCreated']), 'days') < 14
                                        ? ` (${dayjs().diff(
                                              dayjs(appData.returnData['BuildCreated']),
                                              'days',
                                          )} days ago)`
                                        : ''}
                                </div>
                            </StyledOrgDetailsMetaData>
                        )}
                    </RFC>
                </StyledOrgDetailsPane>
            </Slide>
        </div>
    );
};

const StyledAppBar = styled(ResponsiveFlexContainer)`
    && {
        top: 0;
        margin: 0;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.white};
        z-index: 5;
        position: relative;
        box-shadow: 0 0 15px 1px ${({ theme }) => theme.colors.blueGray[5]};
        align-items: center;
        padding: 0.5rem;
    }
`;

const StyledICCLogoTitle = styled(RFC)`
    ${({ pathname }) =>
        pathname !== '/home'
            ? `cursor: pointer; &:hover {cursor: pointer;}`
            : 'cursor: default; &:hover {cursor: default;}'}
    min-width: 200px;
    width: fit-content;
    @media ${({ theme }) => theme.breakpoints.mediaQueries.laptop} {
        min-width: 280px;
    }
`;

const STYLED_H1 = styled.h1`
    position: relative;
    text-align: center;
    min-width: fit-content;
`;

const StyledIMG = styled.img`
    position: relative;
    padding: 0;
    margin: 0;
    top: -2px;
`;

const StyledOrgDetailsPane = styled.div`
    position: fixed;
    text-align: center;
    z-index: 7;
    top: 0;
    right: 0;
    min-width: 200px;
    width: 20%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0.5rem;
`;
const StyledOrgDetailsMetaData = styled.div`
    border: solid 1px ${({ theme }) => theme.colors.blue[5]};
    border-radius: 0.5rem;
    font-size: 0.8rem;
    padding: 0.5rem;
`;

const StyledBackdrop = styled(Backdrop)`
    z-index: 6;
    color: #fff;
`;
const StyledHeaderParagraph = styled.div`
    font-size: 0.8rem;
    text-align: left;
`;
