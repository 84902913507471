import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Common/Hooks/reactReduxHooks';
import { selectListings, getListingsFromDB } from '../ReduxSlices/listingsSlice';
import { FlexDiv } from '../Common/Components/FlexContainers';
import { selectAppDataGetStatus, selectAppData, selectAPIGetStatus } from '../ReduxSlices/userSlice';
import { selectIsProcessStarted } from '../ReduxSlices/sectionProgressSlice';

export const Login = () => {
    const dispatch = useAppDispatch();
    const appData = useAppSelector(selectAppData);
    const appDataGetStatus = useAppSelector(selectAppDataGetStatus);
    const listings = useAppSelector(selectListings);
    const APIGetStatus = useAppSelector(selectAPIGetStatus);
    const isProcessStarted = useAppSelector(selectIsProcessStarted);
    let history = useHistory();

    useEffect(() => {
        if (appDataGetStatus === 'succeeded' && appData.appType === 'EAP') {
            if (listings.status === 'idle') {
                dispatch(getListingsFromDB());
            } else if (listings.status === 'succeeded') {
                if (listings.listings.length === 0) {
                    history.push('/whatyouneed');
                } else {
                    history.push('/listings');
                }
            }
        }
    }, [dispatch, listings, history, appData, appDataGetStatus]);

    useEffect(() => {
        if (appDataGetStatus === 'succeeded' && appData.appType === 'Advisory' && APIGetStatus === 'succeeded') {
            if (isProcessStarted) {
                history.push('/advisory/section1');
            } else {
                history.push('/whatyouneed');
            }
        }
    }, [history, appData, appDataGetStatus, APIGetStatus, isProcessStarted]);

    return (
        <FlexDiv>
            {listings.status === 'succeeded'
                ? 'Success'
                : listings.status === 'failed'
                ? 'There was an error! Error: ' + listings.error
                : ''}
        </FlexDiv>
    );
};
