import React from 'react';
import { styled } from '../../Contexts/ThemeGlobalAndCtxt';

interface IProps {
    widthType?: string | number | false;
    minWidth?: string;
    children: any;
}

export const StyledBoxWrapper = ({ children, widthType = false, minWidth = '100%' }: IProps) => {
    return (
        <StyledBorder widthType={widthType} minWidth={minWidth}>
            {children}
        </StyledBorder>
    );
};

const StyledBorder = styled.div<{ widthType: string | number | false; minWidth: string }>`
    padding: 1em;
    width: ${({ widthType }) =>
        typeof widthType === typeof 1
            ? `${widthType}px`
            : typeof widthType === typeof 'string'
            ? widthType
            : 'fit-content'};
    min-width: ${({ minWidth }) => minWidth};
    background-color: ${({ theme }) => theme.colors.white};
    /* border-radius: 0 1.2em 0 1.2em; */
    box-shadow: ${({ theme }) => `2px 2px 6px 2px ${theme.colors.coolGray[5]}`};
    @media ${({ theme }) => theme.breakpoints.mediaQueries.laptop} {
        padding: 2em;
    }
`;
