export const alphaNumericCharacter = (item: string | number) => {
    let alphaNumericArray = [0, '0', 1, '1', 2, '2', 3, '3', 4, '4', 5, '5', 6, '6', 7, '7', 8, '8', 9, '9'];
    if (alphaNumericArray.includes(item)) {
        return true;
    } else {
        return false;
    }
};

export const extractNodeByPath = (baseObj: object, path: Array<string>) => {
    if (path.length === 1) {
        return baseObj[path[0]];
    } else {
        return extractNodeByPath(baseObj[path[0]], path.slice(1));
    }
};

export const escapeSpecialChars = (inputString: string) => {
    return inputString
        .replace(/\\n/g, '\\n')
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f');
};

export const getCurrentTimeRoundedToFifteenMinutes = () => {
    const now = new Date();
    let minute = now.getMinutes();
    let militaryHour = now.getHours();

    minute = Math.round(minute / 15) * 15;

    let minuteString = '';
    if (minute === 60) {
        minuteString = '00';
        militaryHour += 1;
    } else if (minute === 0) {
        minuteString = '00';
    } else {
        minuteString = minute.toLocaleString();
    }

    const identifier = militaryHour > 11 ? 'pm' : 'am';

    let normalizedHour = militaryHour >= 12 ? militaryHour - 12 : militaryHour;

    if (normalizedHour === 0) {
        normalizedHour = 12;
    }
    let normalizedHourString = normalizedHour.toString();

    return `${normalizedHourString}:${minuteString} ${identifier}`;
};
