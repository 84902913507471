import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { styled, ThemeType } from '../Contexts/ThemeGlobalAndCtxt';
import { useTheme } from 'styled-components';

import { useAppSelector, useAppDispatch } from '../Common/Hooks/reactReduxHooks';
import { updateSectionProgress, selectSectionStatuses, setProgressToDB } from '../ReduxSlices/sectionProgressSlice';
import { selectAdvisoryData } from '../ReduxSlices/formsData';
import type { RootState } from '../store';
import { Spacer, HorizontalDivider, StyledDivLine } from '../Common/Components/Spacer';
import { RFC } from '../Common/Components/FlexContainers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEdit } from '@fortawesome/pro-duotone-svg-icons';
import ReactTooltip from 'react-tooltip';
import { selectAppData } from '../ReduxSlices/userSlice';

export const AdvisoryReview = () => {
    //@ts-ignore
    const theme: ThemeType = useTheme();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const currentUrl = useLocation().pathname;
    const sections = useAppSelector((state: RootState) => {
        return state.sectionProgress.sections;
    });
    const sectionIndex = sections.findIndex((section) => section.routerURL === '/' + currentUrl.split('/')[1]);
    const subSectionIndex = sections[sectionIndex].subSections.findIndex(
        (subSection) => subSection.routerURL === currentUrl,
    );

    const sectionStatuses = useAppSelector(selectSectionStatuses);
    const advisoryData = useAppSelector(selectAdvisoryData);
    const appData = useAppSelector(selectAppData);

    return (
        <RFC axis="row">
            <RFCBorder axis="column" justify="flex-start" align="flex-start">
                <RFC axis="row" justify="space-between" css="width: 100%;">
                    <h2 style={{ alignSelf: 'left' }}>Advisory Review</h2>
                    <Spacer spaceParam="large" />
                    {sectionStatuses[0] !== 'completed' ? (
                        <h3 style={{ color: theme.colors.red[6] }}>Incomplete</h3>
                    ) : (
                        <h3 style={{ color: theme.colors.cyan[5] }}>Complete</h3>
                    )}
                </RFC>

                <HorizontalDivider />

                <RFC axis="row" justify="space-between">
                    <h3>{sections[0].subSections[0].subSectionTitle}</h3>

                    <div
                        className="fontAwesomeCircleWrapper"
                        tabIndex={0}
                        style={{ padding: '0.7rem 0.6rem 0.7rem 0.8rem' }}
                        onClick={() => history.push(sections[0].subSections[0].routerURL)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                history.push(sections[0].subSections[0].routerURL);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} style={{ color: 'inherit' }} />
                    </div>
                </RFC>

                <RFC axis="row" justify="flex-start">
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="20%">
                        <span>Advisory for:</span>
                    </RFC>
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="75%">
                        <span>
                            <b>{appData.returnData['issue']}</b>
                        </span>
                    </RFC>
                </RFC>
                <RFC axis="row" justify="flex-start">
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="20%">
                        <span>To Stakeholders:</span>
                    </RFC>
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="75%">
                        <span>
                            <b>{appData.returnData['stakeholder']}</b>
                        </span>
                    </RFC>
                </RFC>
                <RFC axis="row" justify="flex-start">
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="20%">
                        <span>Advisory Start Date:</span>
                    </RFC>
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="75%">
                        <span>
                            <b>{advisoryData.advisoryOverview.startDate}</b>
                        </span>
                    </RFC>
                </RFC>
                <RFC axis="row" justify="flex-start">
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="20%">
                        <span>Advisory End Date:</span>
                    </RFC>
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="75%">
                        <span>
                            <b>{advisoryData.advisoryOverview.endDate}</b>
                        </span>
                    </RFC>
                </RFC>
                <RFC axis="row" justify="flex-start">
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="20%">
                        <span>Overview:</span>
                    </RFC>
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="75%">
                        <span>
                            <b>{advisoryData.advisoryOverview.overview}</b>
                        </span>
                    </RFC>
                </RFC>
                <RFC axis="row" justify="flex-start">
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="20%">
                        <span>Position:</span>
                    </RFC>
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="75%">
                        <span>
                            <b>{advisoryData.advisoryOverview.position}</b>
                        </span>
                    </RFC>
                </RFC>
                <RFC axis="row" justify="flex-start">
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="20%">
                        <span>Guidance:</span>
                    </RFC>
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="75%">
                        <span>
                            <b>{advisoryData.advisoryOverview.guidance}</b>
                        </span>
                    </RFC>
                </RFC>
                <RFC axis="row" justify="flex-start">
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="20%">
                        <span>Form for users to provide updates to issues team?</span>
                    </RFC>
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start" justify="flex-start" basis="75%">
                        <span>
                            <b>{advisoryData.advisoryOverview.updateSubscribe.updateSubscribeChecked ? 'Yes' : 'No'}</b>
                        </span>
                    </RFC>
                </RFC>

                <HorizontalDivider />

                <RFC axis="row" justify="space-between">
                    <h3>{sections[0].subSections[1].subSectionTitle}</h3>
                    <div
                        className="fontAwesomeCircleWrapper"
                        tabIndex={0}
                        style={{ padding: '0.7rem 0.6rem 0.7rem 0.8rem' }}
                        onClick={() => history.push(sections[0].subSections[1].routerURL)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                history.push(sections[0].subSections[1].routerURL);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} style={{ color: 'inherit' }} />
                    </div>
                </RFC>
                <span>
                    Checklist Provided:&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>{advisoryData.advisoryChecklist.checklistProvided ? 'Yes' : 'No'}</b>
                </span>
                <br />
                {advisoryData.advisoryChecklist.checklistProvided && (
                    <>
                        {advisoryData.advisoryChecklist.checklist.allIDs.map((item, index) => {
                            return (
                                <RFC axis="row" justify="flex-start" key={index}>
                                    <Spacer spaceParam="medium" />
                                    <span>
                                        Checklist Item #{index + 1}:&nbsp;&nbsp;&nbsp;&nbsp;
                                        <b>{advisoryData.advisoryChecklist.checklist.byID[item]}</b>
                                        <br />
                                        <br />
                                    </span>
                                </RFC>
                            );
                        })}
                    </>
                )}

                <HorizontalDivider />

                <RFC axis="row" justify="space-between">
                    <h3>{sections[0].subSections[2].subSectionTitle}</h3>
                    <div
                        className="fontAwesomeCircleWrapper"
                        tabIndex={0}
                        style={{ padding: '0.7rem 0.6rem 0.7rem 0.8rem' }}
                        onClick={() => history.push(sections[0].subSections[2].routerURL)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                history.push(sections[0].subSections[2].routerURL);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} style={{ color: 'inherit' }} />
                    </div>
                </RFC>
                <span>Advisory Contact(s):</span>
                <br />
                {advisoryData.advisoryContacts.allIDs.map((item, index) => {
                    return (
                        <RFC axis="row" justify="flex-start" key={index}>
                            <Spacer spaceParam="medium" />
                            <span>
                                Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{advisoryData.advisoryContacts.byID[item].name}</b>
                                <br />
                                Title:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{advisoryData.advisoryContacts.byID[item].title}</b>
                                <br />
                                Phone:&nbsp;&nbsp;&nbsp;&nbsp;<b>{advisoryData.advisoryContacts.byID[item].phone}</b>
                                <br />
                                Email:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{advisoryData.advisoryContacts.byID[item].email}</b>
                                <br />
                                <br />
                            </span>
                        </RFC>
                    );
                })}

                <HorizontalDivider />

                <RFC axis="row" justify="space-between">
                    <h3>{sections[0].subSections[3].subSectionTitle}</h3>
                    <div
                        className="fontAwesomeCircleWrapper"
                        tabIndex={0}
                        style={{ padding: '0.7rem 0.6rem 0.7rem 0.8rem' }}
                        onClick={() => history.push(sections[0].subSections[3].routerURL)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                history.push(sections[0].subSections[3].routerURL);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} style={{ color: 'inherit' }} />
                    </div>
                </RFC>
                <span>Supplementary Attachments(s):</span>
                <br />
                {advisoryData.advisorySupplementalUploads.allIDs.map((item, index) => {
                    return (
                        <RFC axis="row" justify="flex-start" key={index}>
                            <Spacer spaceParam="medium" />
                            <span>
                                Attachment Supplied Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>{advisoryData.advisorySupplementalUploads.byID[item].suppliedName}</b>
                                <br />
                                <br />
                            </span>
                        </RFC>
                    );
                })}

                <HorizontalDivider />

                <Spacer spaceParam="large" />
                {sectionStatuses[0] !== 'completed' ||
                sectionStatuses[1] !== 'completed' ||
                sectionStatuses[2] !== 'completed' ||
                sectionStatuses[3] !== 'completed' ? (
                    <span data-tip="Must complete all sections before completing Review">
                        <RFC axis="row" justify="center">
                            <button disabled>Confirm</button>
                        </RFC>
                    </span>
                ) : (
                    <RFC axis="row" justify="center">
                        <button
                            onClick={() => {
                                dispatch(
                                    updateSectionProgress({
                                        sectionIndex: sectionIndex,
                                        subSectionIndex: subSectionIndex,
                                        status: 'completed',
                                    }),
                                );
                                dispatch(setProgressToDB());
                                history.push('/advisory/build');
                            }}
                        >
                            {sectionStatuses[4] === 'completed' ? 'Reviewed' : 'Confirm'}
                        </button>
                    </RFC>
                )}
                <ReactTooltip
                    backgroundColor={theme.colors.blue[5]}
                    textColor={theme.colors.white}
                    place="top"
                    effect="solid"
                />
                <Spacer spaceParam="large" />
                <Spacer spaceParam="large" />
            </RFCBorder>
        </RFC>
    );
};

const RFCBorder = styled(RFC)`
    max-width: 90%;

    @media ${({ theme }) => theme.breakpoints.mediaQueries.laptop} {
        width: 60%;
        max-width: 60%;
    }
`;

const PDFBorder = styled.div`
     display: block;
    align-items: left;
    width: 100%;
    padding-top: 2rem;
    &&, && * {
        overflow: visible;
    }
    && h1, && h2, && h3, && h4, && h5, && h6 {
        font-family: Inter, Helvetica Neue, Arial, sans-serif;
        color: ${({ theme }) => theme.colors.blueGray[3]};
        margin: 0;
    }

    }
    && span {
        color: ${({ theme }) => theme.colors.blueGray[5]};
        font-size: 0.9rem;
        overflow-wrap: break-word;
        white-space: normal;
        
    }
    && p, && li, && h1, && h2, && h3, && h4, && h5, && h6, && span, && b {
        text-align: left;
        letter-spacing: .08rem;
        break-inside: avoid !important;
        && {display: inline;}
    }

`;
