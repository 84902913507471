import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useAppSelector } from '../Common/Hooks/reactReduxHooks';
import { useSectionsQualityOfLife } from '../Common/Hooks/customHooks';
import { selectAppData, selectUserEmail, setUnSavedFields } from '../ReduxSlices/userSlice';
import {
    addNormalizedData,
    removeNormalizedData,
    setAdvisoryUploadsToDB,
    updateNormalizedData,
    updateFormsDataNode,
    purgeConditionalItems,
    selectAdvisoryData,
    setAdvisoryDataToDB,
    setCachedData,
} from '../ReduxSlices/formsData';
import { selectSectionStatuses, updateSectionProgress } from '../ReduxSlices/sectionProgressSlice';
import { FormWrapper } from '../Common/Components/FormWrapper';
import { Spacer } from '../Common/Components/Spacer';
import { RFC } from '../Common/Components/FlexContainers';
import { SuccessAlert } from '../Common/Components/SuccessAlert';
import { StyledSaveButton } from '../Common/Components/CommonStyled';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons';
import { faAngleDown, faAngleUp, faTimes } from '@fortawesome/pro-light-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import PhoneInput from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input';
// import 'react-phone-number-input/style.css'

import { PublishPlanToDB, selectPublishPostStatus, resetPublishPostStatus } from '../ReduxSlices/userSlice';
import { Autocomplete } from '@mui/material';
import { getCurrentTimeRoundedToFifteenMinutes } from '../Common/utilityFunctions';
import { IndexKind } from 'typescript';
import { isValueXSSSafe } from '../Common/Security/IcoSanitize';

const timeOptions = [
    '12:00 am',
    '12:15 am',
    '12:30 am',
    '12:45 am',
    '1:00 am',
    '1:15 am',
    '1:30 am',
    '1:45 am',
    '2:00 am',
    '2:15 am',
    '2:30 am',
    '2:45 am',
    '3:00 am',
    '3:15 am',
    '3:30 am',
    '3:45 am',
    '4:00 am',
    '4:15 am',
    '4:30 am',
    '4:45 am',
    '5:00 am',
    '5:15 am',
    '5:30 am',
    '5:45 am',
    '6:00 am',
    '6:15 am',
    '6:30 am',
    '6:45 am',
    '7:00 am',
    '7:15 am',
    '7:30 am',
    '7:45 am',
    '8:00 am',
    '8:15 am',
    '8:30 am',
    '8:45 am',
    '9:00 am',
    '9:15 am',
    '9:30 am',
    '9:45 am',
    '10:00 am',
    '10:15 am',
    '10:30 am',
    '10:45 am',
    '11:00 am',
    '11:15 am',
    '11:30 am',
    '11:45 am',
    '12:00 pm',
    '12:15 pm',
    '12:30 pm',
    '12:45 pm',
    '1:00 pm',
    '1:15 pm',
    '1:30 pm',
    '1:45 pm',
    '2:00 pm',
    '2:15 pm',
    '2:30 pm',
    '2:45 pm',
    '3:00 pm',
    '3:15 pm',
    '3:30 pm',
    '3:45 pm',
    '4:00 pm',
    '4:15 pm',
    '4:30 pm',
    '4:45 pm',
    '5:00 pm',
    '5:15 pm',
    '5:30 pm',
    '5:45 pm',
    '6:00 pm',
    '6:15 pm',
    '6:30 pm',
    '6:45 pm',
    '7:00 pm',
    '7:15 pm',
    '7:30 pm',
    '7:45 pm',
    '8:00 pm',
    '8:15 pm',
    '8:30 pm',
    '8:45 pm',
    '9:00 pm',
    '9:15 pm',
    '9:30 pm',
    '9:45 pm',
    '10:00 pm',
    '10:15 pm',
    '10:30 pm',
    '10:45 pm',
    '11:00 pm',
    '11:15 pm',
    '11:30 pm',
    '11:45 pm',
];
interface DateTimeFrame {
    date: string;
    time: string;
}

const HEIGHT_OF_FIVE_OPTIONS = 180;

const getDateAndTimeFromDate = (date: string): DateTimeFrame => {
    const dateDate = date.split(' ')[0].length > 0 ? date.split(' ')[0] : '';
    const dateTime = date.split(' ').length > 1 ? date.split(' ')[1] + ' ' + date.split(' ')[2] : '';

    return {
        date: dateDate,
        time: dateTime,
    };
};

export const AdvisoryOverview = () => {
    const advisoryDataOverview = useAppSelector(selectAdvisoryData).advisoryOverview;
    const appData = useAppSelector(selectAppData);
    const statuses = useAppSelector(selectSectionStatuses);
    const userEmail = useAppSelector(selectUserEmail);

    const {
        sectionIndex,
        subSectionIndex,
        subSectionTitle,
        dispatch,
        apiGetStatus,
        unSavedFields,
        sectionStatus,
        theme,
        apiPostStatus,
        laptop,
    } = useSectionsQualityOfLife();

    const [initialValuesSet, setInitialValuesSet] = useState(false);

    const { register, handleSubmit, setFocus, formState, getValues, setValue, control, watch, clearErrors } = useForm({
        mode: 'onSubmit',
    });
    const inputsWatch = watch();
    const startDate = getDateAndTimeFromDate(advisoryDataOverview.startDate);
    const endDate = getDateAndTimeFromDate(advisoryDataOverview.endDate);

    useEffect(() => {
        const startDateTimeFrame = getDateAndTimeFromDate(advisoryDataOverview.startDate);
        const endDateTimeFrame = getDateAndTimeFromDate(advisoryDataOverview.endDate);
        if (!initialValuesSet && apiGetStatus === 'succeeded') {
            setTimeout(() => {
                // need to make 3 text fields, issue name, then group name, then all three depending on published or not
                if (startDate.date) {
                    setValue('advisoryStartDate', dayjs(startDateTimeFrame.date).toDate());
                }
                if (startDate.time) {
                    setValue('advisoryStartTime', startDateTimeFrame.time);
                }
                if (endDate.date) {
                    setValue('advisoryEndDate', dayjs(endDateTimeFrame.date).toDate());
                }
                if (endDate.time) {
                    setValue('advisoryEndTime', endDateTimeFrame.time);
                }
                setValue('advisoryOverview', advisoryDataOverview.overview);
                setValue('advisoryPosition', advisoryDataOverview.position);
                setValue('advisoryGuidance', advisoryDataOverview.guidance);
                if (advisoryDataOverview.updateSubscribe) {
                    setValue(
                        'advisorySubscribe',
                        advisoryDataOverview.updateSubscribe.updateSubscribeChecked ? 'Yes' : 'No',
                    );
                }
                // setValue('advisorySubscribeRecipient', advisoryDataOverview.updateSubscribe.updateSubscribeRecipient)

                setInitialValuesSet(true);
            }, 0);
        }
    }, [
        apiGetStatus,
        advisoryDataOverview,
        setValue,
        formState.errors,
        statuses,
        appData,
        initialValuesSet,
        startDate,
        endDate,
    ]);

    useEffect(() => {
        const startDate = getDateAndTimeFromDate(advisoryDataOverview.startDate);
        const endDate = getDateAndTimeFromDate(advisoryDataOverview.endDate);

        const advisoryStartDateFormattedInput = inputsWatch[`advisoryStartDate`]
            ? dayjs(inputsWatch[`advisoryStartDate`]).format('DD-MMM-YYYY')
            : '';

        const advisoryStartTimeFormattedInput = inputsWatch[`advisoryStartTime`]
            ? dayjs(inputsWatch[`advisoryStartTime`], 'h:mm a').format('h:mm a')
            : '';

        const advisoryEndDateFormattedInput = inputsWatch[`advisoryEndDate`]
            ? dayjs(inputsWatch[`advisoryEndDate`]).format('DD-MMM-YYYY')
            : '';

        const advisoryEndTimeFormattedInput = inputsWatch[`advisoryEndTime`]
            ? dayjs(inputsWatch[`advisoryEndTime`], 'h:mm a').format('h:mm a')
            : '';

        const advisoryOverViewChanged = inputsWatch['advisoryOverview'] !== advisoryDataOverview.overview;

        const advisoryPositionChanged = inputsWatch['advisoryPosition'] !== advisoryDataOverview.position;

        const advisoryGuidanceChanged = inputsWatch['advisoryGuidance'] !== advisoryDataOverview.guidance;

        const advisorySubscribeChangedAndEqualsYes =
            advisoryDataOverview.updateSubscribe &&
            inputsWatch['advisorySubscribe'] !== undefined &&
            (inputsWatch['advisorySubscribe'] === 'Yes') !==
                advisoryDataOverview.updateSubscribe.updateSubscribeChecked;

        if (initialValuesSet && startDate && endDate) {
            if (
                advisoryStartDateFormattedInput !== startDate.date ||
                advisoryStartTimeFormattedInput !== startDate.time ||
                advisoryEndDateFormattedInput !== endDate.date ||
                advisoryEndTimeFormattedInput !== endDate.time ||
                advisoryOverViewChanged ||
                advisoryPositionChanged ||
                advisoryGuidanceChanged ||
                advisorySubscribeChangedAndEqualsYes
            ) {
                dispatch(setUnSavedFields(true));
            } else {
                dispatch(setUnSavedFields(false));
            }
        }
    }, [inputsWatch, unSavedFields, initialValuesSet, dispatch, advisoryDataOverview, appData]);

    const onSubmit = () => {
        dispatch(
            updateFormsDataNode({
                node: 'advisoryOverview',
                data: {
                    //TODO update the name getValues depending on the statuses
                    startDate:
                        dayjs(getValues('advisoryStartDate')).format('DD-MMM-YYYY') +
                        ' ' +
                        dayjs(getValues('advisoryStartTime'), 'h:mm a').format('h:mm a'),
                    endDate:
                        dayjs(getValues('advisoryEndDate')).format('DD-MMM-YYYY') +
                        ' ' +
                        dayjs(getValues('advisoryEndTime'), 'h:mm a').format('h:mm a'),
                    overview: getValues('advisoryOverview'),
                    position: getValues('advisoryPosition'),
                    guidance: getValues('advisoryGuidance'),
                    updateSubscribe: {
                        updateSubscribeChecked: getValues('advisorySubscribe') === 'Yes',
                        updateSubscribeRecipient: '',
                    },
                },
            }),
        );
        dispatch(
            updateSectionProgress({
                sectionIndex: sectionIndex,
                subSectionIndex: subSectionIndex,
                status: 'completed',
            }),
        );
        dispatch(setAdvisoryDataToDB('advisoryOverview'));
        dispatch(setUnSavedFields(false));
    };

    const PopperMy = (props) => {
        return <Popper {...props} style={{ width: 250 }} placement="bottom-start" />;
    };

    const timeRegularExpression = new RegExp(/\b(0?\d|1[0-2]):[0-5]\d(\s)?([AaPp][Mm]$)/);

    const renderStatusSection = (
        <RFC axis="row" justify="space-between" css="width: 100%;">
            <h2 style={{ alignSelf: 'left' }}>{subSectionTitle}</h2>
            {sectionStatus === 'completed' && (
                <>
                    <Spacer spaceParam="large" />
                    {unSavedFields ? (
                        <h3 style={{ color: theme.colors.red[6] }}>Unsaved Changes</h3>
                    ) : (
                        <h3 style={{ color: theme.colors.cyan[5] }}>Complete</h3>
                    )}
                </>
            )}
        </RFC>
    );

    const renderStartDayDatePicker = (
        <Controller
            name="advisoryStartDate"
            control={control}
            defaultValue={dayjs().toDate()}
            rules={{
                validate: {
                    valid: (value) =>
                        (value !== null && value !== undefined && value.toString() !== 'Invalid Date') ||
                        'Must pick a valid date',
                    minDate: (value) => dayjs(value).isAfter('12/12/1999') || 'Must pick a date after 1999',
                    maxDate: (value) => dayjs(value).isBefore('01/01/2200') || 'Must pick a date before 2200',
                    startBeforeEnd: (value) =>
                        dayjs(value).isBefore(dayjs(getValues('advisoryEndDate'))) ||
                        dayjs(getValues('advisoryEndDate')).format('MM/DD/YYYY') ===
                            dayjs(value).format('MM/DD/YYYY') ||
                        'Start Date must be before End Date',
                },
            }}
            render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        value={value}
                        label="Date"
                        onChange={(e) => {
                            if (
                                formState.errors['advisoryEndDate'] &&
                                formState.errors['advisoryEndDate'].type === 'startBeforeEnd'
                            ) {
                                clearErrors('advisoryEndDate');
                            }

                            onChange(e);
                        }}
                        minDate={dayjs('01/01/2000').toDate()}
                        maxDate={dayjs('12/12/2199').toDate()}
                        renderInput={(params) => <TextField variant="standard" {...params} />}
                    />
                </LocalizationProvider>
            )}
        />
    );

    const renderStartDayTimePicker = (
        <Controller
            name="advisoryStartTime"
            control={control}
            defaultValue={startDate.time ? startDate.time : getCurrentTimeRoundedToFifteenMinutes()}
            rules={{
                validate: {
                    valid: (value) => {
                        return timeRegularExpression.test(value) ? true : 'Invalid Time';
                    },
                    startBeforeEnd: (value) => {
                        const startDatetValueDayjs = dayjs(value, 'h:mm a');

                        const endDateDayjs = dayjs(getValues('advisoryEndTime'), 'h:mm a');
                        const valuesAreSameDay =
                            dayjs(getValues('advisoryStartDate')).format('DD/MMM/YYYY') ===
                            dayjs(getValues('advisoryEndDate')).format('DD/MMM/YYYY');

                        return !valuesAreSameDay || startDatetValueDayjs.isBefore(endDateDayjs)
                            ? true
                            : 'Start time must be before End time';
                    },
                },
            }}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    options={timeOptions}
                    disableClearable
                    popupIcon={false}
                    autoComplete
                    includeInputInList
                    freeSolo
                    ListboxProps={{ style: { maxHeight: `${HEIGHT_OF_FIVE_OPTIONS}px` } }}
                    PopperComponent={PopperMy}
                    sx={{
                        width: '100px',
                    }}
                    value={value}
                    onInputChange={(_, data) => {
                        if (data) onChange(data);
                    }}
                    renderInput={(params) => (
                        <TextField
                            variant="standard"
                            {...params}
                            label={'Time'}
                            fullWidth
                            error={formState.errors['advisoryStartTime']}
                            InputProps={{
                                ...params.InputProps,

                                sx: {
                                    borderBottom: 'none',
                                },
                            }}
                        />
                    )}
                />
            )}
        />
    );

    const renderEndDayDatePicker = (
        <Controller
            name="advisoryEndDate"
            control={control}
            defaultValue={dayjs().add(1, 'day').toDate()}
            rules={{
                validate: {
                    valid: (value) =>
                        (value !== null && value.toString() !== 'Invalid Date') || 'Must pick a valid date',
                    minDate: (value) => dayjs(value).isAfter('12/12/1999') || 'Must pick a date after 1999',
                    maxDate: (value) => dayjs(value).isBefore('01/01/2200') || 'Must pick a date before 2200',
                    startBeforeEnd: (value) =>
                        dayjs(getValues('advisoryStartDate')).isBefore(dayjs(value)) ||
                        dayjs(getValues('advisoryStartDate')).format('MM/DD/YYYY') ===
                            dayjs(value).format('MM/DD/YYYY') ||
                        'Start Date must be before End Date',
                },
            }}
            render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        value={value}
                        label="Date"
                        onChange={(e) => {
                            if (
                                formState.errors['advisoryStartDate'] &&
                                formState.errors['advisoryStartDate'].type === 'startBeforeEnd'
                            ) {
                                clearErrors('advisoryStartDate');
                            }
                            onChange(e);
                        }}
                        minDate={dayjs('01/01/2000').toDate()}
                        maxDate={dayjs('12/12/2199').toDate()}
                        renderInput={(params) => <TextField variant="standard" {...params} />}
                    />
                </LocalizationProvider>
            )}
        />
    );

    const renderEndDayTimePicker = (
        <Controller
            name="advisoryEndTime"
            control={control}
            defaultValue={endDate.time ? endDate.time : getCurrentTimeRoundedToFifteenMinutes()}
            rules={{
                validate: {
                    valid: (value) => {
                        return timeRegularExpression.test(value) ? true : 'Invalid Time';
                    },
                    startBeforeEnd: (value) => {
                        const endDateValueDayjs = dayjs(value, 'h:mm a');
                        const startDateDayjs = dayjs(getValues('advisoryStartTime'), 'h:mm a');
                        const valuesAreSameDay =
                            dayjs(getValues('advisoryStartDate')).format('DD/MMM/YYYY') ===
                            dayjs(getValues('advisoryEndDate')).format('DD/MMM/YYYY');

                        return !valuesAreSameDay || startDateDayjs.isBefore(endDateValueDayjs)
                            ? true
                            : 'Start time must be before End time';
                    },
                },
            }}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    options={timeOptions}
                    disableClearable
                    popupIcon={false}
                    autoComplete
                    includeInputInList
                    freeSolo
                    ListboxProps={{ style: { maxHeight: `${HEIGHT_OF_FIVE_OPTIONS}px` } }}
                    PopperComponent={PopperMy}
                    sx={{
                        width: '100px',
                    }}
                    value={value}
                    onInputChange={(_, data) => {
                        if (data) onChange(data);
                    }}
                    renderInput={(params) => (
                        <TextField
                            variant="standard"
                            {...params}
                            label={'Time'}
                            fullWidth
                            {...register('advisoryEndTime')}
                            error={formState.errors['advisoryEndTime']}
                            InputProps={{
                                ...params.InputProps,

                                sx: {
                                    borderBottom: 'none',
                                },
                            }}
                        />
                    )}
                />
            )}
        />
    );

    const renderAdvisoryOverview = (
        <RFC axis="row" justify="flex-start" className="input_label_wrapper">
            <textarea
                {...register('advisoryOverview', {
                    validate: {
                        maxLength: (value) => value.length < 1000000 || 'Length must be < 1,000,000 characters',
                        isSafe: (value) => {
                            return isValueXSSSafe(value, 'Advisory Overview', userEmail)
                                ? true
                                : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                        },
                    },
                })}
            />
            <label
                htmlFor={'advisoryOverview'}
                className={inputsWatch['advisoryOverview'] ? 'input_label_transitioned' : ''}
                onClick={(e) => setFocus('advisoryOverview')}
            >
                Provide a brief overview for this advisory.
            </label>
        </RFC>
    );

    const renderAdvisoryPosition = (
        <RFC axis="row" justify="flex-start" className="input_label_wrapper">
            <textarea
                {...register('advisoryPosition', {
                    validate: {
                        maxLength: (value) => value.length < 1000000 || 'Length must be < 1,000,000 characters',
                        isSafe: (value) => {
                            return isValueXSSSafe(value, 'Advisory Position', userEmail)
                                ? true
                                : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                        },
                    },
                })}
            />
            <label
                htmlFor={'advisoryPosition'}
                className={inputsWatch['advisoryPosition'] ? 'input_label_transitioned' : ''}
                onClick={(e) => setFocus('advisoryPosition')}
            >
                Provide the latest position statement for this advisory.
            </label>
        </RFC>
    );

    const renderAdvisoryGuidance = (
        <RFC axis="row" justify="flex-start" className="input_label_wrapper">
            <textarea
                {...register('advisoryGuidance', {
                    validate: {
                        maxLength: (value) => value.length < 1000000 || 'Length must be < 1,000,000 characters',
                        isSafe: (value) => {
                            return isValueXSSSafe(value, 'Advisory Guidance', userEmail)
                                ? true
                                : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                        },
                    },
                })}
            />
            <label
                htmlFor={'advisoryGuidance'}
                className={inputsWatch['advisoryGuidance'] ? 'input_label_transitioned' : ''}
                onClick={(e) => setFocus('advisoryGuidance')}
            >
                Provide the latest guidance for this advisory.
            </label>
        </RFC>
    );

    const renderAdvisorySubscribe = (
        <RFC axis="row" justify="flex-start">
            <Spacer spaceParam="large" />
            <RFC axis="column" align="flex-start" className="radioButtonContainer">
                <label>
                    <input {...register('advisorySubscribe')} type="radio" value="Yes" defaultChecked /> Yes
                </label>
                <Spacer spaceParam="small" />
                <label>
                    <input {...register('advisorySubscribe')} type="radio" value="No" /> No
                </label>
            </RFC>
        </RFC>
    );

    const renderAdvisorySubmit = (
        <RFC axis="column" justify="flex-start" align="flex-end" alignLaptop="center">
            {(apiPostStatus === 'succeeded' || laptop) && (
                <RFC
                    axis="row"
                    basis="75%"
                    align="center"
                    alignLaptop="center"
                    justify="flex-end"
                    justifyLaptop="flex-end"
                    css={!laptop ? 'padding-top: 1em;' : ''}
                >
                    <SuccessAlert active={apiPostStatus === 'succeeded'} style={{ alignSelf: 'right' }}>
                        Successfully Saved!
                    </SuccessAlert>
                    <Spacer spaceParam="medium" direction="horizontal" />
                </RFC>
            )}
            <RFC axis="row" justify="flex-end" basis="25%">
                <StyledSaveButton
                    type="submit"
                    postStatus={apiPostStatus}
                    noChanges={!unSavedFields}
                    onClick={(e) => {
                        if (apiPostStatus !== 'idle') {
                            e.preventDefault();
                        }
                    }}
                >
                    Save
                </StyledSaveButton>
            </RFC>
        </RFC>
    );

    const renderUserTimeHelper = (
        <label style={{ fontSize: '0.7rem' }}>
            <i>
                You can type in your time HH:MM am or pm, or use the time picker and select AM or PM first for your
                selection to save properly.
            </i>
        </label>
    );

    const renderErrorsForSection = (
        advisorySection:
            | 'advisoryStartDate'
            | 'advisoryEndDate'
            | 'advisoryStartTime'
            | 'advisoryEndTime'
            | 'advisoryOverview'
            | 'advisoryPosition'
            | 'advisoryGuidance',
    ) => {
        return (
            formState.errors[advisorySection] &&
            formState.errors[advisorySection].type && (
                <>
                    <Spacer direction="vertical" spaceParam="small" />
                    <p>{formState.errors[advisorySection].message}</p>
                    <Spacer direction="vertical" spaceParam="small" />
                </>
            )
        );
    };

    return (
        <RFC axis="column" justify="flex-start" align="center">
            <FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                formWidth="70%"
                formMinWidth="350px"
                formMaxWidth="900px"
                inputWidth="100%"
                status={apiPostStatus}
            >
                {renderStatusSection}

                <Spacer spaceParam="large" />

                <label>When will this advisory go into effect?</label>

                <Spacer spaceParam="medium" />

                <RFC axis="row" justify={'flex-start'} align={'flex-end'}>
                    {renderStartDayDatePicker}
                    <Spacer spaceParam={'large'} />
                    {renderStartDayTimePicker}
                </RFC>
                <Spacer />

                {renderErrorsForSection('advisoryStartDate')}
                {renderErrorsForSection('advisoryStartTime')}
                {renderUserTimeHelper}

                <Spacer spaceParam="large" />
                <Spacer spaceParam="large" />

                <label>When will this advisory be valid until?</label>
                <label style={{ fontSize: '0.7rem' }}>
                    <i>
                        The default duration for an Advisory is set to one day from your current local time. Please
                        adjust as needed.
                    </i>
                </label>
                <Spacer spaceParam="medium" />

                <RFC axis="row" justify={'flex-start'} align={'flex-end'}>
                    {renderEndDayDatePicker}
                    <Spacer spaceParam="large" />
                    {renderEndDayTimePicker}
                </RFC>

                <Spacer />

                {renderErrorsForSection('advisoryEndDate')}
                {renderErrorsForSection('advisoryEndTime')}
                {renderUserTimeHelper}

                <Spacer spaceParam="large" />
                {renderAdvisoryOverview}
                {renderErrorsForSection('advisoryOverview')}

                <Spacer spaceParam="large" />
                {renderAdvisoryPosition}
                {renderErrorsForSection('advisoryPosition')}

                <Spacer spaceParam="large" />
                {renderAdvisoryGuidance}
                {renderErrorsForSection('advisoryGuidance')}

                <Spacer spaceParam="large" />
                <label>Do You Want Users to have a Form to Provide Updates?</label>

                <Spacer spaceParam="medium" />
                {renderAdvisorySubscribe}
                <Spacer spaceParam="medium" />
                <label style={{ fontSize: '0.7rem' }}>
                    <i>
                        Select 'Yes' to include the 'Provide Updates' event in your advisory; this event will allow
                        stakeholders to submit reports to your issue team.
                    </i>
                </label>

                <Spacer direction="vertical" spaceParam="large" />
                {renderAdvisorySubmit}
            </FormWrapper>
            <Spacer direction="vertical" spaceParam="large" />
        </RFC>
    );
};

export const AdvisoryChecklist = () => {
    const checklistData = useAppSelector(selectAdvisoryData).advisoryChecklist;
    const userEmail = useAppSelector(selectUserEmail);
    const {
        sectionIndex,
        subSectionIndex,
        subSectionTitle,
        dispatch,
        apiGetStatus,
        unSavedFields,
        sectionStatus,
        theme,
        apiPostStatus,
        laptop,
    } = useSectionsQualityOfLife();
    const dataCached = useRef({ dataCached: false, reset: true, data: {} });
    const setInitialValues = useRef(false);

    const [currentFocusedChecklistItem, setCurrentFocusedChecklistItem] = useState('');
    const [checklistItemFocusChange, setChecklistItemFocusChange] = useState(false);

    const { register, watch, handleSubmit, setFocus, formState, getValues, setValue, unregister } = useForm({
        mode: 'all',
    });

    const getPreviousItemFocusIndex = () => {
        const currentFocusedId = parseInt(currentFocusedChecklistItem.replace('checklist', ''));
        const idIndexInAllIds = checklistData.checklist.allIDs.findIndex((id) => {
            return id === currentFocusedId;
        });

        if (!idIndexInAllIds) return '';
        return `checklist${checklistData.checklist.allIDs[idIndexInAllIds - 1]}`;
    };

    const inputsWatch = watch();
    useEffect(() => {
        if (!setInitialValues.current && apiGetStatus === 'succeeded') {
            setTimeout(() => {
                setValue('checklistProvided', checklistData.checklistProvided ? 'Yes' : 'No');
                checklistData.checklist.allIDs.forEach((id) => {
                    setValue(`checklist${id}`, checklistData.checklist.byID[id]);
                });
                setInitialValues.current = true;
            }, 0);
        }
    }, [apiGetStatus, checklistData, setValue, formState.errors]);

    useEffect(() => {
        if (checklistItemFocusChange && currentFocusedChecklistItem) {
            const currentFocusedIndex = parseInt(currentFocusedChecklistItem.replace('checklist', ''));
            const idIndexInAllIds = checklistData.checklist.allIDs.findIndex((id) => {
                return id === currentFocusedIndex;
            });
            if (checklistData.checklist.allIDs[idIndexInAllIds + 1]) {
                const newFocus = `checklist${checklistData.checklist.allIDs[idIndexInAllIds + 1]}`;
                setFocus(newFocus);
                setCurrentFocusedChecklistItem(newFocus);
            } else {
                const newFocus = `checklist${checklistData.checklist.allIDs[idIndexInAllIds]}`;
                setFocus(newFocus);
                setCurrentFocusedChecklistItem(newFocus);
            }
            setChecklistItemFocusChange(false);
        }
    }, [dispatch, checklistData, setFocus, checklistItemFocusChange, currentFocusedChecklistItem]);

    useEffect(() => {
        if (currentFocusedChecklistItem) {
            setFocus(currentFocusedChecklistItem);
        }
    }, [currentFocusedChecklistItem, setFocus]);

    useEffect(() => {
        if (!unSavedFields && setInitialValues.current) {
            if (
                inputsWatch['checklistProvided'] !== undefined &&
                (inputsWatch['checklistProvided'] === 'Yes') !== checklistData.checklistProvided
            ) {
                dispatch(setUnSavedFields(true));
            }
            checklistData.checklist.allIDs.forEach((id) => {
                if (
                    inputsWatch[`checklist${id}`] !== undefined &&
                    inputsWatch[`checklist${id}`] !== checklistData.checklist.byID[id]
                ) {
                    dispatch(setUnSavedFields(true));
                }
            });
        }
    }, [inputsWatch, unSavedFields, setInitialValues, dispatch, checklistData]);

    useEffect(() => {
        if (!dataCached.current.dataCached && apiGetStatus === 'succeeded') {
            dataCached.current.dataCached = true;
            dataCached.current.data = checklistData;
        }
    }, [checklistData, apiGetStatus]);

    useEffect(() => {
        return () => {
            if (dataCached.current.reset) {
                dispatch(
                    updateFormsDataNode({
                        node: 'advisoryChecklist',
                        data: dataCached.current.data,
                    }),
                );
            }
        };
    }, [dispatch]);

    const updateNormalizedDataFromIds = () => {
        for (let i = 0; i < checklistData.checklist.allIDs.length; i++) {
            dispatch(
                updateNormalizedData({
                    node: 'Advisory Checklist',
                    targetIndex: i,
                    value: getValues(`checklist${checklistData.checklist.allIDs[i]}`),
                }),
            );
        }
    };

    const onSubmit = () => {
        let checklistUpdate: typeof checklistData = {
            checklistProvided: getValues('checklistProvided') === 'Yes' ? true : false,
            checklist: checklistData.checklist,
        };
        dispatch(updateFormsDataNode({ node: 'advisoryChecklist', data: checklistUpdate }));
        if (inputsWatch['checklistProvided'] === 'Yes') {
            updateNormalizedDataFromIds();
        }
        dispatch(purgeConditionalItems());
        dispatch(
            updateSectionProgress({
                sectionIndex: sectionIndex,
                subSectionIndex: subSectionIndex,
                status: 'completed',
            }),
        );
        dataCached.current.reset = false;
        dispatch(setAdvisoryDataToDB('advisoryChecklist'));
        dispatch(setUnSavedFields(false));
    };

    const checklistItemValidator = {
        required: (value) =>
            inputsWatch['checklistProvided'] === 'No' ||
            (value && value.length > 0) ||
            'Must provide item for checklist',
        maxLength: (value) =>
            inputsWatch['checklistProvided'] === 'No' ||
            value.length === 0 ||
            value.length < 1000 ||
            'Length must be < 1000 characters',
        notBlankSpaces: (value) => {
            let regex = new RegExp(/.*\S.*/);
            let notOnlyBlanks = false;
            if (regex.exec(value)) {
                notOnlyBlanks = true;
            }
            return (
                inputsWatch['checklistProvided'] === 'No' ||
                notOnlyBlanks ||
                value.length === 0 ||
                'Item cannot be only blank spaces'
            );
        },
        isSafe: (value) => {
            return isValueXSSSafe(value, 'Checklist Item', userEmail)
                ? true
                : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
        },
    };

    const ChecklistItems = checklistData.checklist.allIDs.map((id, index) => {
        let checklistItemName = `checklist${checklistData.checklist.allIDs[index]}`;
        const checklistLength = checklistData.checklist.allIDs.length;

        const RemoveChecklistItemButton = checklistLength > 1 && (
            <>
                <div
                    className="fontAwesomeCircleWrapper"
                    tabIndex={0}
                    style={{ padding: '0.7rem 0.85rem' }}
                    onClick={(e) => {
                        dispatch(
                            removeNormalizedData({
                                node: 'Advisory Checklist',
                                targetIndex: index,
                            }),
                        );
                        dispatch(setUnSavedFields(true));
                        unregister(checklistItemName);
                    }}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            dispatch(
                                removeNormalizedData({
                                    node: 'Advisory Checklist',
                                    targetIndex: index,
                                }),
                            );
                            dispatch(setUnSavedFields(true));
                            unregister(checklistItemName);
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faTimes} style={{ color: 'inherit' }} size="1x" />
                </div>
            </>
        );

        const currentFocusedIdIndex = parseInt(currentFocusedChecklistItem.replace('checklist', ''));

        const idIndexInAllIds = checklistData.checklist.allIDs.findIndex((id) => {
            return id === currentFocusedIdIndex;
        });

        return (
            <React.Fragment key={index}>
                <Spacer spaceParam="large" />
                <RFC axis="row" justify="flex-start">
                    <Spacer spaceParam="large" />
                    <RFC axis="column" align="flex-start">
                        <RFC
                            axis="row"
                            justify="flex-start"
                            className={checklistLength > 1 ? 'input_label_wrapper_with_icon' : 'input_label_wrapper'}
                        >
                            <label
                                htmlFor={checklistItemName}
                                className={inputsWatch[checklistItemName] ? 'input_label_transitioned' : ''}
                                onClick={(e) => {
                                    setCurrentFocusedChecklistItem(checklistItemName);
                                    setChecklistItemFocusChange(true);
                                }}
                            >
                                {checklistLength === 1 ? `Checklist Item` : `Checklist Item #${index + 1}`}
                            </label>
                            <input
                                tabIndex={0}
                                {...register(checklistItemName, {
                                    validate: checklistItemValidator,
                                })}
                                onFocus={(e) => {
                                    setCurrentFocusedChecklistItem(checklistItemName);
                                }}
                                onKeyPress={(e) => {
                                    if (!getValues(checklistItemName)) return;
                                    if (e.key === 'Enter' && !checklistData.checklist.allIDs[idIndexInAllIds + 1]) {
                                        e.preventDefault();
                                        dispatch(addNormalizedData({ node: 'Advisory Checklist' }));
                                        setChecklistItemFocusChange(true);
                                        dispatch(setUnSavedFields(true));
                                    } else if (e.key === 'Enter') {
                                        e.preventDefault();
                                        setChecklistItemFocusChange(true);
                                    }
                                }}
                                onClick={(e) => {
                                    setCurrentFocusedChecklistItem(checklistItemName);
                                }}
                                onKeyDown={(e) => {
                                    if (
                                        e.code === 'Backspace' &&
                                        getValues(currentFocusedChecklistItem) === '' &&
                                        checklistData.checklist.allIDs.length > 1
                                    ) {
                                        e.preventDefault();
                                        dispatch(
                                            removeNormalizedData({
                                                node: 'Advisory Checklist',
                                                targetIndex: index,
                                            }),
                                        );
                                        dispatch(setUnSavedFields(true));
                                        setCurrentFocusedChecklistItem(getPreviousItemFocusIndex());
                                        unregister(checklistItemName);
                                    }
                                }}
                            />
                            {RemoveChecklistItemButton}
                        </RFC>
                        {formState.errors[checklistItemName] && formState.errors[checklistItemName].type && (
                            <>
                                <Spacer direction="vertical" spaceParam="small" />
                                <p>{formState.errors[checklistItemName].message}</p>
                                <Spacer direction="vertical" spaceParam="small" />
                            </>
                        )}
                    </RFC>
                    <Spacer spaceParam="large" />
                </RFC>
            </React.Fragment>
        );
    });

    const CompletedSectionText = (
        <RFC axis="row" justify="space-between" css="width: 100%;">
            <h2 style={{ alignSelf: 'left' }}>{subSectionTitle}</h2>
            {sectionStatus === 'completed' && (
                <>
                    <Spacer spaceParam="large" />
                    {unSavedFields ? (
                        <h3 style={{ color: theme.colors.red[6] }}>Unsaved Changes</h3>
                    ) : (
                        <h3 style={{ color: theme.colors.cyan[5] }}>Complete</h3>
                    )}
                </>
            )}
        </RFC>
    );

    const ProvideChecklistRadioButtons = (
        <RFC axis="row" justify="flex-start">
            <Spacer spaceParam="large" />
            <RFC axis="column" align="flex-start" className="radioButtonContainer">
                <label>
                    <input {...register('checklistProvided')} type="radio" value="Yes" defaultChecked /> Yes
                </label>
                <Spacer spaceParam="small" />
                <label>
                    <input {...register('checklistProvided')} type="radio" value="No" /> No
                </label>
            </RFC>
        </RFC>
    );

    const SuccessfullySavedText = (apiPostStatus === 'succeeded' || laptop) && (
        <RFC
            axis="row"
            basis="75%"
            align="center"
            alignLaptop="center"
            justify="flex-end"
            justifyLaptop="flex-end"
            css={!laptop ? 'padding-top: 1em;' : ''}
        >
            <SuccessAlert active={apiPostStatus === 'succeeded'} style={{ alignSelf: 'right' }}>
                Successfully Saved!
            </SuccessAlert>
            <Spacer spaceParam="medium" direction="horizontal" />
        </RFC>
    );

    return (
        <RFC axis="column" justify="flex-start" align="center">
            <FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                formWidth="70%"
                formMinWidth="350px"
                formMaxWidth="900px"
                inputWidth="100%"
                status={apiPostStatus}
            >
                {CompletedSectionText}

                <Spacer spaceParam="large" />
                <label>Do you wish to provide a checklist?</label>
                <Spacer spaceParam="medium" />
                {ProvideChecklistRadioButtons}

                <RFC
                    axis="column"
                    align="flex-start"
                    css={inputsWatch['checklistProvided'] === 'Yes' ? '' : 'display: none;'}
                >
                    {ChecklistItems}
                </RFC>

                <Spacer direction="vertical" spaceParam="large" />
                <button
                    type="button"
                    className="button-secondary"
                    onClick={() => {
                        const finalValueId = checklistData.checklist.allIDs[checklistData.checklist.allIDs.length - 1];
                        const idIndexInAllIds = checklistData.checklist.allIDs.findIndex((id) => {
                            return id === finalValueId;
                        });
                        if (getValues(`checklist${checklistData.checklist.allIDs[idIndexInAllIds]}`)) {
                            dispatch(addNormalizedData({ node: 'Advisory Checklist' }));
                            setChecklistItemFocusChange(true);
                            dispatch(setUnSavedFields(true));
                        }
                    }}
                >
                    Add another item?
                </button>

                <RFC axis="column" justify="flex-start" align="flex-end" alignLaptop="center" css="width: 100%;">
                    {SuccessfullySavedText}
                    <RFC axis="row" justify="flex-end" basis="25%">
                        <StyledSaveButton
                            type="submit"
                            postStatus={apiPostStatus}
                            noChanges={!unSavedFields}
                            onClick={(e) => {
                                if (apiPostStatus !== 'idle') {
                                    e.preventDefault();
                                }
                            }}
                        >
                            Save
                        </StyledSaveButton>
                    </RFC>
                </RFC>
            </FormWrapper>
        </RFC>
    );
};

export const AdvisoryContacts = () => {
    const advisoryDataContacts = useAppSelector(selectAdvisoryData).advisoryContacts;
    const userEmail = useAppSelector(selectUserEmail);
    const {
        sectionIndex,
        subSectionIndex,
        subSectionTitle,
        dispatch,
        apiGetStatus,
        unSavedFields,
        sectionStatus,
        theme,
        apiPostStatus,
        laptop,
    } = useSectionsQualityOfLife();

    const setInitialValues = useRef(false);

    const [listToggles, setListToggles] = useState<Array<boolean>>(
        advisoryDataContacts.allIDs.map((_, index) => {
            if (index === 0) {
                return true;
            } else {
                return false;
            }
        }),
    );
    const deleteRef = useRef<HTMLButtonElement>(null);
    const dataCached = useRef({ dataCached: false, reset: true, data: {} });
    const [removeContactAnchorEl, setRemoveContactAnchorEl] = useState(null);
    const [removeContactPopperToggled, setRemoveContactPopperToggled] = useState(false);
    const [contactRemovalTarget, setContactRemovalTarget] = useState<{
        index: number;
        registeredField1: string;
        registeredField2: string;
        registeredField3: string;
        registeredField4: string;
    } | null>(null);
    const [addedContact, setAddedContact] = useState(false);

    const { register, handleSubmit, setFocus, formState, getValues, setValue, unregister, control, watch } = useForm({
        mode: 'onSubmit',
    });
    const watchInputs = watch();

    useEffect(() => {
        if (!setInitialValues.current && apiGetStatus === 'succeeded') {
            setTimeout(() => {
                advisoryDataContacts.allIDs.forEach((id) => {
                    setValue(`contact${id}name`, advisoryDataContacts.byID[id].name);
                    setValue(`contact${id}title`, advisoryDataContacts.byID[id].title);
                    setValue(`contact${id}phone`, advisoryDataContacts.byID[id].phone);
                    setValue(`contact${id}email`, advisoryDataContacts.byID[id].email);
                });

                setListToggles(
                    advisoryDataContacts.allIDs.map((_, index) => {
                        if (index === 0) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                );
                setInitialValues.current = true;
            }, 0);
        }
    }, [apiGetStatus, advisoryDataContacts, setValue, formState.errors]);

    useEffect(() => {
        if (!unSavedFields && setInitialValues.current) {
            advisoryDataContacts.allIDs.forEach((id) => {
                if (
                    (watchInputs[`contact${id}name`] !== undefined &&
                        watchInputs[`contact${id}name`] !== advisoryDataContacts.byID[id].name) ||
                    (watchInputs[`contact${id}title`] !== undefined &&
                        watchInputs[`contact${id}title`] !== advisoryDataContacts.byID[id].title) ||
                    (watchInputs[`contact${id}phone`] !== undefined &&
                        watchInputs[`contact${id}phone`] !== advisoryDataContacts.byID[id].phone) ||
                    (watchInputs[`contact${id}email`] !== undefined &&
                        watchInputs[`contact${id}email`] !== advisoryDataContacts.byID[id].email)
                ) {
                    dispatch(setUnSavedFields(true));
                }
            });
        }
    }, [watchInputs, unSavedFields, setInitialValues, dispatch, advisoryDataContacts]);

    useEffect(() => {
        if (addedContact) {
            setFocus(`contact${advisoryDataContacts.allIDs[advisoryDataContacts.allIDs.length - 1]}name`);
            setAddedContact(false);
        }
    }, [advisoryDataContacts, setFocus, addedContact, dispatch]);

    useEffect(() => {
        if (!dataCached.current.dataCached && apiGetStatus === 'succeeded') {
            dataCached.current.dataCached = true;
            dataCached.current.data = advisoryDataContacts;
        }
    }, [advisoryDataContacts, apiGetStatus]);

    useEffect(() => {
        return () => {
            if (dataCached.current.reset) {
                dispatch(
                    updateFormsDataNode({
                        node: 'advisoryContacts',
                        data: dataCached.current.data,
                    }),
                );
            }
        };
    }, []);

    useLayoutEffect(() => {
        if (removeContactPopperToggled) {
            //@ts-ignore
            setTimeout(() => deleteRef.current.focus(), 0);
        }
    }, [removeContactPopperToggled]);

    const handleRemoveContactToggle = (event: any) => {
        setRemoveContactAnchorEl(removeContactAnchorEl ? null : event.currentTarget);
        setRemoveContactPopperToggled((prev) => !prev);
    };
    const removeContactAnchorElID = removeContactPopperToggled ? 'transitions-popper' : undefined;

    const onSubmit = (data: any) => {
        for (let i = 0; i < advisoryDataContacts.allIDs.length; i++) {
            dispatch(
                updateNormalizedData({
                    targetIndex: i,
                    node: 'Advisory contacts',
                    value: {
                        name: getValues(`contact${advisoryDataContacts.allIDs[i]}name`),
                        title: getValues(`contact${advisoryDataContacts.allIDs[i]}title`),
                        phone: getValues(`contact${advisoryDataContacts.allIDs[i]}phone`)
                            ? getValues(`contact${advisoryDataContacts.allIDs[i]}phone`)
                            : '',
                        email: getValues(`contact${advisoryDataContacts.allIDs[i]}email`),
                    },
                }),
            );
        }
        dataCached.current.reset = false;
        dispatch(
            updateSectionProgress({
                sectionIndex: sectionIndex,
                subSectionIndex: subSectionIndex,
                status: 'completed',
            }),
        );
        dispatch(setAdvisoryDataToDB('advisoryContacts'));
        dispatch(setUnSavedFields(false));
    };

    let contacts: JSX.Element[] = [];

    //company contact
    for (let i = 0; i < advisoryDataContacts.allIDs.length; i++) {
        let registeredField = `contact${advisoryDataContacts.allIDs[i]}name`;
        let registeredField4 = `contact${advisoryDataContacts.allIDs[i]}title`;
        let registeredField2 = `contact${advisoryDataContacts.allIDs[i]}phone`;
        let registeredField3 = `contact${advisoryDataContacts.allIDs[i]}email`;
        if (i === 0) {
            contacts.push(
                <React.Fragment key={-3}>
                    <Spacer direction="vertical" spaceParam="large" />
                    <Spacer direction="vertical" spaceParam="large" />
                    <h4>Primary Contact (who is the main contact person for this advisory?)</h4>
                </React.Fragment>,
            );
        }
        contacts.push(
            <React.Fragment key={i}>
                <Spacer direction="vertical" spaceParam="medium" />
                <RFC axis="row" justify="space-between">
                    <RFC axis="row" justify="flex-start" basis="60%">
                        <div
                            className="fontAwesomeCircleWrapper"
                            tabIndex={0}
                            style={{ padding: '0.4rem 0.8rem' }}
                            onClick={() => {
                                setListToggles((prev) => {
                                    let newList: Array<boolean> = [];
                                    prev.forEach((item, index) => {
                                        index === i ? newList.push(!item) : newList.push(item);
                                    });
                                    return newList;
                                });
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    setListToggles((prev) => {
                                        let newList: Array<boolean> = [];
                                        prev.forEach((item, index) => {
                                            index === i ? newList.push(!item) : newList.push(item);
                                        });
                                        return newList;
                                    });
                                }
                            }}
                        >
                            <FontAwesomeIcon
                                icon={listToggles[i] ? faAngleDown : faAngleUp}
                                style={{ color: 'inherit' }}
                                size="lg"
                            />
                        </div>

                        <Spacer spaceParam="medium" />

                        <span>
                            {advisoryDataContacts.allIDs.length === 1
                                ? `Contact${
                                      listToggles[i]
                                          ? ''
                                          : ': ' + getValues(`contact${advisoryDataContacts.allIDs[i]}name`)
                                  }`
                                : `Contact #${i + 1}${
                                      listToggles[i]
                                          ? ''
                                          : ': ' + getValues(`contact${advisoryDataContacts.allIDs[i]}name`)
                                  }`}
                        </span>
                    </RFC>
                    {advisoryDataContacts.allIDs.length > 1 && (
                        <>
                            <div
                                className="fontAwesomeCircleWrapper"
                                tabIndex={0}
                                aria-describedby={removeContactAnchorElID}
                                style={{ padding: '0.6rem 0.7rem' }}
                                onClick={(e) => {
                                    handleRemoveContactToggle(e);
                                    setContactRemovalTarget({
                                        index: i,
                                        registeredField1: registeredField,
                                        registeredField2: registeredField2,
                                        registeredField3: registeredField3,
                                        registeredField4: registeredField4,
                                    });
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleRemoveContactToggle(e);
                                        setContactRemovalTarget({
                                            index: i,
                                            registeredField1: registeredField,
                                            registeredField2: registeredField2,
                                            registeredField3: registeredField3,
                                            registeredField4: registeredField4,
                                        });
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'inherit' }} />
                            </div>

                            <Popper
                                id={removeContactAnchorElID}
                                open={removeContactPopperToggled}
                                anchorEl={removeContactAnchorEl}
                                transition
                            >
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={0}>
                                        <RFC
                                            axis="column"
                                            css={`
                                                background: ${theme.colors.blueGray[10]};
                                                padding: 1rem;
                                                width: 100%;
                                                max-width: 300px;
                                                border-radius: 1rem 1rem 1rem 1rem;
                                            `}
                                        >
                                            <h4>Remove Contact</h4>
                                            <Spacer spaceParam="medium" />
                                            <span>
                                                Are you sure you want to remove this contact? This action cannot be
                                                undone.
                                            </span>
                                            <Spacer spaceParam="medium" />
                                            <RFC axis="row" justify="space-around">
                                                <button
                                                    className="button-tertiary"
                                                    onClick={(e) => handleRemoveContactToggle(e)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="button-delete"
                                                    ref={deleteRef}
                                                    onClick={(e) => {
                                                        setTimeout(() => {
                                                            setListToggles((prev) => {
                                                                let value = prev.pop();
                                                                if (value === true) {
                                                                    prev[prev.length - 1] = true;
                                                                }
                                                                return prev;
                                                            });

                                                            if (!contactRemovalTarget) return;

                                                            dispatch(
                                                                removeNormalizedData({
                                                                    node: 'Advisory contacts',
                                                                    targetIndex: contactRemovalTarget.index,
                                                                }),
                                                            );
                                                            dispatch(setUnSavedFields(true));
                                                            //@ts-ignore
                                                            unregister([
                                                                contactRemovalTarget?.registeredField1,
                                                                contactRemovalTarget?.registeredField2,
                                                                contactRemovalTarget?.registeredField3,
                                                            ]);
                                                        }, 350);
                                                        handleRemoveContactToggle(e);
                                                    }}
                                                >
                                                    Confirm
                                                </button>
                                            </RFC>
                                        </RFC>
                                    </Fade>
                                )}
                            </Popper>
                        </>
                    )}
                </RFC>

                <RFC axis="row" justify="flex-start" css={listToggles[i] ? '' : 'display: none;'}>
                    <Spacer spaceParam="large" />
                    <RFC axis="column" basis="100%" align="flex-start">
                        <Spacer spaceParam="large" />
                        <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                            <label
                                htmlFor={registeredField}
                                className={watchInputs[registeredField] ? 'input_label_transitioned' : ''}
                                onClick={(e) => setFocus(registeredField)}
                            >
                                Name
                            </label>
                            <input
                                className="predetermined-size-33"
                                {...register(registeredField, {
                                    validate: {
                                        required: (value) => (value && value.length > 0) || 'Must provide name',
                                        maxLength: (value) =>
                                            value.length === 0 ||
                                            value.length < 1000 ||
                                            'Length must be < 1000 characters',
                                        notBlankSpaces: (value) => {
                                            let regex = new RegExp(/.*\S.*/);
                                            let notOnlyBlanks = false;
                                            if (regex.exec(value)) {
                                                notOnlyBlanks = true;
                                            }
                                            return (
                                                notOnlyBlanks ||
                                                value.length === 0 ||
                                                'Name cannot be only blank spaces'
                                            );
                                        },
                                        isSafe: (value) => {
                                            return isValueXSSSafe(value, 'Company Contact Name', userEmail)
                                                ? true
                                                : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                        },
                                    },
                                })}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        setFocus(registeredField4);
                                    }
                                }}
                            />
                        </RFC>
                        {formState.errors[registeredField] && formState.errors[registeredField].type && (
                            <>
                                <Spacer direction="vertical" spaceParam="small" />
                                <p>{formState.errors[registeredField].message}</p>
                                <Spacer direction="vertical" spaceParam="small" />
                            </>
                        )}

                        <Spacer spaceParam="large" />
                        <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                            <label
                                htmlFor={registeredField4}
                                className={watchInputs[registeredField4] ? 'input_label_transitioned' : ''}
                                onClick={(e) => setFocus(registeredField4)}
                            >
                                Title
                            </label>
                            <input
                                className="predetermined-size-33"
                                {...register(registeredField4, {
                                    validate: {
                                        maxLength: (value) =>
                                            value.length === 0 ||
                                            value.length < 1000 ||
                                            'Length must be < 1000 characters',
                                        notBlankSpaces: (value) => {
                                            let regex = new RegExp(/.*\S.*/);
                                            let notOnlyBlanks = false;
                                            if (regex.exec(value)) {
                                                notOnlyBlanks = true;
                                            }
                                            return (
                                                notOnlyBlanks ||
                                                value.length === 0 ||
                                                'Title cannot be only blank spaces'
                                            );
                                        },
                                        isSafe: (value) => {
                                            return isValueXSSSafe(value, 'Company title', userEmail)
                                                ? true
                                                : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                                        },
                                    },
                                })}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        setFocus(registeredField2);
                                    }
                                }}
                            />
                        </RFC>
                        {formState.errors[registeredField4] && formState.errors[registeredField4].type && (
                            <>
                                <Spacer direction="vertical" spaceParam="small" />
                                <p>{formState.errors[registeredField4].message}</p>
                                <Spacer direction="vertical" spaceParam="small" />
                            </>
                        )}

                        <Spacer spaceParam="large" />
                        <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                            <label
                                htmlFor={registeredField2}
                                className={watchInputs[registeredField2] ? 'input_label_transitioned' : ''}
                            >
                                Phone
                            </label>
                            <Controller
                                name={registeredField2}
                                control={control}
                                rules={{
                                    validate: (value) => {
                                        let valid: boolean = true;
                                        if (value) {
                                            valid = isValidPhoneNumber(value);
                                        }
                                        return valid;
                                    },
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <PhoneInput
                                        defaultCountry="US"
                                        value={value}
                                        onChange={onChange}
                                        id={registeredField2}
                                        className="predetermined-size-33"
                                    />
                                )}
                            />
                        </RFC>
                        {formState.errors[registeredField2] && (
                            <>
                                <Spacer direction="vertical" spaceParam="small" />
                                <p>Must input a valid phone number</p>
                                <Spacer direction="vertical" spaceParam="small" />
                            </>
                        )}

                        <Spacer spaceParam="large" />
                        <RFC axis="row" justify="flex-start" className="input_label_wrapper">
                            <label
                                htmlFor={registeredField3}
                                className={watchInputs[registeredField3] ? 'input_label_transitioned' : ''}
                                onClick={(e) => setFocus(registeredField3)}
                            >
                                Email
                            </label>
                            <input
                                className="predetermined-size-33"
                                {...register(registeredField3, {
                                    pattern:
                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                })}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </RFC>
                        {formState.errors[registeredField3] && formState.errors[registeredField3].type && (
                            <>
                                <Spacer direction="vertical" spaceParam="small" />
                                <p>Email is required</p>
                                <Spacer direction="vertical" spaceParam="small" />
                            </>
                        )}
                    </RFC>

                    <Spacer spaceParam="large" />
                </RFC>

                {i === advisoryDataContacts.allIDs.length - 1 && (
                    <>
                        <Spacer spaceParam="large" direction="vertical" />
                        <button
                            type="button"
                            className="button-secondary"
                            onClick={() => {
                                dispatch(addNormalizedData({ node: 'Advisory contacts' }));
                                dispatch(setUnSavedFields(true));
                                setAddedContact(true);
                                setListToggles((prev) => {
                                    let newList: Array<boolean> = [];
                                    prev.forEach((item) => newList.push(false));
                                    newList.push(true);
                                    return newList;
                                });
                            }}
                        >
                            Add another contact?
                        </button>
                    </>
                )}
            </React.Fragment>,
        );
    }
    return (
        <RFC axis="column" justify="flex-start" align="center">
            <FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                formWidth="70%"
                formMinWidth="350px"
                formMaxWidth="900px"
                inputWidth="100%"
                status={apiPostStatus}
            >
                <RFC axis="row" justify="space-between" css="width: 100%;">
                    <h2 style={{ alignSelf: 'left' }}>{subSectionTitle}</h2>
                    {sectionStatus === 'completed' && (
                        <>
                            <Spacer spaceParam="large" />
                            {unSavedFields ? (
                                <h3 style={{ color: theme.colors.red[6] }}>Unsaved Changes</h3>
                            ) : (
                                <h3 style={{ color: theme.colors.cyan[5] }}>Complete</h3>
                            )}
                        </>
                    )}
                </RFC>

                {contacts}

                <Spacer direction="vertical" spaceParam="large" />
                <RFC axis="column" justify="flex-start" align="flex-end" alignLaptop="center">
                    {(apiPostStatus === 'succeeded' || laptop) && (
                        <RFC
                            axis="row"
                            basis="75%"
                            align="center"
                            alignLaptop="center"
                            justify="flex-end"
                            justifyLaptop="flex-end"
                            css={!laptop ? 'padding-top: 1em;' : ''}
                        >
                            <SuccessAlert active={apiPostStatus === 'succeeded'} style={{ alignSelf: 'right' }}>
                                Successfully Saved!
                            </SuccessAlert>
                            <Spacer spaceParam="medium" direction="horizontal" />
                        </RFC>
                    )}
                    <RFC axis="row" justify="flex-end" basis="25%">
                        <StyledSaveButton
                            type="submit"
                            postStatus={apiPostStatus}
                            noChanges={!unSavedFields}
                            onClick={(e) => {
                                setListToggles((prev) => {
                                    let newList: Array<boolean> = [];
                                    prev.forEach((item) => newList.push(true));
                                    return newList;
                                });
                                if (apiPostStatus !== 'idle') {
                                    e.preventDefault();
                                }
                            }}
                        >
                            Save
                        </StyledSaveButton>
                    </RFC>
                </RFC>
            </FormWrapper>
            <Spacer direction="vertical" spaceParam="large" />
        </RFC>
    );
};

export const AdvisorySupplemental = () => {
    const userEmail = useAppSelector(selectUserEmail);
    const supplementalData = useAppSelector(selectAdvisoryData).advisorySupplementalUploads;
    const {
        sectionIndex,
        subSectionIndex,
        subSectionTitle,
        dispatch,
        apiGetStatus,
        unSavedFields,
        sectionStatus,
        theme,
        apiPostStatus,
        laptop,
    } = useSectionsQualityOfLife();
    const [localAttachmentFiles, setLocalAttachmentFiles] = useState<Array<File | null>>([null]);

    const setInitialValues = useRef(false);

    const deleteRef = useRef<HTMLButtonElement>(null);
    const dataCached = useRef({ dataCached: false, reset: true, data: {} });
    const [removeAttachmentAnchorEl, setRemoveAttachmentAnchorEl] = useState(null);
    const [removeAttachmentPopperToggled, setRemoveAttachmentPopperToggled] = useState(false);
    const [attachmentRemovalTarget, setAttachmentRemovalTarget] = useState<number | null>(null);

    const { register, handleSubmit, setFocus, formState, getValues, setValue, watch, unregister } = useForm({
        mode: 'onSubmit',
    });
    const watchInputs = watch();

    useEffect(() => {
        if (!setInitialValues.current && apiGetStatus === 'succeeded') {
            setTimeout(() => {
                supplementalData.allIDs.forEach((id) => {
                    setValue(`file${id}name`, supplementalData.byID[id].suppliedName);
                });

                setLocalAttachmentFiles(supplementalData.allIDs.map((_) => null));
                setInitialValues.current = true;
            }, 0);
        }
    }, [apiGetStatus, supplementalData, setValue, formState.errors]);

    useEffect(() => {
        if (!unSavedFields && setInitialValues.current) {
            supplementalData.allIDs.forEach((id) => {
                //@ts-ignore
                if (
                    watchInputs[`file${id}name`] !== undefined &&
                    watchInputs[`file${id}name`] !== supplementalData.byID[id].suppliedName
                ) {
                    dispatch(setUnSavedFields(true));
                }
            });
        }
    }, [localAttachmentFiles, watchInputs, unSavedFields, setInitialValues, dispatch, supplementalData]);

    useEffect(() => {
        if (!dataCached.current.dataCached && apiGetStatus === 'succeeded') {
            dataCached.current.dataCached = true;
            dataCached.current.data = supplementalData;
            dispatch(setCachedData(supplementalData));
        }
    }, [dispatch, supplementalData, apiGetStatus]);

    useEffect(() => {
        return () => {
            if (dataCached.current.reset) {
                dispatch(
                    updateFormsDataNode({
                        node: 'advisorySupplementalUploads',
                        data: dataCached.current.data,
                    }),
                );
            }
        };
    }, []);

    useLayoutEffect(() => {
        if (removeAttachmentPopperToggled) {
            //@ts-ignore
            setTimeout(() => deleteRef.current.focus(), 0);
        }
    }, [removeAttachmentPopperToggled]);

    interface FileSubmission {
        name: string;
        file: File | null;
    }

    const onSubmit = async (data: any) => {
        const fileSubmission: FileSubmission[] = supplementalData.allIDs.map((id, index) => {
            return {
                name: getValues(`file${id}name`),
                file: localAttachmentFiles[index] ? localAttachmentFiles[index] : null,
            };
        });
        dispatch(
            updateSectionProgress({
                sectionIndex: sectionIndex,
                subSectionIndex: subSectionIndex,
                status: 'completed',
            }),
        );
        dispatch(
            setAdvisoryUploadsToDB({
                attachmentFiles: fileSubmission.map((submission) => submission.file),
                suppliedNames: fileSubmission.map((submission) => submission.name),
            }),
        );
        dataCached.current.reset = false;

        dispatch(setUnSavedFields(false));
    };

    const handleRemoveAttachmentToggle = (event: any) => {
        setRemoveAttachmentAnchorEl(removeAttachmentAnchorEl ? null : event.currentTarget);
        setRemoveAttachmentPopperToggled((prev) => !prev);
    };

    const renderAttachmentName = (id: number, index: number) => (
        <RFC axis="row" justify="flex-start" basis="50%" className="input_label_wrapper half-width">
            <label
                htmlFor={`file${id}name`}
                className={watchInputs[`file${id}name`] ? 'input_label_transitioned' : ''}
                onClick={(e) => setFocus(`file${id}name`)}
            >
                Attachment Name
            </label>
            <input
                className="predetermined-size-33"
                {...register(`file${id}name`, {
                    validate: {
                        required: (value) =>
                            (value && value.length > 0) ||
                            (supplementalData.allIDs.length === 1 && value.length === 0) ||
                            'Must provide a name',
                        maxLength: (value) => value.length < 1000 || 'Length must be < 1000 characters',
                        needAttachment: (value) =>
                            supplementalData.byID[id].fileName?.length > 0 ||
                            //@ts-ignore
                            (localAttachmentFiles[index] !== null &&
                                //@ts-ignore
                                localAttachmentFiles[index]?.name.length > 0) ||
                            (supplementalData.allIDs.length === 1 && value.length === 0) ||
                            'Must include an attachment in order to save this attachment event.',
                        notBlankSpaces: (value) => {
                            let regex = new RegExp(/.*\S.*/);
                            const isBlankSpaces = regex.exec(value) ? true : false;
                            return isBlankSpaces || value.length === 0 || 'Name cannot be only blank spaces';
                        },
                        isSafe: (value) => {
                            return isValueXSSSafe(value, 'Artifact item name', userEmail)
                                ? true
                                : 'Invalid Input: A possible security violation has been detected and cannot be entered. ';
                        },
                    },
                })}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }
                }}
            />
        </RFC>
    );

    const renderDeleteButton = (index: number) => (
        <div
            className="fontAwesomeCircleWrapper"
            tabIndex={0}
            aria-describedby={removeAttachmentAnchorElID}
            onClick={(e) => {
                handleRemoveAttachmentToggle(e);
                setAttachmentRemovalTarget(index);
            }}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    handleRemoveAttachmentToggle(e);
                    setAttachmentRemovalTarget(index);
                }
            }}
        >
            <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'inherit' }} />
        </div>
    );

    const removeAttachmentAnchorElID = removeAttachmentPopperToggled ? 'transitions-popper' : undefined;

    const renderDeletePopper = (index: number) => (
        <Popper
            id={removeAttachmentAnchorElID}
            open={removeAttachmentPopperToggled}
            anchorEl={removeAttachmentAnchorEl}
            transition
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={0}>
                    <RFC
                        axis="column"
                        css={`
                            background: ${theme.colors.blueGray[10]};
                            padding: 1rem;
                            width: 100%;
                            max-width: 300px;
                            border-radius: 1rem 1rem 1rem 1rem;
                        `}
                    >
                        <h4>Remove Attachment</h4>
                        <Spacer spaceParam="medium" />
                        <span>Are you sure you want to remove this attachment? This action cannot be undone.</span>
                        <Spacer spaceParam="medium" />
                        <RFC axis="row" justify="space-around">
                            <button className="button-tertiary" onClick={(e) => handleRemoveAttachmentToggle(e)}>
                                Cancel
                            </button>
                            <button
                                className="button-delete"
                                ref={deleteRef}
                                onClick={(e) => {
                                    setTimeout(() => {
                                        if (supplementalData.allIDs.length > 1) {
                                            setLocalAttachmentFiles((prev) => {
                                                let newFiles = prev;
                                                newFiles.splice(index, 1);
                                                return newFiles;
                                            });

                                            if (!attachmentRemovalTarget && attachmentRemovalTarget !== 0) return;
                                            dispatch(
                                                removeNormalizedData({
                                                    node: 'Advisory supplemental',
                                                    targetIndex: attachmentRemovalTarget,
                                                }),
                                            );

                                            unregister([`file${supplementalData.allIDs[attachmentRemovalTarget]}name`]);
                                        } else {
                                            setLocalAttachmentFiles([null]);
                                            if (!attachmentRemovalTarget && attachmentRemovalTarget !== 0) return;
                                            dispatch(
                                                updateNormalizedData({
                                                    node: 'Advisory supplemental',
                                                    targetIndex: attachmentRemovalTarget,
                                                    value: {
                                                        fileID: 0,
                                                        fileName: '',
                                                        suppliedName: '',
                                                    },
                                                }),
                                            );
                                            //@ts-ignore
                                            setValue(`file${supplementalData.allIDs[attachmentRemovalTarget]}name`, '');
                                        }

                                        dispatch(setUnSavedFields(true));
                                    }, 100);
                                    handleRemoveAttachmentToggle(e);
                                }}
                            >
                                Confirm
                            </button>
                        </RFC>
                    </RFC>
                </Fade>
            )}
        </Popper>
    );

    const renderFormErrors = (id: number) =>
        formState.errors[`file${id}name`] &&
        formState.errors[`file${id}name`].type && (
            <>
                <Spacer direction="vertical" spaceParam="small" />
                <p>{formState.errors[`file${id}name`].message}</p>
                <Spacer direction="vertical" spaceParam="small" />
            </>
        );

    const renderCurrentUploadedFileById = (id: number) => {
        return (
            supplementalData.byID[id].fileName && (
                <label htmlFor={`supplementalFile${id}`} className="file">
                    Previously uploaded file: &nbsp;&nbsp;
                    <strong>{supplementalData.byID[id].fileName}</strong>
                </label>
            )
        );
    };

    const attachmentElements = supplementalData.allIDs.map((id, index) => (
        <React.Fragment key={index}>
            <Spacer direction="vertical" spaceParam="large" />
            <RFC axis="row" justify="space-between">
                {renderAttachmentName(id, index)}
                <Spacer spaceParam={'large'} />
                <Spacer spaceParam={'small'} />
                {renderDeleteButton(index)}
                {renderDeletePopper(index)}
            </RFC>
            {renderFormErrors(id)}

            <Spacer spaceParam="large" />
            {renderCurrentUploadedFileById(id)}
            <input
                type="file"
                name={`supplementalFile${id}`}
                id={`supplementalFile${id}`}
                accept=".pdf, .doc, .png, .jpg, .jpeg, .gif, .tif, .tiff, .docx, .xls, .xlsx"
                onChange={(e) => {
                    if (e.target && e.target.files && e.target.files[0]) {
                        setLocalAttachmentFiles((prev) => {
                            let newFiles = prev;
                            newFiles[index] = e.target.files ? e.target.files[0] : null;
                            return newFiles;
                        });
                    }
                }}
            />
        </React.Fragment>
    ));

    const renderUnsavedFields = unSavedFields ? (
        <h3 style={{ color: theme.colors.red[6] }}>Unsaved Changes</h3>
    ) : (
        <h3 style={{ color: theme.colors.cyan[5] }}>Complete</h3>
    );

    const renderAddFileButton = () => (
        <>
            <Spacer spaceParam="large" direction="vertical" />
            <button
                type="button"
                className="button-secondary"
                onClick={() => {
                    dispatch(addNormalizedData({ node: 'Advisory supplemental' }));
                    dispatch(setUnSavedFields(true));
                    setLocalAttachmentFiles((prev) => {
                        return [...prev, null];
                    });
                }}
            >
                Add another file?
            </button>
        </>
    );
    return (
        <RFC axis="column" justify="flex-start" align="center">
            <FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                formWidth="70%"
                formMinWidth="350px"
                formMaxWidth="900px"
                inputWidth="100%"
                status={apiPostStatus}
            >
                <RFC axis="row" justify="space-between" css="width: 100%;">
                    <h2 style={{ alignSelf: 'left' }}>{subSectionTitle}</h2>
                    {sectionStatus === 'completed' && (
                        <>
                            <Spacer spaceParam="large" />
                            {renderUnsavedFields}
                        </>
                    )}
                </RFC>

                {attachmentElements}
                {supplementalData.allIDs.length < 5 && renderAddFileButton()}

                <Spacer direction="vertical" spaceParam="large" />
                <RFC axis="column" justify="flex-start" align="flex-end" alignLaptop="center" css="width: 100%;">
                    {(apiPostStatus === 'succeeded' || laptop) && (
                        <RFC
                            axis="row"
                            basis="75%"
                            align="center"
                            alignLaptop="center"
                            justify="flex-end"
                            justifyLaptop="flex-end"
                            css={!laptop ? 'padding-top: 1em;' : ''}
                        >
                            <SuccessAlert active={apiPostStatus === 'succeeded'} style={{ alignSelf: 'right' }}>
                                Successfully Saved!
                            </SuccessAlert>
                            <Spacer spaceParam="medium" direction="horizontal" />
                        </RFC>
                    )}
                    <RFC axis="row" justify="flex-end" basis="25%">
                        <StyledSaveButton
                            type="submit"
                            postStatus={apiPostStatus}
                            noChanges={!unSavedFields}
                            onClick={(e) => {
                                if (apiPostStatus !== 'idle') {
                                    e.preventDefault();
                                }
                            }}
                        >
                            Save
                        </StyledSaveButton>
                    </RFC>
                </RFC>
            </FormWrapper>
        </RFC>
    );
};

export const AdvisoryBuild = () => {
    const publishPostStatus = useAppSelector(selectPublishPostStatus);
    const userEmail = useAppSelector(selectUserEmail);
    const {
        sectionIndex,
        subSectionIndex,
        subSectionTitle,
        dispatch,
        sectionStatuses,
        theme,
        apiPostStatus,
        timerID,
        history,
        sections,
    } = useSectionsQualityOfLife();

    const { handleSubmit } = useForm({ mode: 'onSubmit' });

    const publishedRef = useRef(false);

    useEffect(() => {
        if (apiPostStatus === 'succeeded' && !publishedRef.current) {
            dispatch(PublishPlanToDB());
            publishedRef.current = true;
        }
        if (timerID) {
            clearTimeout(timerID);
        }
    }, [dispatch, apiPostStatus, timerID, publishPostStatus]);

    useEffect(() => {
        let nextUrl: string = '';
        if (sections[sectionIndex].subSections[subSectionIndex].nextURL) {
            //@ts-ignore
            nextUrl = sections[sectionIndex].subSections[subSectionIndex].nextURL;
        } else {
            nextUrl = '/home';
        }
        if (publishPostStatus === 'succeeded') {
            setTimeout(() => {
                history.push(nextUrl);
            }, 1000);
        }
    }, [publishPostStatus, dispatch, history, sections, sectionIndex, subSectionIndex]);

    useEffect(() => {
        return () => {
            setTimeout(() => {
                dispatch(resetPublishPostStatus());
            }, 1000);
        };
    }, [dispatch]);

    const onSubmit = async (data: any) => {
        dispatch(
            updateSectionProgress({
                sectionIndex: sectionIndex,
                subSectionIndex: subSectionIndex,
                status: 'completed',
            }),
        );
        dispatch(setAdvisoryDataToDB('all'));
    };

    return (
        <RFC axis="column" justify="flex-start" align="center">
            {sectionStatuses[3] === 'completed' && publishPostStatus === 'succeeded' && (
                <>
                    <h2 style={{ color: theme.colors.cyan[5] }}>Your playbook has been successfully created!</h2>
                    <Spacer spaceParam="large" />
                </>
            )}
            {sectionStatuses[3] === 'completed' && publishPostStatus === 'failed' && (
                <>
                    <h2 style={{ color: theme.colors.red[7] }}>There was an error building your playbook!</h2>
                    <Spacer spaceParam="large" />
                </>
            )}
            <FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                formWidth="60%"
                formMinWidth="350px"
                formMaxWidth="700px"
                inputWidth="100%"
                status={publishPostStatus}
            >
                <RFC axis="row" justify="space-between" css="width: 100%;">
                    <h2 style={{ alignSelf: 'left' }}>{subSectionTitle}</h2>
                </RFC>
                <Spacer spaceParam="large" />
                <h4>Are you ready to build this Advisory?</h4>

                <Spacer spaceParam="large" />
                <h4>Next Steps</h4>
                <span>
                    Your advisory playbook will now be sent to the In Case of Crisis Online Portal to be published for
                    download and it will also be assigned to issue stakeholders.
                </span>
                <br />
                <h4>To make changes to your advisory playbook in the Advisory Tool</h4>
                <span>
                    If you would like to make further updates to your advisory playbook, please return to the
                    corresponding issue and open the Stakeholder Details panel and navigate to the Actions tab. From
                    there you will be able to revisit the Advisory Tool, make edits to any of your selections, data, and
                    files, and then regenerate your advisory playbook.
                </span>
                <br />
                <span>
                    Please note that if you make edits to your advisory playbook from within the Online Portal, those
                    edits will be overwritten once the playbook is regenerated using the Advisory Tool for the Issue.
                </span>
                <br />
                <h4>Questions?</h4>
                <span>
                    Contact your dedicated Client Success Manager or email{' '}
                    <a href="mailto: support@incaseofcrisis.com">support@incaseofcrisis.com</a>
                </span>

                <Spacer direction="vertical" spaceParam="large" />
                <RFC axis="row" justify="center" basis="100%">
                    <StyledSaveButton
                        large
                        type="submit"
                        disabled={apiPostStatus !== 'idle' ? true : false}
                        postStatus={apiPostStatus}
                        onClick={(e) => {
                            if (apiPostStatus !== 'idle') {
                                e.preventDefault();
                            }
                        }}
                    >
                        {sectionStatuses[5] === 'completed' ? 'Regenerate' : 'Create'}
                    </StyledSaveButton>
                </RFC>
            </FormWrapper>
        </RFC>
    );
};
